import { OrderedSet } from "immutable";

// Statuses
export const ALERTED = "alerted";
export const DELETED = "deleted";
export const DISMISSED = "dismissed";

export const AlertStatuses = OrderedSet.of(ALERTED, DISMISSED, DELETED);

// Types
export const GPS_OFF = "gps_off";
export const WRONG_ROUTE = "wrong_route";
export const LATE_ATTEMPT = "late_attempt";
export const MULTIPLE_DEVICES = "multiple_devices";
export const NO_DATA_INTERNET = "no_data_internet";
export const UNDELIVERED_PARCEL = "undelivered_parcel";
export const WRONG_ORDER_SEQUENCE = "wrong_order_sequence";
export const FREQUENT_STATUS_UPDATE = "frequent_status_update";

export const AlertTypes = OrderedSet.of(
  GPS_OFF,
  WRONG_ROUTE,
  LATE_ATTEMPT,
  MULTIPLE_DEVICES,
  NO_DATA_INTERNET,
  UNDELIVERED_PARCEL,
  WRONG_ORDER_SEQUENCE,
  FREQUENT_STATUS_UPDATE,
);

// Colors
export const PRIMARY = "primary";
export const CONTRAST = "contrast";
