import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, withHandlers, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Paper, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Search, Menu } from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMarketplace } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/UpperOverallStatus";

const enhancer = compose(
  useSheet({
    root: { width: "500px" },
    visible: { display: "flex" },
    invisible: { display: "none" },
    search: { marginRight: "12px" },
    textField: {
      fontFamily: "Blogger Sans",
      "& > div:first-child": {
        bottom: 11,
      },
    },
    groupStatistics: {
      position: "relative",
      cursor: "pointer",
      "& > div:not(:last-child)": {
        fontFamily: "Blogger Sans",
        fontSize: "20px",
        padding: "0 12px",
        width: "70px",
        display: "inline",
        textAlign: "center",
        borderLeft: "2px solid #ccc",
      },
    },
    drawer: {
      "& > div": {
        top: 0,
      },
    },
    inactivePage: {
      transform: "translate3d(16px, 0, 0)",
    },
    activePage: {
      transform: "translate3d(86px, 0, 0)",
    },
    hasOrdersPage: {
      transform: "translate3d(156px, 0, 0)",
    },
    hasAlertsPage: {
      transform: "translate3d(226px, 0, 0)",
    },
    inactiveStatistic: {
      color: "#9E9E9E",
    },
    activeStatistic: {
      color: "#8BC34A",
    },
    hasOrdersStatistic: {
      color: "#2196F3",
    },
    hasAlertsStatistic: {
      color: "#D32F2F",
    },
    hiddenArrow: {
      display: "none",
    },
    arrow: {
      zIndex: 1,
      top: "60px",
      width: 0,
      height: 0,
      position: "absolute",
      border: "20px solid transparent",
      borderBottomColor: "white",
      borderTop: 0,
      transition: ".7s ease",
    },
  }),
  withState("state", "setState", { search: false, showMenu: false }),
  reduxForm({ form: "DriversRadarSearch" }),
  formValues("search"),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    marketplace: getMarketplace(state),
  })),
  withHandlers({
    handleSubmit: props => event => {
      props.handleSubmit(event);
      props.change("search", null);
      props.setState(fp.set("search", false));
    },
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = propsStream
      .distinctUntilKeyChanged("state", isEqualData)
      .do(props => {
        if (props.state.search && props.filter.getSearch()) {
          props.change("search", props.filter.getSearch());
        }
      })
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, props => ({
        ...props,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(fp.pick(["state", "filter", "search", "groupStatistics"])),
);

DriversRadarSearch.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  handleSubmit: PropTypes.func,
  setState: PropTypes.func,
  search: PropTypes.string,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  onChangePage: PropTypes.func,
  groupStatistics: PropTypes.instanceOf(Immutable.Map),
  marketplace: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func,
  menuLayoutDrawer: PropTypes.node.isRequired,
};

function DriversRadarSearch(props) {
  const { state, classes, filter, getLocalisationMessage } = props;

  return (
    <FlexBox container={16} className={classes.root}>
      <FlexBox gutter={8} flex={true} element={<Paper />}>
        {props.menuLayoutDrawer({
          visibleMenu: state.showMenu,
          toggleMenu: () => props.setState(fp.update("showMenu", x => !x)),
        })}

        <FlexBox align="center">
          <IconButton
            onClick={() => props.setState(fp.update("showMenu", x => !x))}
          >
            <Menu />
          </IconButton>
        </FlexBox>

        <FlexBox flex={true} className={classes.search} align="center">
          <FlexBox
            flex={true}
            gutter={8}
            element={<form autoComplete="off" onSubmit={props.handleSubmit} />}
          >
            <FlexBox flex={true}>
              <FormTextField
                fullWidth={true}
                className={classes.textField}
                underlineStyle={{ display: "none" }}
                onFocus={() => props.setState(fp.set("search", true))}
                onBlur={() => {
                  if (!props.search) {
                    props.setState(fp.set("search", false));
                  }
                }}
                name="search"
                hintText={
                  state.search
                    ? getLocalisationMessage(
                        "search_for_a_driver_or_courier_company",
                        "Search for a Driver or Courier Company",
                      )
                    : filter.getValue("search")
                    ? filter.getValue("search")
                    : `${getLocalisationMessage("search", "Search")}...`
                }
              />
            </FlexBox>

            {state.search && (
              <FlexBox>
                <IconButton disabled={!props.search} type="submit">
                  <Search />
                </IconButton>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>

        <FlexBox
          className={cx(classes.groupStatistics, {
            [classes.visible]: !state.search,
            [classes.invisible]: state.search,
          })}
          align="center"
        >
          <FlexBox
            flex={true}
            className={classes.inactiveStatistic}
            onClick={() =>
              props.onChangePage(
                filter.setValueMap({
                  status: INACTIVE,
                  current_orders: false,
                  current_alerts: false,
                }),
              )
            }
          >
            {props.groupStatistics.get("all_in_active") || 0}
          </FlexBox>

          <FlexBox
            flex={true}
            className={classes.activeStatistic}
            onClick={() =>
              props.onChangePage(
                filter.setValueMap({
                  status: ACTIVE,
                  current_orders: true,
                  current_alerts: false,
                }),
              )
            }
          >
            {props.groupStatistics.get("all_active") || 0}
          </FlexBox>

          <FlexBox
            flex={true}
            className={classes.hasOrdersStatistic}
            onClick={() =>
              props.onChangePage(
                filter.setValueMap({
                  status: ACTIVE,
                  current_orders: false,
                  current_alerts: false,
                }),
              )
            }
          >
            {props.groupStatistics.get("active_has_orders") || 0}
          </FlexBox>

          <FlexBox
            flex={true}
            className={classes.hasAlertsStatistic}
            onClick={() =>
              props.onChangePage(
                filter.setValueMap({
                  status: ACTIVE,
                  current_alerts: true,
                  current_orders: false,
                }),
              )
            }
          >
            {props.groupStatistics.get("has_alerts") || 0}
          </FlexBox>

          <div
            className={cx(classes.arrow, {
              [classes.inactivePage]:
                filter.getValue("status") === INACTIVE &&
                filter.getValue("current_orders") &&
                !filter.getBoolValue("current_orders") &&
                filter.getValue("current_alerts") &&
                !filter.getBoolValue("current_alerts"),
              [classes.activePage]:
                filter.getValue("status") === ACTIVE &&
                filter.getValue("current_orders") &&
                filter.getBoolValue("current_orders") &&
                filter.getValue("current_alerts") &&
                !filter.getBoolValue("current_alerts"),
              [classes.hasOrdersPage]:
                filter.getValue("status") === ACTIVE &&
                filter.getValue("current_orders") &&
                !filter.getBoolValue("current_orders") &&
                filter.getValue("current_alerts") &&
                !filter.getBoolValue("current_alerts"),
              [classes.hasAlertsPage]:
                filter.getValue("status") === ACTIVE &&
                filter.getValue("current_orders") &&
                !filter.getBoolValue("current_orders") &&
                filter.getValue("current_alerts") &&
                filter.getBoolValue("current_alerts"),
              [classes.hiddenArrow]:
                !filter.getValue("status") ||
                !filter.getValue("current_orders") ||
                !filter.getValue("current_alerts"),
            })}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriversRadarSearch);
