import React from "react";
import { differenceInDays } from "date-fns";
import { Map, List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { BOLD, DANGER } from "../ui-core/Text";
import LinkButton from "../ui-core/LinkButton";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import MultiLineCell from "../data-list/MultiLineCell";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatWeight, extractDigitArray } from "../../helpers/FormatUtils";
import {
  orderStatusColor,
  formatOrderStatusCodeForLocalisation,
} from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  COD_PENDING,
  COD_COLLECTED,
  COD_TO_BE_COLLECTED,
} from "../../constants/CODStatus";
import { ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import TextWithLink from "../../../client/components/router/TextWithLink";
import NameAndAvatar from "../../../client/components/avatars/AvatarWithName";
import CodIcon from "../order-job/assets/cod_icon.svg";
import WeightIcon from "../order-job/assets/weight_icon.svg";
import DetailsIcon from "../order-job/assets/details_icon.svg";
import ServiceChargeIcon from "../order-job/assets/service_charge_icon.svg";
import { hasRole } from "../../helpers/RoleUtils";

export const SELECTION_PER_PAGE = "per-page";
export const SELECTION_ALL = "all";

const enhancer = compose(
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      showErrorMessage,
      showSuccessMessage,
      statusMessages: getStatusLocalisation(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
    };
  }),
  useSheet({
    hoverable: { cursor: "pointer", textDecoration: "underline" },
    actionColumn: { paddingLeft: 0, justifyContent: "center" },
    dataListFooter: {
      position: "absolute",
      width: "100%",
      bottom: "0",
      right: "0",
      lineHeight: "64px",
      textAlign: "left",
      paddingRight: "15px",
    },
    avatar: { marginLeft: "20px" },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .pluck("list")
      .distinctUntilChanged(isEqualData)
      .map(list =>
        Map().withMutations(items => {
          list.forEach(item => {
            const pickup = item.getIn(["locations", 0]);
            const dropoff = item.getIn(["locations", 1]);

            items.updateIn(["pickup", pickup], 0, fp.add(1));
            items.updateIn(["dropoff", dropoff], 0, fp.add(1));
          });

          items.update("pickup", Map(), fp.get("size"));
          items.update("dropoff", Map(), fp.get("size"));
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
);

const isOutdated = fp.memoize(
  value => differenceInDays(new Date(), safeParseDate(value)) > 3,
);

const isOrderOutdated = order =>
  (order.get("cod_status") === COD_PENDING ||
    order.get("cod_status") === COD_COLLECTED ||
    order.get("cod_status") === COD_TO_BE_COLLECTED) &&
  isOutdated(order.get("last_cod_status_date"));

JobOrderList.propTypes = {
  sheet: PropTypes.object,
  altHeader: PropTypes.node,
  cardActionIcons: PropTypes.node,
  withCreatedTime: PropTypes.bool,
  withFooter: PropTypes.bool,
  createOrderHref: PropTypes.func,
  onStatusClick: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  maxSearchItems: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  onFilterChange: PropTypes.func,
  rowActionIconRenderer: PropTypes.func,
  selectionType: PropTypes.oneOf([SELECTION_ALL, SELECTION_PER_PAGE]),
  withAmount: PropTypes.bool,
  withCODAmount: PropTypes.bool,
  chipTextHint: PropTypes.string,
  setLocationQuery: PropTypes.func,
  onRequestClose: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  statusMessages: PropTypes.instanceOf(Map),
};

JobOrderList.defaultProps = {
  withAmount: true,
  withCODAmount: false,
  withCreatedTime: true,
  withFooter: true,
  createOrderHref: fp.noop,
  maxSearchItems: Infinity,
  selectionType: SELECTION_PER_PAGE,
};

function JobOrderList(props) {
  const {
    sheet: { classes },
    getLocalisationMessage,
  } = props;

  const idNumberMap = props.list
    .toOrderedMap()
    .mapEntries(([, v]) => [v.get("id"), v.get("order_number")]);

  const orderNumbersArray = idNumberMap.toArray();

  return (
    <DataList
      filter={props.filter}
      chipTextHint={props.chipTextHint}
      searchTypes={props.searchTypes}
      maxSearchItems={props.maxSearchItems}
      onFilterChange={props.onFilterChange}
      altHeader={props.altHeader}
      selectedRowCount={props.selectedItems ? props.selectedItems.size : 0}
      cardActionIcons={props.cardActionIcons}
      totalCount={props.totalCount}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      overscanRowCount={10}
      withFooter={props.withFooter}
      rowGetter={options => props.list.get(options.index)}
      footerContent={
        !props.list.isEmpty() && (
          <div className={classes.dataListFooter}>
            {props.selectedItems.size > 0 && (
              <Button
                href={updateQuery(ORDER_LIST_URL, {
                  search: props.selectedItems.join(","),
                })}
                primary={true}
              >
                {getLocalisationMessage("show_selected_in_order_list")}
              </Button>
            )}
            <Button
              href={updateQuery(ORDER_LIST_URL, {
                search: orderNumbersArray.join(","),
              })}
              primary={true}
            >
              {getLocalisationMessage("show_all_in_order_list")}
            </Button>
            <Button primary={true} onClick={props.onRequestClose}>
              {getLocalisationMessage("close")}
            </Button>
          </div>
        )
      }
    >
      {props.selectedItems && (
        <DataListCheckbox
          allRowsSelected={() => {
            if (props.list.size > 0) {
              switch (props.selectionType) {
                case SELECTION_ALL:
                  return props.selectedItems.isSuperset(idNumberMap);
                case SELECTION_PER_PAGE:
                  return props.selectedItems.size === props.list.size;
                // no default
              }
            }

            return false;
          }}
          onAllRowsSelect={value => {
            switch (props.selectionType) {
              case SELECTION_ALL:
                return props.onRowSelect(
                  value
                    ? props.selectedItems.merge(idNumberMap)
                    : props.selectedItems.filter(
                        (n, id) => !idNumberMap.has(id),
                      ),
                );
              case SELECTION_PER_PAGE:
                return props.onRowSelect(value ? idNumberMap : OrderedMap());

              default:
                return null;
            }
          }}
          rowSelected={row => props.selectedItems.has(row.cellData.get("id"))}
          onRowSelect={row => {
            const id = row.cellData.get("id");
            const orderNumber = row.cellData.get("order_number");

            switch (props.selectionType) {
              case SELECTION_ALL:
                return props.onRowSelect(
                  row.selected
                    ? props.selectedItems.set(id, orderNumber)
                    : props.selectedItems.delete(id),
                );

              case SELECTION_PER_PAGE: {
                const selectedItems = row.selected
                  ? props.selectedItems.set(id, orderNumber)
                  : props.selectedItems.delete(id);

                const sortedSelectedItems =
                  selectedItems.size > 1
                    ? idNumberMap.filter((v, k) => selectedItems.has(k))
                    : selectedItems;

                return props.onRowSelect(sortedSelectedItems);
              }

              default:
                return null;
            }
          }}
        />
      )}

      <DataListColumn
        width={130}
        label={getLocalisationMessage("order_id")}
        dataKey="order_number"
        cellRenderer={row => (
          <MultiLineCell
            firstLine={
              <TextWithLink to={props.createOrderHref(row.cellData.get("id"))}>
                {row.cellData.get("order_number")}
              </TextWithLink>
            }
          />
        )}
      />

      <DataListColumn
        width={144}
        label={getLocalisationMessage("status")}
        dataKey="status"
        cellRenderer={row => (
          <MultiLineCell
            firstLine={
              <LinkButton
                onClick={
                  props.onStatusClick &&
                  (() => props.onStatusClick(row.cellData))
                }
                style={{
                  color: orderStatusColor(row.cellData.get("status")),
                  fontWeight: "bold",
                }}
              >
                {formatOrderStatusCodeForLocalisation(
                  row.cellData.get("status"),
                  getLocalisationMessage,
                )}
              </LinkButton>
            }
          />
        )}
      />

      {props.withCreatedTime && (
        <DataListColumn
          width={80}
          label={getLocalisationMessage("created_time")}
          dataKey="created_date"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      )}

      {props.withAmount && (
        <DataListColumn
          width={130}
          dataKey="amount"
          disableSort={true}
          label={getLocalisationMessage("service_charge")}
          cellRenderer={row => (
            <NameAndAvatar
              imagePath={ServiceChargeIcon}
              classes={classes.avatar}
            >
              <MultiLineCell
                firstLine={
                  <PriceWrapper
                    price={row.cellData.get("amount")}
                    code={row.cellData.getIn(["currency", "code"])}
                    type={isOrderOutdated(row.cellData) ? [DANGER, BOLD] : []}
                  />
                }
              />
            </NameAndAvatar>
          )}
        />
      )}

      <DataListColumn
        width={158}
        disableSort={true}
        label={getLocalisationMessage("weight")}
        dataKey="size"
        cellRenderer={row => (
          <NameAndAvatar imagePath={WeightIcon} classes={classes.avatar}>
            <div>
              <div>
                {formatWeight(
                  extractDigitArray(
                    row.cellData.getIn(["package", "menu", "name"]),
                  ),
                )}
              </div>
            </div>
          </NameAndAvatar>
        )}
      />

      {props.withCODAmount && (
        <DataListColumn
          width={80}
          disableSort={true}
          label={getLocalisationMessage("collectible")}
          dataKey="cod_charge"
          cellRenderer={row => (
            <NameAndAvatar imagePath={CodIcon} classes={classes.avatar}>
              <PriceWrapper
                price={row.cellData.get("parcel_value")}
                code={row.cellData.getIn(["currency", "code"])}
              />
            </NameAndAvatar>
          )}
        />
      )}

      <DataListColumn
        width={80}
        label={getLocalisationMessage("details")}
        dataKey="details"
        cellRenderer={row => (
          <TextWithLink
            style={{ textDecoration: "none" }}
            to={props.createOrderHref(row.cellData.get("id"))}
          >
            <NameAndAvatar imagePath={DetailsIcon} classes={classes.avatar}>
              {getLocalisationMessage("details")}
            </NameAndAvatar>
          </TextWithLink>
        )}
      />

      {props.rowActionIconRenderer && (
        <DataListColumn
          width={61}
          maxWidth={61}
          label={getLocalisationMessage("action")}
          dataKey="action"
          className={classes.actionColumn}
          headerClassName={classes.actionColumn}
          cellRenderer={props.rowActionIconRenderer}
        />
      )}
    </DataList>
  );
}

export default enhancer(JobOrderList);
