import { Map, Set, List, fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import {
  getItem,
  isItemFetching,
  createAsyncReducer,
  createActionReducer,
  createAsyncItemReducer,
} from "../helpers/ReducerFactory";
import { injectReducer } from "../../shared/helpers/ReducerContext";

export const FETCH_DRIVER = "DRIVER/FETCH_DRIVER";
export const FETCH_DRIVER_CHOOSE = "DRIVER/FETCH_DRIVER_CHOOSE";
export const FETCH_DRIVER_SUGGESTIONS = "DRIVER/FETCH_DRIVER_SUGGESTIONS";
export const UPDATE_DRIVER = "DRIVER/UPDATE_DRIVER";

export const UPDATE_DRIVER_SUGGESTIONS = "DRIVER/UPDATE_DRIVER_SUGGESTIONS";

const selector = injectReducer(
  "driver",
  combineReducers({
    item: createAsyncItemReducer(FETCH_DRIVER),
    choose: createAsyncItemReducer(FETCH_DRIVER_CHOOSE, {
      keySelector: action => action.meta.request,
    }),
    suggestions: createAsyncReducer(
      FETCH_DRIVER_SUGGESTIONS,
      Map({ items: Map(), fetching: Set() }),
      {
        pending(state, { meta: { request } }) {
          return state.update("fetching", item => item.add(request));
        },
        fulfilled(state, { meta: { request }, payload: { data } }) {
          return state
            .setIn(["items", request], fromJS(data))
            .update("fetching", item => item.delete(request));
        },
        rejected(state, { meta: { request } }) {
          return state
            .update("items", item => item.delete(request))
            .update("fetching", item => item.delete(request));
        },
      },
    ),
    customSuggestions: createActionReducer(
      UPDATE_DRIVER_SUGGESTIONS,
      Map(),
      (state, { meta: { request }, payload }) => state.set(request, payload),
    ),
    updating: createAsyncReducer(UPDATE_DRIVER, Set(), {
      pending(state, { meta: { id } }) {
        return state.add(id);
      },
      fulfilled(state, { meta: { id } }) {
        return state.delete(id);
      },
      rejected(state, { meta: { id } }) {
        return state.delete(id);
      },
    }),
  }),
);

export function getDriver(state, id): Map {
  return getItem(selector(state).get("item"), id);
}

export function getDriverChoose(state, filter): Map {
  return getItem(selector(state).get("choose"), filter);
}

export function isDriverChooseFetching(state, filter): boolean {
  return isItemFetching(selector(state).get("choose"), filter);
}

export function getDriverSuggestions(state, request) {
  return selector(state).getIn(["suggestions", "items", request], List());
}

export function getDriverCustomSuggestions(state, request) {
  return selector(state).getIn(["customSuggestions", request], List());
}

export function isDriverUpdating(state, id) {
  return selector(state).hasIn(["updating", id]);
}
