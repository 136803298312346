import { createHash, FETCH_ORDER_LIST } from "../../stores/OrderListStore";
import WingApi from "../../helpers/WingApi";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ORDER_LIST_URL = `${API_V2_ROOT_URL}/admin/orders`;

export function fetchOrderList(request) {
  return (dispatch, getState, api: WingApi) =>
    dispatch({
      type: FETCH_ORDER_LIST,
      meta: { notify: true, hashCode: createHash(request) },
      payload: api.get(ORDER_LIST_URL, { params: request }),
    });
}
