import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import PriceWrapper from "../ui-core/PriceWrapper";
import DescriptionTerm from "../ui-core/DescriptionTerm";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: { "& > div": { display: "flex", flex: 1 } },
    totals: { textAlign: "center", padding: "4px 0" },
    withoutMargin: { margin: 0 },
  }),
  mapPropsStream(propsStream => {
    const driverLiabilityTotalsOpenStream = propsStream
      .distinctUntilChanged("driverId", isEqualData)
      .filter(props => props.driverId > 0)
      .switchMap(props =>
        props
          .getCashierOpenLiabilityTotals(props.driverId)
          .catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        driverLiabilityTotalsOpenStream,
        (props, driverLiabilityTotals) => ({
          ...props,
          isLoadingLiabilityOpenTotals: driverLiabilityTotals.get("pending"),
          liabilityOpenTotals: driverLiabilityTotals.get("payload"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

DriverLiabilityOpenShipmentTotal.propTypes = {
  liabilityOpenTotals: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
  driverId: PropTypes.number,
  getLocalisationMessage: PropTypes.func,
  getCashierOpenLiabilityTotals: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
};

function DriverLiabilityOpenShipmentTotal(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    props.liabilityOpenTotals.size > 0 && (
      <FlexBox gutter={8} flex={true} className={classes.totals}>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={`${getLocalisationMessage("open_cod", "Open COD")} :`}
            contentClassName={classes.withoutMargin}
          >
            <PriceWrapper
              price={props.liabilityOpenTotals.getIn(["openCod", "total"])}
              code={props.liabilityOpenTotals.getIn([
                "openCod",
                "currency",
                "code",
              ])}
            />
          </DescriptionTerm>
        </FlexBox>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={`${getLocalisationMessage(
              "order_item_value",
              "Order Item Value",
            )}:`}
            contentClassName={classes.withoutMargin}
          >
            <PriceWrapper
              price={props.liabilityOpenTotals.getIn(["openValue", "total"])}
              code={props.liabilityOpenTotals.getIn([
                "openValue",
                "currency",
                "code",
              ])}
            />
          </DescriptionTerm>
        </FlexBox>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={getLocalisationMessage(
              "open_delivery_charge",
              "Open Delivery Charge",
            )}
            contentClassName={classes.withoutMargin}
          >
            <PriceWrapper
              price={props.liabilityOpenTotals.getIn([
                "openPublicServiceFee",
                "total",
              ])}
              code={props.liabilityOpenTotals.getIn([
                "openPublicServiceFee",
                "currency",
                "code",
              ])}
            />
          </DescriptionTerm>
        </FlexBox>
      </FlexBox>
    )
  );
}

export default enhancer(DriverLiabilityOpenShipmentTotal);
