import React from "react";
import { List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Tab, Close, RadioButtonChecked } from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";
import SortableList, { SortableListItem } from "../sortable-list/SortableList";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    filterName: {
      margin: "-8px",
    },
  }),
);

OrderCustomFilterList.propTypes = {
  classes: PropTypes.object,

  onChange: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  filtersList: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderCustomFilterList(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <SortableList
      onSortEnd={({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
          props.onChange(
            props.filtersList
              .splice(oldIndex, 1)
              .splice(newIndex, 0, props.filtersList.get(oldIndex))
              .map((x, idx) => x.set("sort_order", idx)),
          );
        }
      }}
    >
      {props.filtersList.map((x, index) => (
        <SortableListItem
          key={index}
          primary={
            <FlexBox gutter={8} flex={true} className={classes.filterName}>
              <FlexBox flex={true} align="center">
                {getLocalisationMessage(
                  x.get("name"),
                  formatText(x.get("name")),
                )}
              </FlexBox>

              <FlexBox
                title={
                  x.get("is_tab")
                    ? getLocalisationMessage("tab", "Tab")
                    : getLocalisationMessage("button", "Button")
                }
              >
                {x.get("is_tab") ? (
                  <Tab color="#ccc" />
                ) : (
                  <RadioButtonChecked color="#ccc" />
                )}
              </FlexBox>
            </FlexBox>
          }
          onClick={() => props.onEditClick(index)}
          rightIconButton={
            <IconButton
              onClick={() => props.onChange(props.filtersList.delete(index))}
            >
              <Close />
            </IconButton>
          }
        />
      ))}
    </SortableList>
  );
}

export default enhancer(OrderCustomFilterList);
