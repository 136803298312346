import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavTabs from "./NavTabs";
import TabBadge from "../deprecated/TabBadge";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import DriverLiabiltyStatus from "../../constants/DriverLiabiltyStatus";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

DriverLiabilityStatusTabs.propTypes = {
  location: PropTypes.object,
  statusKey: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
  statusCounts: PropTypes.instanceOf(Map),
};

DriverLiabilityStatusTabs.defaultProps = {
  statusKey: "orderStatus",
};

function DriverLiabilityStatusTabs(props) {
  const { getLocalisationMessage } = props;
  const query = _.omit(props.location.query, ["page"]);
  const value = DriverLiabiltyStatus.has(query.orderStatus)
    ? query.orderStatus
    : DriverLiabiltyStatus.first();

  return (
    <NavTabs
      width={300}
      value={value}
      tabs={DriverLiabiltyStatus.map(type => ({
        to: {
          pathname: props.location.pathname,
          query: { ...query, orderStatus: type },
        },
        value: type,
        label: (
          <TabBadge
            label={getLocalisationMessage(type, formatText(type))}
            badge={props.statusCounts && props.statusCounts.get(type)}
          />
        ),
      })).toArray()}
    />
  );
}

export default enhancer(DriverLiabilityStatusTabs);
