import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "../form/FormAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { chooseAddressBook } from "../../api/shared/AdminAddressBookApi";

const baseFilter = new DataListFilter({
  size: 20,
  search_fields: [
    "name",
    "contact_name",
    "company_name",
    "email",
    "phone",
    "address",
    "details",
  ].join(","),
});

const AddressBookAutoCompleteComponent = componentFromStream(propsStream => {
  const optionsStream = propsStream
    .map(
      fp.flow(fp.get("input.value.name"), searchText =>
        baseFilter.setSearch(searchText),
      ),
    )
    .distinctUntilChanged(isEqualData)
    .switchMap(filter =>
      chooseAddressBook(filter).catch(() => Observable.of({})),
    )
    .map(fp.flow(fp.get("payload.data.list"), fp.toArray));

  return propsStream.combineLatest(optionsStream, (props, options) => (
    <AutoCompleteComponent
      {...props}
      filter={fp.stubTrue}
      options={options}
      parseInput={name => ({ name })}
      formatInput={fp.flow(fp.toPlainObject, fp.get("name"))}
      formatOption={fp.flow(
        fp.toPlainObject,
        item => [item.name, item.address],
        fp.compact,
        fp.join(", "),
      )}
    />
  ));
});

FormAdminAddressBookAutoComplete.propTypes = {
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  hintText: PropTypes.node,
};

export default function FormAdminAddressBookAutoComplete(props) {
  return <Field {...props} component={AddressBookAutoCompleteComponent} />;
}
