import React from "react";
import { Map, List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { green } from "@material-ui/core/colors";
import AvatarWithName from "../avatars/AvatarWithName";
import DataList, { DataListColumn } from "../data-list/DataList";
import MultiLineCell from "../data-list/MultiLineCell";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatDuration,
  formatDistanceInUnity,
  formatDateDistanceToNow,
  formatDateDistanceToNowInSeconds,
} from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMarketplaceDefaultUnitOfDistance } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { statusColors } from "../../../shared/theme/main-theme";
import { toJSON, toBase64 } from "../../../shared/helpers/DataSerializer";
import TimeIcon from "../order-job/assets/time_icon.svg";

const NA = "N/A";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    unitOfDistance: getMarketplaceDefaultUnitOfDistance(state),
  })),
  useSheet({
    hoverable: { cursor: "pointer", textDecoration: "underline" },
    actionColumn: { paddingLeft: 0, justifyContent: "center" },
    dataListFooter: {
      position: "absolute",
      width: "100%",
      bottom: "0",
      right: "0",
      lineHeight: "64px",
      textAlign: "right",
      paddingRight: "15px",
    },
    avatar: { marginLeft: "20px" },
    available: {
      color: statusColors.status13,
      fontWeight: "bold",
      fontSize: "14px",
    },
    busy: {
      color: statusColors.status14,
      fontWeight: "bold",
      fontSize: "13px",
    },
    actionRow: {
      width: "95%",
      padding: "20px 0",
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .pluck("list")
      .distinctUntilChanged(isEqualData)
      .map(list =>
        Map().withMutations(items => {
          list.forEach(item => {
            const pickup = item.getIn(["locations", 0]);
            const dropoff = item.getIn(["locations", 1]);

            items.updateIn(["pickup", pickup], 0, fp.add(1));
            items.updateIn(["dropoff", dropoff], 0, fp.add(1));
          });

          items.update("pickup", Map(), fp.get("size"));
          items.update("dropoff", Map(), fp.get("size"));
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
);

SuggestedDriversList.propTypes = {
  sheet: PropTypes.object,
  altHeader: PropTypes.node,
  withFooter: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  maxSearchItems: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  onFilterChange: PropTypes.func,
  withAmount: PropTypes.bool,
  withCODAmount: PropTypes.bool,
  chipTextHint: PropTypes.string,
  setLocationQuery: PropTypes.func,
  onRequestAssign: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  unitOfDistance: PropTypes.string,
};

SuggestedDriversList.defaultProps = {
  withAmount: true,
  withCODAmount: false,
  withFooter: false,
};

function SuggestedDriversList(props) {
  const {
    sheet: { classes },
    getLocalisationMessage,
    unitOfDistance,
  } = props;

  return (
    <DataList
      filter={props.filter}
      chipTextHint={props.chipTextHint}
      searchTypes={props.searchTypes}
      maxSearchItems={props.maxSearchItems}
      onFilterChange={null}
      altHeader={props.altHeader}
      totalCount={props.totalCount}
      overscanRowCount={0}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      rowGetter={options => props.list.get(options.index)}
    >
      <DataListColumn
        width={180}
        label={getLocalisationMessage("driver_courier", "Driver Courier")}
        dataKey="driver_courier"
        cellRenderer={row => (
          <AvatarWithName
            classes={classes.avatar}
            name={
              row.cellData.get("supplier_name")
                ? row.cellData.get("supplier_name")
                : row.cellData.get("name")
            }
          >
            <MultiLineCell
              firstLine={<strong>{row.cellData.get("name") || NA}</strong>}
              secondLine={row.cellData.get("supplier_name")}
            />
          </AvatarWithName>
        )}
      />

      <DataListColumn
        width={130}
        label={getLocalisationMessage("eta", "Pickup ETA and Distance")}
        dataKey="eta"
        cellRenderer={row => (
          <AvatarWithName imagePath={TimeIcon} classes={classes.avatar}>
            <MultiLineCell
              firstLine={
                <strong>
                  {formatDateDistanceToNow(
                    row.cellData.get("date_time"),
                    null,
                    getLocalisationMessage,
                  )}
                </strong>
              }
              secondLine={formatDistanceInUnity(
                row.cellData.get("distance"),
                unitOfDistance,
              )}
            />
          </AvatarWithName>
        )}
      />

      <DataListColumn
        width={130}
        label={getLocalisationMessage("detad", "Delivery ETA and Distance")}
        dataKey="eta"
        cellRenderer={row => (
          <AvatarWithName imagePath={TimeIcon} classes={classes.avatar}>
            <MultiLineCell
              firstLine={
                <strong>
                  {formatDuration(row.cellData.get("delivery_eta_time"))}
                </strong>
              }
              secondLine={formatDistanceInUnity(
                row.cellData.get("delivery_eta_distance"),
                unitOfDistance,
              )}
            />
          </AvatarWithName>
        )}
      />

      <DataListColumn
        width={130}
        label={getLocalisationMessage("tetad", "Total ETA and Distance")}
        dataKey="distance"
        cellRenderer={row => (
          <AvatarWithName imagePath={TimeIcon} classes={classes.avatar}>
            <MultiLineCell
              firstLine={
                <strong>
                  {formatDuration(
                    formatDateDistanceToNowInSeconds(
                      row.cellData.get("date_time"),
                    ) + row.cellData.get("delivery_eta_time", 0),
                  )}
                </strong>
              }
              secondLine={formatDistanceInUnity(
                row.cellData.get("distance", 0) +
                  row.cellData.get("delivery_eta_distance", 0),
                unitOfDistance,
              )}
            />
          </AvatarWithName>
        )}
      />

      <DataListColumn
        width={90}
        label={getLocalisationMessage("availability", "Availability")}
        dataKey="availability"
        cellRenderer={row => (
          <div>
            {row.cellData.get("busy") ? (
              <MultiLineCell
                firstLine={
                  <div className={classes.busy}>
                    {getLocalisationMessage("busy", "Busy")}:
                  </div>
                }
                secondLine={
                  <div>
                    {row.cellData.get("order_count")}{" "}
                    {getLocalisationMessage("orders", "Orders")}
                  </div>
                }
              />
            ) : (
              <div className={classes.available}>
                {getLocalisationMessage("available", "Available")}
              </div>
            )}
          </div>
        )}
      />

      <DataListColumn
        width={130}
        label=""
        dataKey="show_in_map"
        cellRenderer={row => (
          <div className={classes.actionRow}>
            <Button
              backgroundColor={statusColors.status11}
              labelStyle={{ color: "white", textTransform: "normal" }}
              hoverColor="#cfcfcf"
              fullWidth={true}
              onClick={() =>
                props.setLocationQuery(
                  fp.flow(
                    fp.set("driver_id", row.cellData.get("driver_id")),
                    fp.set("data", toBase64(toJSON(row.cellData))),
                  ),
                )
              }
            >
              {getLocalisationMessage("show_in_the_map", "Show in the Map")}
            </Button>
          </div>
        )}
      />

      <DataListColumn
        width={200}
        label=""
        dataKey="assign"
        cellRenderer={row => (
          <div className={classes.actionRow}>
            <Button
              backgroundColor={green[800]}
              labelStyle={{ color: "white" }}
              hoverColor={green[400]}
              fullWidth={true}
              onClick={() =>
                props.onRequestAssign(row.cellData.get("driver_id"))
              }
            >
              {getLocalisationMessage("assign", "Assign")}
            </Button>
          </div>
        )}
      />
    </DataList>
  );
}

export default enhancer(SuggestedDriversList);
