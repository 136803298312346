import React from "react";
import { Map, List, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Tab, Chip, Tabs, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import {
  red,
  blue,
  amber,
  indigo,
  blueGrey,
  lightGreen,
} from "@material-ui/core/colors";
import TooltipOverlay from "../ui-core/TooltipOverlay";
import TabBadge from "../deprecated/TabBadge";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import {
  FAILED_BIN,
  INCOMPLETE_BIN,
  UNASSIGNED_BIN,
} from "../../helpers/OrderSortingHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  SAVER,
  URGENT,
  NEXT_DAY,
  SAME_DAY,
} from "../../constants/CourierTypes";
import { accent1, success1 } from "../../../shared/theme/main-theme";
import { pipeStreams } from "../../helpers/StreamUtils";

const ALL_TAB = "all";
const MIXED_TAB = "mixed";
const CURRENT_TAB = "current";

const chipGroups = fromJS({
  [MIXED_TAB]: {
    children: "X",
  },
  [CURRENT_TAB]: {
    children: "C",
  },
  [SAVER]: {
    children: "S",
  },
  [NEXT_DAY]: {
    children: "ND",
  },
  [SAME_DAY]: {
    children: "SM",
  },
  [URGENT]: {
    children: "U",
  },
});

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    tabs: {
      margin: "0 -1rem",
    },
    chipWrapper: {
      marginTop: ".5rem",
    },
    chipAccent: { backgroundColor: accent1 },
    chipSuccess: { backgroundColor: success1 },
    mixedTab: { backgroundColor: blueGrey[500] },
    currentTab: { backgroundColor: indigo[500] },
    saver: { backgroundColor: blue[500] },
    nextDay: { backgroundColor: lightGreen[500] },
    sameDay: { backgroundColor: amber[500] },
    urgent: { backgroundColor: red[500] },
  }),
  withState("state", "setState", { tab: ALL_TAB }),
  mapPropsStream(
    pipeStreams(
      (propsStream) => {
        const tabsStream = propsStream
          .distinctUntilKeyChanged("filterTabs", isEqualData)
          .map((props) =>
            Map().withMutations((tabs) => {
              props.filterTabs.forEach((chip) => {
                const tab = chip.get("label");
                const addOrders = fp.add(chip.get("orders").size);

                tabs.setIn([tab, "orders"], chip.get("orders"));
                tabs.updateIn([tab, "count"], addOrders);
                tabs.updateIn([ALL_TAB, "count"], addOrders);
              });
            }),
          )
          .distinctUntilChanged(isEqualData)
          .map((tabs) =>
            tabs
              .filter((x) => x.get("count") > 0)
              .map((x, tab) =>
                x.merge({
                  tab,
                  label: tab,
                }),
              )
              .toOrderedMap()
              .sort((a, b) => {
                const tabTypes = [ALL_TAB, CURRENT_TAB, MIXED_TAB];

                for (let i = 0; i < tabTypes.length; i++) {
                  const tab = tabTypes[i];

                  if (a.get("tab") === tab) {
                    return -1;
                  }

                  if (b.get("tab") === tab) {
                    return 1;
                  }
                }

                if (!a.get("tab") || !b.get("tab")) return -1;

                return a.get("tab").localeCompare(b.get("tab"));
              }),
          );

        return propsStream.combineLatest(tabsStream, (props, tabs) => ({
          ...props,
          tabs,
        }));
      },
      (propsStream) => {
        const filterChipsStream = propsStream
          .map(fp.pick(["state", "filterChips", "tabs"]))
          .distinctUntilChanged(isEqualData)
          .map(
            fp.flow((props) =>
              List().withMutations((chips) => {
                const { tab } = props.state;
                const { tabs } = props;
                const allTab = tab === ALL_TAB;

                props.filterChips.forEach((chip) => {
                  if (allTab || Boolean(tabs && tabs.get(tab, null))) {
                    chips.push(
                      chip.merge({
                        orders: chip.get("orders"),
                      }),
                    );
                  }
                });
              }),
            ),
          );

        return propsStream.combineLatest(
          filterChipsStream,
          (props, filterChips) => ({ ...props, filterChips }),
        );
      },
    ),
  ),
  pureComponent(fp.pick(["tabs", "state", "groups", "filterChips"])),
);

OrderSortingChips.propTypes = {
  classes: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,
  tabs: PropTypes.instanceOf(OrderedMap),

  onSelectOrders: PropTypes.func.isRequired,
  onDeselectOrders: PropTypes.func.isRequired,

  groups: PropTypes.instanceOf(List).isRequired,
  filterChips: PropTypes.instanceOf(List).isRequired,
  filterTabs: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderSortingChips(props) {
  const { tabs, state, classes, getLocalisationMessage } = props;

  return (
    <React.Fragment>
      {tabs.size > 2 && (
        <Tabs
          variant="fullWidth"
          className={classes.tabs}
          onChange={(e, v) => props.setState(fp.set("tab", v))}
          value={tabs.has(state.tab) ? state.tab : ALL_TAB}
        >
          {tabs
            .map((x) => (
              <Tab
                key={x.get("tab")}
                value={x.get("tab")}
                label={
                  <TabBadge label={x.get("label")} badge={x.get("count")} />
                }
                style={{
                  lineHeight: "24px",
                  backgroundColor: chipGroups.getIn([
                    x.get("tab"),
                    "backgroundColor",
                  ]),
                }}
              />
            ))
            .toList()}
        </Tabs>
      )}

      <div className={classes.chipWrapper}>
        {props.filterChips
          .sortBy((x) =>
            x.get("label") === UNASSIGNED_BIN ||
            x.get("label") === FAILED_BIN ||
            x.get("label") === INCOMPLETE_BIN
              ? 1
              : 0,
          )
          .map((chip, index) => {
            const orders = chip.get("orders");
            const selectedAny = chip.get("selected") > 0;
            const selectedAll = chip.get("selected") === orders.size;

            const group = chip.get("group");
            const avatarProps = !chipGroups.has(group)
              ? chipGroups.get(MIXED_TAB)
              : chipGroups.get(group);

            return (
              <TooltipOverlay
                touch={true}
                label={`${getLocalisationMessage("shipments", "Shipments")}: ${
                  orders.size
                }`}
                key={`${chip.get("group")}-${index}`}
              >
                <Chip
                  className={cx(classes.chip, {
                    [classes.chipSuccess]: selectedAll,
                    [classes.chipAccent]: selectedAny && !selectedAll,
                  })}
                  onClick={() => {
                    if (selectedAll) {
                      props.onDeselectOrders(orders);
                    } else {
                      props.onSelectOrders(orders, chip);
                    }
                  }}
                  avatar={<Avatar {...avatarProps.toJS()} size={32} />}
                  label={`${getLocalisationMessage(
                    chip.get("label").toLocaleLowerCase(),
                    chip.get("label"),
                  )} 
                  (${orders.size})`}
                />
              </TooltipOverlay>
            );
          })}
      </div>

      {props.groups.size > 0 && (
        <div className={classes.chipWrapper}>
          {props.groups.map((chip, index) => {
            const orders = chip.get("orders");
            const selectedAny = chip.get("selected") > 0;
            const selectedAll = chip.get("selected") === orders.size;

            return (
              <Chip
                key={index}
                className={cx(classes.chip, {
                  [classes.chipSuccess]: selectedAll,
                  [classes.chipAccent]: selectedAny && !selectedAll,
                })}
                onClick={() => {
                  if (selectedAll) {
                    props.onDeselectOrders(orders);
                  } else {
                    props.onSelectOrders(orders);
                  }
                }}
                label={`${getLocalisationMessage(
                  chip.get("label").toLocaleLowerCase(),
                  chip.get("label"),
                )} (${orders.size})`}
              />
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
}

export default enhancer(OrderSortingChips);
