import {
  subDays,
  getHours,
  setHours,
  getMinutes,
  setMinutes,
  startOfTomorrow,
  startOfYesterday,
  differenceInCalendarDays,
} from "date-fns";
import { Map, Set, fromJS } from "immutable";
import fp from "lodash/fp";
import { isValidDate, safeParseDate } from "./DateUtils";
import {
  formatDate,
  DATE_URL_FORMAT,
  formatDateTimeToUrl,
} from "./FormatUtils";
import DataListFilter from "./DataListFilter";

const dateTimeFields = Set([
  "from_date_time",
  "to_date_time",
  "from_promise_date_time",
  "to_promise_date_time",
  "from_deadline_time",
  "to_deadline_time",
  "status_from_date_time",
  "status_to_date_time",
]);

const dateToString = date => {
  switch (differenceInCalendarDays(new Date(), date)) {
    case 0:
      return "today";
    case 1:
      return "yesterday";
    case -1:
      return "tomorrow";
    case 7:
      return "las_7_days";
    case 30:
      return "last_30_days";
    default:
      return formatDate(date, DATE_URL_FORMAT);
  }
};

const stringToDate = date => {
  switch (date) {
    case "today":
      return new Date();
    case "yesterday":
      return startOfYesterday();
    case "tomorrow":
      return startOfTomorrow();
    case "las_7_days":
      return subDays(new Date(), 7);
    case "last_30_days":
      return subDays(new Date(), 30);

    default:
      return safeParseDate(date);
  }
};

export const parserOrderSmartFilter = smartFilter =>
  new DataListFilter(
    smartFilter.mapEntries(([key, value]) => {
      if (dateTimeFields.has(key)) {
        if (Map.isMap(value)) {
          let date = stringToDate(value.get("date"));

          date = setHours(date, value.getIn(["time", "hours"]));
          date = setMinutes(date, value.getIn(["time", "minutes"]));

          if (isValidDate(date)) {
            return [key, formatDateTimeToUrl(date)];
          }
        }

        return [key, null];
      }

      return [key, value];
    }),
  );

export const createOrderSmartFilter = fp.flow(
  x => x.getValues(),
  fp.toPairs,
  fp.map(([key, value]) => {
    if (dateTimeFields.has(key)) {
      const date = safeParseDate(value);

      return [
        key,
        !date
          ? null
          : {
              date: dateToString(date),
              time: {
                hours: getHours(date),
                minutes: getMinutes(date),
              },
            },
      ];
    }

    return [key, value];
  }),
  fp.filter(([, x]) => !fp.isNil(x)),
  fp.fromPairs,
  x => fromJS(x),
);
