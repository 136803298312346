import { OrderedSet } from "immutable";

export const LATE_ATTEMPT = "late_attempt";
export const NO_DATA_INTERNET = "no_data_internet";
export const GPS_OFF = "gps_off";
export const FREQUENT_STATUS_UPDATE = "frequent_status_update";
export const UNDELIVERED_PARCEL = "undelivered_parcel";
export const WRONG_ROUTE = "wrong_route";
export const WRONG_ORDER_SEQUENCE = "wrong_order_sequence";
export const MULTIPLE_DEVICES = "multiple_devices";

export default OrderedSet.of(
  LATE_ATTEMPT,
  NO_DATA_INTERNET,
  GPS_OFF,
  FREQUENT_STATUS_UPDATE,
  UNDELIVERED_PARCEL,
  WRONG_ROUTE,
  WRONG_ORDER_SEQUENCE,
  MULTIPLE_DEVICES,
);
