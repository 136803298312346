import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Line } from "./ChartComponent";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "16px",
    },
  }),
);

FilledLineChart.propTypes = {
  data: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string,
  options: PropTypes.object,
  tooltipFormat: PropTypes.object,
};

FilledLineChart.defaultProps = {
  width: 100,
  height: 600,
  options: {},
};

function FilledLineChart(props) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    datasetStrokeWidth: 3,
    pointDotStrokeWidth: 4,
    legend: {
      position: "bottom",
      display: true,
      labels: {
        boxWidth: 19,
        fontSize: 18,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
          },
        },
      ],
    },
    tooltips: props.tooltipFormat,
    // tooltipTemplate: "<%if (label){% ><%=label + ' hod' %>: <%}%><%= value + '°C' %>",
    // scaleLabel : "<%= Number(value).toFixed(0).replace('.', ',') + '°C'%>"
    ...props.options,
  };

  return (
    <div
      style={{
        maxHeight: "600px",
      }}
    >
      <Line
        data={props.data}
        options={options}
        width={props.width}
        id={props.id}
        height={props.height}
      />
    </div>
  );
}

export default enhancer(FilledLineChart);
