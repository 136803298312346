import React from "react";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Card,
  CardContent,
  Button,
  IconButton,
  CardActions,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { CloudDownload } from "@material-ui/icons";
import FormFileField from "../form/FormFileField";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { createFileValidator } from "../../helpers/FormUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { OVERWEIGHT } from "../../constants/OrderCSVTemplateTypes";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { DOWNLOAD_ORDER_CSV_TEMPLATE_URL } from "../../../shared/constants/FileProxyControllerConstants";

const CSV_FILE_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
].join(",");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  reduxForm({
    form: "OverweightUploadForm",
  }),
);

OverweightUploadForm.propTypes = {
  handleSubmit: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  getCachedSupplier: PropTypes.func,
  onDismiss: PropTypes.func,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function OverweightUploadForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox align="center" container={8} element={<form autoComplete="off" />}>
      <FlexBox gutter={8} flex={true}>
        <FlexBox flex={true} direction="column">
          <Card>
            <CardContent>
              <PageLoading isLoading={props.submitting} />
              <FlexBox gutter={8} flex={true} direction="column">
                {props.getSupplierPredictions && (
                  <FlexBox flex={true}>
                    <FormSupplierAutoComplete
                      name="supplier"
                      label={getLocalisationMessage("supplier", "Supplier")}
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                    />
                  </FlexBox>
                )}
                <FlexBox flex={true}>
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true}>
                      <FormFileField
                        name="file"
                        fullWidth={true}
                        accept={CSV_FILE_TYPES}
                        label={getLocalisationMessage(
                          "select_file",
                          "Select File",
                        )}
                        validate={createFileValidator(
                          getLocalisationMessage(
                            "please_select_file",
                            "Please Select File",
                          ),
                        )}
                      />
                    </FlexBox>
                    <FlexBox align="center">
                      <Tooltip
                        title={getLocalisationMessage(
                          "download_template",
                          "Download Template",
                        )}
                      >
                        <IconButton
                          target="_blank"
                          href={updateQuery(DOWNLOAD_ORDER_CSV_TEMPLATE_URL, {
                            fileType: OVERWEIGHT,
                          })}
                        >
                          <CloudDownload />
                        </IconButton>
                      </Tooltip>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </CardContent>

            <CardActions>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} justify="flex-end">
                  <Button onClick={props.onDismiss}>
                    {getLocalisationMessage("dismiss", "Dismiss")}
                  </Button>
                  <Button onClick={props.handleSubmit}>
                    {getLocalisationMessage("submit", "Submit")}
                  </Button>
                </FlexBox>
              </FlexBox>
            </CardActions>
          </Card>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OverweightUploadForm);
