import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormDialog from "../form/FormDialog";
import FormFileField from "../form/FormFileField";
import { readFileText } from "../../helpers/FileUtils";
import { createFileValidator } from "../../helpers/FormUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

ImportFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  accept: PropTypes.string,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getLocalisationMessage: PropTypes.func,
};

function ImportFileDialog(props) {
  const { getLocalisationMessage } = props;
  return (
    <FormDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
      onSubmit={values =>
        readFileText(values.file)
          .toPromise()
          .then(props.onSubmit)
      }
    >
      <FormFileField
        name="file"
        fullWidth={true}
        accept={props.accept}
        label={getLocalisationMessage("select_file", "Select File")}
        validate={createFileValidator(
          getLocalisationMessage("please_select_file", "Please Select File"),
        )}
      />
    </FormDialog>
  );
}

export default enhancer(ImportFileDialog);
