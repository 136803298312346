import { List } from "immutable";
import {
  CHARTS_URL,
  ORDER_JOBS_URL,
  ORDER_LIST_URL,
  DRIVER_LIST_URL,
  ORDER_TRACK_URL,
  DRIVER_RADAR_URL,
  VEHICLE_LIST_URL,
  ORDER_LIST_MAP_URL,
  ORDER_TRACKING_URL,
  DRIVER_LIABILITY_URL,
  FINANCE_ORDER_LIST_URL,
  ORDER_SORTING_VIEW_URL,
  FINANCE_TRANSFER_LIST_URL,
  CUSTOMER_FEEDBACK_LIST_URL,
  SUPPLIER_USER_LIST_ALL_URL,
  COMMUNICATION_SETTINGS_LIST_URL,
} from "./SupplierPathConstants";

export const DASHBOARD = {
  ind: 1,
  group: "dashboard",
  slug: "dashboard",
  icon: "actionDashboard",
  url: CHARTS_URL,
};
export const ORDER_TRACKING = {
  ind: 2,
  group: "orders",
  slug: "order_tracking",
  icon: "fileFolderOpen",
  // icon: "actionSearch",
  url: ORDER_TRACK_URL,
};
export const DOMESTIC_ORDERS = {
  ind: 2,
  group: "orders",
  slug: "domestic_orders",
  icon: "truckDelivery",
  url: ORDER_LIST_URL,
  params: { is_uae: true },
};
export const INTERNATIONAL_ORDERS = {
  ind: 2,
  group: "orders",
  slug: "international_orders",
  icon: "actionFlightTakeoff",
  url: ORDER_LIST_URL,
  params: { is_uae: false },
};
export const ORDERS_TRACKING = {
  ind: 2,
  group: "orders",
  slug: "orders_tracking",
  icon: "actionAssignmentReturned",
  url: ORDER_TRACKING_URL,
};
export const JOBS = {
  ind: 2,
  group: "orders",
  slug: "jobs",
  icon: "fileFolderOpen",
  url: ORDER_JOBS_URL,
  params: { is_uae: true },
};
export const ORDERS_SORTING = {
  ind: 2,
  group: "orders",
  slug: "orders_sorting",
  icon: "hardwareDeviceHub",
  url: ORDER_SORTING_VIEW_URL,
};
export const ORDERS_ROUTING = {
  ind: 2,
  group: "orders",
  slug: "orders_routing",
  icon: "actionTimeline",
  url: ORDER_LIST_MAP_URL,
};
export const FINANCE_ORDERS = {
  ind: 3,
  group: "finance",
  slug: "finance_orders",
  icon: "editorMonetizationOn",
  // icon: "actionAccountBalance",
  url: FINANCE_ORDER_LIST_URL,
};
export const COD_TRANSFERS = {
  ind: 3,
  group: "finance",
  slug: "COD_transfers",
  icon: "actionAssignmentReturned",
  url: FINANCE_TRANSFER_LIST_URL,
};
export const CUSTOMER_FEEDBACKS = {
  ind: 3,
  group: "finance",
  slug: "my_customer_feedbacks",
  icon: "communicationMessage",
  url: CUSTOMER_FEEDBACK_LIST_URL,
};
export const DRIVER_LIABILITY = {
  ind: 3,
  group: "finance",
  slug: "driver_liability",
  icon: "notificationDriveEta",
  url: DRIVER_LIABILITY_URL,
};
export const COURIER_USERS = {
  ind: 4,
  group: "staff",
  slug: "users",
  icon: "socialPeopleOutline",
  url: SUPPLIER_USER_LIST_ALL_URL,
};
export const DRIVERS = {
  ind: 4,
  group: "staff",
  slug: "drivers",
  icon: "accountCardDetails",
  url: DRIVER_LIST_URL,
};
export const DRIVER_RADAR = {
  ind: 4,
  group: "staff",
  slug: "radar",
  icon: "radarIcon",
  url: DRIVER_RADAR_URL,
};
export const VEHICLES = {
  ind: 4,
  group: "staff",
  slug: "vehicles",
  icon: "vehicleIcon",
  url: VEHICLE_LIST_URL,
};
// export const REPORTS = {
//  ind: 5,
//  group: "reports",
//  slug: "reports_dashboard",
//  icon: "actionSettings",
//  url: REPORTING_REPORTS_URL,
// };
export const COMMUNICATION_SETTINGS = {
  ind: 6,
  group: "settings",
  slug: "communication_settings",
  icon: "actionSettings",
  // icon: "communicationIcon",
  url: COMMUNICATION_SETTINGS_LIST_URL,
};

export default List([
  DASHBOARD,
  // ORDER_TRACKING,
  DOMESTIC_ORDERS,
  INTERNATIONAL_ORDERS,
  ORDERS_TRACKING,
  JOBS,
  ORDERS_SORTING,
  ORDERS_ROUTING,
  FINANCE_ORDERS,
  COD_TRANSFERS,
  DRIVER_LIABILITY,
  CUSTOMER_FEEDBACKS,
  COURIER_USERS,
  DRIVERS,
  DRIVER_RADAR,
  VEHICLES,
  // REPORTS,
  COMMUNICATION_SETTINGS,
]);
