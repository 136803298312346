import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { HorizontalBar } from "./ChartComponent";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
      // paddingBottom: "120px"
    },
    title: {
      fontSize: "16px",
    },
    mainContainer: {
      "& > div:nth-child(1)": {
        fontSize: "16px",
      },
      "& > div:nth-child(2)": {
        fontSize: "20px",
        color: "#E91E63",
        "& > span": {
          fontSize: "12px",
          lineHeight: "18px",
        },
      },
    },
    priceValue: {
      fontWeight: "bold",
      "& > span": {
        paddingRight: "5px",
      },
    },
  }),
);

HorizontalBarChart.propTypes = {
  classes: PropTypes.object,
  tooltipFormat: PropTypes.object,
  data: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  max: PropTypes.number,
  title: PropTypes.string,
};

HorizontalBarChart.defaultProps = {
  width: 100,
  height: 20,
  max: 100,
};

function HorizontalBarChart(props) {
  const options = {
    maintainAspectRatio: false,
    tooltips: props.tooltipFormat,
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 15,
        bottom: 15,
      },
    },
    elements: {
      rectangle: {
        borderWidth: 0.3,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barThickness: "flex",
          categoryPercentage: 0.5,
          barPercentage: 0.9,

          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };

  return (
    <div
      style={{
        maxHeight: "600px",
        margin: 10,
      }}
    >
      <HorizontalBar
        data={props.data}
        options={options}
        width={props.width}
        height={props.height}
      />
    </div>
  );
}

export default enhancer(HorizontalBarChart);
