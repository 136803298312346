import React, { useEffect, useMemo, useState } from "react";
import socket from "./SocketWrapper";
import { log } from "../../shared/helpers/logger";

export function sockJSHOC() {
  return WrappedComponent =>
    function HOC(props) {
      const [state, setState] = useState({});
      useMemo(() => {
        socket.runSocketChecker(() => {
          setState({ socketClient: socket.getSocketClient() });
        });
      }, []);

      useEffect(() => {
        const { socketClient } = state;

        if (socketClient && socketClient.connected) {
          socketClient.unsubscribe(() => {
            log("SockJS disconnected!");
          });
        }
      }, []);

      const { socketClient } = state;

      return <WrappedComponent socketClient={socketClient} {...props} />;
    };
}
