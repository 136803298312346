import { Observable } from "rxjs";
import React from "react";
import { List, Collection } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  withHandlers,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { Autocomplete as MUIAutoComplete } from "@material-ui/lab";
import { isEqualData } from "../../helpers/DataUtils";

const enhancer = compose(
  withHandlers({
    onChange: () => fp.noop,
    onFocus: props => () => props.onFocus(),
    onNewRequest: props => fp.flow(fp.get("value.props.value"), props.onChange),
  }),
  mapPropsStream(propsStream => {
    const { stream: refStream, handler: ref } = createEventHandler();
    const { stream: onCloseStream, handler: onClose } = createEventHandler();

    const sideEffectsStream = Observable.merge(
      propsStream
        .pluck("focus")
        .filter(Boolean)
        .distinctUntilChanged()
        .combineLatest(
          refStream.map(fp.get(["refs", "searchTextField", "input"])),
        )
        .delay(100)
        .do(([, input]) => input.focus()),
      onCloseStream
        .delay(0)
        .debounceTime(10)
        .withLatestFrom(propsStream)
        .do(([, props]) => props.onBlur()),
    ).startWith(null);

    const dataSourceStream = propsStream
      .filter(fp.get("options"))
      .distinctUntilKeyChanged("options", isEqualData)
      .map(props =>
        props.options.map((item, index) => {
          const primaryText = props.formatOption(item, index);

          return {
            primaryText,
            value: props.parseOption(item, index),
            text: props.formatInput
              ? props.formatInput(item, index)
              : primaryText,
            leftIcon: props.renderLeftIcon(item, index),
            rightIcon: props.renderRightIcon(item, index),
          };
        }),
      )
      .distinctUntilChanged(isEqualData)
      .startWith(List());

    return propsStream
      .map(props => ({
        ...props,
        errorText:
          (!props.active && props.invalid && props.touched && props.error) ||
          props.errorText,
      }))
      .map(
        fp.omit([
          "value",
          "onBlur",
          "onUpdate",
          "onChange",
          "active",
          "autofill",
          "autofilled",
          "dirty",
          "dispatch",
          "error",
          "focus",
          "formatInput",
          "formatOption",
          "getOptionLabel",
          "getOptionText",
          "getOptionValue",
          "initialValue",
          "invalid",
          "isLoading",
          "options",
          "parseOption",
          "pristine",
          "renderLeftIcon",
          "renderRightIcon",
          "touched",
          "valid",
          "visited",
        ]),
      )
      .distinctUntilChanged(isEqualData)
      .combineLatest(
        dataSourceStream,
        sideEffectsStream,
        (props, dataSource) => ({
          ...props,
          ref,
          onClose,
          menuCloseDelay: 0,
          popoverProps: { canAutoPosition: true },
          dataSource: dataSource
            .map(({ text, primaryText, ...itemProps }) => ({
              text,
              value: <MenuItem {...itemProps}>{primaryText}</MenuItem>,
            }))
            .toArray(),
        }),
      );
  }),
);

const AutoComplete = enhancer(MUIAutoComplete);

AutoComplete.propTypes = {
  dataSource: PropTypes.array,
  options: PropTypes.instanceOf(Collection),
  renderLeftIcon: PropTypes.func,
  renderRightIcon: PropTypes.func,
  parseOption: PropTypes.func,
  formatOption: PropTypes.func,
  formatInput: PropTypes.func,
};

AutoComplete.defaultProps = {
  onBlur: fp.noop,
  onFocus: fp.noop,
  hintText: "Введите для поиска",
  parseOption: fp.identity,
  formatOption: fp.identity,
  renderLeftIcon: fp.noop,
  renderRightIcon: fp.noop,
};

AutoComplete.Item = MUIAutoComplete.Item;
AutoComplete.Divider = MUIAutoComplete.Divider;

AutoComplete.noFilter = MUIAutoComplete.noFilter;
AutoComplete.fuzzyFilter = MUIAutoComplete.fuzzyFilter;
AutoComplete.defaultFilter = MUIAutoComplete.defaultFilter;
AutoComplete.caseSensitiveFilter = MUIAutoComplete.caseSensitiveFilter;
AutoComplete.caseInsensitiveFilter = MUIAutoComplete.caseInsensitiveFilter;
AutoComplete.levenshteinDistanceFilter =
  MUIAutoComplete.levenshteinDistanceFilter;

export default AutoComplete;
