import sprintf from "sprintf";
import Stomp from "stompjs";
import fp from "lodash/fp";
import SockJS from "sockjs-client";
import { getInitialState } from "./InitialState";
import { log } from "../../shared/helpers/logger";
import { getAppToken } from "../../shared/reducers/AppReducer";
import { DRIVER_LOCATION_API } from "../../server/api/shared/DriverApi";

class SocketWrapper {
  token = null;
  socketClient = null;
  checkConnection = null;

  constructor() {
    const reduxState = getInitialState();
    this.token = getAppToken(reduxState);
  }

  connectSocket() {
    const socketUrl = sprintf(DRIVER_LOCATION_API, this.token);
    const socketJS = new SockJS(socketUrl);
    this.socketClient = Stomp.over(socketJS);
    this.socketClient.debug = fp.noop();
  }

  checkAndConnect(callback) {
    if (this.socketClient && !this.socketClient.connected) {
      this.connectSocket();
      this.socketClient.connect({}, ({ command }) => {
        if (command === "CONNECTED") {
          log("SockJS connected!");
          if (callback) {
            callback();
          }
        }
      });
    }
  }

  runSocketChecker(callback) {
    this.checkConnection = setInterval(() => {
      this.checkAndConnect(callback);
    }, 10000); // will check connection every 10 seconds
  }

  removeSocketChecker() {
    if (this.checkConnection) {
      clearInterval(this.checkConnection);
      this.checkConnection = null;
    }
  }

  disconnectSocket() {
    this.removeSocketChecker();
    if (this.socketClient && this.socketClient.connected) {
      this.socketClient.unsubscribe(() => {
        log("SockJS disconnected!");
      });
    }
  }

  getSocketClient() {
    return this.socketClient;
  }
}

const socket = new SocketWrapper();

export default socket;
