import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toDriverLiabilityFilterMapper = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),
    orderStatus: types.string,
    payment_flow: types.string,
    driver_id: types.ID,
    operator_id: types.ID,
    eventType: types.string,
    search: queryTypes.stringSeq,
  }),
);
