import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import VehicleMarker from "../osm/VehicleMarker";
import { pureComponent } from "../../../helpers/HOCUtils";

const enhancer = compose(pureComponent(fp.pick(["locations"])));

DriverRadarVehicles.propTypes = {
  onClick: PropTypes.func,
  locations: PropTypes.instanceOf(Immutable.OrderedMap),
};

function DriverRadarVehicles({ locations, onClick }) {
  const list = locations
    .map((item, idx) => {
      const driverId = item.getIn(["location", "driver_id"]);

      return (
        <VehicleMarker
          busy={item.getIn(["location", "busy"], "default")}
          key={idx}
          driverId={driverId}
          onClick={() => onClick(driverId)}
          rotation={item.getIn(["location", "angle"])}
          position={{
            lat: item.getIn(["location", "lat"]),
            lng: item.getIn(["location", "lon"]),
          }}
        />
      );
    })
    .toArray();

  return <div>{list}</div>;
}

export default enhancer(DriverRadarVehicles);
