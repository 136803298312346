import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Marker, MarkerIcon } from "react-google-map-components";
import PinCompleted from "./assets/pin_completed.png";
import PinIncomplete from "./assets/pin_incomplete.png";

const enhancer = compose();

PinMarker.propTypes = {
  size: PropTypes.number,
  complete: PropTypes.bool,

  position: PropTypes.object.isRequired,
};

PinMarker.defaultProps = {
  size: 45,
};

function PinMarker(props) {
  const { size, complete, ...markerProps } = props;

  return (
    <Marker
      {...markerProps}
      icon={
        <MarkerIcon
          anchor={{ y: size, x: size / 2 }}
          size={{ width: 128, height: 128 }}
          scaledSize={{ width: size, height: size }}
          url={complete ? PinCompleted : PinIncomplete}
        />
      }
    />
  );
}

export default enhancer(PinMarker);
