import React from "react";
import { fromJS } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  getCachedVehicle,
  getVehiclePredictions,
} from "../../api/admin/AdminVehicleApi";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });
const enhancer = compose(
  // connect(null, { getCachedVehicle, getVehiclePredictions }),
  getContext({
    getCachedVehicle: PropTypes.func.isRequired,
    getVehiclePredictions: PropTypes.func.isRequired,
  }),
);
FormVehicleChips.propTypes = {
  getCachedVehicle: PropTypes.func,
  getVehiclePredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
  filter: PropTypes.object,
};

function FormVehicleChips({
  getCachedVehicle: getCachedVehicle1,
  getVehiclePredictions: getVehiclePredictions1,
  filter,
  ...props
}) {
  return (
    <FormAbstractChips
      {...props}
      getValue={(value) =>
        getCachedVehicle(value).map((item) =>
          fromJS({ id: item.get("id"), name: item.get("name") }),
        )
      }
      getPredictions={(searchText) =>
        getVehiclePredictions(
          baseFilter.setSearch(searchText).setValueMap(filter),
        )
      }
    />
  );
}

export default enhancer(FormVehicleChips);
