import React from "react";
import sprintf from "sprintf";
import { Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapProps,
  mapPropsStream,
  withContext,
  withHandlers,
} from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListSubheader,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormAutoComplete from "../form/FormAutoComplete";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { formatNumberMinLength, formatText } from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { isEnableEventDateAtBatchUpdate } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COLLECTED,
  CUSTOMER_NOT_PAID,
  PAID_TO_CUSTOMER,
  WITH_WING,
} from "../../constants/AdminCODCodes";
import {
  ACCEPTED,
  ARRIVED,
  COMPLETED,
  DISPATCHED,
  IN_SORTING_FACILITY,
  IN_TRANSIT,
  IN_TRANSIT_TO_SUPPLIER,
  ON_HIS_WAY,
  ON_HOLD,
  OUT_FOR_DELIVERY,
  OUT_FOR_RETURN,
  PICKED_UP,
} from "../../constants/OrderStatusCodes";
import transitModeOptions from "../../constants/TransitModeTypes";
import { ORDER_LIST_URL } from "../../constants/AdminPathConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const statusesWithDriver = Set.of(ACCEPTED, ON_HIS_WAY, IN_TRANSIT, DISPATCHED);

const statusesWithOptionalDriver = Set.of(
  ARRIVED,
  COMPLETED,
  PICKED_UP,
  OUT_FOR_RETURN,
);

const statusesWithWarehouse = Set.of(
  ON_HOLD,
  PICKED_UP,
  IN_TRANSIT,
  IN_SORTING_FACILITY,
  IN_TRANSIT_TO_SUPPLIER,
);

const formatOrderStatus = (status, getLocalisationMessage) => {
  switch (status) {
    // case ACCEPTED:
    //   return "Assign Driver For Pickup";

    default:
      return formatOrderStatusCodeForLocalisation(
        status,
        getLocalisationMessage,
      );
  }
};

const generateBarCodes = (orderNumbers, orderNumber, pieceCount) => {
  let i;
  const items = [];
  for (i = 1; i <= pieceCount; i++) {
    const barcode = sprintf(
      "%s-%s-%s",
      orderNumber,
      formatNumberMinLength(i, 2),
      formatNumberMinLength(pieceCount, 2),
    );
    if (!orderNumbers.includes(barcode)) items.push(barcode);
  }
  return items;
};

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isEnableEventDateAtBatchUpdate: isEnableEventDateAtBatchUpdate(state),
  })),
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: (props) => props.theme.palette.appBarTextColor,
      backgroundColor: (props) => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
    chip: { margin: "4px" },
    moreInfo: { marginTop: "5px", marginLeft: "10px" },
    form: {
      paddingTop: 15,
      "&> div": {
        marginBottom: 15,
      },
    },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
  withHandlers({
    onSubmit:
      (props) =>
      ({ driver, supplier, warehouse, supplierDriverOnly, ...restValues }) => {
        const values = { ...restValues };

        if (isValidObjectId(driver)) {
          values.driver = driver;
        }

        if (isValidObjectId(warehouse)) {
          values.warehouse = warehouse;
        }

        if (isValidObjectId(supplier)) {
          values.supplier = supplier;
        }

        if (values.codCode) {
          values.paid =
            values.codCode === COLLECTED ||
            values.codCode === WITH_WING ||
            values.codCode === CUSTOMER_NOT_PAID ||
            values.codCode === PAID_TO_CUSTOMER;

          values.paidToWing =
            values.codCode === WITH_WING ||
            values.codCode === CUSTOMER_NOT_PAID ||
            values.codCode === PAID_TO_CUSTOMER;

          values.paidToCustomer = values.codCode === PAID_TO_CUSTOMER;
        }

        return props.onSubmit(values);
      },
  }),
  reduxForm({
    form: "BatchUpdateOrderDialog",
    validate: (values, props) => ({
      orderNumbers:
        fp.isEmpty(values.orderNumbers) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("add_orders")) ||
          "Add Orders"),
      orderStatus:
        fp.isEmpty(values.orderStatus) &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_status")) ||
          "Select Status"),
      transitMode:
        Boolean(
          props.getCachedWarehouse &&
            fp.isEmpty(values.transitMode) &&
            (values.orderStatus === IN_TRANSIT ||
              values.orderStatus === IN_TRANSIT_TO_SUPPLIER),
        ) &&
        props.getLocalisationMessage(
          "select_transit_mode",
          "Select Transit Mode",
        ),
    }),
  }),
  formValues({
    supplier: "supplier",
    orderStatus: "orderStatus",
    transitMode: "transitMode",
    orderNumbers: "orderNumbers",
    supplierDriverOnly: "supplierDriverOnly",
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    (props) => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  mapPropsStream((propsStream) => {
    const selectedItemPiecesStream = propsStream
      .distinctUntilKeyChanged("orderNumbers", isEqualData)
      .map((props) => {
        const { list, selectedItems, orderNumbers, isSorting } = props;
        let items = [];

        if (selectedItems) {
          if (isSorting) {
            selectedItems.forEach((orderNumber) => {
              const pieceCount = list.getIn(
                [orderNumber, "info", "piece_count"],
                0,
              );
              if (pieceCount > 1) {
                items = items.concat(
                  generateBarCodes(orderNumbers, orderNumber, pieceCount),
                );
              }
            });
          } else {
            const idPieceMap = list
              .toOrderedMap()
              .mapEntries(([, v]) => [v.get("id"), v.get("piece_count")]);

            const sortedSelectedItems = idPieceMap.filter((v, k) =>
              selectedItems.has(k),
            );

            selectedItems.forEach((orderNumber, id) => {
              const pieceCount = sortedSelectedItems.get(id);
              if (orderNumbers.includes(orderNumber) && pieceCount > 1) {
                items = items.concat(
                  generateBarCodes(orderNumbers, orderNumber, pieceCount),
                );
              }
            });
          }
        }
        return items;
      })
      .distinctUntilChanged(isEqualData)
      .startWith([]);

    return propsStream
      .combineLatest(selectedItemPiecesStream, (props, selectedItemPieces) => ({
        ...props,
        selectedItemPieces,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  mapProps((props) => ({
    ...props,
    statusCodes: props.statusCodes.delete(OUT_FOR_DELIVERY),
  })),
);

BatchUpdateOrderDialog.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  supplier: PropTypes.object,
  orderStatus: PropTypes.string,
  transitMode: PropTypes.string,
  orderNumbers: PropTypes.array,
  supplierDriverOnly: PropTypes.bool,
  showSupplierDriverOnly: PropTypes.bool,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,

  getCachedDriver: PropTypes.func,
  getDriverPredictions: PropTypes.func,

  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,

  getCachedWarehouse: PropTypes.func,
  getWarehousePredictions: PropTypes.func,

  codCodes: PropTypes.instanceOf(Set),
  privacyTypes: PropTypes.instanceOf(Set),
  transitModeOptions: PropTypes.instanceOf(Set),
  statusCodes: PropTypes.instanceOf(Set).isRequired,

  withInTransitToSupplier: PropTypes.bool,
  setLocationQuery: PropTypes.func,
  setLocation: PropTypes.func,

  list: PropTypes.any,
  selectedItems: PropTypes.any,
  selectedItemPieces: PropTypes.array,
  isSorting: PropTypes.bool,
  isEnableEventDateAtBatchUpdate: PropTypes.bool,
  isSupplierTransitOrdersVisibility: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

BatchUpdateOrderDialog.defaultProps = {
  isSorting: false,
  isSupplierTransitOrdersVisibility: true,
  showSupplierDriverOnly: true,
  transitModeOptions,
};

function BatchUpdateOrderDialog(props) {
  const { classes, getLocalisationMessage } = props;

  const selectedItemPiecesSize = fp.size(props.selectedItemPieces);
  const limit = 8;

  const showDriverField =
    statusesWithDriver.has(props.orderStatus) ||
    statusesWithOptionalDriver.has(props.orderStatus);
  const showWarehouseField = statusesWithWarehouse.has(props.orderStatus);

  return (
    <Dialog open={props.open} maxWidth="auto" onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {`${getLocalisationMessage("batch_update", "Batch Update")} ${fp.size(
          props.orderNumbers,
        )} ${getLocalisationMessage(
          "batch_update_orders",
          "Batch Update Orders",
        )}`}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form className={classes.form}>
          <FormChipInput
            name="orderNumbers"
            focus={true}
            fullWidth={true}
            addOnBlur={true}
            label={getLocalisationMessage("orders_numbers", "Orders Numbers")}
          />

          {selectedItemPiecesSize > 0 &&
            (props.orderStatus === IN_TRANSIT ||
              props.orderStatus === IN_TRANSIT_TO_SUPPLIER ||
              props.orderStatus === DISPATCHED ||
              props.orderStatus === ACCEPTED) && (
              <div>
                <ListSubheader>
                  {getLocalisationMessage(
                    "please_sure_you_have_scanned_boxes_of_the_shipments",
                    "Please sure you have scanned boxes of the Shipments",
                  )}
                  :
                </ListSubheader>

                <FlexBox wrap={true}>
                  {props.selectedItemPieces.slice(0, limit).map((number) => (
                    <Chip key={number} className={classes.chip}>
                      {number}
                    </Chip>
                  ))}
                  {selectedItemPiecesSize - limit > 1 && (
                    <div className={classes.moreInfo}>
                      {getLocalisationMessage("and", "and")}{" "}
                      {getLocalisationMessage("more", "more")}{" "}
                      <strong>{selectedItemPiecesSize - limit}</strong>{" "}
                      {getLocalisationMessage("boxes", "boxes")}
                    </div>
                  )}
                </FlexBox>
              </div>
            )}

          <FormAutoComplete
            name="orderStatus"
            fullWidth={true}
            label={getLocalisationMessage("status", "Status")}
            options={props.statusCodes}
            hintText={getLocalisationMessage(
              "type_to_search",
              "Type To Search...",
            )}
            // formatOption={formatOrderStatus}
            formatOption={(x) => formatOrderStatus(x, getLocalisationMessage)}
          />

          {Boolean(
            props.getCachedSupplier &&
              props.isSupplierTransitOrdersVisibility &&
              (props.orderStatus === IN_TRANSIT ||
                props.orderStatus === IN_TRANSIT_TO_SUPPLIER),
          ) && (
            <FormSelectField
              autoWidth={true}
              fullWidth={true}
              name="transitMode"
              formatOption={(x) => getLocalisationMessage(x) || formatText(x)}
              options={props.transitModeOptions}
              label={getLocalisationMessage("transit_mode", "Transit Mode")}
            />
          )}

          {/* {Boolean( */}
          {/*  props.getCachedSupplier && */}
          {/*    props.isSupplierTransitOrdersVisibility && */}
          {/*    (props.orderStatus === ASSIGNED_TO_COURIER || */}
          {/*      (props.orderStatus === IN_TRANSIT && */}
          {/*        props.transitMode === CUSTOM_SUPPLIER) || */}
          {/*      (props.orderStatus === IN_TRANSIT_TO_SUPPLIER && */}
          {/*        props.transitMode === CUSTOM_SUPPLIER) || */}
          {/*      props.orderStatus === PREPARED_FOR_TRANSIT), */}
          {/* ) && ( */}
          {/*  <FormSupplierAutoComplete */}
          {/*    name="supplier" */}
          {/*    fullWidth={true} */}
          {/*    label={getLocalisationMessage("supplier", "Supplier")} */}
          {/*    hintText={getLocalisationMessage( */}
          {/*      "type_to_search", */}
          {/*      "Type To Search...", */}
          {/*    )} */}
          {/*  /> */}
          {/* )} */}

          {Boolean(
            showDriverField ||
              (props.orderStatus === IN_TRANSIT_TO_SUPPLIER &&
                props.withInTransitToSupplier),
          ) && (
            <FormDriverAutoComplete
              name="driver"
              fullWidth={true}
              label={getLocalisationMessage("driver", "Driver")}
              supplierId={
                props.supplierDriverOnly
                  ? getObjectId(props.supplier)
                  : undefined
              }
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
            />
          )}

          {Boolean(
            (props.supplier &&
              showDriverField &&
              props.showSupplierDriverOnly) ||
              (props.orderStatus === IN_TRANSIT_TO_SUPPLIER &&
                props.withInTransitToSupplier &&
                props.showSupplierDriverOnly),
          ) && (
            <FormCheckbox
              name="supplierDriverOnly"
              label={getLocalisationMessage(
                "supplier_driver_only",
                "Supplier Driver Only",
              )}
            />
          )}

          {showWarehouseField && (
            <FormWarehouseAutoComplete
              name="warehouse"
              label={getLocalisationMessage("warehouse", "Warehouse")}
              fullWidth={true}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type To Search...",
              )}
            />
          )}

          <FormTextField
            name="note"
            rows={4}
            rowsMax={40}
            multiLine={true}
            fullWidth={true}
            label={getLocalisationMessage("note_content", "Note Content")}
          />

          {props.privacyTypes && (
            <FormSelectField
              name="privacy"
              autoWidth={true}
              fullWidth={true}
              formatOption={(x) => getLocalisationMessage(x) || formatText(x)}
              label={getLocalisationMessage("privacy", "Privacy")}
              options={props.privacyTypes}
            />
          )}

          {COMPLETED === props.orderStatus && (
            <FormSelectField
              name="codCode"
              autoWidth={true}
              fullWidth={true}
              options={props.codCodes}
              label={getLocalisationMessage("cod_status", "Cod Status")}
              formatOption={(x) => getLocalisationMessage(x) || formatText(x)}
            />
          )}

          {props.isEnableEventDateAtBatchUpdate && (
            <FormDateField
              name="eventDate"
              fullWidth={true}
              label={getLocalisationMessage("event_date", "Event Date")}
            />
          )}

          {Boolean(
            props.orderStatus === IN_SORTING_FACILITY ||
              props.orderStatus === OUT_FOR_RETURN ||
              props.orderStatus === IN_TRANSIT_TO_SUPPLIER ||
              props.withInTransitToSupplier,
          ) && (
            <FormCheckbox
              name="keepSupplier"
              label={getLocalisationMessage("keep_supplier", "Keep Supplier")}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button primary={true} onClick={props.onRequestClose}>
          {getLocalisationMessage("cancel", "Cancel")}
        </Button>
        {props.isSorting ? (
          <Button
            primary={true}
            onClick={() =>
              props.setLocation(
                updateQuery(ORDER_LIST_URL, {
                  search: props.orderNumbers.join(","),
                }),
              )
            }
          >
            {getLocalisationMessage("show_in_the_list", "Show In The List")}
          </Button>
        ) : (
          <Button
            primary={true}
            onClick={() =>
              props.setLocationQuery({ search: props.orderNumbers.join(",") })
            }
          >
            {getLocalisationMessage("show_in_the_list", "Show In The List")}
          </Button>
        )}

        <Button primary={true} onClick={props.handleSubmit}>
          {getLocalisationMessage("ok", "Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchUpdateOrderDialog);
