import React from "react";
import { List as IList, Map, Set } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import { connect } from "react-redux";
import DriverAutoComplete from "../deprecated/DriverAutoComplete";
import {
  formatText,
  numberToCharacter,
  numberToColor,
} from "../../helpers/FormatUtils";
import { formatDriverName } from "../../helpers/DriverHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_DRIVER_RUNSHEET_URL } from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({ fetchDriverChoose: PropTypes.func.isRequired }),
);

OrderRouterSidebar.propTypes = {
  fetchDriverChoose: PropTypes.func,
  onDeleteAll: PropTypes.func.isRequired,
  onOrderDelete: PropTypes.func.isRequired,
  onOrderClick: PropTypes.func.isRequired,
  onFitToOrders: PropTypes.func.isRequired,
  onChangeClusterClick: PropTypes.func.isRequired,
  selectedClusterIndex: PropTypes.number,
  onClusterSelect: PropTypes.func.isRequired,
  clusters: PropTypes.instanceOf(IList),
  suggestions: PropTypes.instanceOf(IList),
  customSuggestions: PropTypes.instanceOf(IList),
  orders: PropTypes.instanceOf(Map).isRequired,
  driverIds: PropTypes.instanceOf(IList).isRequired,
  onDriverChange: PropTypes.func.isRequired,
  onSubmitSuggestions: PropTypes.func.isRequired,
  onRemoveDuplicatedOrderClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderRouterSidebar(props) {
  const {
    fetchDriverChoose,
    orders,
    clusters,
    suggestions,
    customSuggestions,
    onClusterSelect,
    selectedClusterIndex,
    onDriverChange,
    onSubmitSuggestions,
    onDeleteAll,
    // onOrderDelete,
    onOrderClick,
    onFitToOrders,
    // onChangeClusterClick,
    getLocalisationMessage,
  } = props;

  const count = orders.size;
  const items = [];
  let hasDriver = false;

  if (clusters && !clusters.isEmpty()) {
    items.push(<Divider key="total-divider" />);

    items.push(
      <ListItem key="total" disabled={true}>
        <ListItemText
          primary={`${getLocalisationMessage("total", "Total")} : ${
            clusters.size
          } ${getLocalisationMessage("routes", "routes")}`}
        />
      </ListItem>,
    );

    clusters.forEach((cluster, clusterIndex) => {
      const nestedItems = [];
      const overAllDuration = `${cluster.last().get("overAllDuration")}m`;

      let driver = customSuggestions.get(clusterIndex);
      let driverName = null;

      if (driver) {
        if (driver.get("id") > 0) {
          driverName = <strong>{driver.get("name")}</strong>;
        }
      } else {
        driver = suggestions.getIn([clusterIndex, "driver"]);

        if (driver) {
          const quota = suggestions.getIn([clusterIndex, "quota"]) || 0;
          const utilization =
            suggestions.getIn([clusterIndex, "utilization"]) || 0;

          driverName = (
            <strong>
              {driver.get("name")} ({utilization}/{quota})
            </strong>
          );
        }
      }

      let incompleteCluster = false;
      let sizeSet = Set();
      let supplierSet = Set();

      cluster.forEach((metrics, item) => {
        const id = item.get("orderId");

        if (!id) {
          return true; // it's warehouse, skip
        }

        const order = orders.get(id);

        if (!order) {
          incompleteCluster = true;

          return false;
        }
        const size =
          getLocalisationMessage(order.get("size")) ||
          formatText(order.get("size")) ||
          "Medium";

        sizeSet = sizeSet.add(size);

        if (order.getIn(["supplier", "id"]) > 0) {
          supplierSet = supplierSet.add(order.getIn(["supplier", "name"]));
        }

        nestedItems.push(
          <ListItem
            key={id}
            onClick={() => onOrderClick(id)}
            // rightIconButton={
            // TODO: IconMenu Needed replace
            //   <IconMenu
            //     iconButtonElement={
            //       <IconButton
            //         touch={true}
            //         tooltip={getLocalisationMessage("more", "More")}
            //         tooltipPosition="bottom-left"
            //       >
            //         <MoreVertIcon color={grey400} />
            //       </IconButton>
            //     }
            //   >
            //     <MenuItem onClick={() => onChangeClusterClick(id)}>
            //       {getLocalisationMessage("change_cluster", "Change Cluster")}
            //     </MenuItem>
            //   </IconMenu>
            // }
          >
            <ListItemText
              primary={
                <div>
                  {getLocalisationMessage("order", "Order")}
                  {": "}
                  <strong>{order.get("order_number")}</strong> ({size})
                </div>
              }
              secondaryText={
                <div style={{ height: "auto" }}>
                  <div>
                    {getLocalisationMessage("duration", "Duration")}
                    {": "}
                    <strong>
                      {metrics.get("overAllDuration")}{" "}
                      {getLocalisationMessage("min", "min")}
                    </strong>
                  </div>

                  {order.getIn(["supplier", "id"]) > 0 && (
                    <div>
                      {getLocalisationMessage("supplier", "Supplier")}
                      {": "}
                      <strong>
                        {order.getIn(["supplier", "name"]) ||
                          getLocalisationMessage("na", "N/A")}
                      </strong>
                    </div>
                  )}

                  <div>
                    {getLocalisationMessage("driver", "Driver")}
                    {": "}
                    <strong>{formatDriverName(order.get("driver"))}</strong>
                  </div>

                  <div>
                    {getLocalisationMessage(
                      "last_mile_driver",
                      "Last Mile Driver",
                    )}
                    {": "}
                    <strong>
                      {formatDriverName(order.get("last_mile_driver"))}
                    </strong>
                  </div>
                </div>
              }
              secondaryLines={2}
            />
          </ListItem>,
        );

        return true;
      });

      if (incompleteCluster) {
        return;
      }

      if (driver && driver.get("id") > 0) {
        hasDriver = true;
      }

      const size = nestedItems.length;
      const sign = numberToCharacter(clusterIndex);
      const style = {
        marginRight: "36px",
        marginBottom: "-4px",
        paddingBottom: "4px",
        borderBottom: `2px solid ${numberToColor(clusterIndex)}`,
      };

      const driverValue = driver
        ? { id: driver.get("id"), name: driver.get("name") }
        : {};

      nestedItems.push(
        <ListItem key="assign" disabled={true}>
          <DriverAutoComplete
            fullWidth={true}
            label={getLocalisationMessage("driver", "Driver")}
            value={driverValue}
            fetchDriverChoose={fetchDriverChoose}
            onChange={x => onDriverChange(clusterIndex, x)}
          />
        </ListItem>,
      );

      items.push(<Divider key={`${clusterIndex}-divider`} />);

      items.push(
        <ListItem
          key={clusterIndex}
          nestedItems={nestedItems}
          onClick={() =>
            onClusterSelect(
              clusterIndex === selectedClusterIndex ? null : clusterIndex,
            )
          }
        >
          <ListItemText
            primary={
              <div style={style}>
                <strong>{sign}</strong>:{" "}
                {driverName ||
                  (supplierSet.size === 1
                    ? supplierSet.first()
                    : supplierSet.size > 1
                    ? getLocalisationMessage("mixed", "Mixed")
                    : getLocalisationMessage("no_match", "No Match"))}{" "}
                {size} {getLocalisationMessage("in", "in")} {overAllDuration} (
                {sizeSet.join(", ")})
              </div>
            }
          />
        </ListItem>,
      );
    });

    items.push(<Divider key="submit-divider" />);

    items.push(
      <ListItem key="submit" onClick={onSubmitSuggestions}>
        <ListItemText
          primary={getLocalisationMessage(
            "submit_suggestions",
            "Submit Suggestions",
          )}
        />
      </ListItem>,
    );
  } else {
    orders.forEach(order => {
      const id = order.get("id");
      const address = order.getIn(["locations", 1, "address"]);
      const details = order.getIn(["locations", 1, "details"]);

      items.push(
        <ListItem
          key={id}
          onClick={() => onOrderClick(id)}
          // rightIconButton={
          // TODO: IconMenu Needed replace
          //   <IconMenu
          //     iconButtonElement={
          //       <IconButton
          //         touch={true}
          //         tooltip={getLocalisationMessage("more", "More")}
          //         tooltipPosition="bottom-left"
          //       >
          //         <MoreVertIcon color={grey400} />
          //       </IconButton>
          //     }
          //   >
          //     <MenuItem onClick={() => onOrderDelete(id)}>
          //       {getLocalisationMessage("delete", "Delete")}
          //     </MenuItem>
          //   </IconMenu>
          // }
        >
          <ListItemText
            primary={order.get("order_number")}
            secondary={
              <p>
                {address}
                {details && address === details ? (
                  <span>
                    <br />
                    {order.getIn(["locations", 1, "details"])}
                  </span>
                ) : null}
              </p>
            }
            secondaryLines={2}
          />
        </ListItem>,
      );
    });
  }

  return (
    <List>
      <ListSubheader>
        {count} {getLocalisationMessage("orders_on_map", "Orders on Map")}
      </ListSubheader>
      <Divider />
      <ListItem
        onClick={() =>
          onFitToOrders(orders.map(item => item.get("id")).toSet())
        }
      >
        <ListItemText
          primary={getLocalisationMessage("show_all_orders", "Show all orders")}
        />
      </ListItem>
      <Divider />
      <ListItem onClick={props.onRemoveDuplicatedOrderClick}>
        <ListItemText
          primary={getLocalisationMessage(
            "remove_duplicated_orders",
            "Remove Duplicated Orders",
          )}
        />
      </ListItem>
      <Divider />
      <ListItem onClick={onDeleteAll}>
        <ListItemText
          primary={getLocalisationMessage("remove_all", "Remove All")}
        />
      </ListItem>

      {items}

      {hasDriver ? (
        <ListItem
          target="_blank"
          component="a"
          href={updateQuery(CREATE_DRIVER_RUNSHEET_URL, {
            ids: props.driverIds.join(","),
          })}
        >
          <ListItemText
            primary={getLocalisationMessage(
              "download_runsheet",
              "Download Runsheet",
            )}
          />
        </ListItem>
      ) : null}
    </List>
  );
}

export default enhancer(OrderRouterSidebar);
