import React from "react";
import { OrderedSet } from "immutable";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Create, OpenInNew } from "@material-ui/icons";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import {
  isValidObjectId,
  validateEmailLocalisation,
  validateString,
} from "../../helpers/ValidateUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVE, INACTIVE } from "../../constants/OverallStatus";

const valueSelector = formValueSelector("SupplierUserForm");

const Statuses = OrderedSet([ACTIVE, INACTIVE]);

const enhancer = compose(
  connect((state) => ({
    status: valueSelector(state, "status"),
    supplier: valueSelector(state, "supplier"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
  })),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    (props) => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  reduxForm({
    form: "SupplierUserForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      email: validateEmailLocalisation(
        values.email,
        props.getLocalisationMessage,
      ),
      firstName: validateString(values.firstName)
        ? props.getLocalisationMessage("enter_first_name", "Enter First Name")
        : null,
    }),
  }),
);

SupplierUserForm.propTypes = {
  supplier: PropTypes.object,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  status: PropTypes.string,

  onDismiss: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,

  onSupplierClick: PropTypes.func,
  onCreateSupplierClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  isRTL: PropTypes.bool,
};

function SupplierUserForm(props) {
  return (
    <Card>
      <PageLoading isLoading={props.submitting} />

      <Card>
        <CardContent>
          <FlexBox container={8} direction="column">
            <FlexBox gutter={8}>
              <FlexBox flex={true}>
                <FormTextField
                  name="firstName"
                  fullWidth={true}
                  label={`${props.getLocalisationMessage(
                    "first_name",
                    "First Name",
                  )} *`}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  name="lastName"
                  fullWidth={true}
                  label={props.getLocalisationMessage("last_name", "Last Name")}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox gutter={8}>
              <FlexBox flex={true}>
                <FormTextField
                  ignoreRtl={props.isRTL}
                  name="phone"
                  fullWidth={true}
                  label={props.getLocalisationMessage("phone", "Phone")}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  name="email"
                  fullWidth={true}
                  label={`${props.getLocalisationMessage("email", "Email")} *`}
                />
              </FlexBox>
              <FlexBox flex={true}>
                <FormTextField
                  name="password"
                  type="password"
                  fullWidth={true}
                  label={`${props.getLocalisationMessage(
                    "password",
                    "Password",
                  )} *`}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox gutter={8}>
              {props.getSupplierPredictions && (
                <FlexBox flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FlexBox flex={true}>
                      <FormSupplierAutoComplete
                        name="supplier"
                        fullWidth={true}
                        openOnFocus={false}
                        label={`${props.getLocalisationMessage(
                          "supplier",
                          "Supplier",
                        )} *`}
                        hintText={props.getLocalisationMessage(
                          "type_to_search",
                          "Type to search ...",
                        )}
                      />
                    </FlexBox>

                    {isValidObjectId(props.supplier)
                      ? Boolean(props.onSupplierClick) && (
                          <FlexBox align="center">
                            <Tooltip
                              title={props.getLocalisationMessage(
                                "edit_supplier",
                                "Edit Supplier",
                              )}
                            >
                              <IconButton
                                onClick={() =>
                                  props.onSupplierClick(props.supplier.id)
                                }
                              >
                                <OpenInNew />
                              </IconButton>
                            </Tooltip>
                          </FlexBox>
                        )
                      : Boolean(props.onCreateSupplierClick) && (
                          <FlexBox align="center">
                            <Tooltip
                              title={props.getLocalisationMessage(
                                "create_supplier",
                                "Create Supplier",
                              )}
                            >
                              <IconButton onClick={props.onCreateSupplierClick}>
                                <Create />
                              </IconButton>
                            </Tooltip>
                          </FlexBox>
                        )}
                  </FlexBox>
                </FlexBox>
              )}

              <FlexBox flex={true} align="flex-end">
                <FormSelectField
                  name="status"
                  fullWidth={true}
                  options={Statuses}
                  formatOption={(x) =>
                    props.getLocalisationMessage(x) || formatText(x)
                  }
                  label={`${props.getLocalisationMessage(
                    "status",
                    "Status",
                  )} *`}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>

        <FlexBox justify="flex-end">
          <CardActions>
            {props.dirty ? (
              <Button onClick={props.reset}>
                {props.getLocalisationMessage("reset", "Reset")}
              </Button>
            ) : (
              Boolean(props.onDismiss) && (
                <Button onClick={props.onDismiss}>
                  {props.getLocalisationMessage("dismiss", "Dismiss")}
                </Button>
              )
            )}

            <Button onClick={props.handleSubmit}>
              {props.getLocalisationMessage("submit", "Submit")}
            </Button>
          </CardActions>
        </FlexBox>
      </Card>
    </Card>
  );
}

export default enhancer(SupplierUserForm);
