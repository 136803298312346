import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { fromJS, List, Map, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withContext, withState } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { ContactPhone } from "@material-ui/icons";
import OrderCityField from "./OrderCityField";
import OrderNeighborhoodField from "./OrderNeighborhoodField";
import FormCheckbox from "../form/FormCheckbox";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import FormImageListUpload from "../form/FormImageListUploadV2";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormCountryAutoComplete from "../form/FormCountryAutoComplete";
import FormCountyPhoneCodeField from "../form/FormCountyPhoneCodeField";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FormYandexGeoAutoComplete from "../form/FormYandexGeoAutoComplete";
import ManualSetOrderLocationDialog from "../form/ManualSetOrderLocationDialog";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import PriceWrapper from "../ui-core/PriceWrapper";
import AdminAddressBookSelectV2DialogPickup from "../address-book-core/AdminAddressBookSelectV2DialogPickup";
import AdminAddressBookSelectV2DialogDropOff from "../address-book-core/AdminAddressBookSelectV2DialogDropOff";
import { getProperGeoAddressObj } from "../../helpers/GeoUtils";
import {
  getValue,
  isEmpty,
  isEqualData,
  isEqualDataIn,
  isShallowEqual,
  toArray,
  toPlainObject,
} from "../../helpers/DataUtils";
import { isValidDate, safeParseDate } from "../../helpers/DateUtils";
import {
  cuteCodeFromPhone,
  findPhoneCodeByCountryName,
  formatDateToUrl,
  formatText,
  parseOnlyPositiveFloat,
  parsePhone,
} from "../../helpers/FormatUtils";
import {
  formatOrderStatusCodeLocalization,
  formatPaymentTypeI18n,
  getPhoneCode,
  shouldDisablePayer,
  shouldDisableRecipientNotAvailable,
} from "../../helpers/OrderHelper";
import {
  isBlankString,
  isPositive,
  isValidCoordinates,
  isValidObjectId,
  validatePackage,
  validatePackageMenu,
  validatePaymentType,
  validatePhoneNumber,
  validateString,
} from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { geocode } from "../../helpers/GoogleMapsHelper";
import {
  getMarketplace as getStateMarketplace,
  isCustomMarketplace,
  showMarketplaceAlternatePhoneNumbers,
} from "../../reducers/MarketplaceReducer";
import { getIsRTL, getMessages } from "../../reducers/LocalizationReducer";
import OrderTypes, { REGULAR_ORDER } from "../../constants/OrderTypes";
import AddressTypes, { RESIDENTIAL } from "../../constants/AddressType";
import OrderPayerTypes, {
  RECIPIENT,
  SENDER,
} from "../../constants/OrderPayerTypes";
import { CREDIT_BALANCE } from "../../constants/OrderPaymentTypes";
import UnitsOfMeasureTypes, {
  CM_KG,
  INCHES_POUNDS,
} from "../../constants/UnitsOfMeasureTypes";
import {
  COD,
  SERVICE,
  SERVICE_CUSTOM,
} from "../../constants/OrderChargeItemTypes";
import OrderRecipientNotAvailableActionTypes, {
  DO_NOT_DELIVER,
} from "../../constants/OrderRecipientNotAvailableActionTypes";
import { getCountries } from "../../api/shared/CountryApi";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import { getPackagePricesStaringFrom } from "../../api/shared/PackagePricesApi";
import { getVolumetricWeight } from "../../api/shared/PackageVolumetricWeightApi";
import {
  getCourierPrices,
  getLocationDetails,
} from "../../api/v2/admin/AdminOrderApi";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { YANDEX_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";

// import { Autocomplete } from '@material-ui/lab';

// <div>
//           <Autocomplete
//             id="combo-box-demo"
//             options={[{title: 'title1', year: 1}, {title: 'title2', year: 2}, {title: 'title3', year: 3}]}
//             getOptionLabel={(option) => option.title}
//             style={{ width: 300 }}
//             renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
//           />
//         </div>

const PICKUP = "PICKUP";
const DROP = "DROP";

const trueFalseOptions = List.of(true, false);

const getValues = getFormValues("OrderFormV2");

const getCountryCode = fp.flow(
  fp.first,
  fp.get("address_components"),
  fp.find(fp.flow(fp.get("types"), fp.includes("country"))),
  fp.get("short_name"),
);

const formatUnitsOfMeasureTypes = (type, i18n) => {
  switch (type) {
    case CM_KG:
      return i18n.get("cm_kg", "cm/kg");
    case INCHES_POUNDS:
      return i18n.get("inches_pounds", "Inches/Pounds");
    default:
      return type;
  }
};

const getDimensionText = (unit, dimensionsArray: Array) => {
  if (unit) {
    return `(${dimensionsArray[unit === INCHES_POUNDS ? 0 : 1]})`;
  }
  return "";
};

const validateLocation = (location, country, i18n) =>
  !isValidCoordinates(location)
    ? i18n.get("type_address_above_to_search", "Type address above to search")
    : getValue(location, "country") !== getValue(country, "code") &&
      "Please select address with selected country";

const mapResponseDataMap = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      data: response.getIn(["payload", "data"]),
    }),
);

const mapResponseDataAsList = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      list: response.getIn(["payload", "data"], List()),
    }),
);

const isValidCOD = fp.overSome([fp.isNil, isPositive]);
const hasSameId = isEqualDataIn("id");

const enhancer = compose(
  useSheet({
    addressInfo: { marginTop: "15px", width: "100%" },
    autocompleteRtl: {
      "& input": {
        direction: "ltr",
        textAlign: "right",
      },
    },
    autocompleteLtr: {
      "& input": {
        textAlign: "initial",
      },
    },
    spanCode: {
      width: "18%",
      marginBottom: "10px",
      marginRight: "1%",
      fontSize: "16px",
      textAlign: "right",
    },
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
      getCachedCity: PropTypes.func,
      getCityPredictions: PropTypes.func,
      getCachedPostcode: PropTypes.func,
      getPostcodePredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
    }),
  ),
  connect(state => ({
    isCustom: isCustomMarketplace(state),
    i18n: getMessages(state),
    isRTL: getIsRTL(state),
    orderReferenceIdVisibility: getStateMarketplace(state).getIn(
      ["setting", "settings", "orderReferenceIdVisibility"],
      false,
    ),
    showMarketplaceAlternatePhoneNumbers: showMarketplaceAlternatePhoneNumbers(
      state,
    ),
    mapProvider: getMapProvider(state),
  })),
  withState("state", "setState", {
    showPickUpAddressDialog: false,
    showDropoffAddressDialog: false,
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .pluck("order")
      .distinctUntilChanged(isEqualData)
      .combineLatest(getCountries().distinctUntilChanged(isEqualData))
      .switchMap(([order, countries]) => {
        if (isEmpty(order)) {
          const marketplaceStream = getMarketplace()
            .takeLast(1)
            .catch(() => Observable.of({}))
            .map(fp.flow(fp.get("payload.data"), fp.toPlainObject));

          return marketplaceStream.map(
            fp.flow(
              item => item.country.id,
              countryId => {
                const country = countries
                  .find(c => c.get("id") === countryId)
                  .toJS();

                return (
                  country || countries.find(c => c.get("code") === "AE").toJS()
                );
              },
              country => ({ pickupCountry: country, dropoffCountry: country }),
            ),
          );
        }
        const pickupCountryCode = order.getIn([
          "sender_data",
          "country",
          "code",
        ]);
        const dropoffCountryCode = order.getIn([
          "recipient_data",
          "country",
          "code",
        ]);
        if (Boolean(pickupCountryCode) && Boolean(dropoffCountryCode)) {
          const pickupCountry = countries
            .find(c => c.get("code") === pickupCountryCode)
            .toJS();
          const dropoffCountry = countries
            .find(c => c.get("code") === dropoffCountryCode)
            .toJS();

          return Observable.of({ pickupCountry, dropoffCountry, order });
        }

        return Observable.combineLatest(
          geocode({
            location: {
              lat: order.getIn(["sender_data", "lat"]),
              lng: order.getIn(["sender_data", "lon"]),
            },
          }),
          geocode({
            location: {
              lat: order.getIn(["recipient_data", "lat"]),
              lng: order.getIn(["recipient_data", "lon"]),
            },
          }),
          (pickup, dropoff) => {
            const pickupCountry = countries.find(
              c => c.get("code") === getCountryCode(pickup).toJS(),
            );
            const dropoffCountry = countries.find(
              c => c.get("code") === getCountryCode(dropoff).toJS(),
            );

            return { pickupCountry, dropoffCountry, order };
          },
        );
      })
      .distinctUntilChanged(isEqualData)
      .filter(v => v && v.order)
      .map(({ pickupCountry, dropoffCountry, order }) => {
        const chargeItems = order.get("charge_items")
          ? order
              .get("charge_items")
              .toMap()
              .mapEntries(([, v]) => [v.get("charge_type"), v])
          : Map();

        const pickupCity = order.getIn(["sender_data", "city", "id"], null)
          ? order.getIn(["sender_data", "city"])
          : order.getIn(["sender_data", "city", "name"]);
        const pickupNeighborhood = order.getIn(
          ["sender_data", "neighborhood", "id"],
          null,
        )
          ? order.getIn(["sender_data", "neighborhood"])
          : order.getIn(["sender_data", "neighborhood", "name"]);

        const dropoffCity = order.getIn(["recipient_data", "city", "id"], null)
          ? order.getIn(["recipient_data", "city"])
          : order.getIn(["recipient_data", "city", "name"]);
        const dropoffNeighborhood = order.getIn(
          ["recipient_data", "neighborhood", "id"],
          null,
        )
          ? order.getIn(["recipient_data", "neighborhood"])
          : order.getIn(["recipient_data", "neighborhood", "name"]);

        return {
          id: order.get("id"),
          reference_id: order.get("reference_id"),

          customer: toPlainObject(order.get("customer")),
          supplier: toPlainObject(order.get("supplier")),
          driver: toPlainObject(order.get("driver")),

          package: order.get("package_type"),
          menu: order.get("package_type"),
          attachments: toArray(order.get("attachments")),

          note: order.get("note"),
          payer: order.get("payer"),
          status: order.get("status"),
          pieceCount: order.get("piece_count", 1),
          parcelValue: order.get("parcel_value"),
          fragile: order.get("fragile"),
          paymentType: order.get("payment_type"),
          recipientNotAvailable: order.get("recipient_not_available"),

          codCharge: chargeItems.getIn([COD, "charge"], null),
          codChargePaid: chargeItems.getIn([COD, "paid"], false),
          logistic_type: order
            .get("logistic_type", REGULAR_ORDER)
            .toLowerCase(),

          serviceCharge: chargeItems.getIn([SERVICE, "charge"], null),
          serviceChargePaid: chargeItems.getIn([SERVICE, "paid"], false),

          pickupCountry,
          pickupDetails: order.getIn(["locations", 0, "details"]),
          pickupCity,
          pickupBuilding: order.getIn(["sender_data", "building"]),
          pickupApartment: order.getIn(["sender_data", "apartment"]),
          pickupStreet: order.getIn(["sender_data", "street"]),
          pickupNeighborhood,
          pickupNearestLandmark: order.getIn(["sender_data", "landmark"]),
          pickupAddressType: order.getIn(
            ["sender_data", "address_type"],
            RESIDENTIAL,
          ),
          pickupContactName: order.getIn(["sender_data", "name"]),
          pickupContactEmail: order.getIn(["sender_data", "email"]),
          pickupContactPhoneCode: {
            code: order.getIn(
              ["sender_data", "phone_code"],
              findPhoneCodeByCountryName(getValue(pickupCountry, "name")),
            ),
          },
          pickupContactPhone: cuteCodeFromPhone(
            order.getIn(["sender_data", "phone"]),
            order.getIn(["sender_data", "phone_code"]),
          ),
          pickupSecondPhone: order.getIn(["sender_data", "second_phone"]),
          pickupThirdPhone: order.getIn(["sender_data", "third_phone"]),
          pickupLocation: {
            lat: order.getIn(["sender_data", "lat"]),
            lng: order.getIn(["sender_data", "lon"]),
            address:
              order.getIn(["sender_data", "geo_address"]) ||
              order.getIn(["sender_data", "address"]),
            country: getValue(pickupCountry, "code"),
          },
          pickupContactSave: false,

          dropoffCountry,
          dropoffDetails: order.getIn(["locations", 1, "details"]),
          dropoffStreet: order.getIn(["recipient_data", "street"]),
          dropoffCity,
          dropoffBuilding: order.getIn(["recipient_data", "building"]),
          dropoffApartment: order.getIn(["recipient_data", "apartment"]),
          dropoffNeighborhood,
          dropoffNearestLandmark: order.getIn(["recipient_data", "landmark"]),
          dropoffAddressType: order.getIn(
            ["recipient_data", "address_type"],
            RESIDENTIAL,
          ),
          dropoffContactName: order.getIn(["recipient_data", "name"]),
          dropoffContactEmail: order.getIn(["recipient_data", "email"]),
          dropoffContactPhoneCode: {
            code: order.getIn(
              ["recipient_data", "phone_code"],
              findPhoneCodeByCountryName(getValue(dropoffCountry, "name")),
            ),
          },
          dropoffContactPhone: cuteCodeFromPhone(
            order.getIn(["recipient_data", "phone"]),
            order.getIn(["recipient_data", "phone_code"]),
          ),
          dropoffSecondPhone: order.getIn(["recipient_data", "second_phone"]),
          dropoffThirdPhone: order.getIn(["recipient_data", "third_phone"]),
          dropoffLocation: {
            lat: order.getIn(["recipient_data", "lat"]),
            lng: order.getIn(["recipient_data", "lon"]),
            address:
              order.getIn(["recipient_data", "geo_address"]) ||
              order.getIn(["recipient_data", "address"]),
            country: getValue(dropoffCountry, "code"),
          },
          dropoffContactSave: false,
          dimensionsUnit: fp.toUpper(
            order.getIn(["dimensions", "unit"], "METRIC"),
          ),
          dimensionsWidth: order.getIn(["dimensions", "width"], 32),
          dimensionsLength: order.getIn(["dimensions", "length"], 45),
          dimensionsHeight: order.getIn(["dimensions", "height"], 1),
          dimensionsWeight: order.get("chargeable_weight", 0),
          dimensionsVolumetricWeight: 0,

          pickupTimeSlot: fromJS({
            timeslot_availability_id: order.get(
              "pickup_timeslot_availability_id",
            ),
          }),
          pickupDate: safeParseDate(order.get("pickup_date")),

          deliveryTimeSlot: fromJS({
            timeslot_availability_id: order.get(
              "drop_off_timeslot_availability_id",
            ),
          }),
          dropOffDate: safeParseDate(order.get("drop_off_date")),
        };
      })
      .startWith(null);

    const handleSubmit = props => values => {
      if (!props.onSubmit) {
        return null;
      }

      const pickupTimeSlotId =
        values.pickupTimeSlot &&
        values.pickupTimeSlot.get("timeslot_availability_id");
      const deliveryTimeSlotId =
        values.deliveryTimeSlot &&
        values.deliveryTimeSlot.get("timeslot_availability_id");

      return props.onSubmit({
        pickup_time_now: !pickupTimeSlotId, // legacy api

        id: values.id,
        reference_id: values.reference_id,
        customer_id: isValidObjectId(values.customer)
          ? values.customer.id
          : null,
        supplier_id: isValidObjectId(values.supplier)
          ? values.supplier.id
          : null,
        driver_id: isValidObjectId(values.driver) ? values.driver.id : null,
        attachments: values.attachments,

        note: values.note,
        payer: values.payer,
        status: values.status,
        logistic_type:
          values.logistic_type && values.logistic_type.toUpperCase(),
        piece_count: values.pieceCount > 0 ? values.pieceCount : 1,
        parcel_value: values.parcelValue,
        fragile: values.fragile,
        payment_type: values.paymentType,
        recipient_not_available: values.recipientNotAvailable,

        charge_items: [
          {
            charge_type: COD,
            charge: values.codCharge,
            paid: values.codChargePaid,
          },
          {
            charge_type: SERVICE_CUSTOM,
            charge: values.serviceCharge,
            paid: values.serviceChargePaid,
          },
        ],

        sender_data: {
          address_type: values.pickupAddressType,
          name: values.pickupContactName,
          email: values.pickupContactEmail,
          apartment: values.pickupApartment,
          building: values.pickupBuilding,
          city: _.isObject(values.pickupCity)
            ? values.pickupCity
            : { name: values.pickupCity },
          country: values.pickupCountry,
          landmark: values.pickupNearestLandmark,
          street: values.pickupStreet,
          neighborhood: _.isObject(values.pickupNeighborhood)
            ? values.pickupNeighborhood
            : { name: values.pickupNeighborhood },
          phone: values.pickupContactPhone,
          phone_code: values.pickupContactPhoneCode.code,
          second_phone: values.pickupSecondPhone,
          third_phone: values.pickupThirdPhone,

          lat: values.pickupLocation.lat,
          lon: values.pickupLocation.lng,
          geo_address: values.pickupLocation.address,
          save: values.pickupContactSave,
        },

        recipient_data: {
          address_type: values.dropoffAddressType,
          name: values.dropoffContactName,
          email: values.dropoffContactEmail,
          apartment: values.dropoffApartment,
          building: values.dropoffBuilding,
          city: _.isObject(values.dropoffCity)
            ? values.dropoffCity
            : { name: values.dropoffCity },
          country: values.dropoffCountry,
          landmark: values.dropoffNearestLandmark,
          street: values.dropoffStreet,
          neighborhood: _.isObject(values.dropoffNeighborhood)
            ? values.dropoffNeighborhood
            : { name: values.dropoffNeighborhood },
          phone: values.dropoffContactPhone,
          phone_code: values.pickupContactPhoneCode.code,
          second_phone: values.dropoffSecondPhone,
          third_phone: values.dropoffThirdPhone,

          lat: values.dropoffLocation.lat,
          lon: values.dropoffLocation.lng,
          geo_address: values.dropoffLocation.address,
          save: values.dropoffContactSave,
        },

        dimensions: {
          width: values.dimensionsWidth,
          length: values.dimensionsLength,
          height: values.dimensionsHeight,
          unit: values.dimensionsUnit,
          weight:
            values.dimensionsWeight && values.dimensionsVolumetricWeight
              ? values.dimensionsWeight > values.dimensionsVolumetricWeight
                ? values.dimensionsWeight
                : values.dimensionsVolumetricWeight
              : values.dimensionsVolumetricWeight,
        },

        package_type: {
          id: values.package.get("id"),
          package_price: {
            id: values.package.getIn(["price", "id"]),
          },
        },

        pickup_timeslot_availability_id: pickupTimeSlotId || null,
        pickup_date: pickupTimeSlotId ? values.pickupDate : null,

        drop_off_timeslot_availability_id: deliveryTimeSlotId || null,
        drop_off_date: deliveryTimeSlotId ? values.dropOffDate : null,
      });
    };

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isShallowEqual);
  }),
  reduxForm({
    form: "OrderFormV2",
    enableReinitialize: true,

    validate: (values, props) => ({
      pickupCountry:
        !getValue(values.pickupCountry, "id") &&
        props.i18n.get(
          "please_select_pickup_country",
          "Please select pickup country",
        ),
      pickupLocation: validateLocation(
        values.pickupLocation,
        values.pickupCountry,
        props.i18n,
      ),
      pickupContactName: validateString(
        values.pickupContactName,
        props.i18n.get("please_enter_sender_name", "Please enter sender name"),
      ),

      pickupCity:
        isBlankString(values.pickupCity) &&
        props.i18n.get("please_select_city", "Please select city"),

      pickupNeighborhood:
        isBlankString(values.pickupNeighborhood) &&
        props.i18n.get("this_field_is_required", "This field is required"),

      pickupBuilding: isBlankString(values.pickupBuilding)
        ? props.i18n.get("this_field_is_required", "This field is required")
        : fp.size(values.pickupBuilding) > 512 &&
          props.i18n.get(
            "the_field_value_is_too_long",
            "The field value is too long",
          ),

      pickupContactPhone: validatePhoneNumber(values.pickupContactPhone),
      customer:
        !isValidObjectId(values.customer) &&
        props.i18n.get("customer_is_required", "Customer is required"),
      paymentType:
        validatePaymentType(values.paymentType) &&
        props.i18n.get("payment_type_is_required", "Payment Type is required"),
      package:
        validatePackage(values.package) &&
        props.i18n.get("package_is_required", "Package is required"),
      menu:
        validatePackageMenu(values.menu) &&
        props.i18n.get("this_field_is_required", "This field is required"),

      dropoffCountry:
        !getValue(values.dropoffCountry, "id") &&
        props.i18n.get(
          "Please_select_dropoff_country",
          "Please select dropoff country",
        ),
      dropoffLocation: validateLocation(
        values.dropoffLocation,
        values.dropoffCountry,
        props.i18n,
      ),
      dropoffContactName: validateString(
        values.dropoffContactName,
        props.i18n.get(
          "please_enter_recipient_name",
          "Please enter recipient name",
        ),
      ),
      dropoffContactPhone: validatePhoneNumber(values.dropoffContactPhone),

      dropoffBuilding: isBlankString(values.dropoffBuilding)
        ? props.i18n.get("this_field_is_required", "This field is required")
        : fp.size(values.dropoffBuilding) > 512 &&
          props.i18n.get(
            "the_field_value_is_too_long",
            "The field value is too long",
          ),

      dropoffAddressType:
        isBlankString(values.dropoffAddressType) &&
        props.i18n.get("this_field_is_required", "This field is required"),
      dropoffCity:
        isBlankString(values.dropoffCity) &&
        props.i18n.get("please_select_city", "Please select city"),
      dropoffNeighborhood:
        isBlankString(values.dropoffNeighborhood) &&
        props.i18n.get("this_field_is_required", "This field is required"),

      codCharge:
        !isValidCOD(values.codCharge) &&
        props.i18n.get("please_enter_valid_price", "Please enter valid price"),
      serviceCharge:
        !isPositive(values.serviceCharge) &&
        props.i18n.get("please_enter_valid_price", "Please enter valid price"),
      parcelValue:
        !isValidCOD(values.parcelValue) &&
        props.i18n.get("please_enter_valid_price", "Please enter valid price"),

      pieceCount:
        values.pieceCount > 100 &&
        props.i18n.get(
          "please_enter_valid_piece_count",
          "Please enter valid piece Count",
        ),
      dimensionsWeight:
        values.dimensionsWeight + values.dimensionsVolumetricWeight <= 0 &&
        props.i18n.get(
          "weight_or_volumetric_weight_is_required",
          "Weight or volumetric weight is required",
        ),
      pickupContactPhoneCode:
        !values.pickupContactPhoneCode &&
        props.i18n.get("please_enter_phone_code", "Please enter phone code"),
      dropoffContactPhoneCode:
        !values.dropoffContactPhoneCode &&
        props.i18n.get("please_enter_phone_code", "Please enter phone code"),
    }),
  }),

  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
        menuId: values.menu && values.menu.get("id"),
        courierType: values.menu && values.menu.getIn(["courier_type", "type"]),
        packageId: values.package && values.package.get("id"),
        customerId: values.customer && values.customer.id,
        supplierId: values.supplier && values.supplier.id,
        priceId: values.package && values.package.getIn(["price", "id"]),
        packageSupplierId:
          values.package && values.package.getIn(["supplier", "id"]),
        chargeAbleWeight:
          values.dimensionsWeight && values.dimensionsVolumetricWeight
            ? values.dimensionsWeight > values.dimensionsVolumetricWeight
              ? values.dimensionsWeight
              : values.dimensionsVolumetricWeight
            : values.dimensionsVolumetricWeight
            ? values.dimensionsVolumetricWeight
            : values.dimensionsWeight,
        domestic: hasSameId(values.pickupCountry, values.dropoffCountry),
        validLocations:
          isValidCoordinates(values.pickupLocation) &&
          isValidCoordinates(values.dropoffLocation),
      },
    })),
  ),
  mapPropsStream(propsStream => {
    const pickupLocationDetailsStream = propsStream
      .map(props =>
        props.values.pickupLocation.lat && props.values.pickupLocation.lng
          ? new DataListFilter({
              lat: props.values.pickupLocation.lat,
              lon: props.values.pickupLocation.lng,
            })
          : null,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        filter
          ? getLocationDetails(filter).catch(error => Observable.of({ error }))
          : Observable.of({}),
      )
      .map(mapResponseDataMap)
      .distinctUntilChanged(isEqualData);

    const dropoffLocationDetailsStream = propsStream
      .map(props =>
        props.values.dropoffLocation.lat && props.values.dropoffLocation.lng
          ? new DataListFilter({
              lat: props.values.dropoffLocation.lat,
              lon: props.values.dropoffLocation.lng,
            })
          : null,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        filter
          ? getLocationDetails(filter).catch(error => Observable.of({ error }))
          : Observable.of({}),
      )
      .map(mapResponseDataMap)
      .distinctUntilChanged(isEqualData);

    const volumetricWeightResponseStream = propsStream
      .map(props =>
        props.values.dimensionsWidth &&
        props.values.dimensionsLength &&
        props.values.dimensionsHeight &&
        props.values.dimensionsUnit
          ? new DataListFilter({
              width: props.values.dimensionsWidth,
              length: props.values.dimensionsLength,
              height: props.values.dimensionsHeight,
              unit: props.values.dimensionsUnit,
              domestic: props.values.domestic,
            })
          : null,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        filter
          ? getVolumetricWeight(filter).catch(error => Observable.of({ error }))
          : Observable.of({}),
      )
      .map(mapResponseDataMap)
      .distinctUntilChanged(isEqualData);

    const packageMenuStream = propsStream
      .map(props =>
        props.values.dimensionsUnit &&
        props.values.validLocations &&
        props.values.pickupCountry &&
        props.values.dropoffCountry &&
        props.values.chargeAbleWeight
          ? new DataListFilter({
              logistic_type:
                props.values.logistic_type &&
                props.values.logistic_type.toUpperCase(),
              "dimensions.unit": props.values.dimensionsUnit,
              "dimensions.length": props.values.dimensionsLength,
              "dimensions.width": props.values.dimensionsWidth,
              "dimensions.height": props.values.dimensionsHeight,
              "dimensions.weight": props.values.chargeAbleWeight,
              from_latitude: props.values.pickupLocation.lat,
              from_longitude: props.values.pickupLocation.lng,
              from_country_id: props.values.pickupCountry.id,
              to_country_id: props.values.dropoffCountry.id,
              to_latitude: props.values.dropoffLocation.lat,
              to_longitude: props.values.dropoffLocation.lng,
              customerId: props.values.customerId,
            })
          : null,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        filter
          ? getPackagePricesStaringFrom(filter).catch(error =>
              Observable.of({ error }),
            )
          : Observable.of({}),
      )
      .startWith({})
      .map(response =>
        fromJS(response).getIn(["payload", "data", "list"], List()),
      )
      .distinctUntilChanged(isEqualData);

    const packagePricesStream = propsStream
      .map(props =>
        props.values.menu &&
        props.values.courierType &&
        props.values.validLocations &&
        props.values.dimensionsUnit &&
        props.values.dimensionsLength &&
        props.values.dimensionsHeight &&
        props.values.chargeAbleWeight &&
        props.values.pickupCountry &&
        props.values.dropoffCountry &&
        props.values.dimensionsWidth
          ? new DataListFilter({
              logistic_type:
                props.values.logistic_type &&
                props.values.logistic_type.toUpperCase(),
              "dimensions.unit": props.values.dimensionsUnit,
              "dimensions.length": props.values.dimensionsLength,
              "dimensions.height": props.values.dimensionsHeight,
              "dimensions.weight": props.values.chargeAbleWeight,
              "dimensions.width": props.values.dimensionsWidth,
              from_latitude: props.values.pickupLocation.lat,
              from_longitude: props.values.pickupLocation.lng,
              from_country_id: props.values.pickupCountry.id,
              to_country_id: props.values.dropoffCountry.id,
              to_latitude: props.values.dropoffLocation.lat,
              to_longitude: props.values.dropoffLocation.lng,
              courier_type: fp.toUpper(props.values.courierType),
              customerId: props.values.customerId,
            })
          : null,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        filter
          ? getCourierPrices(filter).catch(error => Observable.of({ error }))
          : Observable.of({}),
      )
      .startWith({})
      .map(response =>
        fromJS(response).getIn(["payload", "data", "list"], List()),
      )
      .distinctUntilChanged(isEqualData);

    const pickupTimeSlotsStream = propsStream
      .map(props => ({
        getTimeSlots: props.getTimeSlots,
        filter:
          props.values.courierType &&
          props.values.validLocations &&
          isValidDate(props.values.pickupDate)
            ? new DataListFilter({
                supplier_id: props.values.packageSupplierId,
                courier_type: fp.toUpper(props.values.courierType),
                timeslot_type: PICKUP,
                lat: props.values.pickupLocation.lat,
                lng: props.values.pickupLocation.lng,
                date: formatDateToUrl(props.values.pickupDate),
              })
            : null,
      }))
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.filter
          ? props.getTimeSlots(props.filter).catch(() => Observable.of({}))
          : Observable.of({}),
      )
      .startWith({})
      .map(response =>
        fromJS(response).getIn(["payload", "data", "list"], List()),
      )
      .distinctUntilChanged(isEqualData);

    const deliveryTimeSlotsStream = propsStream
      .map(props => ({
        getTimeSlots: props.getTimeSlots,
        filter:
          props.values.packageSupplierId > 0 &&
          props.values.courierType &&
          props.values.validLocations &&
          isValidDate(props.values.dropOffDate)
            ? new DataListFilter({
                timeslot_type: DROP,
                supplier_id: props.values.packageSupplierId,
                courier_type: fp.toUpper(props.values.courierType),
                lat: props.values.dropoffLocation.lat,
                lng: props.values.dropoffLocation.lng,
                date: formatDateToUrl(props.values.dropOffDate),
              })
            : null,
      }))
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.filter
          ? props.getTimeSlots(props.filter).catch(() => Observable.of({}))
          : Observable.of({}),
      )
      .startWith({})
      .map(response =>
        fromJS(response).getIn(["payload", "data", "list"], List()),
      )
      .distinctUntilChanged(isEqualData);

    const paymentTypesStream = propsStream
      .map(props => ({
        customerId: props.values.customerId,
        getPaymentMethods: props.getPaymentMethods,
        filter:
          props.values.packageId > 0 &&
          props.values.priceId > 0 &&
          props.values.validLocations &&
          props.values.dimensionsLength &&
          props.values.dimensionsHeight &&
          props.values.chargeAbleWeight &&
          props.values.dimensionsWidth &&
          props.values.dimensionsUnit &&
          props.values.pickupCountry &&
          props.values.dropoffCountry &&
          props.values.customerId
            ? new DataListFilter({
                "dimensions.width": props.values.dimensionsWidth,
                "dimensions.length": props.values.dimensionsLength,
                "dimensions.height": props.values.dimensionsHeight,
                "dimensions.unit": props.values.dimensionsUnit,
                "dimensions.weight": props.values.chargeAbleWeight,
                from_latitude: props.values.pickupLocation.lat,
                from_longitude: props.values.pickupLocation.lng,
                to_latitude: props.values.dropoffLocation.lat,
                to_longitude: props.values.dropoffLocation.lng,
                from_country_id: props.values.pickupCountry.id,
                to_country_id: props.values.dropoffCountry.id,
                price_id: props.values.priceId,
                package_id: props.values.packageId,
              })
            : null,
      }))
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.filter
          ? props
              .getPaymentMethods(props.customerId, props.filter)
              .catch(() => Observable.of({}))
          : Observable.of({}),
      )
      .startWith({})
      .map(mapResponseDataAsList)
      .distinctUntilChanged(isEqualData);

    const sideEffectsStream = Observable.merge(
      propsStream.filter(fp.get("dirty")).do(props => {
        const { pickupLocationDetails } = props;

        if (pickupLocationDetails) {
          props.change(
            "pickupCity",
            pickupLocationDetails.getIn(["city", "name"]),
          );
        }
      }),
      propsStream
        .distinctUntilChanged(
          fp.overEvery([
            isEqualDataIn("values.package"),
            isEqualDataIn("values.paymentType"),
          ]),
        )
        .filter(fp.get("dirty"))
        .do(props => {
          const serviceCharge = getValue(
            props.values.package,
            ["price", "total"],
            0,
          );

          if (props.values.serviceCharge !== serviceCharge) {
            props.change("serviceCharge", serviceCharge);
          }

          if (
            props.values.serviceChargePaid !==
            props.initialValues.serviceChargePaid
          ) {
            props.change(
              "serviceChargePaid",
              props.initialValues.serviceChargePaid,
            );
          }
        }),
      propsStream
        .map(
          fp.flow(
            fp.pick(["dirty", "change", "values"]),
            fp.update(
              "values",
              fp.pick([
                "codCharge",
                "payer",
                "paymentType",
                "recipientNotAvailable",
                "customer",
              ]),
            ),
          ),
        )
        .distinctUntilChanged(isEqualDataIn("values"))
        .filter(fp.get("dirty"))
        .do(props => {
          const {
            codCharge,
            payer,
            paymentType,
            recipientNotAvailable,
            customer,
          } = props.values;

          if (customer && customer.description) {
            props.change("pickupContactEmail", customer.description);
          }

          if (2 === 3 && payer !== SENDER) {
            props.change("payer", SENDER);
          } else if (paymentType === CREDIT_BALANCE && payer !== RECIPIENT) {
            if (props.isCustomMarketplace) props.change("payer", RECIPIENT);
            else props.change("payer", SENDER);
            props.change("payer", SENDER);
          } else if (
            (payer === RECIPIENT || codCharge > 0) &&
            recipientNotAvailable !== DO_NOT_DELIVER
          ) {
            props.change("recipientNotAvailable", DO_NOT_DELIVER);
          }
        }),
      propsStream
        .map(
          fp.flow(
            fp.pick(["change", "values"]),
            fp.update("values", fp.pick(["pickupCountry", "dropoffCountry"])),
          ),
        )
        .distinctUntilKeyChanged("values", isEqualData)
        .do(props => {
          const { pickupCountry, dropoffCountry } = props.values;

          const pickupCountryId = fp.get("id", pickupCountry);
          const dropoffCountryId = fp.get("id", dropoffCountry);

          if (pickupCountryId !== dropoffCountryId) {
            props.change("domestic", false);
          } else {
            props.change("domestic", true);
          }
        }),
      propsStream
        .combineLatest(
          volumetricWeightResponseStream,
          (props, volumetricWeight) => ({
            volumetricWeight,
            ...props,
          }),
        )
        .map(fp.pick(["change", "volumetricWeight"]))
        .distinctUntilKeyChanged("volumetricWeight")
        .filter(fp.get("volumetricWeight"))
        .do(props => {
          props.change(
            "dimensionsVolumetricWeight",
            props.volumetricWeight.get("data"),
          );
        }),
    )
      .mapTo(null)
      .startWith(null)
      .distinctUntilChanged();

    return propsStream
      .map(
        fp.flow(
          fp.pick([
            "change",
            "values",
            "classes",
            "orderReferenceIdVisibility",
            "showMarketplaceAlternatePhoneNumbers",
            "dirty",
            "pristine",
            "submitting",
            "change",
            "reset",
            "domestic",
            "onDismiss",
            "handleSubmit",
            "getTimeSlots",
            "getPaymentMethods",
            "getCachedDriver",
            "getDriverPredictions",
            "getCachedSupplier",
            "getSupplierPredictions",
            "getCachedCustomer",
            "getCustomerPredictions",
            "getCachedCity",
            "getCityPredictions",
            "isReverseLogisticEnabled",
            "i18n",
            "orderStatusCodes",
            "state",
            "isRTL",
            "setState",
            "mapProvider",
          ]),
          fp.update(
            "values",
            fp.pick([
              "supplier",
              "menu",
              "dimensionsUnit",
              "payer",
              "paymentType",
              "package",
              "pickupCountry",
              "pickupLocation",
              "pickupTimeSlot",
              "dropoffCountry",
              "dropoffLocation",
              "pickupContactPhoneCode",
              "dropoffContactPhoneCode",
              "reference_id",
            ]),
          ),
        ),
      )
      .combineLatest(
        packageMenuStream,
        paymentTypesStream,
        packagePricesStream,
        pickupTimeSlotsStream,
        deliveryTimeSlotsStream,
        pickupLocationDetailsStream,
        dropoffLocationDetailsStream,
        volumetricWeightResponseStream,
        sideEffectsStream,
        (
          props,
          packageMenu,
          paymentTypes,
          packagePrices,
          pickupTimeSlots,
          deliveryTimeSlots,
          pickupLocationDetails,
          dropoffLocationDetails,
          volumetricWeightResponse,
        ) => ({
          ...props,
          packageMenu,
          paymentTypes: paymentTypes.get("list"),
          packagePrices,
          pickupTimeSlots,
          deliveryTimeSlots,
          pickupLocationDetails: pickupLocationDetails
            ? pickupLocationDetails.get("data")
            : null,
          pickupLocationDetailsPending: pickupLocationDetails.get("pending"),
          dropoffLocationDetails: dropoffLocationDetails
            ? dropoffLocationDetails.get("data")
            : null,
          dropoffLocationDetailsPending: dropoffLocationDetails.get("pending"),
          volumetricWeight: volumetricWeightResponse.get("data"),
          volumetricWeightFetching: volumetricWeightResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
  mapPropsStream(propsStream => {
    propsStream
      .map(fp.pick(["change", "pickupLocationDetails"]))
      .filter(
        props =>
          Boolean(props.pickupLocationDetails) &&
          !props.pickupLocationDetails.isEmpty(),
      )
      .distinctUntilKeyChanged("pickupLocationDetails")
      .subscribe(({ change, pickupLocationDetails }) => {
        if (pickupLocationDetails.getIn(["city", "name"]))
          change("pickupCity", pickupLocationDetails.get("city").toJS());

        change("pickupContactPhoneCode", getPhoneCode(pickupLocationDetails));

        if (pickupLocationDetails.getIn(["neighborhood", "name"]))
          change(
            "pickupNeighborhood",
            pickupLocationDetails.get("neighborhood").toJS(),
          );
      });

    propsStream
      .map(fp.pick(["change", "dropoffLocationDetails"]))
      .filter(
        props =>
          Boolean(props.dropoffLocationDetails) &&
          !props.dropoffLocationDetails.isEmpty(),
      )
      .distinctUntilKeyChanged("dropoffLocationDetails")
      .subscribe(({ change, dropoffLocationDetails }) => {
        if (dropoffLocationDetails.getIn(["city", "name"]))
          change("dropoffCity", dropoffLocationDetails.get("city").toJS());

        change("dropoffContactPhoneCode", getPhoneCode(dropoffLocationDetails));

        if (dropoffLocationDetails.getIn(["neighborhood", "name"]))
          change(
            "dropoffNeighborhood",
            dropoffLocationDetails.get("neighborhood").toJS(),
          );
      });

    const sideEffectsStream = Observable.merge(
      propsStream
        .take(1)
        .map(fp.pick(["change", "pickupLocationDetails"]))
        .filter(
          props =>
            Boolean(props.pickupLocationDetails) &&
            !props.pickupLocationDetails.isEmpty(),
        )
        .distinctUntilChanged()
        .do(({ change, pickupLocationDetails }) =>
          change("pickupCity", pickupLocationDetails.getIn(["city", "name"])),
        ),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
);

OrderFormV2.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  reset: PropTypes.func,
  dirty: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  change: PropTypes.func,

  packageMenu: PropTypes.instanceOf(List),
  paymentTypes: PropTypes.instanceOf(List),
  packagePrices: PropTypes.instanceOf(List),
  pickupTimeSlots: PropTypes.instanceOf(List),
  deliveryTimeSlots: PropTypes.instanceOf(List),
  orderStatusCodes: PropTypes.instanceOf(OrderedSet),
  pickupLocationDetails: PropTypes.instanceOf(Map),
  pickupLocationDetailsPending: PropTypes.bool,
  dropoffLocationDetails: PropTypes.instanceOf(Map),
  dropoffLocationDetailsPending: PropTypes.bool,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onDismiss: PropTypes.func,
  order: PropTypes.instanceOf(Map),
  getTimeSlots: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  getCachedDriver: PropTypes.func.isRequired,
  getDriverPredictions: PropTypes.func.isRequired,
  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  getCachedCustomer: PropTypes.func,
  getCustomerPredictions: PropTypes.func,
  getCachedCity: PropTypes.func,
  getCityPredictions: PropTypes.func,
  getCachedPostcode: PropTypes.func,
  getPostcodePredictions: PropTypes.func,

  volumetricWeightFetching: PropTypes.bool,
  volumetricWeight: PropTypes.number,
  isReverseLogisticEnabled: PropTypes.bool,
  isCustomMarketplace: PropTypes.bool,
  orderReferenceIdVisibility: PropTypes.bool,
  showMarketplaceAlternatePhoneNumbers: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
  state: PropTypes.object,
  setState: PropTypes.func,
  mapProvider: PropTypes.string,
  isRTL: PropTypes.bool,
};

function OrderFormV2(props) {
  const { values, i18n, classes, isRTL } = props;

  const supplierId = getValue(values.supplier, "id");
  const pickupCountryId = getValue(values.pickupCountry, "id");
  const pickupCountryCode = getValue(values.pickupCountry, "code");
  const dropoffCountryId = getValue(values.dropoffCountry, "id");
  const dropoffCountryCode = getValue(values.dropoffCountry, "code");
  const pickupAddress = getValue(values.pickupLocation, "address");
  const dropoffAddress = getValue(values.dropoffLocation, "address");
  const pickupPhoneCode = getValue(values.pickupContactPhoneCode, "code");
  const dropoffPhoneCode = getValue(values.dropoffContactPhoneCode, "code");

  const disablePayer = shouldDisablePayer(values.paymentType);
  const disableRecipientNotAvailable = shouldDisableRecipientNotAvailable(
    values.payer,
    values.paymentType,
  );

  const isYandexMap = props.mapProvider === YANDEX_MAP_PROVIDER;

  return (
    <FlexBox
      align="center"
      container={16}
      direction="column"
      element={<form id="OrderFormV2" onSubmit={props.handleSubmit} />}
    >
      <PageLoading
        isLoading={
          props.submitting ||
          props.pickupLocationDetailsPending ||
          props.dropoffLocationDetailsPending ||
          props.volumetricWeightFetching
        }
      />

      <FlexBox
        flex="none"
        gutter={8}
        direction="column"
        style={{ width: "768px" }}
      >
        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8}>
                <FlexBox direction="column" flex={true}>
                  <FormCustomerAutoComplete
                    name="customer"
                    label={`${i18n.get("customer", "Customer")} *`}
                    fullWidth={true}
                    hintText={
                      i18n.get("type_to_search") || "Type to search ..."
                    }
                  />
                </FlexBox>

                <FlexBox direction="column" flex={true}>
                  <FormSupplierAutoComplete
                    name="supplier"
                    label={i18n.get("supplier") || "Supplier"}
                    fullWidth={true}
                    hintText={
                      i18n.get("type_to_search") || "Type to search ..."
                    }
                  />
                </FlexBox>

                <FlexBox direction="column" flex={true}>
                  <FormDriverAutoComplete
                    name="driver"
                    fullWidth={true}
                    disabled={!supplierId}
                    supplierId={supplierId}
                    label={i18n.get("driver") || "Driver"}
                    hintText={
                      i18n.get("type_to_search") || "Type to search ..."
                    }
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8}>
                {props.isReverseLogisticEnabled && (
                  <FlexBox direction="column" flex={true}>
                    <FormSelectField
                      name="logistic_type"
                      maxHeight={256}
                      autoWidth={true}
                      fullWidth={true}
                      formatOption={x => i18n.get(x, formatText(x))}
                      label={`${i18n.get("shipping_type") ||
                        "Shipping Type"} *`}
                      options={OrderTypes}
                    />
                  </FlexBox>
                )}
                <FlexBox direction="column" flex={true}>
                  <FormSelectField
                    name="status"
                    maxHeight={256}
                    autoWidth={true}
                    fullWidth={true}
                    formatOption={
                      x => formatOrderStatusCodeLocalization(x, i18n)
                      // formatText((statusMessages && statusMessages.get(x)) || x)
                    }
                    label={i18n.get("status") || "Status"}
                    options={props.orderStatusCodes}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        {props.orderReferenceIdVisibility && (
          <FlexBox direction="column">
            <Card>
              <CardContent>
                <FormTextField
                  name="reference_id"
                  autoWidth={true}
                  fullWidth={true}
                  label={i18n.get("reference_id") || "Reference ID"}
                />
              </CardContent>
            </Card>
          </FlexBox>
        )}

        <FlexBox gutter={8}>
          <FlexBox style={{ width: "50%" }}>
            <Card>
              <CardContent>
                <FlexBox>
                  <FormTextField
                    name="pickupContactName"
                    label={`${i18n.get("sender_name") || "Sender Name"} *`}
                    fullWidth={true}
                  />
                  <IconButton
                    onClick={() =>
                      props.setState(fp.set("showPickUpAddressDialog", true))
                    }
                  >
                    <ContactPhone />
                  </IconButton>
                </FlexBox>

                <AdminAddressBookSelectV2DialogPickup
                  open={props.state.showPickUpAddressDialog}
                  countryId={pickupCountryId}
                  onRequestClose={() =>
                    props.setState(fp.set("showPickUpAddressDialog", false))
                  }
                  onSubmit={details => {
                    props.setState(fp.set("showPickUpAddressDialog", false));

                    props.change("pickupLocation", {
                      country: pickupCountryCode,
                      lat: details.address.lat,
                      lng: details.address.lon,
                      address: details.address.address,
                      geo_address: getProperGeoAddressObj(details.address),
                    });
                    props.change("pickupContactName", details.address.name);
                    props.change("pickupContactPhone", details.address.phone);
                    props.change("pickupContactPhoneCode", {
                      code: details.address.phone_code,
                    });
                    props.change(
                      "pickupAddressType",
                      details.address.address_type,
                    );
                    props.change("pickupStreet", details.address.street);
                    props.change("pickupBuilding", details.address.building);
                    props.change("pickupApartment", details.address.apartment);
                    props.change(
                      "pickupNearestLandmark",
                      details.address.landmark,
                    );
                  }}
                />

                <FormCountryAutoComplete
                  readOnly={true}
                  fullWidth={true}
                  openOnFocus={false}
                  name="pickupCountry"
                  label={`${i18n.get("pickup_country") || "Pickup Country"} *`}
                  hintText={i18n.get("type_to_search") || "Type to search ..."}
                />

                {Boolean(pickupCountryCode) && (
                  <FlexBox>
                    {!isValidCoordinates(values.pickupLocation) ? (
                      <FlexBox flex={true}>
                        {isYandexMap ? (
                          <FormYandexGeoAutoComplete
                            fullWidth={true}
                            autoComplete="off"
                            name="pickupLocation"
                            countryCode={pickupCountryCode}
                            label={`${i18n.get(
                              "pickup_location",
                              "Pickup Location",
                            )} *`}
                            hintText={i18n.get(
                              "type_to_search_3_letters",
                              "Type to Search (3 letters minimum)",
                            )}
                          />
                        ) : (
                          <FormGeoAutoComplete
                            fullWidth={true}
                            name="pickupLocation"
                            countryCode={pickupCountryCode}
                            label={`${i18n.get(
                              "pickup_location",
                              "Pickup Location",
                            )} *`}
                            hintText={i18n.get(
                              "type_to_search_3_letters",
                              "Type to Search (3 letters minimum)",
                            )}
                          />
                        )}
                      </FlexBox>
                    ) : (
                      <div className={classes.addressInfo}>
                        <strong>
                          {i18n.get("pickup_address", "Pickup Address")}:
                        </strong>
                        <br />
                        {pickupAddress}
                      </div>
                    )}
                    <ManualSetOrderLocationDialog
                      fieldName="pickupLocation"
                      change={props.change}
                      location={values.pickupLocation}
                      countryCode={pickupCountryCode}
                    />
                  </FlexBox>
                )}

                {!props.pickupLocationDetailsPending &&
                  props.pickupLocationDetails && (
                    <div>
                      <OrderCityField
                        name="pickupCity"
                        label={`${i18n.get("city", "City")} *`}
                        readOnly={true}
                        location={props.pickupLocationDetails}
                      />

                      <OrderNeighborhoodField
                        name="pickupNeighborhood"
                        label={`${i18n.get("region", "Region")} *`}
                        readOnly={true}
                        location={props.pickupLocationDetails}
                      />
                    </div>
                  )}

                <FormTextField
                  fullWidth={true}
                  name="pickupStreet"
                  label={i18n.get("street", "Street")}
                />

                <FormTextField
                  fullWidth={true}
                  name="pickupBuilding"
                  label={`${i18n.get("building", "Building")} *`}
                />

                <FormTextField
                  fullWidth={true}
                  name="pickupApartment"
                  label={i18n.get("apartment_office", "Apartment / Office")}
                />

                <FormSelectField
                  name="pickupAddressType"
                  label={`${i18n.get("address_type", " Address Type")} *`}
                  fullWidth={true}
                  options={AddressTypes}
                  formatOption={x => i18n.get(x, formatText(x))}
                />

                <FormTextField
                  fullWidth={true}
                  name="pickupNearestLandmark"
                  label={i18n.get("nearest_landmark") || "Nearest Landmark"}
                />
                <FormCountyPhoneCodeField
                  fullWidth={true}
                  className={
                    isRTL ? classes.autocompleteRtl : classes.autocompleteLtr
                  }
                  name="pickupContactPhoneCode"
                  label={`${i18n.get("phone_code") || "Phone Code"} *`}
                  hintText={i18n.get("type_to_search") || "Type to search ..."}
                />
                <FlexBox align="flex-end" justify="flex-end">
                  {pickupPhoneCode && (
                    <span className={classes.spanCode}>
                      ({pickupPhoneCode})
                    </span>
                  )}
                  <FormTextField
                    style={{ width: "81%" }}
                    name="pickupContactPhone"
                    label={`${i18n.get("phone_number") || "Phone number"} *`}
                    parse={parsePhone}
                    ignoreRtl={isRTL}
                  />
                </FlexBox>
                {props.showMarketplaceAlternatePhoneNumbers && (
                  <div>
                    <FormTextField
                      ignoreRtl={isRTL}
                      parse={parsePhone}
                      name="pickupSecondPhone"
                      fullWidth={true}
                      label={`${i18n.get(
                        "alternate_phone1",
                        "Alternate Phone 1",
                      )}`}
                    />

                    <FormTextField
                      ignoreRtl={isRTL}
                      parse={parsePhone}
                      name="pickupThirdPhone"
                      fullWidth={true}
                      label={`${i18n.get(
                        "alternate_phone2",
                        "Alternate Phone 2",
                      )}`}
                    />
                  </div>
                )}

                <FormTextField
                  name="pickupContactEmail"
                  fullWidth={true}
                  label={i18n.get("sender_email") || "Sender Email"}
                />

                <FormCheckbox
                  name="pickupContactSave"
                  label={i18n.get(
                    "save_to_address_book",
                    "Save To Address Book",
                  )}
                />
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox style={{ width: "50%" }}>
            <Card>
              <CardContent>
                <FlexBox>
                  <FormTextField
                    name="dropoffContactName"
                    label={`${i18n.get("recipient_name", "Recipient Name")} *`}
                    fullWidth={true}
                  />
                  <IconButton
                    onClick={() =>
                      props.setState(fp.set("showDropoffAddressDialog", true))
                    }
                  >
                    <ContactPhone />
                  </IconButton>
                </FlexBox>

                <AdminAddressBookSelectV2DialogDropOff
                  open={props.state.showDropoffAddressDialog}
                  countryId={dropoffCountryId}
                  onRequestClose={() =>
                    props.setState(fp.set("showDropoffAddressDialog", false))
                  }
                  onSubmit={details => {
                    props.setState(fp.set("showDropoffAddressDialog", false));

                    props.change("dropoffLocation", {
                      country: dropoffCountryCode,
                      lat: details.address.lat,
                      lng: details.address.lon,
                      address: details.address.address,
                      geo_address: getProperGeoAddressObj(details.address),
                    });
                    props.change("dropoffContactName", details.address.name);
                    props.change("dropoffContactPhone", details.address.phone);
                    props.change("dropoffContactPhoneCode", {
                      code: details.address.phone_code,
                    });
                    props.change(
                      "dropoffAddressType",
                      details.address.address_type,
                    );
                    props.change("dropoffStreet", details.address.street);
                    props.change("dropoffBuilding", details.address.building);
                    props.change("dropoffApartment", details.address.apartment);
                    props.change(
                      "dropoffNearestLandmark",
                      details.address.landmark,
                    );
                  }}
                />

                <FormCountryAutoComplete
                  fullWidth={true}
                  name="dropoffCountry"
                  label={`${i18n.get("dropoff_country") ||
                    "Dropoff Country"} *`}
                  hintText={i18n.get("type_to_search") || "Type to search ..."}
                />

                {Boolean(dropoffCountryCode) && (
                  <FlexBox>
                    {!isValidCoordinates(values.dropoffLocation) ? (
                      <FlexBox flex={true}>
                        {isYandexMap ? (
                          <FormYandexGeoAutoComplete
                            fullWidth={true}
                            name="dropoffLocation"
                            countryCode={dropoffCountryCode}
                            label={`${i18n.get(
                              "dropoff_location",
                              "Dropoff Location",
                            )} *`}
                            hintText={i18n.get(
                              "type_to_search_3_letters",
                              "Type to Search (3 letters minimum)",
                            )}
                          />
                        ) : (
                          <FormGeoAutoComplete
                            fullWidth={true}
                            name="dropoffLocation"
                            countryCode={dropoffCountryCode}
                            label={`${i18n.get(
                              "dropoff_location",
                              "Dropoff Location",
                            )} *`}
                            hintText={i18n.get(
                              "type_to_search_3_letters",
                              "Type to Search (3 letters minimum)",
                            )}
                          />
                        )}
                      </FlexBox>
                    ) : (
                      <div className={classes.addressInfo}>
                        <strong>
                          {i18n.get("dropoff_address", "Dropoff Address")}:
                        </strong>
                        <br />
                        {dropoffAddress}
                      </div>
                    )}
                    <ManualSetOrderLocationDialog
                      fieldName="dropoffLocation"
                      location={values.dropoffLocation}
                      change={props.change}
                      countryCode={dropoffCountryCode}
                    />
                  </FlexBox>
                )}

                {!props.dropoffLocationDetailsPending &&
                  props.dropoffLocationDetails && (
                    <div>
                      <OrderCityField
                        name="dropoffCity"
                        label={`${i18n.get("city", "City")} *`}
                        readOnly={true}
                        location={props.dropoffLocationDetails}
                      />

                      <OrderNeighborhoodField
                        name="dropoffNeighborhood"
                        label={`${i18n.get("region", "Region")} *`}
                        readOnly={true}
                        location={props.dropoffLocationDetails}
                      />
                    </div>
                  )}
                <FormTextField
                  fullWidth={true}
                  name="dropoffStreet"
                  label={i18n.get("street") || "Street"}
                />

                <FormTextField
                  fullWidth={true}
                  name="dropoffBuilding"
                  label={`${i18n.get("building", "Building")} *`}
                />

                <FormTextField
                  fullWidth={true}
                  name="dropoffApartment"
                  label={i18n.get("apartment_office") || "Apartment / Office"}
                />

                <FormSelectField
                  name="dropoffAddressType"
                  label={`${i18n.get("address_type", "Address Type")} *`}
                  fullWidth={true}
                  options={AddressTypes}
                  formatOption={x => i18n.get(x, formatText(x))}
                />

                <FormTextField
                  fullWidth={true}
                  name="dropoffNearestLandmark"
                  label={i18n.get("nearest_landmark", "Nearest Landmark")}
                />
                <FormCountyPhoneCodeField
                  fullWidth={true}
                  className={
                    isRTL ? classes.autocompleteRtl : classes.autocompleteLtr
                  }
                  name="dropoffContactPhoneCode"
                  label={`${i18n.get("phone_code") || "Phone Code"} *`}
                  hintText={i18n.get("type_to_search") || "Type to search ..."}
                />
                <FlexBox align="flex-end" justify="flex-end">
                  {dropoffPhoneCode && (
                    <span className={classes.spanCode}>
                      ({dropoffPhoneCode})
                    </span>
                  )}
                  <FormTextField
                    style={{ width: "81%" }}
                    name="dropoffContactPhone"
                    label={`${i18n.get("phone_number") || "Phone number"} *`}
                    parse={parsePhone}
                    ignoreRtl={isRTL}
                  />
                </FlexBox>

                {props.showMarketplaceAlternatePhoneNumbers && (
                  <div>
                    <FormTextField
                      ignoreRtl={isRTL}
                      parse={parsePhone}
                      name="dropoffSecondPhone"
                      fullWidth={true}
                      label={`${i18n.get(
                        "alternate_phone1",
                        "Alternate Phone 1",
                      )}`}
                    />

                    <FormTextField
                      ignoreRtl={isRTL}
                      parse={parsePhone}
                      name="dropoffThirdPhone"
                      fullWidth={true}
                      label={`${i18n.get(
                        "alternate_phone2",
                        "Alternate Phone 2",
                      )}`}
                    />
                  </div>
                )}

                <FormTextField
                  name="dropoffContactEmail"
                  label={i18n.get("recipient_email", "Recipient Email")}
                  fullWidth={true}
                />

                <FormCheckbox
                  name="dropoffContactSave"
                  label={i18n.get(
                    "save_to_address_book",
                    "Save To Address Book",
                  )}
                />
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="dimensionsWidth"
                    label={`${i18n.get(
                      "width",
                      "Width",
                    )} ${getDimensionText(values.dimensionsUnit, [
                      i18n.get("inches", "inches"),
                      i18n.get("cm", "cm"),
                    ])} *`}
                    type="number"
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="dimensionsLength"
                    label={`${i18n.get(
                      "length",
                      "Length",
                    )} ${getDimensionText(values.dimensionsUnit, [
                      i18n.get("inches", "inches"),
                      i18n.get("cm", "cm"),
                    ])} *`}
                    type="number"
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="dimensionsHeight"
                    label={`${i18n.get(
                      "height",
                      "Height",
                    )} ${getDimensionText(values.dimensionsUnit, [
                      i18n.get("inches", "inches"),
                      i18n.get("cm", "cm"),
                    ])} *`}
                    type="number"
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="dimensionsUnit"
                    autoWidth={true}
                    fullWidth={true}
                    options={UnitsOfMeasureTypes}
                    label={`${i18n.get("unit", "Unit")} *`}
                    formatOption={value =>
                      formatUnitsOfMeasureTypes(value, i18n)
                    }
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="dimensionsWeight"
                    label={`${i18n.get(
                      "weight",
                      "Weight",
                    )} ${getDimensionText(values.dimensionsUnit, [
                      i18n.get("pound", "pound"),
                      i18n.get("kg", "kg"),
                    ])} *`}
                    type="number"
                    fullWidth={true}
                    parseOnBlur={parseOnlyPositiveFloat}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormTextField
                    name="dimensionsVolumetricWeight"
                    label={`${i18n.get(
                      "volumetric_weight",
                      "Volumetric Weight",
                    )} ${getDimensionText(values.dimensionsUnit, [
                      i18n.get("pound", "pound"),
                      i18n.get("kg", "kg"),
                    ])} *`}
                    type="number"
                    fullWidth={true}
                    readOnly={true}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8}>
                <FlexBox
                  flex={true}
                  direction="column"
                  style={{ maxWidth: "35%" }}
                >
                  <FormSelectField
                    name="menu"
                    autoWidth={true}
                    fullWidth={true}
                    options={props.packageMenu}
                    disabled={props.packageMenu.isEmpty()}
                    label={`${i18n.get("starting_from", "Starting From")} *`}
                    formatOption={item => (
                      <span>
                        {item.get("name")} -{" "}
                        <PriceWrapper
                          price={item.getIn(["price", "total"])}
                          code={item.getIn(["price", "currency", "code"])}
                        />
                      </span>
                    )}
                    compareOptions={isEqualDataIn("id")}
                  />
                </FlexBox>

                <FlexBox
                  flex={true}
                  direction="column"
                  style={{ maxWidth: "45%" }}
                >
                  <FormSelectField
                    name="package"
                    autoWidth={true}
                    fullWidth={true}
                    label={`${i18n.get("how", "How")} *`}
                    formatOption={item => (
                      <span>
                        {item.getIn(["supplier", "name"])} - {item.get("name")}{" "}
                        -{" "}
                        <PriceWrapper
                          price={item.getIn(["price", "total"])}
                          code={item.getIn(["price", "currency", "code"])}
                        />
                      </span>
                    )}
                    compareOptions={isEqualDataIn(["price", "id"])}
                    options={props.packagePrices}
                    disabled={props.packagePrices.isEmpty()}
                  />
                </FlexBox>

                <FlexBox
                  flex={true}
                  direction="column"
                  style={{ maxWidth: "20%" }}
                >
                  <FormSelectField
                    name="fragile"
                    autoWidth={true}
                    fullWidth={true}
                    options={trueFalseOptions}
                    label={i18n.get("is_it_fragile", "is it Fragile?")}
                    formatOption={v => (v ? i18n.get("yes") : i18n.get("no"))}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormDateField
                  name="pickupDate"
                  fullWidth={true}
                  label={i18n.get("pickup_date", "Pickup Date")}
                />

                <FormSelectField
                  name="pickupTimeSlot"
                  autoWidth={true}
                  fullWidth={true}
                  options={props.pickupTimeSlots}
                  label={i18n.get("pickup_timeslot", "Pickup Timeslot")}
                  disabled={props.pickupTimeSlots.isEmpty()}
                  compareOptions={isEqualDataIn("timeslot_availability_id")}
                  formatInput={item => item.get("name")}
                  formatOption={item =>
                    `${item.get("name")} (${item.get(
                      "start_time",
                    )} - ${item.get("end_time")})`
                  }
                />
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormDateField
                  name="dropOffDate"
                  fullWidth={true}
                  label={i18n.get("dropoff_date", "Dropoff Date")}
                />

                <FormSelectField
                  name="deliveryTimeSlot"
                  autoWidth={true}
                  fullWidth={true}
                  options={props.deliveryTimeSlots}
                  label={i18n.get("dropoff_timeslot", "Dropoff Timeslot")}
                  disabled={props.deliveryTimeSlots.isEmpty()}
                  compareOptions={isEqualDataIn("timeslot_availability_id")}
                  formatInput={item => item.get("name")}
                  formatOption={item =>
                    `${item.get("name")} (${item.get(
                      "start_time",
                    )} - ${item.get("end_time")})`
                  }
                />
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    autoWidth={true}
                    fullWidth={true}
                    name="paymentType"
                    label={`${i18n.get("payment_type", "Payment Type")} *`}
                    options={props.paymentTypes}
                    formatOption={value => formatPaymentTypeI18n(value, i18n)}
                    disabled={props.paymentTypes.isEmpty()}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="payer"
                    autoWidth={true}
                    fullWidth={true}
                    disabled={disablePayer}
                    formatOption={value => i18n.get(value, formatText(value))}
                    options={OrderPayerTypes}
                    label={i18n.get(
                      "delivery_charge_paid_by",
                      "Delivery Charge Paid By",
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="recipientNotAvailable"
                    autoWidth={true}
                    fullWidth={true}
                    disabled={disableRecipientNotAvailable}
                    formatOption={x => i18n.get(x, formatText(x))}
                    label={i18n.get(
                      "if_recipient_not_available",
                      "If Recipient Not Available",
                    )}
                    options={OrderRecipientNotAvailableActionTypes}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8} flex={true} direction="column">
                <FlexBox flex={true} direction="column">
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormTextField
                        name="codCharge"
                        fullWidth={true}
                        parseOnBlur={fp.toFinite}
                        label={i18n.get("cod_amount", "Cod Amount")}
                      />
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <FormSelectField
                        name="codChargePaid"
                        autoWidth={true}
                        fullWidth={true}
                        label={i18n.get("cod_paid", "Cod Paid")}
                        options={trueFalseOptions}
                        formatOption={v =>
                          v
                            ? i18n.get("paid", "Paid")
                            : i18n.get("not_paid", "Not Paid")
                        }
                      />
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <FormTextField
                        fullWidth={true}
                        name="serviceCharge"
                        parseOnBlur={fp.toFinite}
                        label={`${i18n.get(
                          "service_charge",
                          "Service Charge",
                        )} *`}
                      />
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <FormSelectField
                        name="serviceChargePaid"
                        autoWidth={true}
                        fullWidth={true}
                        label={i18n.get(
                          "service_charge_paid",
                          "Service Charge Paid",
                        )}
                        options={trueFalseOptions}
                        formatOption={v =>
                          v
                            ? i18n.get("paid", "Paid")
                            : i18n.get("not_paid", "Not Paid")
                        }
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox direction="column">
                      <FormTextField
                        name="parcelValue"
                        label={i18n.get("item_value", "Item Value")}
                        parseOnBlur={parseOnlyPositiveFloat}
                        fullWidth={true}
                      />
                    </FlexBox>

                    <FlexBox direction="column">
                      <FormTextField
                        name="pieceCount"
                        label={i18n.get("piece_count", "Piece Count")}
                        fullWidth={true}
                        type="number"
                      />
                    </FlexBox>
                  </FlexBox>
                  <FormTextField
                    name="note"
                    rowsMax={6}
                    fullWidth={true}
                    multiLine={true}
                    label={i18n.get("note", "Note")}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>
        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FormImageListUpload
                name="attachments"
                label={i18n.get("photos") || "Photos"}
              />
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <FlexBox justify="flex-end">
              <CardActions>
                {props.dirty ? (
                  <Button onClick={props.reset}>
                    {" "}
                    {i18n.get("reset") || "Reset"}{" "}
                  </Button>
                ) : (
                  Boolean(props.onDismiss) && (
                    <Button
                      label={i18n.get("dismiss") || "Dismiss"}
                      onClick={props.onDismiss}
                    />
                  )
                )}
                <Button type="submit">
                  {" "}
                  {i18n.get("submit") || "Submit"}{" "}
                </Button>
              </CardActions>
            </FlexBox>
          </Card>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderFormV2);
