import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Card,
  CardContent,
  ListSubheader,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Cancel as NavigationCancel } from "@material-ui/icons";
import FormChipInput from "../form/FormChipInput";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FormDriverChips from "../form/FormDriverChips";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import { safeParseDate, formatDateTimeToUrl } from "../../helpers/FormatUtils";
import {
  formatPaymentType,
  formatLocalisedPaymentType,
} from "../../helpers/OrderHelper";
import { isValidDate } from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  parseString,
  parseIntString,
  stringifyArray,
} from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import FormSelectField from "../form/FormSelectField";
import { OrderedSet } from "immutable";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import FormOperatorChips from "../form/FormOperatorChips";
import FormWarehouseChips from "../form/FormWarehouseChips";

const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,

      getCachedUser: PropTypes.func,
      getOperatorPredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,

      getCachedUser: props.getCachedUser,
      getOperatorPredictions: props.getOperatorPredictions,
    }),
  ),
  useSheet({
    form: { padding: "5px 6px" },
    subheader: { paddingLeft: "0px" },
    toggle: {
      marginTop: "13px",
      whiteSpace: "nowrap",
    },
    cardActions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    searchByType: {
      "& .MuiFormControl-root": {
        "& .MuiInputBase-root": {
          paddingTop: 0,
          "& .MuiChip-root": {
            margin: "0 8px 0px 0",
          },
          "& .MuiInputBase-input": {
            height: 20,
            marginTop: 0,
            paddingTop: 0,
            marginBottom: 0,
            paddingBottom: 0,
          },
        },
      },
    },
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => {
        const x: DataListFilter = props.filter;

        return {
          scores: parseString(x.getValue("scores")),
          createrRuleType: x.getValue("creater_rule_type"),
          paymentType: parseString(x.getValue("payment_type")),
          courierTypes: parseString(x.getValue("courier_types")),

          driverIds: idsToObjectArray(x.getValue("driver_ids")),
          warehouseIds: idsToObjectArray(x.getValue("warehouse_ids")),
          issuedByIds: idsToObjectArray(x.getValue("issued_by_ids")),

          fromDateTime: safeParseDate(x.getValue("from_date_time")),
          toDateTime: safeParseDate(x.getValue("to_date_time")),
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            driver_ids: null,
            warehouse_ids: null,
            issued_by_ids: null,
            exclude_driver_ids: null,

            scores: stringifyArray(values.scores),
            creater_rule_type: values.createrRuleType,

            payment_type: stringifyArray(values.paymentType),
            courier_types: stringifyArray(values.courierTypes),

            from_date_time: formatDateTimeToUrl(values.fromDateTime),
            to_date_time: formatDateTimeToUrl(values.toDateTime),
          });

          if (!fp.isEmpty(values.driverIds)) {
            filter.setValue(
              "driver_ids",
              stringifyArray(values.driverIds.map(getObjectId)),
            );
          }
          if (!fp.isEmpty(values.warehouseIds)) {
            filter.setValue(
              "warehouse_ids",
              stringifyArray(values.warehouseIds.map(getObjectId)),
            );
          }
          if (!fp.isEmpty(values.issuedByIds)) {
            filter.setValue(
              "issued_by_ids",
              stringifyArray(values.issuedByIds.map(getObjectId)),
            );
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({
    enableReinitialize: true,
    form: "CustomerFeedbackFilterDialog",
  }),
  formValues({
    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",

    includeDrivers: "includeDrivers",
  }),
  pureComponent(
    fp.pick([
      "dirty",
      "pristine",
      "initialValues",

      "fromDateTime",
      "toDateTime",
      "includeDrivers",

      "toggleContainer",
    ]),
  ),
);

CustomerFeedbackFilterForm.propTypes = {
  classes: PropTypes.object,

  dirty: PropTypes.bool,
  pristine: PropTypes.bool,

  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,

  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,
  includeDrivers: PropTypes.bool,

  onRequestClose: PropTypes.func.isRequired,

  getDriverPredictions: PropTypes.func,

  getLocalisationMessage: PropTypes.func,
};

function CustomerFeedbackFilterForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <form className={classes.form} onSubmit={props.handleSubmit}>
      <Card>
        <CardContent>
          <FlexBox wrap={true} gutter={8}>
            <FlexBox xs={12} flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage("drivers") || "Drivers"}
              </ListSubheader>

              <FlexBox direction="row">
                <FormDriverChips
                  name="driverIds"
                  fullWidth={true}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox xs={12} flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage("service_type", "Service Type")}
              </ListSubheader>
              <FormCourierTypeChips
                name="courierTypes"
                fullWidth={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
              />
            </FlexBox>

            <FlexBox xs={12} flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage("payment_type", "Payment Type")}
              </ListSubheader>
              <FormChipAutoComplete
                name="paymentType"
                fullWidth={true}
                underlineShow={true}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type to search ...",
                )}
                options={OrderPaymentTypes}
                formatOption={option =>
                  formatLocalisedPaymentType(option, getLocalisationMessage) ||
                  formatPaymentType(option)
                }
              />
            </FlexBox>

            <FlexBox xs={12} flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage("created_by", "Created By")}
              </ListSubheader>
              <FormSelectField
                name="createrRuleType"
                fullWidth={true}
                options={OrderedSet.of("CUSTOMER", "OPERATOR")}
                formatOption={option => getLocalisationMessage(option)}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox wrap={true} gutter={8}>
            <FlexBox xs={12} flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage("created_date", "Created Date")}
              </ListSubheader>

              <FlexBox wrap={true} gutter={8}>
                <FlexBox xs={12} flex={true} gutter={8}>
                  <FlexBox flex={true} direction="column">
                    <FormDateField
                      name="fromDateTime"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "from_date",
                        "From Date",
                      )}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormTimeField
                        fullWidth={true}
                        name="fromDateTime"
                        hintText={getLocalisationMessage(
                          "from_time",
                          "From Time",
                        )}
                      />
                    </FlexBox>

                    {Boolean(isValidDate(props.fromDateTime)) && (
                      <FlexBox align="center">
                        <IconButton
                          onClick={() => props.change("fromDateTime", null)}
                        >
                          <NavigationCancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>

                <FlexBox xs={12} flex={true} gutter={8}>
                  <FlexBox flex={true} direction="column">
                    <FormDateField
                      name="toDateTime"
                      fullWidth={true}
                      hintText={getLocalisationMessage("to_date", "To Date")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormTimeField
                        name="toDateTime"
                        fullWidth={true}
                        hintText={getLocalisationMessage("to_time", "To Time")}
                      />
                    </FlexBox>

                    {Boolean(isValidDate(props.toDateTime)) && (
                      <FlexBox align="center">
                        <IconButton
                          onClick={() => props.change("toDateTime", null)}
                        >
                          <NavigationCancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox wrap={true} gutter={8}>
            <FlexBox xs={12} flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage("post_office")}
              </ListSubheader>
              <FlexBox>
                <FormWarehouseChips
                  name="warehouseIds"
                  fullWidth={true}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox xs={12} flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage("issued_by_operator")}
              </ListSubheader>
              <FlexBox>
                <FormOperatorChips
                  name="issuedByIds"
                  fullWidth={true}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type to search ...",
                  )}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox xs={12} flex={true} direction="column">
              <ListSubheader className={classes.subheader}>
                {getLocalisationMessage("scores")}
              </ListSubheader>
              <FlexBox className={classes.searchByType}>
                <FormChipInput
                  hintText={getLocalisationMessage("scores", "Scores")}
                  name="scores"
                  fullWidth={true}
                  addOnBlur={true}
                  clearOnBlur={false}
                  underlineShow={true}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          {props.dirty && (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          )}
          {props.pristine && (
            <Button
              onClick={() => {
                const includeKeys = [
                  "includeDrivers",
                  "includeSuppliers",
                  "includeCustomers",
                  "includeParentSuppliers",
                ];

                fp.keys(props.initialValues).forEach(key => {
                  if (includeKeys.indexOf(key) === -1) {
                    props.change(key, null);
                  } else {
                    props.change(key, true);
                  }
                });

                props.change("isUae", true);
              }}
            >
              {getLocalisationMessage("clear", "Clear")}
            </Button>
          )}

          <Button type="submit">
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(CustomerFeedbackFilterForm);
