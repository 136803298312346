import { Set } from "immutable";
import reduceReducers from "reduce-reducers";
import { combineReducers } from "redux-immutable";
import { createAsyncReducer } from "../helpers/ReducerFactory";
import { injectReducer } from "../../shared/helpers/ReducerContext";

export const BATCH_ASSIGN_ORDER_LAST_MILE_DRIVER =
  "ORDER/BATCH_ASSIGN_ORDER_LAST_MILE_DRIVER";

const selector = injectReducer(
  "order",
  combineReducers({
    updating: reduceReducers(
      createAsyncReducer(BATCH_ASSIGN_ORDER_LAST_MILE_DRIVER, Set(), {
        pending(state, action) {
          return state.merge(action.meta.orderIds);
        },
        fulfilled(state, action) {
          return state.subtract(action.meta.orderIds);
        },
        rejected(state, action) {
          return state.subtract(action.meta.orderIds);
        },
      }),
    ),
  }),
);

export function isAnyOrderUpdating(state) {
  return !selector(state)
    .get("updating")
    .isEmpty();
}
