function calculateSpeedLinearly(scrollProps) {
  return scrollProps.speed;
}

function calculateSpeedAccelerated(scrollProps) {
  const scrollSpeedByDistance =
    Math.abs(scrollProps.distance) * scrollProps.speed;
  return Math.max(scrollSpeedByDistance, 2);
}

function hasReachedTargetPosition(scrollProps) {
  if (Math.abs(scrollProps.distance) <= scrollProps.speed * 1.5) {
    return true;
  }
  return false;
}

export function calculateScrollPosition(
  currentPosition,
  targetPosition,
  speed,
  easeTypeName,
) {
  const distance = targetPosition - currentPosition;
  const scrollProps = { speed, distance };

  if (hasReachedTargetPosition(scrollProps)) {
    return targetPosition;
  }

  let speedIncrement = 0;

  if (easeTypeName === "linear") {
    speedIncrement = calculateSpeedLinearly(scrollProps);
  } else {
    speedIncrement = calculateSpeedAccelerated(scrollProps);
  }

  const directionToTarget = distance > 0 ? 1.0 : -1.0;
  return currentPosition + speedIncrement * directionToTarget;
}
