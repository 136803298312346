import React from "react";
import { differenceInDays } from "date-fns";
import { List, Map, OrderedMap } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OrderListTimeSlot from "./OrderListTimeSlot";
import OrderListServiceType from "./OrderListServiceType";
import PaymentTypeAvatarAndInfo from "./PaymentTypeAvatarAndInfo";
import UserAvatar from "../avatars/UserAvatar";
import AvatarWithName from "../avatars/AvatarWithName";
import { BOLD } from "../ui-core/Text";
import LinkButton from "../ui-core/LinkButton";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, {
  DataListCheckbox,
  DataListColumn,
} from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import MultiLineCell from "../data-list/MultiLineCell";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import {
  formatDateDistanceToNow,
  formatNumber,
} from "../../helpers/FormatUtils";
import {
  formatCODStatusCode,
  formatCODStatusCodeLocalised,
  formatOrderStatusCodeForLocalisation,
  formatShippingTypeLocalisation,
  getChargeItem,
} from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplaceSettings,
  isReverseLogisticEnabled,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  COD_COLLECTED,
  COD_PENDING,
  COD_TO_BE_COLLECTED,
} from "../../constants/CODStatus";
import { COD, SERVICE } from "../../constants/OrderChargeItemTypes";
import { getAppName } from "../../../shared/reducers/AppReducer";
import { ADMIN, CUSTOMER } from "../../../server/constants/AppTypes";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import TextWithLink from "../../../client/components/router/TextWithLink";
import { hasRole } from "../../helpers/RoleUtils";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../ui-core/FlexBox";
import { CallMade } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

export const SELECTION_PER_PAGE = "per-page";
export const SELECTION_ALL = "all";

const enhancer = compose(
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        marketplaceSettings: getMarketplaceSettings(state),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
        reverseLogisticEnabled: isReverseLogisticEnabled(state),
        app: getAppName(state),
        getLocalisationMessage,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .pluck("list")
      .distinctUntilChanged(isEqualData)
      .map(list =>
        Map().withMutations(items => {
          list.forEach(item => {
            const pickup = item.getIn(["locations", 0]);
            const dropoff = item.getIn(["locations", 1]);

            items.updateIn(["pickup", pickup], 0, fp.add(1));
            items.updateIn(["dropoff", dropoff], 0, fp.add(1));
          });

          items.update("pickup", Map(), fp.get("size"));
          items.update("dropoff", Map(), fp.get("size"));
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
);

const isOutdated = fp.memoize(
  value => differenceInDays(new Date(), safeParseDate(value)) > 3,
);

const isOrderOutdated = order =>
  (order.get("cod_status") === COD_PENDING ||
    order.get("cod_status") === COD_COLLECTED ||
    order.get("cod_status") === COD_TO_BE_COLLECTED) &&
  isOutdated(order.get("last_cod_status_date"));

const useStyles = makeStyles({
  hoverable: { cursor: "pointer", textDecoration: "underline" },
  actionColumn: { paddingLeft: 0, justifyContent: "center" },
  longString: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
  },
});

CustomizableOrderList.propTypes = {
  marketplaceSettings: PropTypes.instanceOf(Map),
  altHeader: PropTypes.node,
  cardActionIcons: PropTypes.node,
  withCustomer: PropTypes.bool,
  withAttempts: PropTypes.bool,
  withCreatedTime: PropTypes.bool,
  withPickupTime: PropTypes.bool,
  withEstDeliveryTime: PropTypes.bool,
  withCODStatus: PropTypes.bool,
  withFooter: PropTypes.bool,
  createCustomerHref: PropTypes.func,
  onEstimatedDeliveryDateClick: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  maxSearchItems: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  onFilterChange: PropTypes.func,
  rowActionIconRenderer: PropTypes.func,
  selectionType: PropTypes.oneOf([SELECTION_ALL, SELECTION_PER_PAGE]),
  withService: PropTypes.bool,
  withLocation: PropTypes.bool,
  location: PropTypes.object,
  withWarehouse: PropTypes.bool,
  withAmount: PropTypes.bool,
  withShipmentNumber: PropTypes.bool,
  withCODAmount: PropTypes.bool,
  withPickupCollectible: PropTypes.bool,
  withDropOffCollectible: PropTypes.bool,
  withPickedUpBy: PropTypes.bool,
  withDeliveredBy: PropTypes.bool,
  chipTextHint: PropTypes.string,
  withMerchantPromiseDate: PropTypes.bool,
  withPieceCount: PropTypes.bool,
  isAdminViewer: PropTypes.bool,
  withTimeslot: PropTypes.bool,
  showPickUpAddress: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  withLogisticType: PropTypes.bool,
  reverseLogisticEnabled: PropTypes.bool,
  app: PropTypes.string,
  updateQuery: PropTypes.func,
  withSwitch: PropTypes.bool,
};

CustomizableOrderList.defaultProps = {
  withCustomer: true,
  withLastDriver: true,
  withLocation: true,
  withAttempts: true,
  withAmount: true,
  withCODAmount: false,
  withService: true,
  withWarehouse: true,
  withLogisticType: false,
  withShipmentNumber: true,
  withReferenceId: true,
  withCreatedTime: true,
  withPickupTime: true,
  withEstDeliveryTime: true,
  withCODStatus: true,
  withFooter: true,
  withPieceCount: true,
  withTimeslot: true,
  createCustomerHref: fp.noop,
  createOrderHref: fp.noop,
  createWarehouseHref: fp.noop,
  createAddressChangedNotificationHref: fp.noop,
  maxSearchItems: Infinity,
  selectionType: SELECTION_PER_PAGE,
  withMerchantPromiseDate: true,
  showPickUpAddress: false,
};

function CustomizableOrderList({
  altHeader,
  app,
  cardActionIcons,
  chipTextHint,
  createCustomerHref,
  filter,
  getLocalisationMessage,
  isAdminViewer,
  isLoading,
  list,
  location,
  marketplaceSettings,
  maxSearchItems,
  onEstimatedDeliveryDateClick,
  onFilterChange,
  onRowSelect,
  reverseLogisticEnabled,
  rowActionIconRenderer,
  searchTypes,
  selectedItems,
  selectionType,
  showPickUpAddress,
  totalCount,
  updateQuery,
  withAmount,
  withAttempts,
  withCODAmount,
  withCODStatus,
  withCreatedTime,
  withCustomer,
  withDeliveredBy,
  withDropOffCollectible,
  withEstDeliveryTime,
  withFooter,
  withLocation,
  withLogisticType,
  withMerchantPromiseDate,
  withPickedUpBy,
  withPickupCollectible,
  withPickupTime,
  withPieceCount,
  withService,
  withTimeslot,
  withWarehouse,
  withSwitch,
}) {
  const { actionColumn, avatar, longString } = useStyles();

  const columns =
    app === ADMIN
      ? marketplaceSettings.get("orderListColumns", Map())
      : app === CUSTOMER
      ? marketplaceSettings.get("orderListColumnsForCustomer", Map())
      : marketplaceSettings.get("orderListColumnsForSupplier", Map());

  const NA = getLocalisationMessage("na", "N/A");

  const idNumberMap = list
    .toOrderedMap()
    .mapEntries(([, v]) => [v.get("id"), v.get("order_number")]);

  return (
    <DataList
      filter={filter}
      list={list}
      chipTextHint={chipTextHint}
      withSwitch={withSwitch}
      searchTypes={searchTypes}
      maxSearchItems={maxSearchItems}
      onFilterChange={onFilterChange}
      altHeader={altHeader}
      selectedRowCount={selectedItems ? selectedItems.size : 0}
      cardActionIcons={cardActionIcons}
      totalCount={totalCount}
      rowCount={list.size}
      isLoading={isLoading}
      overscanRowCount={10}
      withFooter={withFooter}
      rowGetter={options => list.get(options.index)}
    >
      {selectedItems && (
        <DataListCheckbox
          allRowsSelected={() => {
            if (list.size > 0) {
              switch (selectionType) {
                case SELECTION_ALL:
                  return selectedItems.isSuperset(idNumberMap);
                case SELECTION_PER_PAGE:
                  return selectedItems.size === list.size;
                // no default
              }
            }

            return false;
          }}
          onAllRowsSelect={value => {
            switch (selectionType) {
              case SELECTION_ALL:
                return onRowSelect(
                  value
                    ? selectedItems.merge(idNumberMap)
                    : selectedItems.filter((n, id) => !idNumberMap.has(id)),
                );
              case SELECTION_PER_PAGE:
                return onRowSelect(value ? idNumberMap : OrderedMap());

              default:
                return null;
            }
          }}
          rowSelected={row => selectedItems.has(row.cellData.get("id"))}
          onRowSelect={row => {
            const id = row.cellData.get("id");
            const orderNumber = row.cellData.get("order_number");

            switch (selectionType) {
              case SELECTION_ALL:
                return onRowSelect(
                  row.selected
                    ? selectedItems.set(id, orderNumber)
                    : selectedItems.delete(id),
                );

              case SELECTION_PER_PAGE: {
                const selectedItemsTemp = row.selected
                  ? selectedItems.set(id, orderNumber)
                  : selectedItems.delete(id);

                const sortedSelectedItems =
                  selectedItemsTemp.size > 1
                    ? idNumberMap.filter((v, k) => selectedItemsTemp.has(k))
                    : selectedItemsTemp;

                return onRowSelect(sortedSelectedItems);
              }

              default:
                return null;
            }
          }}
        />
      )}

      <DataListColumn
        label={getLocalisationMessage("barcode", "Barcode")}
        dataKey="order_number"
        justifyContent="center"
        cellRenderer={row => (
          <MultiLineCell
            firstLine={
              <TextWithLink
                to={updateQuery(
                  location,
                  fp.set("view_v2", row.cellData.get("id")),
                )}
              >
                {row.cellData.get("order_number")}
              </TextWithLink>
            }
          />
        )}
      />

      {Boolean(withService && columns.getIn(["service", "active"], false)) && (
        <DataListColumn
          disableSort={true}
          label={getLocalisationMessage("shipment_type")}
          dataKey="service"
          cellRenderer={row =>
            row.cellData.getIn(["notification", "enabled"]) ? (
              <OrderListServiceType order={row.cellData} />
            ) : (
              <AvatarWithName
                name={row.cellData.getIn(["package", "delivery_label"]) || NA}
                imagePath={row.cellData.getIn(["package", "courier_type_icon"])}
                classes={avatar}
              >
                <MultiLineCell
                  firstLine={
                    <strong>
                      {row.cellData.getIn(["package", "delivery_label"]) || NA}
                    </strong>
                  }
                  secondLine={row.cellData.getIn(["package", "menu", "name"])}
                  // thirdLine={<FlexBox>
                  //   <LowPriority style={{transform:"rotate(180deg)"}}/>
                  //   {getLocalisationMessage("with_notification")}
                  // </FlexBox>}
                />
              </AvatarWithName>
            )
          }
        />
      )}

      {Boolean(
        withCustomer && columns.getIn(["customer_name", "active"], false),
      ) && (
        <DataListColumn
          label={
            columns.getIn(["customer_name", "custom_name"]) ||
            getLocalisationMessage("customer", "Customer")
          }
          dataKey="customer_name"
          cellRenderer={row =>
            !isAdminViewer ? (
              <div>
                <TextWithLink
                  to={createCustomerHref(
                    row.cellData.getIn(["customer", "id"]),
                  )}
                  style={{ textDecoration: "none" }}
                >
                  <AvatarWithName
                    name={row.cellData.getIn(["customer", "name"])}
                    classes={avatar}
                    imagePath={row.cellData.getIn(["customer", "photo"])}
                  >
                    {row.cellData.getIn(["customer", "name"])}
                  </AvatarWithName>
                </TextWithLink>
              </div>
            ) : (
              <div>{row.cellData.getIn(["customer", "name"])}</div>
            )
          }
        />
      )}

      {Boolean(
        withLogisticType &&
          columns.getIn(["shipping_type", "active"], false) &&
          reverseLogisticEnabled,
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["shipping_type", "custom_name"]) ||
            getLocalisationMessage("shipping_type", "Shipping Type")
          }
          dataKey="logistic_type"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("logistic_type")
              ? formatShippingTypeLocalisation(
                  row.cellData.get("logistic_type"),
                  getLocalisationMessage,
                )
              : NA
          }
        />
      )}

      {Boolean(
        withWarehouse && columns.getIn(["warehouse", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["warehouse", "custom_name"]) ||
            getLocalisationMessage("warehouse", "Warehouse")
          }
          dataKey="warehouse"
          justifyContent="center"
          cellRenderer={row => (
            <MultiLineCell
              firstLine={
                <FlexBox align={ALIGN_CENTER} justify={JUSTIFY_SPACE_AROUND}>
                  <CallMade
                    fontSize="small"
                    style={{
                      color: "#A8ADAF",
                      fontSize: "1rem",
                      transform: "rotate(90deg)",
                    }}
                  />
                  <span style={{ color: "#A8ADAF" }}>
                    {row.cellData.getIn(["from_warehouse", "name"], NA)}
                  </span>
                </FlexBox>
              }
              secondLine={
                <FlexBox align={ALIGN_CENTER} justify={JUSTIFY_SPACE_AROUND}>
                  <CallMade
                    fontSize="small"
                    style={{
                      color: "#21C004",
                      transform: "rotate(135deg)",
                      fontSize: "1rem",
                    }}
                  />
                  <span style={{ color: "#263238" }}>
                    {row.cellData.getIn(["warehouse", "name"], NA)}
                  </span>
                </FlexBox>
              }
              thirdLine={
                <FlexBox align={ALIGN_CENTER} justify={JUSTIFY_SPACE_AROUND}>
                  <CallMade
                    fontSize="small"
                    style={{
                      color: "#A8ADAF",
                      fontSize: "1rem",
                    }}
                  />
                  <span style={{ color: "#A8ADAF" }}>
                    {row.cellData.getIn(["to_warehouse", "name"], NA)}
                  </span>
                </FlexBox>
              }
            />
          )}
        />
      )}

      {columns.getIn(["supplier_name", "active"], false) && (
        <DataListColumn
          label={
            columns.getIn(["supplier_name", "custom_name"]) ||
            getLocalisationMessage("courier_driver", "Courier / Driver")
          }
          dataKey="supplier_name"
          cellRenderer={row => (
            <AvatarWithName
              name={row.cellData.getIn(["supplier", "name"]) || NA}
              classes={avatar}
              imagePath={row.cellData.getIn(["supplier", "photo"])}
            >
              <MultiLineCell
                firstLine={
                  <strong>
                    {row.cellData.getIn(["supplier", "name"]) || NA}
                  </strong>
                }
                secondLine={row.cellData.getIn(["driver", "name"])}
              />
            </AvatarWithName>
          )}
        />
      )}

      {columns.getIn(["pickup_address", "active"], false) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["pickup_address", "custom_name"]) ||
            getLocalisationMessage("pickup_address", "Pickup Address")
          }
          cellRenderer={row => (
            <span className={longString}>
              {row.cellData.getIn(["locations", 0, "jurisdiction"])},
              {row.cellData.getIn(["locations", 0, "address"])}
            </span>
          )}
        />
      )}

      {Boolean(
        withLocation && columns.getIn(["dropoff_address", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          dataKey="dropoff_address"
          label={`${
            showPickUpAddress
              ? columns.getIn(["pickup_address", "custom_name"]) ||
                getLocalisationMessage("pickup_address", "Pickup Address")
              : columns.getIn(["dropoff_address", "custom_name"]) ||
                getLocalisationMessage("dropoff_address", "Dropoff Address")
          } `}
          cellRenderer={row => (
            <span className={longString}>
              {row.cellData.getIn(["locations", 1, "jurisdiction"])},
              {row.cellData.getIn(["locations", 1, "address"])}
            </span>
          )}
        />
      )}

      {columns.getIn(["weight", "active"], false) && (
        <DataListColumn
          disableSort={true}
          label={getLocalisationMessage("weight", "Weight")}
          dataKey="weight"
          justifyContent="center"
          cellRenderer={row =>
            ` ${row.cellData.get("chargeable_weight") ||
              NA} ${getLocalisationMessage("kg", "Kg")}`
          }
        />
      )}

      {columns.getIn(["batch", "active"], false) && (
        <DataListColumn
          disableSort={true}
          label={getLocalisationMessage("batch", "Batch")}
          dataKey="batch"
          justifyContent="center"
          cellRenderer={row => row.cellData.get("batch_id") || NA}
        />
      )}
      {Boolean(
        withTimeslot && columns.getIn(["timeslot", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["timeslot", "custom_name"]) ||
            getLocalisationMessage(
              "timeslot_pickup_dropoff",
              "Timeslot (Pickup | Dropoff)",
            )
          }
          dataKey="timeslot"
          cellRenderer={row => (
            <OrderListTimeSlot location={location} order={row.cellData} />
          )}
        />
      )}

      {Boolean(
        withPieceCount && columns.getIn(["piece_count", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["piece_count", "custom_name"]) ||
            getLocalisationMessage("box_count", "Box Count")
          }
          dataKey="piece_count"
          justifyContent="center"
          cellRenderer={row => (
            <div>
              {`${row.cellData.get(
                "scanned_piece_count",
                0,
              )} / ${row.cellData.get("piece_count", 0)}`}
            </div>
          )}
        />
      )}

      {Boolean(
        withAttempts && columns.getIn(["attempts_count", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["attempts_count", "custom_name"]) ||
            getLocalisationMessage("attempts", "Attempts")
          }
          dataKey="attempts_count"
          justifyContent="center"
          cellRenderer={row => (
            <div style={{ width: "40px", height: "40px" }}>
              <UserAvatar
                size={40}
                name={formatNumber(
                  row.cellData.get("delivery_attempt_count", 0),
                )}
              />
            </div>
          )}
        />
      )}

      {Boolean(
        withCreatedTime && columns.getIn(["created_date", "active"], false),
      ) && (
        <DataListColumn
          label={
            columns.getIn(["created_date", "custom_name"]) ||
            getLocalisationMessage("created_date", "Created Date")
          }
          dataKey="created_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      )}

      {Boolean(
        withPickupTime && columns.getIn(["pickup_time", "active"], false),
      ) && (
        <DataListColumn
          label={
            columns.getIn(["pickup_time", "custom_name"]) ||
            getLocalisationMessage("pickup_time", "Pickup Time")
          }
          dataKey="pickup_time"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("pickup_time") ? (
              <DateTimeCell date={row.cellData.get("pickup_time")} />
            ) : (
              <div>ASAP</div>
            )
          }
        />
      )}

      {Boolean(
        withEstDeliveryTime &&
          columns.getIn(["deadline_time", "active"], false),
      ) && (
        <DataListColumn
          label={
            columns.getIn(["deadline_time", "custom_name"]) ||
            getLocalisationMessage("est_delivery", "Est. Delivery")
          }
          dataKey="deadline_time"
          justifyContent="center"
          cellRenderer={row => (
            <LinkButton
              onClick={
                onEstimatedDeliveryDateClick &&
                (() => onEstimatedDeliveryDateClick(row.cellData))
              }
            >
              {row.cellData.get("deadline_time") ? (
                <DateTimeCell date={row.cellData.get("deadline_time")} />
              ) : (
                <div>{NA}</div>
              )}
            </LinkButton>
          )}
        />
      )}

      {Boolean(
        withMerchantPromiseDate &&
          columns.getIn(["merchant_promise_date", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["merchant_promise_date", "custom_name"]) ||
            getLocalisationMessage("promise_date", "Promise Date")
          }
          dataKey="merchant_promise_date"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("merchant_promise_date") ? (
              <DateTimeCell date={row.cellData.get("merchant_promise_date")} />
            ) : (
              NA
            )
          }
        />
      )}

      {Boolean(
        withPickedUpBy &&
          columns.getIn(["pickup_supplier_name", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["pickup_supplier_name", "custom_name"]) ||
            getLocalisationMessage("picked_up_by", "Picked Up By")
          }
          dataKey="pickup_supplier_name"
          justifyContent="center"
          cellRenderer={row => (
            <MultiLineCell
              firstLine={row.cellData.getIn(["pickup_supplier", "name"]) || NA}
              secondLine={row.cellData.getIn(["pickup_driver", "name"]) || NA}
            />
          )}
        />
      )}

      {Boolean(
        withPickupCollectible &&
          columns.getIn(["pickup_collectible", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          justifyContent="end"
          label={
            columns.getIn(["pickup_collectible", "custom_name"]) ||
            getLocalisationMessage(
              "pickup_total_collectible",
              "Pickup Total Collectible",
            )
          }
          dataKey="pickup_collectible"
          headerClassName={actionColumn}
          cellRenderer={row => (
            <PriceWrapper price={row.cellData.get("pickup_collectible")} />
          )}
        />
      )}

      {Boolean(
        withDeliveredBy &&
          columns.getIn(["delivery_supplier_name", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["delivery_supplier_name", "custom_name"]) ||
            getLocalisationMessage("delivered_by", "Delivered By")
          }
          dataKey="delivery_supplier_name"
          justifyContent="center"
          cellRenderer={row => (
            <MultiLineCell
              firstLine={
                row.cellData.getIn(["delivery_supplier", "name"]) || NA
              }
              secondLine={row.cellData.getIn(["delivery_driver", "name"]) || NA}
            />
          )}
        />
      )}

      {Boolean(
        withDropOffCollectible &&
          columns.getIn(["dropoff_collectible", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          justifyContent="end"
          label={
            columns.getIn(["dropoff_collectible", "custom_name"]) ||
            getLocalisationMessage(
              "dropoff_total_collectible",
              "Dropoff Total Collectible",
            )
          }
          dataKey="dropoff_collectible"
          headerClassName={actionColumn}
          cellRenderer={row => (
            <PriceWrapper price={row.cellData.get("dropoff_collectible")} />
          )}
        />
      )}

      {columns.getIn(["last_modified_date", "active"], false) && (
        <DataListColumn
          label={
            columns.getIn(["last_modified_date", "custom_name"]) ||
            getLocalisationMessage("status", "Status")
          }
          dataKey="last_status_date"
          justifyContent="center"
          cellRenderer={row => (
            <MultiLineCell
              firstLine={
                <span
                  style={{
                    color: "#FF9800",
                  }}
                >
                  {formatOrderStatusCodeForLocalisation(
                    row.cellData.get("status"),
                    getLocalisationMessage,
                  )}
                </span>
              }
              secondLine={
                <DateTimeCell date={row.cellData.get("last_status_date")} />
              }
            />
          )}
        />
      )}

      {Boolean(
        withCODAmount && columns.getIn(["cod_charge", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["cod_charge", "custom_name"]) ||
            getLocalisationMessage("cod", "COD")
          }
          dataKey="cod_charge"
          justifyContent="center"
          cellRenderer={row => row.cellData.get("cod_charge") || NA}
        />
      )}

      {Boolean(
        withCODStatus && columns.getIn(["cod_status", "active"], false),
      ) && (
        <DataListColumn
          disableSort={true}
          label={
            columns.getIn(["cod_status", "custom_name"]) ||
            getLocalisationMessage("cod_status", "COD Status")
          }
          dataKey="cod_status"
          justifyContent="center"
          cellRenderer={row => (
            <MultiLineCell
              firstLine={
                formatCODStatusCodeLocalised(
                  row.cellData.get("cod_status"),
                  getLocalisationMessage,
                  getLocalisationMessage("na", NA),
                ) || formatCODStatusCode(row.cellData.get("cod_status"))
              }
              secondLine={formatDateDistanceToNow(
                row.cellData.get("last_cod_status_date"),
                "N/A",
                getLocalisationMessage,
              )}
            />
          )}
        />
      )}

      {Boolean(withAmount && columns.getIn(["amount", "active"], false)) && (
        <DataListColumn
          dataKey="amount"
          disableSort={true}
          label={
            columns.getIn(["amount", "custom_name"]) ||
            getLocalisationMessage(
              "service_price_parcel_price_cod",
              "Service Price, Parcel Price, COD",
            )
          }
          cellRenderer={row => (
            <PaymentTypeAvatarAndInfo
              paymentType={row.cellData.get("payment_type")}
            >
              <MultiLineCell
                style={{ paddingRight: "10px" }}
                firstLine={
                  <PriceWrapper
                    price={getChargeItem(row.cellData).getIn([
                      SERVICE,
                      "charge",
                    ])}
                    type={isOrderOutdated(row.cellData) ? [BOLD] : [BOLD]}
                  />
                }
                secondLine={
                  <PriceWrapper
                    price={row.cellData.get("parcel_value", 0)}
                    code={row.cellData.getIn(["currency", "code"])}
                  />
                }
                thirdLine={
                  <PriceWrapper
                    price={getChargeItem(row.cellData).getIn([COD, "charge"])}
                    code={row.cellData.getIn(["currency", "code"])}
                  />
                }
              />
            </PaymentTypeAvatarAndInfo>
          )}
        />
      )}

      {rowActionIconRenderer && (
        <DataListColumn
          maxWidth={61}
          label={getLocalisationMessage("action", "Action")}
          dataKey="action"
          className={actionColumn}
          headerClassName={actionColumn}
          cellRenderer={rowActionIconRenderer}
        />
      )}
    </DataList>
  );
}

export default enhancer(CustomizableOrderList);
