import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toReportListFilter = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),

    from_date: queryTypes.date,
    to_date: queryTypes.date,
    promise_date: queryTypes.date,

    courier_types: queryTypes.stringSeq,

    group_by: types.string,
    filter_by: types.string,
    show_by: types.string,
    report_by: types.string,
    reportType: types.string,

    report_customer_type: queryTypes.stringSeq,

    supplier_ids: queryTypes.IDSeq,
    driver_ids: queryTypes.IDSeq,
    only_dedicated: types.boolean,
  }),
);
