import React from "react";
import _ from "lodash";
import { List } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import TextWithLink from "../router/TextWithLink";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, { DataListColumn } from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { PENDING, COMPLETED } from "../../constants/CODTransferStatus";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
);

CODTransferTable.propTypes = {
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  list: PropTypes.instanceOf(List).isRequired,

  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,

  onRemoveItem: PropTypes.func,
  createCODTransferHref: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

CODTransferTable.defaultProps = { createCODTransferHref: _.noop };

function CODTransferTable(props) {
  const { getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");
  const UNASSIGNED = getLocalisationMessage("unassigned", "Unassigned");

  return (
    <DataList
      filter={props.filter}
      onFilterChange={props.onFilterChange}
      overscanRowCount={10}
      totalCount={props.total}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      altHeader={<div />}
      rowGetter={row => props.list.get(row.index)}
    >
      <DataListColumn
        width={120}
        label={getLocalisationMessage("id", "ID")}
        dataKey="id"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <TextWithLink
            to={props.createCODTransferHref(row.cellData.get("id"))}
          >
            {row.cellData.get("id")}
          </TextWithLink>
        )}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("created_date", "Created Date")}
        dataKey="created_date"
        justifyContent="center"
        cellRenderer={row => (
          <DateTimeCell date={row.cellData.get("created_date")} />
        )}
      />

      {props.filter.getValue("status") !== PENDING && (
        <DataListColumn
          width={120}
          label={getLocalisationMessage("approved_date", "Approved Date")}
          dataKey="approved_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("approved_date")} />
          )}
        />
      )}

      {props.filter.getValue("status") === COMPLETED && (
        <DataListColumn
          width={120}
          label={getLocalisationMessage("process_date", "Process Date")}
          dataKey="completed_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("process_date")} />
          )}
        />
      )}

      <DataListColumn
        width={120}
        label={getLocalisationMessage("supplier", "Supplier")}
        dataKey="supplier_name"
        justifyContent="center"
        cellRenderer={row =>
          row.cellData.getIn(["supplier", "id"]) > 0
            ? row.cellData.getIn(["supplier", "name"]) || NA
            : UNASSIGNED
        }
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("transfer_type", "Transfer Type")}
        dataKey="transfer_type"
        justifyContent="center"
        cellRenderer={row =>
          _.toUpper(
            getLocalisationMessage(
              row.cellData.get("transfer_type"),
              formatText(row.cellData.get("transfer_type")),
            ),
          ) || NA
        }
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("note", "Note")}
        dataKey="note"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => row.cellData.get("note") || NA}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("amount", "Amount")}
        dataKey="amount"
        justifyContent="center"
        cellRenderer={row => (
          <PriceWrapper
            price={row.cellData.get("amount")}
            code={row.cellData.getIn(["currency", "code"])}
          />
        )}
      />

      {Boolean(
        props.filter.getValue("status") !== COMPLETED && props.onRemoveItem,
      ) && (
        <DataListColumn
          width={120}
          label={getLocalisationMessage("actions", "Actions")}
          dataKey="actions"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <Button onClick={() => props.onRemoveItem(row.rowData.get("id"))}>
              {getLocalisationMessage("remove", "Remove")}
            </Button>
          )}
        />
      )}
    </DataList>
  );
}

export default enhancer(CODTransferTable);
