import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import {
  Android as ActionAndroid,
  NotificationsOff,
  Language,
} from "@material-ui/icons";
import Apple from "../icons/Apple";
import { isWeb } from "../../helpers/OrderHelper";

const enhancer = useSheet();

// const isDocument = fp.startsWith("Document");
const isIOS = fp.includes("x-app-type:[customer_ios]");
const isAndroid = fp.includes("x-app-type:[customer_android]");

DeviceTypeIcon.propTypes = {
  sheet: PropTypes.object,
  children: PropTypes.node,
  deviceType: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};
DeviceTypeIcon.defaultProps = {
  size: 28,
};

function DeviceTypeIcon({ deviceType, size, color }) {
  return (
    <div>
      {isIOS(deviceType) ? (
        <Avatar
          size={size}
          style={{
            backgroundColor: color,
          }}
        >
          <Apple />
        </Avatar>
      ) : isAndroid(deviceType) ? (
        <Avatar
          size={size}
          style={{
            backgroundColor: color,
          }}
        >
          <ActionAndroid />
        </Avatar>
      ) : isWeb(deviceType) ? (
        <Avatar
          size={size}
          style={{
            backgroundColor: color,
          }}
        >
          <Language />
        </Avatar>
      ) : (
        <Avatar
          size={size}
          style={{
            backgroundColor: color,
          }}
        >
          <NotificationsOff />
        </Avatar>
      )}
    </div>
  );
}

export default enhancer(DeviceTypeIcon);
