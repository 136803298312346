import sprintf from "sprintf";
import WingApi from "../helpers/WingApi";
import { FETCH_WAREHOUSE } from "../reducers/WarehouseReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

export const WAREHOUSE_LIST_URL = `${API_ROOT_URL}/admin/warehouses`;
const WAREHOUSE_ITEM_URL = `${API_ROOT_URL}/admin/warehouse/%s`;
const WAREHOUSE_KML_URL = `${WAREHOUSE_ITEM_URL}/kml`;
const WAREHOUSE_DRIVERS_URL = `${WAREHOUSE_ITEM_URL}/drivers`;

export function fetchWarehouse(id) {
  return (dispatch, getState, api: WingApi) =>
    dispatch({
      meta: { id },
      type: FETCH_WAREHOUSE,
      payload: api.get(sprintf(WAREHOUSE_ITEM_URL, id)),
    });
}

export function loadWarehouseList(request) {
  return (dispatch, getState, api: WingApi) => {
    const params = request.getDefinedValues();
    if (params.status === "all") delete params.status;
    return api.getStream(WAREHOUSE_LIST_URL, { params });
  };
}

export function updateWarehouse(id, data) {
  return (dispatch, getState, api: WingApi) =>
    api.put(sprintf(WAREHOUSE_ITEM_URL, id), { body: data });
}

export function fetchWarehouseDrivers(id) {
  return (dispatch, getState, api: WingApi) =>
    api.getStream(sprintf(WAREHOUSE_DRIVERS_URL, id));
}

export function uploadWarehouseKML(id, file) {
  return (dispatch, getState, api: WingApi) =>
    api.postStream(sprintf(WAREHOUSE_KML_URL, id), { file });
}
