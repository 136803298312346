import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PanTo } from "react-google-map-components";
import { PanTo as LPanTo, Marker } from "react-leflet-map-components";
import BrandMarker from "../maps/BrandMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import {
  getMapProvider,
  isMapKeyAvailable,
} from "../../../shared/reducers/AppReducer";
import { MAP_PROVIDERS } from "../../../shared/constants/MapsControllerConstants";

const enhancer = compose(
  useSheet({ map: { height: "300px" } }),
  connect(state => ({
    mapProvider: getMapProvider(state),
    isMapKeyAvailable: isMapKeyAvailable(state),
  })),
  mapPropsStream(propsStream => {
    const positionStream = propsStream
      .distinctUntilKeyChanged("driverId")
      .switchMap(props =>
        props
          .getDriverLocation(props.driverId)
          .takeLast(1)
          .let(mapObjectResponseStream)
          .repeatWhen(stream => stream.delay(10 * 1000)),
      )
      .map(x => x.get("payload"))
      .startWith(Immutable.Map())
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(positionStream, (props, position) => ({
      ...props,
      position,
    }));
  }),
  pureComponent(fp.pick(["position"])),
);

DriverLocationMap.propTypes = {
  classes: PropTypes.object,

  position: PropTypes.instanceOf(Immutable.Map),
  mapProvider: PropTypes.oneOf(MAP_PROVIDERS),
  isMapKeyAvailable: PropTypes.bool,
  driverId: PropTypes.number.isRequired,
  getDriverLocation: PropTypes.func.isRequired,
};

function DriverLocationMap(props) {
  const { classes } = props;

  const position = {
    lat: props.position.get("lat"),
    lng: props.position.get("lon"),
  };

  return (
    <div>
      {props.isMapKeyAvailable ? (
        <GoogleMapWrapper className={classes.map}>
          {isValidCoordinates(position) && (
            <div>
              <PanTo latLng={position} />
              <BrandMarker position={position} />
            </div>
          )}
        </GoogleMapWrapper>
      ) : (
        <LeafletMapWrapper className={classes.map}>
          {isValidCoordinates(position) && (
            <div>
              <LPanTo latLng={position} />
              <Marker position={position} />
            </div>
          )}
        </LeafletMapWrapper>
      )}
    </div>
  );
}

export default enhancer(DriverLocationMap);
