import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import PropTypes from "prop-types";
import NavTabs from "../ui-core/NavTabs";
import TabBadge from "../deprecated/TabBadge";
import { formatText } from "../../helpers/FormatUtils";
import CODTransferStatus, {
  PENDING,
  APPROVED,
} from "../../constants/CODTransferStatus";

const formatTransferStatus = (type, getLocalisationMessage) => {
  switch (type) {
    case PENDING:
      return getLocalisationMessage("pending_review", "Pending Review");

    case APPROVED:
      return getLocalisationMessage(
        "approved_for_transfer",
        "Approved for Transfer",
      );

    default:
      return formatText(getLocalisationMessage(type, type));
  }
};

CODTransferTableTabs.propTypes = {
  statusCounts: PropTypes.instanceOf(Map),
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  getLocalisationMessage: PropTypes.func,
};

export default function CODTransferTableTabs({
  statusCounts,
  location,
  getLocalisationMessage,
}) {
  const query = _.omit(location.query, ["page"]);
  const value = CODTransferStatus.has(query.status)
    ? query.status
    : CODTransferStatus.first();

  return (
    <NavTabs
      width={540}
      value={value}
      tabs={CODTransferStatus.map(type => ({
        value: type,
        to: { pathname: location.pathname, query: { ...query, status: type } },
        label: (
          <TabBadge
            label={formatTransferStatus(type, getLocalisationMessage)}
            badge={statusCounts && statusCounts.get(type)}
          />
        ),
      })).toArray()}
    />
  );
}
