import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import DateTimeCell from "../data-list/DateTimeCell";
import _ from "lodash";
import CustomButton from "../ui-core/CustomButton";

export const EDIT = "edit";
export const SAVE = "save";
export const LINK = "link";
export const DIALOG = "dialog";
export const DATE = "date";
export const COLUMN = "column";
export const RENDER = "render";
export const ACTION = "action";

const EnhancedTableHead = (props) => (
  <TableHead>
    <TableRow>
      {props.headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? "right" : "left"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={false}
        >
          {headCell.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const enhancer = compose(
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);
EnhancedTableHead.propTypes = {
  headCells: PropTypes.array,
};

const useStyles = makeStyles({});

function MUITableSimple({
  columns,
  // getLocalisationMessage,
  list,
  size,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={10}
              headCells={columns}
            />
            <TableBody>
              {list.map((row, index) => (
                <TableRow
                  hover={true}
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                >
                  {columns.map((item, i) =>
                    item.type === RENDER ? (
                      <TableCell key={i} padding="normal">
                        {item.render(row)}
                      </TableCell>
                    ) : item.type === DATE ? (
                      <TableCell key={i} padding="normal">
                        <DateTimeCell date={_.get(row, item.keyName)} />
                      </TableCell>
                    ) : item.type === ACTION ? (
                      <TableCell key={i} padding="normal">
                        <CustomButton
                          onClick={() => item.dispatch(row.id)}
                          endIcon={item.endIcon}
                        >
                          {item.label}
                        </CustomButton>
                      </TableCell>
                    ) : (
                      <TableCell key={i} padding="normal">
                        {row[item.name]}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

MUITableSimple.propTypes = {
  getLocalisationMessage: PropTypes.func,
  list: PropTypes.array,
  columns: PropTypes.array,
  size: PropTypes.oneOf(["small", "medium"]),
};

export default enhancer(MUITableSimple);
