import { Observable } from "rxjs";
import React from "react";
import Immutable, { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, withContext, withHandlers, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FormMarketplaceSelectField from "../form/FormMarketplaceSelectField";
import PageLoading from "../ui-core/PageLoading";
import PriceWrapper from "../ui-core/PriceWrapper";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CODTransferType from "../../constants/CODTransferType";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
    };
  }),
  withTheme,
  renderIf("open"),
  withHandlers({
    onSubmit: props => values =>
      props.onSubmit({
        ...values,
        to: values.to ? { id: values.to } : null,
      }),
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  reduxForm({
    form: "CreateCODTransferDialog",
    validate: (values, props) => {
      if (props.marketplace.get("custom")) {
        return {
          note:
            fp.isEmpty(values.note) &&
            props.getLocalisationMessage(
              "add_transfer_note",
              "Add Transfer Note",
            ),
          type:
            fp.isEmpty(values.type) &&
            props.getLocalisationMessage(
              "select_transfer_type",
              "Select Transfer Type",
            ),
          orderNumbers:
            fp.isEmpty(values.orderNumbers) &&
            props.getLocalisationMessage("add_orders", "Add Orders"),
          to:
            !values.to &&
            props.getMarketplace &&
            props.getLocalisationMessage(
              "select_marketplace",
              "Select Marketplace",
            ),
        };
      }
      return {
        note:
          fp.isEmpty(values.note) &&
          props.getLocalisationMessage(
            "add_transfer_note",
            "Add Transfer Note",
          ),
        type:
          fp.isEmpty(values.type) &&
          props.getLocalisationMessage(
            "select_transfer_type",
            "Select Transfer Type",
          ),
        orderNumbers:
          fp.isEmpty(values.orderNumbers) &&
          props.getLocalisationMessage("add_orders", "Add Orders"),
        supplier:
          props.getCachedSupplier &&
          !isValidObjectId(values.supplier) &&
          props.getLocalisationMessage("select_supplier", "Select Supplier"),
      };
    },
  }),
  formValues({ orderNumbers: "orderNumbers" }),
  mapPropsStream(propsStream => {
    const totalAmountStream = propsStream
      .distinctUntilKeyChanged("orderNumbers")
      .switchMap(props =>
        fp.isEmpty(props.orderNumbers)
          ? Observable.of(null)
          : props
              .calculateCodTransferTotal({
                order_numbers: props.orderNumbers,
              })
              .catch(() => Observable.of({})),
      )
      .map(fp.flow(fp.get("data"), fromJS))
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      totalAmountStream,
      (props, totalAmount) => ({
        ...props,
        totalAmount,
      }),
    );
  }),
  pureComponent(fp.pick(["submitting", "totalAmount", "orderNumbers"])),
);

CreateCODTransferDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  orderNumbers: PropTypes.array,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  totalAmount: PropTypes.instanceOf(Map),
  calculateCodTransferTotal: PropTypes.func.isRequired,

  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  getMarketplace: PropTypes.func,
  marketplace: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

function CreateCODTransferDialog(props) {
  const { getLocalisationMessage, getMarketplace } = props;
  return (
    <Dialog
      modal={true}
      open={props.open}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          width: 800,
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary1Color,
        }}
      >
        <h3>
          {props.orderNumbers.length}{" "}
          {getLocalisationMessage(
            "orders_for_transfer_approval_total_amount",
            "orders for transfer approval. Total Amount",
          )}
          :{" "}
          {props.totalAmount ? (
            <PriceWrapper
              price={props.totalAmount.get("total")}
              code={props.totalAmount.getIn(["currency", "code"])}
            />
          ) : (
            getLocalisationMessage("calculating", "Calculating")
          )}
        </h3>
      </DialogTitle>
      <DialogContent>
        <form autoComplete="off">
          <FormChipInput
            name="orderNumbers"
            focus={true}
            fullWidth={true}
            label={`${getLocalisationMessage(
              "enter_order_numbers",
              "Enter Order Numbers",
            )} *`}
          />

          {props.getCachedSupplier &&
            props.marketplace &&
            !props.marketplace.get("custom") && (
              <FormSupplierAutoComplete
                name="supplier"
                fullWidth={true}
                label={`${getLocalisationMessage("supplier", "Supplier")} *`}
                hintText={getLocalisationMessage(
                  "type_to_search",
                  "Type To Search...",
                )}
              />
            )}

          {props.marketplace &&
            getMarketplace &&
            props.marketplace.get("custom") && (
              <FormMarketplaceSelectField
                name="to"
                optional={true}
                autoWidth={true}
                fullWidth={true}
                label={`${getLocalisationMessage(
                  "marketplace",
                  "Marketplace",
                )} *`}
                getMarketplace={props.getMarketplace}
              />
            )}

          <FormSelectField
            name="type"
            autoWidth={true}
            fullWidth={true}
            label={`${getLocalisationMessage(
              "cod_transfer_type",
              "COD Transfer Type",
            )} *`}
            options={CODTransferType}
            formatOption={x => getLocalisationMessage(x, formatText(x))}
          />

          <FormTextField
            name="note"
            fullWidth={true}
            multiLine={true}
            label={`${getLocalisationMessage(
              "cod_transfer_note",
              "COD Transfer Note",
            )} *`}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button primary={true} onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button primary={true} onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(CreateCODTransferDialog);
