import React from "react";
import PropTypes from "prop-types";
import { getBatchOrderUpdateItem } from "../../api/admin/AdminBatchApi";
import BatchUpdatesItemDialog from "../../components/batch-updates-dialog/BatchUpdatesItemDialog";

const AdminBatchUpdatesItemDialogWrapper = ({
  batchId,
  onRequestClose,
  refreshBatchList,
  isCustoms,
  isHybrid,
  hideAddBatch,
  refreshTabAndList,
  isRefresh,
  hideAction,
}) =>
  batchId ? (
    <BatchUpdatesItemDialog
      isRefresh={isRefresh}
      hideAction={hideAction}
      refreshTabAndList={refreshTabAndList}
      isHybrid={isHybrid}
      hideAddBatch={hideAddBatch}
      isCustoms={isCustoms}
      refreshBatchList={refreshBatchList}
      batchId={batchId}
      onRequestClose={onRequestClose}
      getBatchOrderUpdateItem={getBatchOrderUpdateItem}
    />
  ) : null;
AdminBatchUpdatesItemDialogWrapper.propTypes = {
  batchId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  refreshTabAndList: PropTypes.func,
  refreshBatchList: PropTypes.func,
  isCustoms: PropTypes.bool,
  isHybrid: PropTypes.bool,
  hideAddBatch: PropTypes.bool,
  isRefresh: PropTypes.bool,
  hideAction: PropTypes.bool,
};
export default AdminBatchUpdatesItemDialogWrapper;
