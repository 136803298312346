import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NewReleases, CheckCircle } from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { formatDate, formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { PRIMARY, CONTRAST } from "../../constants/AlertsContastants";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    showErrorMessage,
    showSuccessMessage,
  })),
  useSheet({
    statusIcon: {
      padding: "16px",
    },
    statusInfo: {
      color: "rgba(255, 255, 255, 0.54)",
      fontSize: "16px",
      "& > div": {
        lineHeight: "18px",
        "&:last-child": {
          fontSize: "14px",
        },
      },
    },
    "primary-text": {
      color: "#fff",
      fontSize: "16px",
    },
    "contrast-text": {
      color: "#4e4646",
    },
    dismissPrimaryText: {
      color: "#9E9E9E",
    },
    dismissContrastText: {
      color: "rgba(158, 158, 158, 0.54)",
    },
  }),
  pureComponent(fp.pick(["color", "detailed", "item"])),
);

AlertLocationUpdateInfo.propTypes = {
  classes: PropTypes.object,

  item: PropTypes.instanceOf(Map),
  detailed: PropTypes.instanceOf(Map),

  isHistory: PropTypes.bool,
  isDismissed: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,

  color: PropTypes.oneOf([CONTRAST, PRIMARY]),
};

AlertLocationUpdateInfo.defaultProps = {
  color: PRIMARY,
};

function AlertLocationUpdateInfo(props) {
  const { classes, getLocalisationMessage } = props;
  const alertsCount = props.item.get("last_week_active_alerts");

  return (
    <FlexBox>
      <FlexBox align="center" className={classes.statusIcon}>
        {props.item.get("last_week_active_alerts") || props.isHistory ? (
          <NewReleases color={props.color === PRIMARY ? "fff" : "#9E9E9E"} />
        ) : (
          <CheckCircle color="#fff" />
        )}
      </FlexBox>

      <FlexBox flex={true}>
        {props.item.get("last_week_active_alerts") ? (
          <FlexBox
            justify="center"
            direction="column"
            className={classes.statusInfo}
          >
            <FlexBox
              className={cx(classes[`${props.color}-text`], {
                [classes.dismissPrimaryText]: props.isDismissed,
              })}
            >
              {getLocalisationMessage(
                props.detailed.getIn(["last_alert", "type"]),
                formatText(props.detailed.getIn(["last_alert", "type"])),
              )}
            </FlexBox>

            {props.detailed.getIn(["last_alert", "created_date"]) && (
              <FlexBox
                className={cx({
                  [classes.dismissContrastText]: props.isDismissed,
                })}
              >
                {getLocalisationMessage("alerted", "Alerted")}:{" "}
                {formatDate(
                  props.detailed.getIn(["last_alert", "created_date"]),
                )}{" "}
                {alertsCount - 1 > 0
                  ? ` + ${alertsCount - 1} ${getLocalisationMessage(
                      "more",
                      "more",
                    )}`
                  : ""}
              </FlexBox>
            )}
          </FlexBox>
        ) : props.isHistory ? (
          <FlexBox
            justify="center"
            direction="column"
            className={classes.statusInfo}
          >
            <FlexBox
              className={cx(classes[`${props.color}-text`], {
                [classes.dismissPrimaryText]: props.isDismissed,
              })}
            >
              {getLocalisationMessage(
                props.item.get("type"),
                formatText(props.item.get("type")),
              )}
            </FlexBox>

            <FlexBox
              className={cx({
                [classes.dismissContrastText]: props.isDismissed,
              })}
            >
              {getLocalisationMessage("alerted", "Alerted")}:{" "}
              {formatDate(props.item.get("created_date"))}
            </FlexBox>
          </FlexBox>
        ) : (
          <FlexBox
            justify="center"
            direction="column"
            className={classes.statusInfo}
          >
            <FlexBox element={<span />} className={classes["primary-text"]}>
              {getLocalisationMessage(
                "good_job_currently_no_alerts",
                "Good Job! Currently No Alerts",
              )}
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(AlertLocationUpdateInfo);
