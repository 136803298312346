import _ from "lodash";
import { Map, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import DataListFilter from "./DataListFilter";
import { baseFilter } from "./OrderFilterMapper";
import { queryTypes } from "../../shared/helpers/ObjectMapper";

const baseExcludeFields = OrderedSet.of(
  "page",
  "size",
  "search",
  "order_by",
  "search_type",
  "order_by_direction",
);

export const mergeFilterToFilter = (
  originFilter: DataListFilter,
  destinationFilter: DataListFilter,
  fieldTypes: Object = baseFilter,
  excludeFields: Array = baseExcludeFields,
) => {
  if (
    !(originFilter instanceof DataListFilter) ||
    !(destinationFilter instanceof DataListFilter)
  ) {
    return originFilter;
  }

  const origin = fromJS(originFilter.getDefinedValues());
  const destination = fromJS(destinationFilter.getDefinedValues());
  const filter = origin.withMutations(map => {
    destination.forEach((value, idx) => {
      if (!map.get(idx)) {
        map.set(idx, value);
      } else if (
        excludeFields.includes(idx) ||
        fp.isNull(value) ||
        fp.isUndefined(value)
      ) {
        // Exclude field
      } else {
        switch (fieldTypes[idx]) {
          case queryTypes.IDSeq:
          case queryTypes.floatSeq:
          case queryTypes.stringSeq: {
            const x = OrderedSet(map.get(idx).split(","));

            map.set(idx, x.union(value.split(",")).join(","));
            break;
          }

          default:
            map.set(idx, value);
        }
      }
    });
  });

  return originFilter.setValueMap(filter);
};

export const unmergeValuesFromFilter = (
  destinationFilter: DataListFilter,
  values: Map,
  excludeValues: Map = Map(),
  excludeFields: Array = baseExcludeFields,
  fieldTypes: Object = baseFilter,
) => {
  if (
    !(destinationFilter instanceof DataListFilter) ||
    !(values instanceof Map)
  ) {
    return destinationFilter;
  }

  const destination = fromJS(destinationFilter.getDefinedValues());

  const filter = destination.withMutations(map => {
    values.forEach((value, idx) => {
      if (!map.get(idx)) {
        // map.set(idx, value);
      } else if (
        excludeFields.includes(idx) ||
        fp.isNull(value) ||
        fp.isUndefined(value)
      ) {
        // Exclude field
      } else {
        switch (fieldTypes[idx]) {
          case queryTypes.IDSeq:
          case queryTypes.floatSeq:
          case queryTypes.stringSeq: {
            const x = OrderedSet(map.get(idx).split(","));
            const ex = excludeValues.get(idx, null);

            if (!fp.isNull(ex)) {
              const arr = [];
              const exArray = ex.split(",");
              x.forEach(v => {
                // Changed !ex to ex to fix the issue
                // eslint-disable-next-line lodash/prefer-includes
                if (_.indexOf(exArray, v) > -1) {
                  arr.push(v);
                }
              });

              map.set(idx, fromJS(arr));
            } else {
              map.set(idx, null);
            }

            break;
          }

          default:
            if (fp.isNull(excludeValues.get(idx))) {
              map.set(idx, null);
            }
        }
      }
    });
  });

  return new DataListFilter(filter.toJS());
};
