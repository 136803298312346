import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormCheckbox from "../form/FormCheckbox";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import PriceWrapper from "../ui-core/PriceWrapper";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import useSheet from "react-jss";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withTheme,
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
  }),
  renderIf("open"),
  reduxForm({
    form: "BatchUpdateLiabilityTotalDialog",
  }),
  mapPropsStream(propsStream => {
    const totalAmountStream = propsStream
      .distinctUntilKeyChanged("orderNumbers")
      .switchMap(props =>
        !props.orderNumbers.length
          ? Observable.of(null)
          : props
              .getCalculateLiabilityTotal({
                driver_id: props.driverId,
                order_numbers: props.orderNumbers,
              })
              .catch(() => Observable.of(null)),
      )
      .map(fp.flow(fp.get("data"), fromJS))
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      totalAmountStream,
      (props, totalAmount) => ({
        ...props,
        totalAmount,
      }),
    );
  }),
);

BatchUpdateLiabilityTotalDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  theme: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  totalAmount: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
};

function BatchUpdateLiabilityTotalDialog(props) {
  const { getLocalisationMessage } = props;
  return (
    <Dialog modal={true} open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary1Color,
        }}
      >
        {getLocalisationMessage("total_amount", "Total Amount")}:{" "}
        {props.totalAmount ? (
          <PriceWrapper
            price={props.totalAmount.get("total")}
            code={props.totalAmount.getIn(["currency", "code"])}
          />
        ) : (
          getLocalisationMessage("calculating", "Calculating")
        )}
      </DialogTitle>
      <DialogContent>
        <FlexBox
          style={{
            marginTop: "30px",
          }}
          flex={true}
          direction="column"
        >
          <FormCheckbox
            name="updateCodAsWithCashier"
            label={getLocalisationMessage(
              "update_cod_as_with_cashier",
              "Update COD as with Cashier",
            )}
          />
          <FormCheckbox
            name="updatePsfAsWithCashier"
            label={getLocalisationMessage(
              "update_psf_as_with_cashier",
              "Update Deliver Charge as with Cashier",
            )}
          />
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <Button primary={true} onClick={props.onRequestClose}>
          {getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button primary={true} onClick={props.handleSubmit}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchUpdateLiabilityTotalDialog);
