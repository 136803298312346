import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import SortableList, { SortableListItem } from "../sortable-list/SortableList";
import { pureComponent } from "../../helpers/HOCUtils";

const enhancer = compose(pureComponent(fp.pick(["ruleList"])));

RuleList.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  ruleList: PropTypes.instanceOf(List).isRequired,
};

function RuleList(props) {
  const { ruleList } = props;

  return (
    <SortableList
      onSortEnd={({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
          props.onChange(
            ruleList
              .splice(oldIndex, 1)
              .splice(newIndex, 0, ruleList.get(oldIndex))
              .map((x, idx) => x.set("weight", idx)),
          );
        }
      }}
    >
      {ruleList.map((x, index) => (
        <SortableListItem
          key={index}
          primaryText={x.get("name")}
          onClick={() => props.onEditClick(index)}
          rightIconButton={
            <IconButton onClick={() => props.onChange(ruleList.delete(index))}>
              <Close />
            </IconButton>
          }
        />
      ))}
    </SortableList>
  );
}

export default enhancer(RuleList);
