import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { Chip, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import UserAvatar from "../avatars/UserAvatar";
import FlexBox from "../ui-core/FlexBox";
import {
  orderStatusColor,
  formatOrderStatusCodeLocalization,
} from "../../helpers/OrderHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import { headerInfoColor } from "../../../shared/theme/main-theme";
import OrderIdIcon from "../order-job/assets/order_id_icon.svg";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);
    return {
      getLocalisationMessage,
      statusMessages: getStatusLocalisation(state),
    };
  }),
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  useSheet({
    root: {
      flex: "1 1 0%",
      height: "64px",
      padding: "12px",
      display: "flex",
      flexDirection: "column",

      color: "#fff",

      "& a": {
        color: "#fff",
      },
    },

    panel: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        flex: "1 1 0%",
        fontFamily: "Blogger Sans",
      },
    },

    createdDate: {
      width: "32px",
      height: "32px",
      margin: "4px",
      fill: "rgba(0, 0, 0, 0.54)",
    },

    informerTextBlock: {
      display: "flex",
      lineHeight: 1.2,
      marginLeft: "8px",
      flexDirection: "column",
      justifyContent: "center",
      textTransform: "uppercase",
    },

    informerSecondaryText: {
      fontSize: "10px",
      textTransform: "normal",
      color: headerInfoColor,
      paddingRight: "10px",
    },

    shipment: { textAlign: "right" },
    customer: { display: "flex", marginTop: "20px", flexDirection: "row" },
    customerTextBlock: {
      marginLeft: "12px",
      lineHeight: "15px",
      paddingTop: "4px",
      textTransform: "uppercase",
    },
    customerTitle: { color: "#7e8092", fontSize: "10px" },

    courier: {
      display: "flex",
      marginTop: "0",
      textAlign: "right",
      flexDirection: "row-reverse",
    },

    status: {
      display: "flex",
      textAlign: "right",
      flexDirection: "row-reverse",
      margin: "0",
      padding: "0",
      height: "30px",
      marginTop: "5px",
      textTransform: "uppercase",
      borderRadius: "5px",
      float: "left",
    },

    courierTextBlock: {
      paddingTop: "4px",
      lineHeight: "15px",
      marginRight: "12px",
      textTransform: "uppercase",
    },
    courierTitle: { color: "#7e8092", fontSize: "10px" },
    toCourier: { textAlign: "center", marginTop: "20px", lineHeight: "18px" },
    toCourierTime: { fontSize: "12px" },
  }),
);

OrderDriverSuggestionDialogHeader.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  statusMessages: PropTypes.instanceOf(Map),
};

function OrderDriverSuggestionDialogHeader(props) {
  const { classes, order, getLocalisationMessage, statusMessages } = props;
  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <FlexBox>
          <Avatar
            size={40}
            style={{
              backgroundColor: "white",
            }}
            src={OrderIdIcon}
          />

          <div className={classes.informerTextBlock}>
            <div>
              {getLocalisationMessage("order_upper_case", "ORDER")}: #
              {order.get("order_number") || getLocalisationMessage("na", "N/A")}
            </div>
            <div className={classes.informerSecondaryText}>
              {getLocalisationMessage(order.get("type"), order.get("type")) ||
                order.get("type") ||
                getLocalisationMessage("na", "N/A")}
            </div>
          </div>
        </FlexBox>

        <FlexBox>
          <div style={{ height: "40px", width: "40px" }}>
            <UserAvatar
              size={40}
              name={order.getIn(["customer", "name"])}
              src={order.getIn(["customer", "photo"])}
            />
          </div>
          <div className={classes.informerTextBlock}>
            <div>{order.getIn(["customer", "name"])}</div>
            <div className={classes.informerSecondaryText}>
              <span style={{ textTransform: "normal" }}>
                {order.getIn(["locations", 1, "address"])}
              </span>
            </div>
          </div>
        </FlexBox>

        {order.get("driver") && (
          <FlexBox>
            <div style={{ height: "40px", width: "40px" }}>
              <UserAvatar
                size={40}
                name={order.getIn(["driver", "name"])}
                src={order.getIn(["driver", "photo"])}
              />
            </div>
            <div className={classes.informerTextBlock}>
              <div>{order.getIn(["driver", "name"])}</div>
              <div className={classes.informerSecondaryText}>
                <span style={{ textTransform: "normal" }}>
                  {order.getIn(["supplier", "name"])}
                </span>
              </div>
            </div>
          </FlexBox>
        )}

        <FlexBox>
          <div
            style={{ width: "100%", paddingRight: "20px", paddingLeft: "40px" }}
          >
            <Chip
              className={classes.status}
              labelColor="white"
              backgroundColor={orderStatusColor(order.get("status"))}
            >
              {formatOrderStatusCodeLocalization(
                order.get("status"),
                statusMessages,
              )}
            </Chip>
          </div>
        </FlexBox>
      </div>
    </div>
  );
}

export default enhancer(OrderDriverSuggestionDialogHeader);
