import React from "react";
import sprintf from "sprintf";
import { Map, Set, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Close } from "@material-ui/icons";
import {
  Marker,
  FitBounds,
  InfoWindow,
  DataPolygon,
} from "react-google-map-components";
import PolylineWrapper from "../maps/PolylineWrapper";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import LinkButton from "../ui-core/LinkButton";
import { pureComponent } from "../../helpers/HOCUtils";
import { numberToColor, numberToCharacter } from "../../helpers/FormatUtils";
import { IconButton } from "@material-ui/core";

const ICON_TEMPLATE =
  "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%s|%s|FFFFFF";

const infoWindowStyle = {
  position: "absolute",
  top: 0,
  right: "2px",
  padding: "2px",
  width: "16px",
  height: "16px",
  zIndex: 1,
  background: "#fff",
};

ClusterInfoWindow.propTypes = {
  onCloseClick: PropTypes.func.isRequired,

  order: PropTypes.instanceOf(Map).isRequired,
  onOrderDeleteClick: PropTypes.func.isRequired,

  cluster: PropTypes.instanceOf(Map),
  clusterIndex: PropTypes.number,
  onChangeClusterClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function ClusterInfoWindow({
  onCloseClick,
  order,
  onOrderDeleteClick,
  cluster,
  clusterIndex,
  onChangeClusterClick,
  getLocalisationMessage,

  ...restProps
}) {
  const address = order.getIn(["locations", 1, "address"]);
  const details = order.getIn(["locations", 1, "details"]);
  const position = {
    lat: order.getIn(["locations", 1, "lat"]),
    lng: order.getIn(["locations", 1, "lon"]),
  };

  return (
    <InfoWindow
      {...restProps}
      style={{ position: "relative" }}
      position={position}
      title={order.get("name")}
    >
      <div>
        <IconButton
          style={infoWindowStyle}
          iconStyle={{ width: "14px", height: "14px" }}
          onClick={onCloseClick}
        >
          <Close />
        </IconButton>

        {cluster && (
          <div>
            <strong>
              Route: {numberToCharacter(clusterIndex)} (
              {cluster.last().get("overAllDuration")}
              m)
            </strong>
          </div>
        )}

        <div>
          <strong>Order Number: {order.get("order_number")}</strong>
        </div>

        <div>
          <strong>Address: {address}</strong>
        </div>

        <div>
          <strong>Supplier: {order.getIn(["supplier", "name"])}</strong>
        </div>

        <div>
          <strong>Driver: {order.getIn(["driver", "name"])}</strong>
        </div>

        {details && address !== details ? (
          <div>
            <strong>Details: {details}</strong>
          </div>
        ) : null}

        <LinkButton onClick={onOrderDeleteClick}>
          {getLocalisationMessage &&
            getLocalisationMessage("remove_order", "Remove Order")}
        </LinkButton>

        {cluster && (
          <span>
            &nbsp;/&nbsp;
            <LinkButton onClick={onChangeClusterClick}>
              Change Cluster
            </LinkButton>
          </span>
        )}
      </div>
    </InfoWindow>
  );
}

const enhancer = compose(
  useSheet({
    mapContainer: {
      left: "0",
      right: "0",
      top: "64px",
      bottom: "0",
      position: "fixed",
    },
  }),
  pureComponent(
    fp.pick([
      "zones",
      "orders",
      "clusters",
      "selectedOrderId",
      "selectedClusterIndex",
    ]),
  ),
);

OrderRouterMap.propTypes = {
  classes: PropTypes.object,

  clusters: PropTypes.instanceOf(List),
  clustersUntilWarehouse: PropTypes.instanceOf(Map),
  clustersFilteredOrders: PropTypes.instanceOf(Map),
  selectedOrderId: PropTypes.number,

  selectedClusterIndex: PropTypes.number,

  zones: PropTypes.instanceOf(List),

  onOrderClick: PropTypes.func.isRequired,
  onOrderDelete: PropTypes.func.isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,

  fitToOrderIds: PropTypes.instanceOf(Set).isRequired,
  onCenterChanged: PropTypes.func.isRequired,

  onChangeClusterClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderRouterMap(props) {
  const {
    classes,

    orders,
    clusters,
    fitToOrderIds,
    selectedOrderId,
    onOrderClick,
    onOrderDelete,
    onChangeClusterClick,
    selectedClusterIndex,
    getLocalisationMessage,

    zones,
  } = props;

  const fitToPositions = [];
  const children = [];

  const selectedOrder = selectedOrderId && orders.get(selectedOrderId);
  const clusterIndex = clusters
    ? clusters.findIndex(x =>
        x.find((v, k) => k.get("orderId") === selectedOrderId),
      )
    : -1;

  const cluster = clusterIndex === -1 ? null : clusters.get(clusterIndex);

  if (clusters) {
    const whPositions = {};
    const hasSelectedCluster = clusters.has(selectedClusterIndex);

    orders.forEach(order => {
      if (fitToOrderIds.has(order.get("id"))) {
        fitToPositions.push({
          lat: order.getIn(["locations", 1, "lat"]),
          lng: order.getIn(["locations", 1, "lon"]),
        });
      }
    });

    clusters.forEach((x, idx) => {
      if (x.isEmpty() || (hasSelectedCluster && selectedClusterIndex !== idx)) {
        return;
      }

      const color = numberToColor(idx);
      const label = numberToCharacter(idx);
      const icon = sprintf(ICON_TEMPLATE, label, color.slice(1));

      const direction = { waypoints: [] };

      let index = -1;

      let pickupWarehouseId = 0;
      let lastPosition = {};

      x.forEach((duration, item) => {
        const position = {};

        index++;

        if (item.get("id")) {
          // it's warehouse
          position.lat = item.get("lat");
          position.lng = item.get("lon");

          pickupWarehouseId = item.get("id");

          whPositions[item.get("id")] = position;
        } else {
          const id = item.get("orderId");

          const order = orders.get(id);

          if (!order) {
            return false; // order is not on map, leave
          }

          position.lat = order.getIn(["locations", 1, "lat"]);
          position.lng = order.getIn(["locations", 1, "lon"]);

          children.push(
            <Marker
              icon={icon}
              position={position}
              key={`order-marker-${id}`}
              onClick={() => onOrderClick(id)}
            />,
          );

          const relatedOrders = props.clustersFilteredOrders.get(id, []);
          if (relatedOrders.length > 0) {
            relatedOrders.forEach(relatedOrderId => {
              const relatedOrder = orders.get(relatedOrderId);

              if (relatedOrder) {
                const relatedOrderPosition = {
                  lat: relatedOrder.getIn(["locations", 1, "lat"]),
                  lng: relatedOrder.getIn(["locations", 1, "lon"]),
                };

                children.push(
                  <Marker
                    icon={icon}
                    position={relatedOrderPosition}
                    key={`order-marker-${relatedOrderId}`}
                    onClick={() => onOrderClick(relatedOrderId)}
                  />,
                );
              }
            });
          }
        }

        if (index === 0) {
          direction.origin = position;
        } else if (index === x.size - 1) {
          direction.destination = position;
        } else {
          direction.waypoints.push({
            stopover: true,
            location: position,
          });
        }

        lastPosition = position;
        return true;
      });

      if (direction.origin && direction.destination) {
        children.push(
          <PolylineWrapper
            key={`${idx}-route`}
            origin={direction.origin}
            waypoints={direction.waypoints}
            strokeColor={numberToColor(idx)}
            destination={direction.destination}
          />,
        );
      }

      if (
        pickupWarehouseId > 0 &&
        props.clustersUntilWarehouse.get(pickupWarehouseId, null)
      ) {
        children.push(
          <PolylineWrapper
            key={`${idx}-route-back`}
            origin={lastPosition}
            strokeColor="#000"
            strokeOpacity={1}
            destination={whPositions[pickupWarehouseId]}
          />,
        );
      }
    });

    fp.keys(whPositions).forEach(id => {
      children.push(
        <Marker key={`wh-marker-${id}`} position={whPositions[id]} />,
      );
    });
  } else {
    orders.forEach(order => {
      const orderId = order.get("id");

      const position = {
        lat: order.getIn(["locations", 1, "lat"]),
        lng: order.getIn(["locations", 1, "lon"]),
      };

      if (fitToOrderIds.size > 0) {
        if (fitToOrderIds.has(orderId)) {
          fitToPositions.push(position);
        }
      } else {
        fitToPositions.push(position);
      }

      children.push(
        <Marker
          key={orderId}
          position={position}
          onClick={() => onOrderClick(orderId)}
        />,
      );
    });
  }

  return (
    <div>
      <div className={classes.mapContainer}>
        <GoogleMapWrapper
          onCenterChanged={
            !fitToPositions.length ? fp.noop : props.onCenterChanged
          }
        >
          {fitToPositions.length > 0 && (
            <FitBounds latLngBounds={fitToPositions} />
          )}

          {Boolean(selectedOrder) && (
            <ClusterInfoWindow
              order={selectedOrder}
              cluster={cluster}
              clusterIndex={clusterIndex}
              onCloseClick={() => onOrderClick(null)}
              onOrderDeleteClick={() => onOrderDelete(selectedOrderId)}
              onChangeClusterClick={() => onChangeClusterClick(selectedOrderId)}
              getLocalisationMessage={getLocalisationMessage}
            />
          )}

          {false &&
            Boolean(zones) &&
            zones.map((paths, index) => (
              <DataPolygon
                key={index}
                strokeWeight={2}
                strokeOpacity={0.4}
                strokeColor="#000000"
                fillOpacity={0.1}
                fillColor="#000000"
                geometry={[paths.toJS()]}
              />
            ))}

          {children}
        </GoogleMapWrapper>
      </div>
    </div>
  );
}

export default enhancer(OrderRouterMap);
