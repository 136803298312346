import _ from "lodash";
import sprintf from "sprintf";
import { Set, List, fromJS } from "immutable";
import fp from "lodash/fp";
import WingApi from "../helpers/WingApi";
import DataListFilter from "../helpers/DataListFilter";
import {
  FETCH_DRIVER,
  UPDATE_DRIVER,
  isDriverUpdating,
  FETCH_DRIVER_CHOOSE,
  FETCH_DRIVER_SUGGESTIONS,
  UPDATE_DRIVER_SUGGESTIONS,
} from "../reducers/DriverReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const DRIVER_URL = `${API_ROOT_URL}/admin/driver/%s`;
const DRIVER_CHOOSE_URL = `${API_ROOT_URL}/admin/driver/choose`;
const DRIVER_WAREHOUSE_URL = `${API_ROOT_URL}/admin/driver/%s/warehouse`;
const DRIVER_SUGGESTIONS_URL = `${API_ROOT_URL}/admin/driver/suggestion`;

export function fetchDriver(id) {
  return (dispatch, getState, api: WingApi) =>
    dispatch({
      meta: { id },
      type: FETCH_DRIVER,
      payload: api.get(sprintf(DRIVER_URL, id)),
    });
}

export function loadDriverPredictions(request: DataListFilter) {
  return (dispatch, getState, api: WingApi) =>
    api
      .getStream(DRIVER_CHOOSE_URL, { params: request.getDefinedValues() })
      .map(fp.update("payload", fp.flow(fp.get("data"), fromJS)));
}

/**
 * @deprecated
 * @param request
 * @returns {function(*, *, WingApi)}
 */
export function fetchDriverChoose(request) {
  return (dispatch, getState, api: WingApi) =>
    dispatch({
      meta: { request },
      type: FETCH_DRIVER_CHOOSE,
      payload: api.get(DRIVER_CHOOSE_URL, {
        params: request.toRequestParams(),
      }),
    });
}

function updateDriverWarehouse(driverId, warehouseId) {
  return (dispatch, getState, api: WingApi) => {
    if (isDriverUpdating(getState(), driverId)) {
      return Promise.reject(new Error("Driver is already updating"));
    }

    const url = sprintf(DRIVER_WAREHOUSE_URL, driverId);

    return dispatch({
      meta: { id: driverId },
      type: UPDATE_DRIVER,
      payload: api.put(url, {
        body: { driver: { id: driverId }, warehouse: { id: warehouseId } },
      }),
    });
  };
}

export const batchAssignDriversWarehouse = (
  driverIds,
  warehouseId,
) => dispatch =>
  Promise.all(
    driverIds
      .map(id =>
        dispatch(updateDriverWarehouse(id, warehouseId)).catch(() =>
          Promise.resolve(),
        ),
      )
      .toArray(),
  );

export function fetchDriverSuggestions(request) {
  let orderIdsList = List();

  return (dispatch, getState, api: WingApi) =>
    dispatch({
      meta: { request, notify: true },
      type: FETCH_DRIVER_SUGGESTIONS,
      payload: api
        .post(DRIVER_SUGGESTIONS_URL, {
          body: request.reduce(
            (body, cluster) => {
              const orderIds = [];
              const warehouseIds = [];
              const newBody = { ...body };

              cluster.forEach((metrics, item) => {
                if (item.get("id")) {
                  warehouseIds.push(item.get("id"));
                } else {
                  orderIds.push(item.get("orderId"));
                }
              });

              newBody.clusters.push({ order_ids: orderIds });

              if (!newBody.warehouse_id && warehouseIds.length) {
                newBody.warehouse_id = warehouseIds[warehouseIds.length - 1];
              }

              orderIdsList = orderIdsList.push(Set(orderIds));

              return newBody;
            },
            { clusters: [], warehouse_id: null },
          ),
        })
        .then(response => {
          const clusters = [];

          _.forEach(response.data.clusters, cluster => {
            const index = orderIdsList.indexOf(Set(cluster.order_ids));

            clusters[index] = cluster;
          });

          return { ...response, data: clusters };
        }),
    });
}

export function updateDriverSuggestions(request, data) {
  return { meta: { request }, payload: data, type: UPDATE_DRIVER_SUGGESTIONS };
}
