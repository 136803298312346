import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "../form/FormAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { chooseAddressBook } from "../../api/shared/AdminAddressBookV2Api";

const baseFilter = new DataListFilter({
  size: 20,
  country_id: 229,
});

const AddressBookAutoCompleteComponent = componentFromStream(propsStream => {
  const optionsStream = propsStream
    .map(
      fp.flow(
        fp.over([
          fp.get("input.value.name"),
          fp.flow(fp.get("countryId"), parseInt),
        ]),
        ([searchText, countryId]) =>
          baseFilter.setSearch(searchText).setValue("country_id", countryId),
      ),
    )
    .distinctUntilChanged(isEqualData)
    .switchMap(filter =>
      chooseAddressBook(filter).catch(() => Observable.of({})),
    )
    .map(fp.flow(fp.get("payload.data.list"), fp.toArray));

  return propsStream.combineLatest(optionsStream, (props, options) => (
    <AutoCompleteComponent
      {...props}
      optionStyle={{ maxHeight: "auto" }}
      filter={fp.stubTrue}
      options={options}
      parseInput={name => ({ name })}
      formatInput={fp.flow(fp.toPlainObject, fp.get("name"))}
      formatOption={fp.flow(
        fp.toPlainObject,
        item => [item.name, item.address],
        fp.compact,
        fp.join(", "),
      )}
    />
  ));
});

FormAdminAddressBookAutoCompleteV2.propTypes = {
  autoWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  countryId: PropTypes.number,
};

export default function FormAdminAddressBookAutoCompleteV2(props) {
  return <Field {...props} component={AddressBookAutoCompleteComponent} />;
}
