import React from "react";
import { List, Range } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import SelectField from "../deprecated/SelectField";
import { numberToCharacter } from "../../helpers/FormatUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";

OrderRouterChangeClusterDialog.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  onClusterChange: PropTypes.func.isRequired,
  clusters: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
};

const bodyStyle = { maxWidth: "320px" };

const formatOption = (index, getLocalisationMessage) =>
  `${(getLocalisationMessage && getLocalisationMessage("cluster", "Cluster")) ||
    "Cluster"}:
  ${numberToCharacter(index)}`;

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
  })),
);

function OrderRouterChangeClusterDialog({
  clusters,
  onRequestClose,
  onClusterChange,
  getLocalisationMessage,
  isRTL,
}) {
  const actions = (
    <Button onClick={onRequestClose}>
      {getLocalisationMessage("dismiss", "Dismiss")}
    </Button>
  );

  const options = new Range(0, clusters.size + 1).toList();

  return (
    <Dialog
      open={true}
      contentStyle={bodyStyle}
      autoScrollBodyContent={true}
      onClose={onRequestClose}
    >
      <DialogContent
        style={{
          maxWidth: "320px",
        }}
      >
        <SelectField
          autoWidth={true}
          fullWidth={true}
          label={getLocalisationMessage(
            "select_new_cluster",
            "Select new Cluster",
          )}
          value={null}
          options={options}
          onChange={onClusterChange}
          formatOption={option => formatOption(option, getLocalisationMessage)}
          menuItemStyle={{ textAlign: isRTL ? "right" : "left" }}
          iconStyle={{
            left: !isRTL ? "auto" : "0",
            right: isRTL ? "auto" : "0",
          }}
          labelStyle={{
            paddingLeft: !isRTL ? "0" : "56px",
            paddingRight: isRTL ? "0" : "56px",
            textAlign: isRTL ? "right" : "left",
          }}
          floatingLabelStyle={{
            right: isRTL ? "0" : "auto",
          }}
        />
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderRouterChangeClusterDialog);
