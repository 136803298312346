import { formatText } from "./FormatUtils";
import { SAVER } from "../constants/CourierTypes";
import {
  SCORE_RANKING,
  DRIVER_ATTENDANCE,
  NOT_OFD_SHIPMENTS,
  SHIPMENTS_CREATED,
  SHIPMENTS_PENDING,
  SHIPMENTS_DELIVERED,
  PENDING_COD_SHIPMENT,
  COD_RECEIVABLES_AGING,
  PENDING_RTO_SHIPMENTS,
  DAILY_CALL_PERFORMANCE,
  UN_ATTEMPTED_SHIPMENTS,
} from "../constants/OperationalReportTypes";

const NA = "N/A";

export const formatOperationalReportsTypes = (
  reportType,
  notAvailableValue = NA,
) => {
  switch (reportType) {
    case SCORE_RANKING:
      return "DSP Score ranking";

    case PENDING_RTO_SHIPMENTS:
      return "Pending RTO Shipments";

    case NOT_OFD_SHIPMENTS:
      return "Not OFD Shipments";

    case COD_RECEIVABLES_AGING:
      return "COD Receivable Aging";

    case PENDING_COD_SHIPMENT:
      return "Pending COD Shipment";

    default:
      return formatText(reportType) || notAvailableValue;
  }
};

export const formatOperationalReportsTypesLocalize = (
  reportType,
  getLocalisationMessage,
) => {
  switch (reportType) {
    case SCORE_RANKING:
      return (
        getLocalisationMessage &&
        getLocalisationMessage("dsp_score_ranking", "DSP Score ranking")
      );

    case PENDING_RTO_SHIPMENTS:
      return (
        getLocalisationMessage &&
        getLocalisationMessage("pending_rto_shipments", "Pending RTO Shipments")
      );

    case NOT_OFD_SHIPMENTS:
      return (
        getLocalisationMessage &&
        getLocalisationMessage("not_ofd_shipments", "Not OFD Shipments")
      );

    case COD_RECEIVABLES_AGING:
      return (
        getLocalisationMessage &&
        getLocalisationMessage("cod_receivable_aging", "COD Receivable Aging")
      );

    case PENDING_COD_SHIPMENT:
      return (
        getLocalisationMessage &&
        getLocalisationMessage("pending_cod_shipment", "Pending COD Shipment")
      );

    default:
      return (
        getLocalisationMessage(
          reportType.toLocaleLowerCase(),
          formatText(reportType),
        ) || getLocalisationMessage("na", "N/A")
      );
  }
};

export const formatCourierTypes = (courierType, notAvailableValue = NA) => {
  switch (courierType) {
    case SAVER:
      return "Saver";

    default:
      return formatText(courierType) || notAvailableValue;
  }
};

export const formatCourierTypesLocalize = (
  courierType,
  getLocalisationMessage,
) => {
  switch (courierType) {
    case SAVER:
      return getLocalisationMessage && getLocalisationMessage("saver", "Saver");

    default:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage(
            courierType.toLocaleLowerCase(),
            formatText(courierType),
          )) ||
        getLocalisationMessage("na", "N/A")
      );
  }
};

export const adminFilterReport = filter => {
  switch (filter.getValue("reportType")) {
    case SCORE_RANKING:
      return filter.getValue("filter_by");

    case DRIVER_ATTENDANCE:
      return true;

    case SHIPMENTS_CREATED:
      return (
        filter.getValue("from_date") &&
        filter.getValue("to_date") &&
        filter.getValue("group_by") &&
        filter.getValue("show_by")
      );

    case SHIPMENTS_PENDING:
      return (
        filter.getValue("from_date") &&
        filter.getValue("to_date") &&
        filter.getValue("group_by")
      );

    case SHIPMENTS_DELIVERED:
      return (
        filter.getValue("from_date") &&
        filter.getValue("to_date") &&
        filter.getValue("group_by")
      );

    case PENDING_COD_SHIPMENT:
      return filter.getValue("from_date") && filter.getValue("to_date");

    case COD_RECEIVABLES_AGING:
      return true;

    case DAILY_CALL_PERFORMANCE:
      return filter.getValue("from_date") && filter.getValue("to_date");

    default:
      return false;
  }
};

export const supplierFilterReport = filter => {
  switch (filter.getValue("reportType")) {
    case SCORE_RANKING:
      return filter.getValue("filter_by");

    case UN_ATTEMPTED_SHIPMENTS:
      return true;

    case PENDING_RTO_SHIPMENTS:
      return true;

    case NOT_OFD_SHIPMENTS:
      return true;

    default:
      return false;
  }
};
