import { Observable } from "rxjs";
import React from "react";
import { subDays } from "date-fns";
import Immutable from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import TabBadge from "../deprecated/TabBadge";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ALERTED } from "../../constants/AlertStatuses";

export const ALL_TAB = "all";
export const WEEK_TAB = "week";
export const CURRENT_TAB = "current";

const tabBaseFilters = Immutable.OrderedMap()
  .set(
    CURRENT_TAB,
    Immutable.fromJS({
      from_date_time: null,
      to_date_time: null,
      alert_statuses: [ALERTED],
    }),
  )
  .set(
    WEEK_TAB,
    Immutable.fromJS({
      alert_statuses: null,
      from_date_time: formatDateTimeToUrl(subDays(new Date(), 7)),
      to_date_time: formatDateTimeToUrl(new Date()),
    }),
  )
  .set(
    ALL_TAB,
    Immutable.fromJS({
      alert_statuses: null,
    }),
  );

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    root: {
      position: "relative",
    },
    tab: {
      color: "#fff",
      height: "48px",
      cursor: "pointer",
      textTransform: "uppercase",
      borderBottom: "2px solid transparent",
    },
    active: {
      color: "white",
      borderBottomColor: "white",
    },
    underline: {
      left: 0,
      right: 0,
      bottom: 0,
      height: "3px",
      width: "333.33px",
      position: "absolute",
      backgroundColor: "#fff",
      transition: "left .7s ease",
    },
    "week-underline": {
      left: "333.33px",
    },
    "all-underline": {
      left: "666.66px",
    },
  }),
  mapPropsStream(propsStream => {
    const tabsQueryStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props =>
        tabBaseFilters.map(tabFilter => props.filter.setValueMap(tabFilter)),
      )
      .distinctUntilChanged(isEqualData);

    const tabCountsStream = propsStream
      .map(props => ({
        driverId: props.driverId,
        excludeCount: props.excludeCount,
        filter: props.filter.setValue("alerts_tab", null),
        batchGetAlertsHistoryListCount: props.batchGetAlertsHistoryListCount,
      }))
      .distinctUntilChanged(isEqualData)
      .map(props => ({
        batchGetAlertsHistoryListCount: props.batchGetAlertsHistoryListCount,
        filters: tabBaseFilters.map(tabFilter =>
          props.filter.setValueMap(tabFilter).setValue("size", 1),
        ),
        driverId: props.driverId,
      }))
      .switchMap(({ driverId, filters, batchGetAlertsHistoryListCount }) =>
        batchGetAlertsHistoryListCount
          ? batchGetAlertsHistoryListCount(driverId, filters)
          : Observable.of(Immutable.fromJS({})),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      tabsQueryStream,
      tabCountsStream,
      (props, tabsQuery, tabCount) => ({
        ...props,
        tabs: tabsQuery
          .map((filter: DataListFilter, tab) => (
            <FlexBox
              key={tab}
              flex={true}
              justify="center"
              className={props.classes.tab}
              align="center"
              onClick={() =>
                props.onTabChange(filter.setValue("alerts_tab", tab))
              }
            >
              <TabBadge
                label={props.getLocalisationMessage(tab, tab)}
                badge={tabCount.get(tab)}
              />
            </FlexBox>
          ))
          .toArray(),
      }),
    );
  }),
);

DriverAlertHistoryTabs.propTypes = {
  classes: PropTypes.object,

  tab: PropTypes.oneOf([ALL_TAB, WEEK_TAB, CURRENT_TAB]),
  onTabChange: PropTypes.func.isRequired,

  filter: PropTypes.instanceOf(DataListFilter),

  batchGetAlertsHistoryListCount: PropTypes.func,

  tabs: PropTypes.array,

  excludeCount: PropTypes.number,

  getLocalisationMessage: PropTypes.func,
};

DriverAlertHistoryTabs.defaultProps = {
  tab: CURRENT_TAB,
};

function DriverAlertHistoryTabs(props) {
  const { classes } = props;

  return (
    <FlexBox flex={true} direction="column" className={classes.root}>
      <FlexBox flex={true}>{props.tabs}1</FlexBox>
      <FlexBox
        className={cx(classes.underline, classes[`${props.tab}-underline`])}
      />
    </FlexBox>
  );
}

export default enhancer(DriverAlertHistoryTabs);
