import {
  wrapText,
  formatText,
  takeFirstString,
  cleanupStringArray,
} from "./FormatUtils";
import { FIXED, PER_SHIPMENT } from "../constants/DriverPaymentTypes";

export function formatDriverName(driver, notSetValue = "N/A") {
  if (!driver || !driver.size) {
    return notSetValue;
  }

  return cleanupStringArray([
    takeFirstString([
      cleanupStringArray([
        driver.get("first_name"),
        driver.get("last_name"),
      ]).join(" "),
      driver.get("email"),
      // for SelectItem
      driver.get("name"),
    ]),
    wrapText(
      takeFirstString([
        driver.getIn(["supplier", "name"]),
        // for SelectItem
        driver.getIn(["description"]),
      ]),
    ),
  ]).join(" ");
}

export function formatDriverPaymentType(type) {
  switch (type) {
    case PER_SHIPMENT:
      return "Per Shipment";

    case FIXED:
      return "Dedicated driver";

    default:
      return formatText(type);
  }
}

export function formatLocalisedDriverPaymentType(type, getLocalisationMessage) {
  switch (type) {
    case PER_SHIPMENT:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("per_shipment", "Per Shipment")) ||
        "Per Shipment"
      );
    case FIXED:
      return (
        (getLocalisationMessage &&
          getLocalisationMessage("dedicated_driver", "Dedicated driver")) ||
        "Dedicated driver"
      );
    default:
      return (
        (getLocalisationMessage && getLocalisationMessage(type)) ||
        formatText(type)
      );
  }
}
