import { List } from "immutable";
import {
  green,
  grey,
  lightGreen,
  orange,
  red,
  yellow,
} from "@material-ui/core/colors";

const RATE_1 = red[700];
const RATE_2 = orange[700];
const RATE_3 = yellow[700];
const RATE_4 = lightGreen[700];
const RATE_5 = green[700];
const IN_ACTIVE = grey[400];

export default List([IN_ACTIVE, RATE_1, RATE_2, RATE_3, RATE_4, RATE_5]);
