import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { Paper, Slider } from "@material-ui/core";
import Text, { MUTED } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import { formatTime } from "../../helpers/FormatUtils";

const enhancer = compose(
  useSheet({
    time: {
      margin: "0 12px",
      fontSize: "13px",
      fontFamily: "Blogger Sans",
      lineHeight: "15px",
    },
    currentTime: {
      position: "absolute",
      fontSize: "13px",
      fontFamily: "Blogger Sans",
    },
    slider: {
      width: "100%",
      height: "18px",
      margin: "19px 0",
      "& > div": {
        margin: 0,
      },
    },
    sliderContainer: {
      position: "relative",
    },
  }),
  withState("state", "setState", () =>
    Immutable.Map({
      value: 0,
    }),
  ),
);

DriversRadarTimeSlider.propTypes = {
  classes: PropTypes.object,
  setState: PropTypes.func,
  state: PropTypes.object,

  onChange: PropTypes.func,
  locations: PropTypes.instanceOf(Immutable.Map),
};

function DriversRadarTimeSlider(props) {
  const { classes, state, locations } = props;

  const currentIndex = fp.toFinite(state.get("value", 0));

  const first = locations.get("locations").first();
  const last = locations.get("locations").last();
  const count = fp.toFinite(locations.get("locations").size - 1);

  return (
    <FlexBox container={8} flex={true}>
      <FlexBox flex={true} element={<Paper />}>
        <FlexBox flex={true}>
          <FlexBox gutter={8} flex={true} align="center">
            <FlexBox>
              <Text type={MUTED} className={classes.time}>
                {first ? formatTime(first.get("date")) : "00:00"}
              </Text>
            </FlexBox>

            <FlexBox flex={true} className={classes.sliderContainer}>
              <Slider
                min={0}
                step={1}
                value={currentIndex}
                max={Math.max(1, count)}
                onChange={(event, index) => {
                  props.setState(x => x.set("value", index));
                  props.onChange(index);
                }}
                className={classes.slider}
              />

              <span
                className={classes.currentTime}
                style={{
                  top: "39px",
                  left: "50%",
                }}
              >
                {formatTime(
                  locations.getIn(["locations", currentIndex, "date"]),
                )}
              </span>
            </FlexBox>

            <FlexBox>
              <Text type={MUTED} className={classes.time}>
                {last ? formatTime(last.get("date")) : "00:00"}
              </Text>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriversRadarTimeSlider);
