import React from "react";
import PropTypes from "prop-types";
import { Marker, MarkerIcon } from "react-leflet-map-components";
import { Motion, spring } from "react-motion";
import { pureComponent } from "../../helpers/HOCUtils";
import vehicle from "./assets/vehicle.svg";
import fp from "lodash/fp";

const springConfig = { stiffness: 10, damping: 40 };
const DEFAULT = "default";
const enhancer = pureComponent(fp.pick(["rotation", "position"]));

LVehicleMarker.propTypes = {
  onClick: PropTypes.func,
  rotation: PropTypes.number,
  position: PropTypes.object.isRequired,

  withMotion: PropTypes.bool,
  busy: PropTypes.oneOf([true, false, DEFAULT]),
  icon: PropTypes.element,
  size: PropTypes.number,
};

LVehicleMarker.defaultProps = {
  rotation: 0,
  size: 45,
  withMotion: true,
  busy: DEFAULT,
};

function LVehicleMarker({ size, ...props }) {
  return props.withMotion ? (
    <Motion
      style={{
        rotation: spring(props.rotation),
        lat: spring(props.position.lat, springConfig),
        lng: spring(props.position.lng, springConfig),
      }}
    >
      {x => (
        <Marker
          onClick={props.onClick}
          position={{ lat: x.lat, lng: x.lng }}
          rotation={props.rotation}
          icon={
            <MarkerIcon
              url={vehicle}
              anchor={{ y: size, x: size / 2 }}
              origin={{ x: size, y: size / 2 }}
              size={{ width: size, height: size }}
              scaledSize={{
                width: 2 * size,
                height: 2 * size,
              }}
            />
          }
        />
      )}
    </Motion>
  ) : (
    <Marker
      onClick={props.onClick}
      position={props.position}
      rotation={props.rotation}
      icon={
        <MarkerIcon
          anchor={{ y: size, x: size / 2 }}
          size={{ width: size, height: size }}
          origin={{ x: size, y: size / 2 }}
          scaledSize={{
            width: 2 * size,
            height: 2 * size,
          }}
          url={vehicle}
        />
      }
    />
  );
}

export default enhancer(LVehicleMarker);
