import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import { getInitialState } from "../../helpers/InitialState";
import { getAppName } from "../../../shared/reducers/AppReducer";
import { HOST } from "../../../shared/constants/ApiConstants";

const UI_SETTINGS_URL = `${HOST}/api/ui-settings`;
const UI_SETTINGS_ITEM_URL = `${HOST}/api/ui-settings/%s`;

const initialState = getInitialState();

export class SettingsApi {
  constructor({ domain = "generic", app = getAppName(initialState) }) {
    this.app = app;
    this.domain = domain;
  }

  wrapKey(key) {
    return [this.app, this.domain, key].join("-");
  }

  getItem(key) {
    return api.getStream(sprintf(UI_SETTINGS_ITEM_URL, this.wrapKey(key)));
  }

  getWithoutStreamItem(key) {
    return api.get(sprintf(UI_SETTINGS_ITEM_URL, this.wrapKey(key)));
  }

  saveItem(key, value) {
    return api.post(UI_SETTINGS_URL, {
      body: { settings: value, key: this.wrapKey(key) },
    });
  }

  updateItem(key, value) {
    return api.put(UI_SETTINGS_URL, {
      body: { settings: value, key: this.wrapKey(key) },
    });
  }

  deleteItem(key) {
    return api.delete(sprintf(UI_SETTINGS_ITEM_URL, this.wrapKey(key)));
  }
}
