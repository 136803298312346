import React from "react";
import { startOfToday } from "date-fns";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const startTime = startOfToday();

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: {
      "& > div": { display: "flex", flexDirection: "column", flex: "1 1 0%" },
    },
  }),
  reduxForm({
    form: "DriverRunSheetFilterForm",
    enableReinitialize: true,
    initialValues: { date: startTime },
  }),
);

DriverRunSheetFilterForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  dirty: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCloseDriverRunSheet: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function DriverRunSheetFilterForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox
      container={16}
      element={<form onSubmit={props.handleSubmit} />}
      flex={true}
    >
      <FlexBox
        gutter={8}
        flex={true}
        element={<Card className={classes.card} />}
        direction="column"
      >
        <CardContent>
          <FlexBox flex={true}>
            <FormDateField
              fullWidth={true}
              name="date"
              hintText={getLocalisationMessage("date", "Date")}
            />
          </FlexBox>
        </CardContent>
        <FlexBox justify="flex-end">
          <CardActions>
            {Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {props.getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )}

            {Boolean(props.onCloseDriverRunSheet) && (
              <Button onClick={props.onCloseDriverRunSheet}>
                {props.getLocalisationMessage(
                  "close_driver_run_sheet",
                  "Close Run Sheet",
                )}
              </Button>
            )}

            <Button type="submit">
              {props.getLocalisationMessage(
                "download_report",
                "Download Report",
              )}
            </Button>
          </CardActions>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverRunSheetFilterForm);
