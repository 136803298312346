import { Observable } from "rxjs";
import React from "react";
import Immutable from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { FitBounds } from "react-google-map-components";
import BrandMarker from "../maps/BrandMarker";
import BrandMarkerCar from "../maps/BrandMarkerCar";
import PolylineWrapper from "../maps/PolylineWrapper";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { ON_HIS_WAY, OUT_FOR_DELIVERY } from "../../constants/OrderStatusCodes";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { GOOGLE_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";
import LeafletMapWrapper from "../maps-leaflet/LeafletMapWrapper";
import {
  // DrawingControl as LeafletDrawingControl,
  FitBounds as LeafletFitBounds,
  // DataPolygon as LeafletDataPolygon
} from "react-leflet-map-components";
import LeafletBrandMarkerCar from "../maps/osm/BrandMarkerCar";
import { connect } from "react-redux";

const enhancer = compose(
  connect(state => ({
    mapProvider: getMapProvider(state),
  })),
  useSheet({ root: { margin: "0", height: "320px", paddingTop: "64px" } }),
  mapPropsStream(propsStream => {
    const driverPositionStream = propsStream
      .distinctUntilKeyChanged("order", isEqualData)
      .switchMap(props => {
        const { order } = props;

        if (!order.hasIn(["driver", "id"])) {
          return Observable.of(Immutable.Map());
        }

        return props
          .getDriverLocation(order.getIn(["driver", "id"]))
          .takeLast(1)
          .let(mapObjectResponseStream)
          .map(x => x.get("payload"))
          .repeatWhen(stream =>
            stream.delay(
              order.get("status") === ON_HIS_WAY ||
                order.get("status") === OUT_FOR_DELIVERY
                ? 5 * 1000
                : 5 * 60 * 1000,
            ),
          );
      })
      .startWith(Immutable.Map())
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(driverPositionStream, (props, driverPosition) => ({
        ...props,
        driverPosition,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

OrderJobDetailsDialogMap.propTypes = {
  classes: PropTypes.object,
  driverPosition: PropTypes.instanceOf(Immutable.Map),

  getDriverLocation: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Immutable.Map).isRequired,
  mapProvider: PropTypes.string,
};

function OrderJobDetailsDialogMap(props) {
  const { classes } = props;

  const pickupPosition = {
    lat: props.order.getIn(["locations", 0, "lat"], 0),
    lng: props.order.getIn(["locations", 0, "lon"], 0),
  };

  const dropoffPosition = {
    lat: props.order.getIn(["locations", 1, "lat"], 0),
    lng: props.order.getIn(["locations", 1, "lon"], 0),
  };

  const driverPosition = {
    lat: props.driverPosition.get("lat"),
    lng: props.driverPosition.get("lon"),
  };

  const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <Paper className={classes.root}>
      {isGoogleMapProvider ? (
        <GoogleMapWrapper>
          <BrandMarker position={pickupPosition} />
          <BrandMarker accent={false} position={dropoffPosition} />

          <PolylineWrapper
            origin={pickupPosition}
            destination={dropoffPosition}
          />

          {!isValidCoordinates(driverPosition) ? (
            <FitBounds latLngBounds={[pickupPosition, dropoffPosition]} />
          ) : (
            <div>
              <BrandMarkerCar position={driverPosition} />

              <FitBounds
                latLngBounds={[pickupPosition, dropoffPosition, driverPosition]}
              />
            </div>
          )}
        </GoogleMapWrapper>
      ) : (
        <LeafletMapWrapper>
          <LeafletBrandMarkerCar position={pickupPosition} />
          <LeafletBrandMarkerCar accent={false} position={dropoffPosition} />

          {!isValidCoordinates(driverPosition) ? (
            <LeafletFitBounds
              latLngBounds={[pickupPosition, dropoffPosition]}
            />
          ) : (
            <div>
              <LeafletBrandMarkerCar position={driverPosition} />

              <LeafletFitBounds
                latLngBounds={[pickupPosition, dropoffPosition, driverPosition]}
              />
            </div>
          )}
        </LeafletMapWrapper>
      )}
    </Paper>
  );
}

export default enhancer(OrderJobDetailsDialogMap);
