import React from "react";
import { Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Chip,
  Dialog,
  ListSubheader,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormDialog from "../form/FormDialog";
import FormCheckbox from "../form/FormCheckbox";
import Audio from "../ui-core/Audio";
import FlexBox from "../ui-core/FlexBox";
import ConfirmDialog from "../deprecated/ConfirmDialog";
import ScannerTextField from "../deprecated/ScannerTextField";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { danger1 } from "../../../shared/theme/main-theme";
import ErrorSound from "../../assets/voices/error.mp3";
import { withTheme } from "@material-ui/core/styles";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({
    chip: { margin: "4px" },
    chipDanger: { backgroundColor: danger1 },
  }),
  withState(
    "state",
    "setState",
    Map({
      showReselectDialog: false,
      scannedOrders: Set(),
      ignoredUnselectedOrders: Set(),
    }),
  ),
  mapPropsStream((propsStream) => {
    const invalidOrdersStream = propsStream.map((props) => ({
      unknown: props.state.get("scannedOrders").subtract(props.selectedOrders),
      unverified: props.selectedOrders.subtract(
        props.state.get("scannedOrders"),
      ),
    }));

    return propsStream.combineLatest(
      invalidOrdersStream,
      (props, invalidOrders) => ({
        ...props,

        unknown: invalidOrders.unknown,
        unverified: invalidOrders.unverified,
      }),
    );
  }),
  withTheme,
);

OrderSortingVerifyOrdersDialog.propTypes = {
  classes: PropTypes.object,

  unknown: PropTypes.instanceOf(Set).isRequired,
  unverified: PropTypes.instanceOf(Set).isRequired,

  setState: PropTypes.func,
  state: PropTypes.instanceOf(Map),

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Set).isRequired,

  onSelectOrders: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  theme: PropTypes.object,
};

function OrderSortingVerifyOrdersDialog(props) {
  const { state, classes, getLocalisationMessage } = props;

  const unknownCount = props.unknown.size;
  const unverifiedCount = props.unverified.size;
  const totalCount = props.selectedOrders.size;

  const verifiedCount = totalCount - unverifiedCount;

  const blockView = !props.unknown.equals(state.get("ignoredUnselectedOrders"));

  return (
    <Dialog
      modal={true}
      open={props.open}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
    >
      <FormDialog
        title={getLocalisationMessage("reselect_orders", "Reselect Orders")}
        form="OrderSortingVerifyOrdersReselectDialog"
        open={state.get("showReselectDialog")}
        onRequestClose={() =>
          props.setState((s) => s.set("showReselectDialog", false))
        }
        onSubmit={(values) =>
          props.onSelectOrders(
            Set().withMutations((x) => {
              if (values.unknown) {
                x.union(props.unknown);
              }

              if (values.unverified) {
                x.union(props.unverified);
              }

              if (values.verified) {
                x.union(props.selectedOrders.subtract(props.unverified));
              }
            }),
          )
        }
      >
        <FormCheckbox
          name="verified"
          label={`${getLocalisationMessage(
            "verified",
            "Verified",
          )} (${verifiedCount})`}
        />
        <FormCheckbox
          name="unverified"
          label={`${getLocalisationMessage(
            "unverified",
            "Unverified",
          )} (${unverifiedCount})`}
        />
        <FormCheckbox
          name="unknown"
          label={`${getLocalisationMessage(
            "unknown",
            "unknown",
          )} (${unknownCount})`}
        />
      </FormDialog>

      <DialogTitle
        style={{
          color: props.theme.palette.appBarTextColor,
          backgroundColor: props.theme.palette.primary.main,
        }}
      >
        {`${getLocalisationMessage("verify_orders", "Verify Orders")} (
      ${getLocalisationMessage(
        "verified",
        "Verified",
      )}: ${verifiedCount}, ${getLocalisationMessage(
          "total",
          "Total",
        )}: ${totalCount})`}
      </DialogTitle>

      <DialogContent>
        <ScannerTextField
          focus={true}
          fullWidth={true}
          hintText={getLocalisationMessage("verify_orders", "Verify Orders")}
          disabled={blockView}
          onChange={(value) =>
            props.setState((s) =>
              s.update("scannedOrders", (x) => x.add(fp.trim(value))),
            )
          }
        />

        {Boolean(unknownCount === 0 && unverifiedCount === 0) && (
          <ListSubheader>
            {getLocalisationMessage(
              "all_orders_were_verified",
              "All Orders Were Verified",
            )}
          </ListSubheader>
        )}

        {unknownCount > 0 && (
          <div>
            <ConfirmDialog
              open={blockView}
              contentWidth="md"
              dismissButtonLabel={getLocalisationMessage("ignore", "Ignore")}
              onRequestClose={() =>
                props.setState((s) =>
                  s.set("ignoredUnselectedOrders", props.unknown),
                )
              }
              confirmButtonLabel={getLocalisationMessage(
                "remove_from_verification",
                "Remove From Verification",
              )}
              onConfirm={() =>
                props.setState((s) =>
                  s
                    .update("scannedOrders", (x) => x.subtract(props.unknown))
                    .update("ignoredUnselectedOrders", (x) =>
                      x.subtract(props.unknown),
                    ),
                )
              }
              title={`${unknownCount} ${getLocalisationMessage(
                "unknown_orders",
                "Unknown Orders",
              )}`}
            >
              <strong>{props.unknown.join(", ")}</strong>
              <Audio play={true} src={ErrorSound} />
            </ConfirmDialog>

            <ListSubheader>
              {getLocalisationMessage("unselected_orders", "Unselected Orders")}
              :
            </ListSubheader>

            <FlexBox wrap={true}>
              {props.unknown.map((number) => (
                <Chip
                  key={number}
                  label={number}
                  labelColor="#fff"
                  className={cx(classes.chip, classes.chipDanger)}
                  onRequestDelete={() =>
                    props.setState((s) =>
                      s
                        .update("scannedOrders", (x) => x.delete(number))
                        .update("ignoredUnselectedOrders", (x) =>
                          x.delete(number),
                        ),
                    )
                  }
                />
              ))}
            </FlexBox>
          </div>
        )}

        {unverifiedCount > 0 && (
          <div>
            <ListSubheader>
              {getLocalisationMessage("unverified_orders", "Unverified Orders")}
              :
            </ListSubheader>

            <FlexBox wrap={true}>
              {props.unverified.map((number) => (
                <Chip key={number} className={classes.chip} label={number} />
              ))}
            </FlexBox>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("close", "Close")}
          </Button>
          <Button
            onClick={() =>
              props.setState((s) => s.set("showReselectDialog", true))
            }
          >
            {getLocalisationMessage("reselect_orders", "Reselect Orders")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderSortingVerifyOrdersDialog);
