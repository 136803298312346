import React from "react";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormDialog from "./FormDialog";
import FormWarehouseAutoComplete from "./FormWarehouseAutoComplete";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withContext(
    {
      getCachedWarehouse: PropTypes.func.isRequired,
      getWarehousePredictions: PropTypes.func.isRequired,
    },
    (props) => ({
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
);

FormWarehouseDialog.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  isDisabled: PropTypes.bool,

  includeWarehouses: PropTypes.array,
};

function FormWarehouseDialog(props) {
  return (
    <FormDialog
      form="FormWarehouseDialog"
      open={props.open}
      onRequestClose={props.onRequestClose}
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    >
      <FormWarehouseAutoComplete
        disabled={props.isDisabled}
        fullWidth={true}
        name="warehouse"
        warehouseIds={props.includeWarehouses}
        label={props.getLocalisationMessage("warehouse", "Warehouse")}
        hintText={props.getLocalisationMessage(
          "type_to_search",
          "Type to search ...",
        )}
      />
    </FormDialog>
  );
}

export default enhancer(FormWarehouseDialog);
