import React from "react";
import { differenceInDays } from "date-fns";
import { Map, List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import UserAvatar from "../avatars/UserAvatar";
import { BOLD, DANGER } from "../ui-core/Text";
import LinkButton from "../ui-core/LinkButton";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import MultiLineCell from "../data-list/MultiLineCell";
import JobListAddress from "../order-job/JobListAddress";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatWeight, extractDigitArray } from "../../helpers/FormatUtils";
import {
  orderStatusColor,
  formatOrderStatusCodeForLocalisation,
} from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getIsRTL,
  getMessage,
  getCurrentLanguage,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  COD_PENDING,
  COD_COLLECTED,
  COD_TO_BE_COLLECTED,
} from "../../constants/CODStatus";
import { RUSSIAN_LANGUAGE } from "../../constants/LocaleTypes";
import { PICK_UP, DROP_OFF } from "../../constants/OrderJobsTypes";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import TextWithLink from "../../../client/components/router/TextWithLink";
import NameAndAvatar from "../../../client/components/avatars/AvatarWithName";
import CodIcon from "../order-job/assets/cod_icon.svg";
import WeightIcon from "../order-job/assets/weight_icon.svg";
import ServiceChargeIcon from "../order-job/assets/service_charge_icon.svg";
import IconPickUp from "../orders-core/assets/pickup_icon.svg";
import IconDelivery from "../orders-core/assets/delivery_icon.svg";
import { hasRole } from "../../helpers/RoleUtils";

export const SELECTION_PER_PAGE = "per-page";
export const SELECTION_ALL = "all";

const NA = "N/A";

const enhancer = compose(
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      showErrorMessage,
      showSuccessMessage,
      statusMessages: getStatusLocalisation(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      currentLanguage: getCurrentLanguage(state),
      isRTL: getIsRTL(state),
    };
  }),
  useSheet({
    hoverable: { cursor: "pointer", textDecoration: "underline" },
    actionColumn: { paddingLeft: 0, justifyContent: "center" },
    container: {
      width: "200px",
      display: "table",
      padding: "1em",
      paddingLeft: "0",
      alignItems: "center",
    },
    avatar: {
      "&>div": {
        width: "16px",
        height: "16px",
      },
      width: "10px",
      borderRadius: "50%",
      textAlign: "center",
      display: "table-cell",
      textTransform: "uppercase",
      color: "#fff",
      verticalAlign: "middle",
    },
    nameBox: {
      display: "table-cell",
      verticalAlign: "middle",
      textAlign: "left",
      paddingLeft: "0.5em",
      fontSize: "1em",
    },
  }),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .pluck("list")
      .distinctUntilChanged(isEqualData)
      .map(list =>
        Map().withMutations(items => {
          list.forEach(item => {
            const types = item.get("type");

            const pickup = types === "pickup";
            const dropoff = types === "delivery";

            items.updateIn(
              ["pickup", items.get("pickup", 0)],
              0,
              fp.add(pickup),
            );
            items.updateIn(
              ["dropoff", items.get("dropoff", 0)],
              0,
              fp.add(dropoff),
            );
          });

          items.update("pickup", Map(), fp.get("size"));
          items.update("dropoff", Map(), fp.get("size"));
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
);

const isOutdated = fp.memoize(
  value => differenceInDays(new Date(), safeParseDate(value)) > 3,
);

const isOrderOutdated = order =>
  (order.get("cod_status") === COD_PENDING ||
    order.get("cod_status") === COD_COLLECTED ||
    order.get("cod_status") === COD_TO_BE_COLLECTED) &&
  isOutdated(order.get("last_cod_status_date"));

const footerContent = (currentLanguage, pickup, dropoff, totalSize) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return `Показано заздач: ${totalSize}. Забор посылки: ${pickup}. Доставка: ${dropoff}.`;

    default:
      return `Total ${pickup} Pickup AND ${dropoff} jobs in ${totalSize} Jobs`;
  }
};

JobList.propTypes = {
  sheet: PropTypes.object,
  stats: PropTypes.instanceOf(Map),
  altHeader: PropTypes.node,
  cardActionIcons: PropTypes.node,
  withCustomer: PropTypes.bool,
  withLastDriver: PropTypes.bool,
  withAttempts: PropTypes.bool,
  withCreatedTime: PropTypes.bool,
  withPickupTime: PropTypes.bool,
  withEstDeliveryTime: PropTypes.bool,
  withCODStatus: PropTypes.bool,
  withFooter: PropTypes.bool,
  createCustomerHref: PropTypes.func,
  createOrderHref: PropTypes.func,
  createWarehouseHref: PropTypes.func,
  onStatusClick: PropTypes.func,
  onEstimatedDeliveryDateClick: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  maxSearchItems: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  searchTypes: PropTypes.instanceOf(OrderedMap),
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  onFilterChange: PropTypes.func,
  rowActionIconRenderer: PropTypes.func,
  selectionType: PropTypes.oneOf([SELECTION_ALL, SELECTION_PER_PAGE]),
  withService: PropTypes.bool,
  withLocation: PropTypes.bool,
  withWarehouse: PropTypes.bool,
  withAmount: PropTypes.bool,
  withShipmentNumber: PropTypes.bool,
  withReferenceId: PropTypes.bool,
  withCODAmount: PropTypes.bool,
  withPickupCollectible: PropTypes.bool,
  withDropOffCollectible: PropTypes.bool,
  withPickedUpBy: PropTypes.bool,
  withDeliveredBy: PropTypes.bool,
  chipTextHint: PropTypes.string,
  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  statusMessages: PropTypes.instanceOf(Map),
  currentLanguage: PropTypes.string,
  isRTL: PropTypes.bool,
};

JobList.defaultProps = {
  withCustomer: true,
  withLastDriver: true,
  withLocation: true,
  withAttempts: true,
  withAmount: true,
  withCODAmount: false,
  withService: true,
  withWarehouse: true,
  withShipmentNumber: true,
  withReferenceId: true,
  withCreatedTime: true,
  withPickupTime: true,
  withEstDeliveryTime: true,
  withCODStatus: true,
  withFooter: true,
  createCustomerHref: fp.noop,
  createOrderHref: fp.noop,
  createWarehouseHref: fp.noop,
  maxSearchItems: Infinity,
  selectionType: SELECTION_PER_PAGE,
};

function JobList(props) {
  const {
    sheet: { classes },
    getLocalisationMessage,
    currentLanguage,
  } = props;

  const idNumberMap = props.list
    .toOrderedMap()
    .mapEntries(([, v]) => [v.get("id"), v.get("order_number")]);

  return (
    <DataList
      filter={props.filter}
      chipTextHint={props.chipTextHint}
      searchTypes={props.searchTypes}
      maxSearchItems={props.maxSearchItems}
      onFilterChange={props.onFilterChange}
      altHeader={props.altHeader}
      selectedRowCount={props.selectedItems ? props.selectedItems.size : 0}
      cardActionIcons={props.cardActionIcons}
      totalCount={props.totalCount}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      overscanRowCount={10}
      withFooter={props.withFooter}
      rowGetter={options => props.list.get(options.index)}
      footerContent={
        // !props.list.isEmpty() && (
        //   <div>
        //     {getLocalisationMessage("total")}{" "}
        //     <strong>
        //       {props.stats.get("pickup")} {getLocalisationMessage("pickup")}{" "}
        //     </strong>{" "}
        //     {getLocalisationMessage("and")}{" "}
        //     <strong>
        //       {props.stats.get("dropoff")} {getLocalisationMessage("delivery")}{" "}
        //     </strong>{" "}
        //     {getLocalisationMessage("jobs_in")} {props.list.size}{" "}
        //     {getLocalisationMessage("jobs")}.
        //   </div>
        // )
        footerContent(
          currentLanguage,
          props.stats.get("pickup"),
          props.stats.get("dropoff"),
          props.list.size,
        )
      }
    >
      {props.selectedItems && (
        <DataListCheckbox
          allRowsSelected={() => {
            if (props.list.size > 0) {
              switch (props.selectionType) {
                case SELECTION_ALL:
                  return props.selectedItems.isSuperset(idNumberMap);
                case SELECTION_PER_PAGE:
                  return props.selectedItems.size === props.list.size;
                // no default
              }
            }

            return false;
          }}
          onAllRowsSelect={value => {
            switch (props.selectionType) {
              case SELECTION_ALL:
                return props.onRowSelect(
                  value
                    ? props.selectedItems.merge(idNumberMap)
                    : props.selectedItems.filter(
                        (n, id) => !idNumberMap.has(id),
                      ),
                );
              case SELECTION_PER_PAGE:
                return props.onRowSelect(value ? idNumberMap : OrderedMap());

              default:
                return null;
            }
          }}
          rowSelected={row => props.selectedItems.has(row.cellData.get("id"))}
          onRowSelect={row => {
            const id = row.cellData.get("id");
            const orderNumber = row.cellData.get("order_number");

            switch (props.selectionType) {
              case SELECTION_ALL:
                return props.onRowSelect(
                  row.selected
                    ? props.selectedItems.set(id, orderNumber)
                    : props.selectedItems.delete(id),
                );

              case SELECTION_PER_PAGE: {
                const selectedItems = row.selected
                  ? props.selectedItems.set(id, orderNumber)
                  : props.selectedItems.delete(id);

                const sortedSelectedItems =
                  selectedItems.size > 1
                    ? idNumberMap.filter((v, k) => selectedItems.has(k))
                    : selectedItems;

                return props.onRowSelect(sortedSelectedItems);
              }

              default:
                return null;
            }
          }}
        />
      )}

      <DataListColumn
        width={130}
        label={getLocalisationMessage("id")}
        dataKey="order_number"
        cellRenderer={row => (
          <MultiLineCell
            firstLine={
              <TextWithLink to={props.createOrderHref(row.cellData.get("id"))}>
                {row.cellData.get("order_number")}
              </TextWithLink>
            }
          />
        )}
      />

      <DataListColumn
        width={60}
        disableSort={true}
        label={getLocalisationMessage("type")}
        dataKey="type"
        cellRenderer={row => (
          <div className={classes.avatarContainer}>
            <div className={classes.avatar}>
              <img
                alt="delivery_pickup"
                name={row.cellData.getIn(["customer", "name"])}
                src={
                  row.cellData.get("type", NA) === PICK_UP
                    ? IconPickUp
                    : row.cellData.get("type", NA) === DROP_OFF
                    ? IconDelivery
                    : ""
                }
                style={{ background: "transparent" }}
                width={20}
                height={20}
              />
            </div>
            <div className={classes.nameBox} style={{ padding: "0" }}>
              {" "}
              {row.cellData.get("type", NA) === PICK_UP
                ? getLocalisationMessage("pick_up")
                : row.cellData.get("type", NA) === DROP_OFF
                ? getLocalisationMessage("drop_off")
                : getLocalisationMessage("na")}{" "}
            </div>
          </div>
        )}
      />

      {props.withCreatedTime && (
        <DataListColumn
          width={80}
          label={getLocalisationMessage("created_time")}
          dataKey="created_date"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      )}

      {props.withCustomer && (
        <DataListColumn
          width={110}
          label={getLocalisationMessage("customer")}
          dataKey="customer_name"
          cellRenderer={row => (
            <TextWithLink
              to={props.createCustomerHref(
                row.cellData.getIn(["customer", "id"]),
              )}
              style={{ textDecoration: "none" }}
            >
              <NameAndAvatar
                name={row.cellData.getIn(["customer", "name"])}
                classes={classes.avatar}
                imagePath={row.cellData.getIn(["customer", "photo"])}
              >
                {row.cellData.getIn(["customer", "name"])}
              </NameAndAvatar>
            </TextWithLink>
          )}
        />
      )}

      {props.withLocation && (
        <DataListColumn
          width={300}
          disableSort={true}
          label={getLocalisationMessage("address")}
          dataKey="from"
          cellRenderer={row => <JobListAddress order={row.cellData} />}
        />
      )}

      {props.withAttempts && (
        <DataListColumn
          width={80}
          disableSort={true}
          label={getLocalisationMessage("orders")}
          dataKey="attempts_count"
          justifyContent="center"
          cellRenderer={row => (
            <MultiLineCell
              firstLine={
                <TextWithLink
                  to={props.createOrderHref(row.cellData.get("id"))}
                >
                  {row.cellData.get("order_count")}{" "}
                  {getLocalisationMessage("orders", "orders")}
                </TextWithLink>
              }
            />
          )}
        />
      )}

      <DataListColumn
        width={144}
        label={getLocalisationMessage("status")}
        dataKey="last_modified_date"
        cellRenderer={row => (
          <MultiLineCell
            firstLine={
              <LinkButton
                onClick={
                  props.onStatusClick &&
                  (() => props.onStatusClick(row.cellData))
                }
                style={{
                  color: orderStatusColor(row.cellData.get("status")),
                  fontWeight: "bold",
                }}
              >
                {formatOrderStatusCodeForLocalisation(
                  row.cellData.get("status"),
                  getLocalisationMessage,
                )}
              </LinkButton>
            }
          />
        )}
      />

      <DataListColumn
        width={120}
        label={getLocalisationMessage("courier_driver")}
        dataKey="supplier_name"
        cellRenderer={row =>
          row.cellData.getIn(["supplier", "id"]) > 0 && (
            <NameAndAvatar
              name={row.cellData.getIn(["supplier", "name"])}
              classes={classes.avatar}
              imagePath={row.cellData.getIn(["driver", "photo"])}
            >
              <MultiLineCell
                style={{
                  paddingRight: props.isRTL ? "0" : "30px",
                  paddingLeft: !props.isRTL ? "0" : "30px",
                  width: "100%",
                }}
                firstLine={
                  <strong>{row.cellData.getIn(["supplier", "name"])}</strong>
                }
                secondLine={<div>{row.cellData.getIn(["driver", "name"])}</div>}
              />
            </NameAndAvatar>
          )
        }
      />

      {props.withService && (
        <DataListColumn
          width={128}
          disableSort={true}
          label={getLocalisationMessage("service_charge")}
          dataKey="service"
          cellRenderer={row => (
            <NameAndAvatar
              classes={classes.avatar}
              imagePath={ServiceChargeIcon}
            >
              <PriceWrapper
                price={row.cellData.get("amount")}
                code={row.cellData.getIn(["currency", "code"])}
                type={isOrderOutdated(row.cellData) ? [DANGER, BOLD] : []}
              />
            </NameAndAvatar>
          )}
        />
      )}

      <DataListColumn
        width={80}
        disableSort={true}
        label={getLocalisationMessage("weight")}
        dataKey="size"
        cellRenderer={row => (
          <NameAndAvatar classes={classes.avatar} imagePath={WeightIcon}>
            <div>
              {formatWeight(extractDigitArray(row.cellData.get("weight")))}
            </div>
          </NameAndAvatar>
        )}
      />

      {props.withAmount && (
        <DataListColumn
          width={90}
          dataKey="amount"
          disableSort={true}
          label={getLocalisationMessage("collectible", "Collectible")}
          cellRenderer={row => (
            <MultiLineCell
              firstLine={
                <NameAndAvatar classes={classes.avatar} imagePath={CodIcon}>
                  <PriceWrapper
                    price={row.cellData.get("parcel_value")}
                    code={row.cellData.getIn(["currency", "code"])}
                  />
                </NameAndAvatar>
              }
            />
          )}
        />
      )}
    </DataList>
  );
}

export default enhancer(JobList);
