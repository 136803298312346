import { getHash } from "../helpers/DataUtils";
import { findPointPolygon } from "../helpers/MapPolygonUtils";
import {
  createClusters,
  computeDurationMatrix,
} from "../helpers/OrderRouterHelper";
import { CREATE_ORDER_CLUSTER } from "../reducers/OrderClusterReducer";

function createOrdersDurationMatrix(options) {
  const zones = options.get("zones");
  const warehouse = options.get("warehouse");
  const destinationWarehouse = options.get("destinationWarehouse");

  const locations = options
    .get("locations")
    .map(node => node.set("zone", getHash(findPointPolygon(node, zones))))
    .toSet()
    .withMutations(nodes => {
      if (warehouse) {
        nodes.add(warehouse);
      }

      if (destinationWarehouse) {
        nodes.add(destinationWarehouse);
      }
    });

  return computeDurationMatrix(locations);
}

export function calculateClusteringData(options) {
  return {
    meta: {
      options,
    },
    type: CREATE_ORDER_CLUSTER,
    payload: createClusters({
      routeStartPoints: [
        options.get("warehouse"),
        options.get("destinationWarehouse"),
      ],
      maxDuration: options.get("maxDuration"),
      sortingDuration: options.get("sortingDuration"),
      handlingDuration: options.get("handlingDuration"),

      zones: options.get("zones"),
      durationMatrix: createOrdersDurationMatrix(options),
    }),
  };
}
