import { Observable } from "rxjs";
import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import {
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import canUseDom from "../../../shared/helpers/canUseDom";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestClosedDelayed,
      stream: onRequestClosedDelayedStream,
    } = createEventHandler();

    const fileUrlStream = propsStream
      .map(fp.pick(["fileName", "fileType", "fileContent"]))
      .distinctUntilChanged(isEqualData)
      .scan((prevUrl, props) => {
        if (!canUseDom) {
          return null;
        }

        if (prevUrl) {
          window.URL.revokeObjectURL(prevUrl);
        }

        if (!props.fileName) {
          return null;
        }

        const file = new window.Blob([props.fileContent], {
          type: props.fileType,
        });

        return window.URL.createObjectURL(file);
      }, null);

    const sideEffectsStream = onRequestClosedDelayedStream
      .delay(300)
      .withLatestFrom(propsStream)
      .do(([, props]) => props.onRequestClose())
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream
      .combineLatest(fileUrlStream, sideEffectsStream, (props, fileUrl) => ({
        ...props,
        fileUrl,
        onRequestClosedDelayed,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

ExportFileDialog.propTypes = {
  fileUrl: PropTypes.string,
  onRequestClosedDelayed: PropTypes.func,

  fileType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileContent: PropTypes.string.isRequired,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  getLocalisationMessage: PropTypes.instanceOf(Map),
};

function ExportFileDialog(props) {
  const { getLocalisationMessage } = props;
  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <DialogContent>
        <DialogContentText>
          {props.fileUrl ? (
            <div>
              {getLocalisationMessage(
                "file_is_ready_to_download",
                "File is ready to download.",
              )}
            </div>
          ) : (
            <div>
              {getLocalisationMessage(
                "file_is_not_ready_yet_please_wait",
                "File is not ready yet, please wait.",
              )}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>

          {Boolean(props.fileUrl) && (
            <Button
              href={props.fileUrl}
              target="_blank"
              component="a"
              download={props.fileName}
              onClick={() => {
                props.onRequestClosedDelayed();
              }}
            >
              {getLocalisationMessage("download", "Download")}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(ExportFileDialog);
