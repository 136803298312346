import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormAdminAddressBookAutoComplete from "../form/FormAdminAddressBookAutoComplete";
import PageLoading from "../ui-core/PageLoading";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  reduxForm({
    form: "AdminAddressBookSelectDialogPickup",
    validate: (values, props) => ({
      address:
        !isValidObjectId(values.address) &&
        props.getLocalisationMessage("select_address", "Select Address"),
    }),
  }),
);

AdminAddressBookSelectDialogPickup.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminAddressBookSelectDialogPickup(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          width: "320px",
        },
      }}
    >
      <PageLoading isLoading={props.submitting} />

      <DialogContent>
        <form>
          <FormAdminAddressBookAutoComplete
            hintText={props.getLocalisationMessage(
              "type_to_search",
              "Type To Search...",
            )}
            focus={true}
            name="address"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button variant="text" onClick={props.onRequestClose}>
            {props.getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button variant="text" onClick={props.handleSubmit}>
            {props.getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(AdminAddressBookSelectDialogPickup);
