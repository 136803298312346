import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { spring, Motion } from "react-motion";
import { red, green } from "@material-ui/core/colors";
import { Marker, MarkerSymbol } from "react-google-map-components";
import { pureComponent } from "../../helpers/HOCUtils";
import { primary1 } from "../../../shared/theme/main-theme";

const vehiclePath =
  "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";

const springConfig = { stiffness: 10, damping: 40 };

const DEFAULT = "default";
const enhancer = pureComponent(fp.pick(["rotation", "position"]));

VehicleMarker.propTypes = {
  onClick: PropTypes.func,
  rotation: PropTypes.number,
  position: PropTypes.object.isRequired,

  withMotion: PropTypes.bool,
  busy: PropTypes.oneOf([true, false, DEFAULT]),
  icon: PropTypes.element,
};

VehicleMarker.defaultProps = {
  rotation: 0,

  withMotion: true,
  busy: DEFAULT,
};

function VehicleMarker(props) {
  const vehicleColor =
    props.busy === DEFAULT ? primary1 : props.busy ? red[900] : green[900];

  return props.withMotion ? (
    <Motion
      style={{
        rotation: spring(props.rotation),
        lat: spring(props.position.lat, springConfig),
        lng: spring(props.position.lng, springConfig),
      }}
    >
      {(x) => (
        <Marker
          onClick={props.onClick}
          position={{ lat: x.lat, lng: x.lng }}
          icon={
            <MarkerSymbol
              id="10"
              path={vehiclePath}
              scale={0.7}
              strokeColor="#fff"
              strokeOpacity={1}
              strokeWeight={0.1}
              fillOpacity={1}
              fillColor={vehicleColor}
              rotation={x.rotation}
            />
          }
        />
      )}
    </Motion>
  ) : (
    <Marker
      id="10"
      onClick={props.onClick}
      position={props.position}
      icon={
        <MarkerSymbol
          path={vehiclePath}
          scale={0.7}
          strokeColor="#fff"
          strokeOpacity={0.1}
          strokeWeight={1}
          fillOpacity={1}
          fillColor={vehicleColor}
          rotation={props.rotation}
        />
      }
    />
  );
}

export default enhancer(VehicleMarker);
