import React from "react";
import {
  addDays,
  endOfDay,
  endOfToday,
  startOfDay,
  startOfToday,
  differenceInCalendarDays,
} from "date-fns";
import fp from "lodash/fp";
import { compose, withContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId } from "../../helpers/FormUtils";
import {
  formatText,
  safeParseDate,
  formatDateTimeToUrl,
} from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import AlertTypes from "../../constants/AlertTypes";
import AlertStatuses from "../../constants/AlertStatuses";

const startTime = startOfToday();
const endTime = endOfToday();

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(({ filter }) => ({
        search: filter.getSearch(),
        alertTypes: parseString(filter.getValue("alert_types")),
        alertStatuses: parseString(filter.getValue("alert_statuses")),

        fromDateTime: safeParseDate(filter.getValue("from_date_time")),
        toDateTime: safeParseDate(filter.getValue("to_date_time")),
        supplier: { id: filter.getIntegerValue("supplier_id") || null },
      }))
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            alert_types: stringifyArray(values.alertTypes),
            alert_statuses: stringifyArray(values.alertStatuses),

            from_date_time: formatDateTimeToUrl(values.fromDateTime),
            to_date_time: formatDateTimeToUrl(values.toDateTime),

            supplier_id: getObjectId(values.supplier),

            search: values.search,
          });
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  reduxForm({
    form: "DriversRadarFilterForm",
    enableReinitialize: true,
  }),
  formValues("fromDateTime", "toDateTime"),
);

DriversRadarFilterForm.propTypes = {
  handleSubmit: PropTypes.func,

  onDismiss: PropTypes.func,

  reset: PropTypes.func,
  change: PropTypes.func,
  dirty: PropTypes.bool,

  initialValues: PropTypes.object,

  withoutSupplier: PropTypes.bool,

  toDateTime: PropTypes.instanceOf(Date),
  fromDateTime: PropTypes.instanceOf(Date),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function DriversRadarFilterForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox container={8} element={<form onSubmit={props.handleSubmit} />}>
      <FlexBox flex={true} gutter={8} direction="column" element={<Card />}>
        <FlexBox flex={true} element={<CardContent />}>
          <FlexBox gutter={8} flex={true} direction="column">
            <FlexBox>
              <FormTextField
                floatingLabelText={getLocalisationMessage("search", "Search")}
                fullWidth={true}
                name="search"
              />
            </FlexBox>

            {!props.withoutSupplier && (
              <FlexBox>
                <FormSupplierAutoComplete
                  fullWidth={true}
                  name="supplier"
                  floatingLabelText={getLocalisationMessage(
                    "courier_company",
                    "Courier Company",
                  )}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                />
              </FlexBox>
            )}

            <FlexBox>
              <FormChipAutoComplete
                floatingLabelText={getLocalisationMessage(
                  "alert_statuses",
                  "Alert Statuses",
                )}
                options={AlertStatuses}
                fullWidth={true}
                formatOption={x => getLocalisationMessage(x, formatText(x))}
                name="alertStatuses"
              />
            </FlexBox>

            <FlexBox>
              <FormChipAutoComplete
                floatingLabelText={getLocalisationMessage(
                  "alert_types",
                  "Alert Types",
                )}
                options={AlertTypes}
                fullWidth={true}
                formatOption={x => getLocalisationMessage(x, formatText(x))}
                name="alertTypes"
              />
            </FlexBox>

            <FlexBox>
              <FlexBox gutter={8} flex={true}>
                <FlexBox>
                  <FormDateField
                    floatingLabelText={getLocalisationMessage(
                      "from_date",
                      "From Date",
                    )}
                    name="fromDateTime"
                    initialTime={startTime}
                    onChange={(event, date) => {
                      props.change("fromDateTime", startOfDay(date));

                      if (
                        differenceInCalendarDays(props.toDateTime, date) > 30
                      ) {
                        props.change("toDateTime", null);
                      }
                    }}
                  />
                </FlexBox>

                <FlexBox>
                  <FormDateField
                    name="toDateTime"
                    initialTime={endTime}
                    floatingLabelText={getLocalisationMessage(
                      "to_date",
                      "To Date",
                    )}
                    maxDate={addDays(props.fromDateTime, 30)}
                    onChange={(event, date) => {
                      props.change("toDateTime", endOfDay(date));
                    }}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox element={<CardActions />} justify="flex-end">
          <Button
            onClick={() => {
              fp.keys(props.initialValues).forEach(key => {
                props.change(key, null);
              });
            }}
          >
            {getLocalisationMessage("clear", "Clear")}
          </Button>

          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            )
          )}

          <Button type="submit">
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriversRadarFilterForm);
