import { Observable } from "rxjs";
import { Map, List, OrderedMap } from "immutable";
import { captureException } from "../helpers/ErrorTracker";
import {
  optimizeRoute,
  findOptimalRoute,
  calculateRouteMetrics,
  optimizeWarehouseRoute,
  normalizeRoutingOptions,
} from "../helpers/OrderRoutingUtils";
import {
  getClusters,
  CREATE_ORDER_CLUSTER,
  CREATE_ORDER_CLUSTER_ERROR,
  CREATE_ORDER_CLUSTER_PROGRESS,
} from "../reducers/OrderClusterReducer";

export const createRoutes = options => dispatch => {
  dispatch({
    payload: 0,
    meta: { options },
    type: CREATE_ORDER_CLUSTER_PROGRESS,
  });

  Observable.defer(() => normalizeRoutingOptions(options))
    .switchMap(
      ({ initialRoute, locations, filteredOrders, ...routingOptions }) =>
        findOptimalRoute(initialRoute, locations, routingOptions).map(
          result => {
            const totalCount = result.matrix.size;
            const visitedCount = result.visited.size;

            return visitedCount === totalCount
              ? {
                  progress: 100,
                  payload: result.routes,
                  options,
                  matrix: result.matrix,
                  filtered: filteredOrders,
                  requestCount: result.requestCount,
                  elementsTotal: result.elementsTotal,
                }
              : {
                  payload: null,
                  progress: (visitedCount * 100) / totalCount,
                };
          },
        ),
    )
    .switchMap(response => {
      if (response.payload && response.options.get("warehouse").get("id")) {
        let routeLastLocations = new List();
        let routeLastLocationsOrder = new List();
        const warehouseLocation = new List().push(
          Map({
            lat: response.options.get("warehouse").get("lat"),
            lon: response.options.get("warehouse").get("lon"),
          }),
        );

        response.payload.forEach(route => {
          routeLastLocationsOrder = routeLastLocationsOrder.push(
            route
              .keySeq()
              .last()
              .get("orderId"),
          );
          routeLastLocations = routeLastLocations.push(
            new OrderedMap({
              lat: route
                .keySeq()
                .last()
                .get("lat"),
              lon: route
                .keySeq()
                .last()
                .get("lon"),
            }),
          );
        });

        return Observable.combineLatest(
          Observable.of(response),
          optimizeWarehouseRoute(
            routeLastLocations,
            warehouseLocation,
            response.options.get("warehouse").get("id"),
            routeLastLocationsOrder,
            response.options.get("estimateRequestCount", false),
          ),
        ).map(([initialRoutes, distanceUntilWarehouse]) => ({
          initial: initialRoutes,
          distanceUntilWarehouse,
        }));
      }
      return Observable.of(response);
    })
    .map(response => {
      if (response.initial) {
        return {
          payload: {
            routes: response.initial.payload,
            distanceWarehouse: response.distanceUntilWarehouse,
            filtered: response.initial.filtered,
            requestCount: response.initial.requestCount + 1, // Estimating as back to pickup point request as 1
            elementsTotal:
              response.initial.elementsTotal + response.initial.payload.size,
          },
          progress: 100,
        };
      }

      return response;
    })
    .subscribe({
      next: response => {
        // eslint-disable-next-line no-param-reassign
        const option = options.delete("isMapBox");

        if (response.payload) {
          dispatch({
            meta: { options: option },
            payload: response.payload,
            type: CREATE_ORDER_CLUSTER,
          });
        } else {
          dispatch({
            meta: { options: option },
            payload: response.progress,
            type: CREATE_ORDER_CLUSTER_PROGRESS,
          });
        }
      },
      error: error => {
        // eslint-disable-next-line no-param-reassign
        const option = delete options.isMapBox;

        dispatch({
          payload: error,
          meta: { options: option },
          type: CREATE_ORDER_CLUSTER_ERROR,
        });

        captureException(error, option);
      },
    });
};

export const changeOrderRoute = (orderId, targetRouteIndex, options) => (
  dispatch,
  getState,
) => {
  const routes = getClusters(getState(), options);

  if (!routes) {
    return;
  }

  let order = null;
  let orderRoute = null;
  let orderRouteIndex = -1;

  routes.forEach((route, index) => {
    route.forEach((metrics, item) => {
      if (item.get("orderId") === orderId) {
        order = item;
        orderRoute = route;
        orderRouteIndex = index;
      }

      return !orderRoute;
    });

    return !orderRoute;
  });

  if (!orderRoute || orderRoute.equals(routes.get(targetRouteIndex))) {
    return;
  }

  dispatch({
    payload: 20,
    meta: { options },
    type: CREATE_ORDER_CLUSTER_PROGRESS,
  });

  Observable.defer(() =>
    normalizeRoutingOptions(
      options.merge({
        zones: null,
        maxDuration: Infinity,
        groupBySize: false,
        groupBySupplier: false,
      }),
    ),
  )
    .switchMap(({ initialRoute, ...routingOptions }) => {
      const targetRoute =
        targetRouteIndex >= routes.size
          ? initialRoute.toOrderedMap()
          : routes.get(targetRouteIndex);

      const nextSourceRoute = orderRoute.delete(order);
      const nextTargetRoute = targetRoute.set(order, null);

      return calculateRouteMetrics(initialRoute, options).switchMap(
        routeStart =>
          Observable.combineLatest(
            nextSourceRoute.size > routeStart.size
              ? optimizeRoute(routeStart, nextSourceRoute, routingOptions)
              : Observable.of(nextSourceRoute),
            optimizeRoute(routeStart, nextTargetRoute, routingOptions),
            (optimizedSourceRoute, optimizedTargetRoute) =>
              routes
                .withMutations(x => {
                  x.set(orderRouteIndex, optimizedSourceRoute);
                  x.set(targetRouteIndex, optimizedTargetRoute);
                })
                .filter(x => x.size > routeStart.size),
          ),
      );
    })
    .subscribe({
      next: payload =>
        dispatch({
          payload,
          meta: { options },
          type: CREATE_ORDER_CLUSTER,
        }),
      error: error => {
        dispatch({
          payload: error,
          meta: { options },
          type: CREATE_ORDER_CLUSTER_ERROR,
        });

        captureException(error, options);
      },
    });
};
