import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Bar } from "./ChartComponent";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "16px",
    },
  }),
);

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  tooltipFormat: PropTypes.object,
};

BarChart.defaultProps = {
  width: 100,
  height: 200,
};

function BarChart(props) {
  const options = {
    maintainAspectRatio: false,
    tooltips: props.tooltipFormat,
    layout: {
      padding: {
        left: 15,
        right: 15,
        top: 15,
        bottom: 15,
      },
    },
    elements: {
      rectangle: {
        borderWidth: 1,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barThickness: "flex",
          categoryPercentage: 0.5,
          barPercentage: 0.9,

          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };

  return (
    <div
      style={{
        maxHeight: "600px",
        margin: 10,
      }}
    >
      <Bar
        data={props.data}
        options={options}
        width={props.width}
        height={props.height}
      />
    </div>
  );
}

export default enhancer(BarChart);
