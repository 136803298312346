import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Paper } from "@material-ui/core";
// import { Paper } from "@material-ui/core";

import FormSwitcherDatePicker from "../form/FormSwitcherDatePicker";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";

const enhancer = compose(
  useSheet({
    datePicker: {
      margin: 0,
    },
  }),
  reduxForm({
    form: "DriversRadarDateForm",
  }),
  pureComponent(fp.pick([])),
);

DriversRadarDateForm.propTypes = {
  classes: PropTypes.object,
};

function DriversRadarDateForm(props) {
  const { classes } = props;

  return (
    <FlexBox container={8}>
      <FlexBox flex={true} element={<Paper />}>
        <FlexBox element={<form />} align="center">
          <FormSwitcherDatePicker
            dateWithWords={true}
            className={classes.datePicker}
            name="date"
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriversRadarDateForm);
