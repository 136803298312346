import React from "react";
import { isDate, isValid, isFuture } from "date-fns";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  renderIf("open"),
  useSheet({ content: { maxWidth: "320px" } }),
  reduxForm({
    form: "UpdateCODReferenceDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      bankReference:
        Boolean(fp.isEmpty(values.bankReference)) &&
        props.i18n.get("add_bank_reference", "Add Bank Reference"),
      bankPaymentDate:
        Boolean(
          !isDate(values.bankPaymentDate) ||
            !isValid(values.bankPaymentDate) ||
            isFuture(values.bankPaymentDate),
        ) &&
        props.i18n.get(
          "add_bank_payment_date_properly",
          "Add Bank Payment Date Properly",
        ),
    }),
  }),
);

UpdateCODReferenceDialog.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

function UpdateCODReferenceDialog(props) {
  const { i18n } = props;

  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />

      <DialogContent
        style={{
          maxWidth: "320px",
        }}
      >
        <form autoComplete="off">
          <FormTextField
            name="bankReference"
            fullWidth={true}
            label={i18n.get("payment_ref", "Payment ref #")}
          />

          <FormDateField
            name="bankPaymentDate"
            fullWidth={true}
            label={i18n.get("bank_payment_date", "Bank Payment Date")}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {i18n.get("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {i18n.get("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(UpdateCODReferenceDialog);
