import React from "react";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapProps, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Cancel } from "@material-ui/icons";
import FormDateField from "../form/FormDateField";
import FormDriverChips from "../form/FormDriverChips";
import FormSelectField from "../form/FormSelectField";
import FormSupplierChips from "../form/FormSupplierChips";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { getObjectId } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { formatCourierTypesLocalize } from "../../helpers/ReportsHelper";
import { stringifyArray } from "../../helpers/SerializeUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CourierTypes from "../../constants/CourierTypes";
import { GUARANTEED_NEXT_DAY } from "../../constants/ScoreCourierTypes";
import {
  RTO,
  BIKE,
  SEDAN,
  BY_SIP,
  BY_TIME,
  MINIVAN,
  URGENCY,
  PANELVAN,
  BY_DRIVER,
  COMPLETED,
  BY_SUPPLIER,
  ALL_DOMESTIC,
  BY_WAREHOUSE,
  SCORE_RANKING,
  SOUQ_CUSTOMER,
  ORIGIN_COUNTRY,
  AVERAGE_PER_DAY,
  DESTINATION_CITY,
  MARHABA_CUSTOMER,
  ALL_INTERNATIONAL,
  DRIVER_ATTENDANCE,
  NON_SOUQ_CUSTOMER,
  NOT_OFD_SHIPMENTS,
  SHIPMENTS_CREATED,
  SHIPMENTS_PENDING,
  DESTINATION_COUNTRY,
  SHIPMENTS_DELIVERED,
  PENDING_RTO_SHIPMENTS,
  DAILY_CALL_PERFORMANCE,
  UN_ATTEMPTED_SHIPMENTS,
} from "../../constants/OperationalReportTypes";

const customerTypesOptions = OrderedSet.of(
  SOUQ_CUSTOMER,
  MARHABA_CUSTOMER,
  NON_SOUQ_CUSTOMER,
);
const reportByOptions = OrderedSet.of(BIKE, SEDAN, MINIVAN, PANELVAN);
const filterByOptions = OrderedSet.of(COMPLETED, RTO, AVERAGE_PER_DAY);
const courierTypesOptions = CourierTypes.add(ALL_INTERNATIONAL).add(
  ALL_DOMESTIC,
);
const filterCourierTypes = CourierTypes.add(GUARANTEED_NEXT_DAY);
const groupByOptions = OrderedSet.of(BY_WAREHOUSE, BY_SUPPLIER, BY_DRIVER);
const showByOptions = OrderedSet.of(
  DESTINATION_COUNTRY,
  DESTINATION_CITY,
  ORIGIN_COUNTRY,
  URGENCY,
);
const callsByOptions = OrderedSet.of(BY_SIP, BY_TIME);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    attendance: { marginTop: "-14px" },
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
    }),
  ),
  reduxForm({
    form: "OperationalReportFilterForm",
    initialValues: {
      callsBy: BY_SIP,
    },
    enableReinitialize: true,
  }),
  formValues(
    "filterBy",
    "reportBy",
    "showBy",
    "supplierIds",
    "reportCustomerType",
  ),
  mapProps(
    ({
      filterBy,
      reportBy,
      showBy,
      supplierIds,
      reportCustomerType,
      ...props
    }) => ({
      ...props,
      values: {
        showBy,
        filterBy,
        reportBy,
        supplierIds,
        reportCustomerType,
      },
    }),
  ),
);

OperationalReportFilterForm.propTypes = {
  reportType: PropTypes.string,
  withoutDate: PropTypes.bool,
  withSupplier: PropTypes.bool,
  withDriver: PropTypes.bool,
  getDriverPredictions: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  values: PropTypes.object,
  change: PropTypes.func,
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OperationalReportFilterForm(props) {
  const { values, classes, getLocalisationMessage } = props;

  return (
    <FlexBox gutter={8} flex={props.reportType === DRIVER_ATTENDANCE}>
      <FlexBox flex={true}>
        <FlexBox gutter={8} flex={true}>
          {Boolean(!props.withoutDate) && (
            <FlexBox>
              <FlexBox gutter={8}>
                <FlexBox>
                  <FormDateField
                    name="fromDate"
                    label={
                      <span>
                        {getLocalisationMessage("from_date", "From date")}{" "}
                        <sup>*</sup>
                      </span>
                    }
                  />
                </FlexBox>
                <FlexBox>
                  <FormDateField
                    name="toDate"
                    label={
                      <span>
                        {getLocalisationMessage("to_date", "Date to")}{" "}
                        <sup>*</sup>
                      </span>
                    }
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          )}

          {Boolean(
            props.reportType === UN_ATTEMPTED_SHIPMENTS ||
              props.reportType === NOT_OFD_SHIPMENTS,
          ) && (
            <FlexBox align="flex-end">
              <FormDateField
                name="promiseDate"
                label={getLocalisationMessage("promise_date", "Promise Date")}
              />
            </FlexBox>
          )}

          {Boolean(
            props.reportType === SHIPMENTS_PENDING ||
              props.reportType === SHIPMENTS_DELIVERED ||
              props.reportType === SHIPMENTS_CREATED,
          ) && (
            <FlexBox>
              <FormSelectField
                name="groupBy"
                label={
                  <span>
                    {getLocalisationMessage("group_by", "Group By")}{" "}
                    <sup>*</sup>
                  </span>
                }
                options={groupByOptions}
                formatOption={x =>
                  getLocalisationMessage(x.toLocaleLowerCase(), formatText)
                }
              />
            </FlexBox>
          )}

          {Boolean(
            props.reportType === SHIPMENTS_PENDING ||
              props.reportType === SHIPMENTS_DELIVERED,
          ) && (
            <FlexBox>
              <FlexBox gutter={8}>
                <FlexBox>
                  {props.reportType === SHIPMENTS_PENDING && (
                    <FormSelectField
                      name="filterBy"
                      label={getLocalisationMessage("filter_by", "Filter By")}
                      options={courierTypesOptions}
                      formatOption={x =>
                        formatCourierTypesLocalize(x, getLocalisationMessage)
                      }
                    />
                  )}

                  {props.reportType === SHIPMENTS_DELIVERED && (
                    <FlexBox>
                      <FormSelectField
                        name="filterBy"
                        label={getLocalisationMessage("filter_by", "Filter By")}
                        options={filterByOptions}
                        formatOption={x =>
                          getLocalisationMessage(
                            x.toLocaleLowerCase(),
                            formatText,
                          )
                        }
                      />
                    </FlexBox>
                  )}
                </FlexBox>

                {values.filterBy && (
                  <FlexBox align="center">
                    <IconButton onClick={() => props.change("filterBy", null)}>
                      <Cancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          )}

          {Boolean(
            props.reportType === SHIPMENTS_DELIVERED ||
              props.reportType === SHIPMENTS_CREATED,
          ) && (
            <FlexBox>
              <FlexBox gutter={8}>
                <FlexBox>
                  {props.reportType === SHIPMENTS_DELIVERED && (
                    <FormSelectField
                      name="reportBy"
                      label={getLocalisationMessage("report_by", "Report By")}
                      options={reportByOptions}
                      formatOption={x =>
                        getLocalisationMessage(
                          x.toLocaleLowerCase(),
                          formatText,
                        )
                      }
                    />
                  )}

                  {props.reportType === SHIPMENTS_CREATED && (
                    <FormSelectField
                      name="reportBy"
                      label={getLocalisationMessage("report_by", "Report By")}
                      options={courierTypesOptions}
                      formatOption={x =>
                        formatCourierTypesLocalize(x, getLocalisationMessage)
                      }
                    />
                  )}
                </FlexBox>

                {values.reportBy && (
                  <FlexBox align="center">
                    <IconButton onClick={() => props.change("reportBy", null)}>
                      <Cancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          )}

          {Boolean(
            props.reportType === SHIPMENTS_DELIVERED ||
              props.reportType === SHIPMENTS_CREATED,
          ) && (
            <FlexBox>
              <FlexBox gutter={8}>
                <FlexBox>
                  <FormSelectField
                    name="showBy"
                    label={
                      <span>
                        {getLocalisationMessage("show_by", "Show By")}{" "}
                        {props.reportType === SHIPMENTS_CREATED ? (
                          <sup>*</sup>
                        ) : (
                          ""
                        )}
                      </span>
                    }
                    options={showByOptions}
                    formatOption={x =>
                      getLocalisationMessage(x.toLocaleLowerCase(), formatText)
                    }
                  />
                </FlexBox>

                {Boolean(
                  values.showBy && props.reportType !== SHIPMENTS_CREATED,
                ) && (
                  <FlexBox align="center">
                    <IconButton onClick={() => props.change("showBy", null)}>
                      <Cancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          )}

          {Boolean(
            props.reportType === DRIVER_ATTENDANCE &&
              (props.getSupplierPredictions || props.getDriverPredictions) &&
              (props.withDriver || props.withSupplier),
          ) && (
            <FlexBox flex={true}>
              <FlexBox gutter={8} flex={true} className={classes.attendance}>
                {Boolean(
                  props.getSupplierPredictions && props.withSupplier,
                ) && (
                  <FlexBox flex={true}>
                    <FormSupplierChips
                      filter={{
                        only_dedicated: true,
                      }}
                      fullWidth={true}
                      name="supplierIds"
                      label={getLocalisationMessage(
                        "filter_by_suppliers",
                        "Filter By Suppliers",
                      )}
                    />
                  </FlexBox>
                )}

                {Boolean(props.getDriverPredictions && props.withDriver) && (
                  <FlexBox flex={true} align="flex-end">
                    <FormDriverChips
                      filter={
                        !fp.isEmpty(values.supplierIds)
                          ? {
                              supplier_ids: stringifyArray(
                                values.supplierIds.map(getObjectId),
                              ),
                            }
                          : {
                              only_dedicated: true,
                            }
                      }
                      fullWidth={true}
                      name="driverIds"
                      label={getLocalisationMessage(
                        "filter_by_drivers",
                        "Filter By Drivers",
                      )}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          )}

          {props.reportType === DAILY_CALL_PERFORMANCE && (
            <FlexBox>
              <FormSelectField
                name="callsBy"
                label={getLocalisationMessage("calls", "Calls")}
                options={callsByOptions}
                formatOption={x =>
                  getLocalisationMessage(x.toLocaleLowerCase(), formatText)
                }
              />
            </FlexBox>
          )}

          {props.reportType === SCORE_RANKING && (
            <FlexBox>
              <FormSelectField
                name="filterBy"
                label={
                  <span>
                    {getLocalisationMessage("filter_by", "Filter By")}{" "}
                    <sup>*</sup>
                  </span>
                }
                options={filterCourierTypes}
                formatOption={x =>
                  formatCourierTypesLocalize(x, getLocalisationMessage)
                }
              />
            </FlexBox>
          )}

          {Boolean(
            props.reportType === UN_ATTEMPTED_SHIPMENTS ||
              props.reportType === NOT_OFD_SHIPMENTS,
          ) && (
            <FlexBox className={classes.attendance}>
              <FormChipAutoComplete
                name="courierTypes"
                fullWidth={true}
                label={getLocalisationMessage("courier_types", "Courier Types")}
                options={CourierTypes}
                formatOption={x =>
                  formatCourierTypesLocalize(x, getLocalisationMessage)
                }
              />
            </FlexBox>
          )}

          {Boolean(
            props.reportType === UN_ATTEMPTED_SHIPMENTS ||
              props.reportType === NOT_OFD_SHIPMENTS ||
              props.reportType === PENDING_RTO_SHIPMENTS,
          ) && (
            <FlexBox>
              <FlexBox gutter={8}>
                <FlexBox align="flex-end">
                  <FormSelectField
                    name="reportCustomerType"
                    autoWidth={true}
                    label={getLocalisationMessage(
                      "customer_type",
                      "Customer Type",
                    )}
                    options={customerTypesOptions}
                    formatOption={formatText}
                  />
                </FlexBox>

                {values.reportCustomerType && (
                  <FlexBox align="center">
                    <IconButton
                      onClick={() => props.change("reportCustomerType", null)}
                    >
                      <Cancel />
                    </IconButton>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OperationalReportFilterForm);
