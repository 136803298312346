import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import { withTheme } from "@material-ui/core/styles";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isValidDate } from "../../helpers/ValidateUtils";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMessage,
  getCurrentLanguage,
} from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const valueSelector = formValueSelector("BatchRescheduleOrderDialog");

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  reduxForm({
    form: "BatchRescheduleOrderDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      deadline:
        !isValidDate(values.deadline) &&
        props.getLocalisationMessage(
          "select_estimated_delivery_date",
          "Select Estimated Delivery Date",
        ),
    }),
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        orderNumbers: valueSelector(state, "orderNumbers"),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
        currentLanguage: getCurrentLanguage(state),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  // connect(state => ({ orderNumbers: valueSelector(state, "orderNumbers") })),
);

BatchRescheduleOrderDialog.propTypes = {
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  orderNumbers: PropTypes.array,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  currentLanguage: PropTypes.string,
  classes: PropTypes.object,
  theme: PropTypes.object,
};

function BatchRescheduleOrderDialog(props) {
  const { getLocalisationMessage, currentLanguage, classes } = props;
  let title;
  if (currentLanguage === "ru") {
    title = `${getLocalisationMessage(
      "batch_reschedule",
      "Batch Reschedule",
    )} (${getLocalisationMessage("orders_3", "Orders")}-${fp.size(
      props.orderNumbers,
    )})`;
  } else {
    title = `${getLocalisationMessage(
      "batch_reschedule",
      "Batch Reschedule",
    )} ${fp.size(props.orderNumbers)} ${getLocalisationMessage(
      "orders",
      "Orders",
    )}`;
  }

  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {title}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form>
          <FlexBox container={8} direction="column">
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FormChipInput
                  name="orderNumbers"
                  focus={true}
                  fullWidth={true}
                  label={getLocalisationMessage("orders_numbers")}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <FormDateField
                  name="deadline"
                  fullWidth={true}
                  label={getLocalisationMessage("estimated_delivery_date")}
                />
              </FlexBox>

              <FlexBox flex={true} direction="column">
                <FormTimeField
                  name="deadline"
                  fullWidth={true}
                  label={getLocalisationMessage("estimated_delivery_time")}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>
      <DialogActions>
        {props.dirty ? (
          <Button onClick={props.reset} primary={true}>
            {getLocalisationMessage("reset")}
          </Button>
        ) : (
          Boolean(props.onRequestClose) && (
            <Button onClick={props.onRequestClose} primary={true}>
              {getLocalisationMessage("dismiss")}
            </Button>
          )
        )}

        <Button onClick={props.handleSubmit} primary={true}>
          {getLocalisationMessage("submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchRescheduleOrderDialog);
