import { Observable } from "rxjs";
import _ from "lodash";
import { Map, Set, List, fromJS, OrderedMap, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { computeDistance } from "./GeoUtils";
import { getHash } from "./DataUtils";
import { findPointPolygon } from "./MapPolygonUtils";
import { getDistanceMatrix } from "../api/shared/MapsApi";

export const NO_GROUP = "no_group";
export const GROUP_BY_FIRST_DECIMAL_PLACE = "group_by_11_km";
export const GROUP_BY_SECOND_DECIMAL_PLACE = "group_by_1_km";
export const GROUP_BY_THIRD_DECIMAL_PLACE = "group_by_100_m";
export const GROUP_BY_FOURTH_DECIMAL_PLACE = "group_by_11_m";

export const decimalFilterOptions = OrderedSet.of(
  NO_GROUP,
  GROUP_BY_FIRST_DECIMAL_PLACE,
  GROUP_BY_SECOND_DECIMAL_PLACE,
  GROUP_BY_THIRD_DECIMAL_PLACE,
  GROUP_BY_FOURTH_DECIMAL_PLACE,
);

type RoutingOptions = {
  groupBySize: boolean,
  groupByZone: boolean,
  groupBySupplier: boolean,

  mergeNoSupplier: boolean,
  estimateRequestCount: boolean,
  groupByDistance: string,
  splitByNeighborhoods: boolean,
  isMapBox: boolean,

  maxDuration: number,
  sortingDuration: number,
  handlingDuration: number,
};

const round = (n: number, precision = 100): number =>
  fp.isFinite(n) ? Math.round(n * precision) / precision : 0;

const mapToLatLng = item => ({ lat: item.get("lat"), lng: item.get("lon") });
const mapToLatLngString = item => `${item.get("lat")},${item.get("lon")}`;

function getDistanceWithDuration(origin, destination) {
  const distance = round(
    computeDistance(mapToLatLng(origin), mapToLatLng(destination)),
  );

  const maxSpeed = 1000; // 60 km/h
  const acceleration = 2;
  const deceleration = 3;
  const velocity = maxSpeed / 60;
  const seconds =
    0.5 * (velocity / acceleration + velocity / deceleration) +
    distance / velocity;

  // TO DO working in seconds
  // const duration = round(seconds / 60);
  const duration = round(seconds);

  return { distance, duration };
}

export function calculateDistanceMatrix(
  origins: List,
  destinations: List,
  options: RoutingOptions,
) {
  // eslint-disable-next-line no-console
  console.log("DISTANCE MATRIX REQUEST", origins.toJS(), destinations.toJS());

  if (options.estimateRequestCount) {
    return Observable.of(
      Map().withMutations(matrix => {
        origins.forEach(origin => {
          destinations.forEach(destination => {
            if (origin.equals(destination)) {
              return;
            }

            matrix.setIn(
              [origin, destination],
              Map(getDistanceWithDuration(origin, destination)),
            );
          });
        });
      }),
    );
  }

  return getDistanceMatrix({
    origins: origins.map(mapToLatLngString).join("|"),
    destinations: destinations.map(mapToLatLngString).join("|"),
  })
    .retry(1) // TODO: Retry only one time
    .map(response =>
      Map().withMutations(matrix => {
        origins.forEach((origin, rowIndex) => {
          destinations.forEach((destination, elementIndex) => {
            if (origin.equals(destination)) {
              return;
            }

            const result = fp.get(
              ["rows", rowIndex, "elements", elementIndex],
              response.payload,
            );

            const metrics = Map(
              result && result.status === "OK"
                ? {
                    distance: round(result.distance.value),
                    // duration: round(result.duration.value / 60),
                    duration: round(result.duration.value),
                  }
                : getDistanceWithDuration(origin, destination),
            );

            matrix.setIn([origin, destination], metrics);
          });
        });
      }),
    );
}

export function calculateDistanceMatrixByWarehouse(
  origins: List,
  destinations: List,
  warehouseId,
  orderIds: List,
  estimateRequestCount,
) {
  if (estimateRequestCount) {
    return Observable.of(
      Map().withMutations(matrix => {
        origins.forEach((origin, rowIndex) => {
          destinations.forEach(destination => {
            if (origin.equals(destination)) {
              return;
            }

            matrix.setIn(
              [warehouseId, orderIds.get(rowIndex)],
              Map(getDistanceWithDuration(origin, destination)),
            );
          });
        });
      }),
    );
  }

  return getDistanceMatrix({
    origins: origins.map(mapToLatLngString).join("|"),
    destinations: destinations.map(mapToLatLngString).join("|"),
  })
    .retry(1) // TODO: Retry only one time
    .map(response =>
      Map().withMutations(matrix => {
        origins.forEach((origin, rowIndex) => {
          destinations.forEach((destination, elementIndex) => {
            if (origin.equals(destination)) {
              return;
            }

            const result = fp.get(
              ["rows", rowIndex, "elements", elementIndex],
              response.payload,
            );

            const metrics = Map(
              result && result.status === "OK"
                ? {
                    distance: round(result.distance.value),
                    duration: round(result.duration.value),
                    // duration: round(result.duration.value / 60),
                  }
                : getDistanceWithDuration(origin, destination),
            );
            matrix.setIn([warehouseId, orderIds.get(rowIndex)], metrics);
          });
        });
      }),
    );
}

export const normalizeRoutingOptions = (
  options: Map,
): Promise<RoutingOptions> =>
  new Promise(resolve => {
    const zones = options.get("zones");
    const filteredOrders = Map().asMutable();
    options.get("locations").forEach(item => {
      filteredOrders.set(item.get("orderId"), []);
    });

    let locations = options.get("locations");

    if (
      options.get("groupByDistance") &&
      options.get("groupByDistance") !== NO_GROUP
    ) {
      let roundSize = 0;
      switch (options.get("groupByDistance")) {
        case GROUP_BY_FIRST_DECIMAL_PLACE:
          roundSize = 10;
          break;
        case GROUP_BY_SECOND_DECIMAL_PLACE:
          roundSize = 100;
          break;
        case GROUP_BY_THIRD_DECIMAL_PLACE:
          roundSize = 1000;
          break;
        default:
          roundSize = 10000;
          break;
      }

      locations = Set().withMutations(nodes => {
        options.get("locations").forEach(item => {
          const destinationLat = item.get("lat");
          const destinationLng = item.get("lng");
          const destinationNeighborhoodId = item.get("neighborhoodId");

          let alreadyExistedNearDestination = false;
          nodes.forEach(existed => {
            const visitedLat = existed.get("lat");
            const visitedLng = existed.get("lng");
            const existedNeighborhoodId = existed.get("neighborhoodId");

            if (
              round(visitedLat, roundSize) ===
                round(destinationLat, roundSize) &&
              round(visitedLng, roundSize) === round(destinationLng, roundSize)
            ) {
              if (options.get("splitByNeighborhoods")) {
                if (destinationNeighborhoodId === existedNeighborhoodId) {
                  alreadyExistedNearDestination = true;
                  const children = filteredOrders.get(existed.get("orderId"));
                  children.push(item.get("orderId"));
                  filteredOrders.set(existed.get("orderId"), children);
                }
              } else {
                alreadyExistedNearDestination = true;
                const children = filteredOrders.get(existed.get("orderId"));
                children.push(item.get("orderId"));
                filteredOrders.set(existed.get("orderId"), children);
              }
            }
          });

          if (!alreadyExistedNearDestination) {
            nodes.add(item);
          }
        });
      });
    }

    // eslint-disable-next-line no-console
    console.log("Init Locations", locations.toJS());
    // eslint-disable-next-line no-console
    console.log("Init Locations Merged", filteredOrders.toJS());

    resolve({
      groupBySize: options.get("groupBySize") !== false,
      groupByZone: options.get("groupByZone") !== false,
      groupBySupplier: options.get("groupBySupplier") !== false,

      mergeNoSupplier: Boolean(options.get("mergeNoSupplier")),

      maxDuration: fp.toFinite(options.get("maxDuration")),
      sortingDuration: fp.toFinite(options.get("sortingDuration")),
      handlingDuration: fp.toFinite(options.get("handlingDuration")),

      // TODO: Added new options
      splitByNeighborhoods: Boolean(options.get("splitByNeighborhoods")),
      estimateRequestCount: Boolean(options.get("estimateRequestCount")),
      isMapBox: Boolean(options.get("isMapBox")),
      groupByDistance: options.get("groupByDistance"),

      initialRoute: OrderedSet.of(
        options.get("warehouse"),
        options.get("destinationWarehouse"),
      ).filter(Map.isMap),

      filteredOrders,

      locations: Set().withMutations(nodes => {
        locations.forEach(item => {
          if (zones) {
            const zone = findPointPolygon(item, zones);

            nodes.add(item.set("zone", getHash(zone)));
          } else {
            nodes.add(item);
          }
        });
      }),
    });
  });

const sortMatrixDestinations = (matrix: Map, options: RoutingOptions): Map =>
  matrix.map((destinations, origin) =>
    destinations.toOrderedMap().sortBy(
      (metrics, destination) => [metrics, destination],
      ([aMetrics, aDestination], [bMetrics, bDestination]) => {
        if (origin.get("orderId") > 0 && options.groupByZone) {
          if (aDestination.get("zone") !== bDestination.get("zone")) {
            if (origin.get("zone") === aDestination.get("zone")) {
              return -1;
            }

            if (origin.get("zone") === bDestination.get("zone")) {
              return 1;
            }
          }
        }

        return aMetrics.get("duration") - bMetrics.get("duration");
      },
    ),
  );

const createLocalDistanceMatrix = (locations, options: RoutingOptions) =>
  Observable.defer(
    () =>
      new Promise(resolve => {
        const distanceMatrix = Map().withMutations(matrix => {
          locations.forEach(origin => {
            locations.forEach(destination => {
              if (
                origin.equals(destination) ||
                (origin.hasIn([origin, destination]) &&
                  origin.hasIn([destination, origin]))
              ) {
                return;
              }

              const metrics = Map(getDistanceWithDuration(origin, destination));

              matrix.setIn([origin, destination], metrics);
              matrix.setIn([destination, origin], metrics);
            });
          });
        });

        resolve(sortMatrixDestinations(distanceMatrix, options));
      }),
  );

const createMetrics = (
  route: OrderedMap,
  destination: Map,
  matrix: Map,
  extraDuration: number = 0,
  counter: number = 0,
) => {
  if (!route || route.isEmpty() || !matrix || !destination) {
    return Map({
      driveDistance: 0,
      driveDuration: 0,
      routeDistance: 0,
      routeDuration: extraDuration * 60, // TODO converts to seconds
    });
  }

  const prevMetrics = route.last();
  const prev = route.keySeq().last();
  const driveMetrics = matrix.getIn([prev, destination]);

  const driveDistance = driveMetrics.get("distance");
  const driveDuration = driveMetrics.get("duration");
  const routeDistance = round(driveDistance + prevMetrics.get("routeDistance"));
  const routeDuration = round(
    driveDuration + extraDuration * 60 + prevMetrics.get("routeDuration"),
  );

  return Map({
    driveDistance,
    driveDuration,
    routeDistance,
    routeDuration,
    counter,
    duration: driveDuration,
    overAllDuration: routeDuration,
  });
};

function addClosestToRoute(
  route: OrderedMap,
  matrix: OrderedMap,
  visited: Set,
  options: RoutingOptions,
  counter: number = 0,
) {
  let routeStart = route;
  const locations = route.keySeq();
  const origin = locations.last();
  const destinations = matrix.get(origin);

  // TODO temporary disable groupBySupplier
  // let routeSupplier = null;

  // if (options.groupBySupplier) {
  //   const locationWithSupplier = locations.findLast(
  //     x => x.get("supplierId") > 0,
  //   );
  //
  //   if (locationWithSupplier) {
  //     routeSupplier = locationWithSupplier.get("supplierId");
  //   }
  // }

  let routeNeighborhood = null;
  if (options.splitByNeighborhoods) {
    const locationInNeighborhood = locations.findLast(
      x => x.get("neighborhoodId") > 0,
    );

    if (locationInNeighborhood) {
      routeNeighborhood = locationInNeighborhood.get("neighborhoodId");
    }
  }

  destinations.forEach((durationMetrics, destination) => {
    // Check if destination is already in in route or was visited.
    if (route.has(destination) || visited.has(destination)) {
      return true;
    }

    // Check grouping only if origin is order.
    if (origin.get("orderId") > 0) {
      // Skip if orders have different size.
      // if (options.groupBySize) {
      //   if (origin.get("size") !== destination.get("size")) {
      //     return true;
      //   }
      // }

      // Skip if orders have different supplier.
      // if (options.groupBySupplier) {
      //   const destinationSupplier = destination.get("supplierId");
      //   const sameSupplier = routeSupplier === destinationSupplier;
      //   const shouldMerge =
      //     options.mergeNoSupplier && (!routeSupplier || !destinationSupplier);
      //
      //   if (!sameSupplier && !shouldMerge) {
      //     return true;
      //   }
      // }

      if (options.splitByNeighborhoods) {
        const destinationNeighborhood = destination.get("neighborhoodId");
        const sameNeighborhood = routeNeighborhood === destinationNeighborhood;
        const shouldMerge = !routeNeighborhood || !destinationNeighborhood;

        if (!sameNeighborhood && !shouldMerge) {
          return true;
        }
      }
    }

    const metrics = createMetrics(
      route,
      destination,
      matrix,
      options.handlingDuration,
      counter,
    );

    // Check if duration is exceeded. Also stop iteration. and divided 60 in order to convert seconds to minutes
    if (
      !options.splitByNeighborhoods &&
      metrics.get("routeDuration") / 60 > options.maxDuration
    ) {
      return false;
    }

    routeStart = routeStart.set(destination, metrics);
    routeStart = addClosestToRoute(
      routeStart,
      matrix,
      visited,
      options,
      counter,
    );

    return false;
  });

  return routeStart;
}

function composeRoute(
  route: OrderedMap,
  matrix: OrderedMap,
  visited: Set,
  options: RoutingOptions,
  counter: 0,
  elementsCount: 0,
) {
  const sourceWarehouse = route.keySeq().last();
  const destinations = matrix.get(sourceWarehouse);

  const routeVariations = List()
    .withMutations(list => {
      destinations.forEach(() => {
        const routeStart = addClosestToRoute(
          route,
          matrix,
          visited,
          options,
          counter,
        );

        if (!routeStart.equals(route)) {
          list.push(routeStart);
        }
      });
    })
    .sort((a, b) =>
      a.size !== b.size
        ? b.size - a.size
        : b.last().get("routeDuration") - a.last().get("routeDuration"),
    );

  if (routeVariations.isEmpty()) {
    return Observable.throw(
      new Error(
        `There are orders that does not fit in ${
          options.maxDuration
        } minutes from ${sourceWarehouse.get("name")}.`,
      ),
    );
  }

  return Observable.of({
    route: routeVariations.first(),
    counter,
    elementsCount,
  });
}

export const splitToChunks = (items: List, size: number) => {
  let nextItems = items;
  return List().withMutations(chunks => {
    while (nextItems.size > size) {
      chunks.push(nextItems.take(size));
      nextItems = nextItems.skip(size);
    }
    if (nextItems.size > 0) {
      chunks.push(nextItems);
    }
  });
};

export function optimizeWarehouseRoute(
  lastOrders: List,
  warehouseDestination: List,
  warehouseId,
  orderIds: List,
  estimateRequestCount,
) {
  const maxRequestCount = 25;

  const locationChunks = splitToChunks(lastOrders, maxRequestCount);
  const orderIdsChunks = splitToChunks(orderIds, maxRequestCount);

  const requests = [];
  locationChunks.forEach(origins => {
    requests.push([origins, warehouseDestination]);
  });

  return Observable.from(requests)
    .concatMap(([origins, destinations], index) =>
      calculateDistanceMatrixByWarehouse(
        origins,
        destinations,
        warehouseId,
        orderIdsChunks.get(index),
        estimateRequestCount,
      ),
    )
    .reduce(
      (x: Map, matrix: Map) =>
        x.withMutations(acc => {
          matrix.forEach((destinations, origin) => {
            destinations.forEach((metrics, destination) => {
              acc.setIn([origin, destination], metrics);
            });
          });
        }),
      Map(),
    )
    .map(
      fp.flow(matrix =>
        matrix.withMutations(x => {
          x.forEach((destinations, origin) => {
            destinations.forEach((metrics, destination) => {
              if (!x.hasIn([origin, destination])) {
                x.setIn([origin, destination, metrics]);
              }
            });
          });
        }),
      ),
    );
}

export function optimizeRoute(
  initialRoute: OrderedMap,
  route: OrderedMap,
  options: RoutingOptions,
) {
  const locations = route.keySeq().toList();

  const locationChunks = splitToChunks(locations, options.isMapBox ? 25 : 10);

  const requests = [];
  let elementsCount = 0;

  locationChunks.forEach(origins => {
    locationChunks.forEach(destinations => {
      requests.push([origins, destinations]);
      elementsCount += origins.size * destinations.size;
    });
  });

  return Observable.from(requests)
    .concatMap(
      ([origins, destinations]) =>
        calculateDistanceMatrix(origins, destinations, options),
      // .retryWhen(errors => errors.delay(5000))
      // .delay(500),
    )
    .reduce(
      (x: Map, matrix: Map) =>
        x.withMutations(acc => {
          matrix.forEach((destinations, origin) => {
            destinations.forEach((metrics, destination) => {
              if (origin.equals(destination)) {
                return;
              }
              acc.setIn([origin, destination], metrics);
            });
          });
        }),
      Map(),
    )
    .map(
      fp.flow(
        matrix =>
          matrix.withMutations(x => {
            x.forEach((destinations, origin) => {
              destinations.forEach((metrics, destination) => {
                if (!x.hasIn([destination, origin])) {
                  x.setIn([destination, options, metrics]);
                }
              });
            });
          }),
        matrix => sortMatrixDestinations(matrix, options),
      ),
    )
    .switchMap(matrix =>
      composeRoute(
        initialRoute,
        matrix,
        Set(),
        options,
        requests.length,
        elementsCount,
      ),
    );
}

export function calculateRouteMetrics(locations, options: RoutingOptions) {
  const locationList = locations.toList();
  const firstPoint = locationList.first();
  const routeWithFirst = OrderedMap().set(
    firstPoint,
    createMetrics(null, null, null, options.sortingDuration),
  );

  return locationList.size === 1
    ? Observable.of(routeWithFirst)
    : calculateDistanceMatrix(locationList, locationList, options).map(
        matrix => {
          const restRoute = locationList.skip(1);
          let route = routeWithFirst;

          restRoute.forEach(destination => {
            const metrics = createMetrics(
              route,
              destination,
              matrix,
              options.sortingDuration,
              1,
            );

            route = route.set(destination, metrics);
          });

          return route;
        },
      );
}

export function findOptimalRoute(
  initialRoute: OrderedSet,
  locations: Set,
  options: RoutingOptions,
) {
  const origin = initialRoute.last();

  const routeStartStream = calculateRouteMetrics(initialRoute, options);
  const localDistanceMatrixStream = createLocalDistanceMatrix(
    locations.add(origin),
    options,
  );

  return Observable.combineLatest(
    routeStartStream,
    localDistanceMatrixStream,
  ).switchMap(([routeStart, localDistanceMatrix]) =>
    Observable.of({
      routes: List(),
      visited: Set(),
      matrix: localDistanceMatrix,
      requestCount: 0,
      elementsTotal: 0,
    }).expand(({ matrix, visited, routes, requestCount, elementsTotal }) => {
      if (visited.size >= matrix.size) {
        return Observable.never();
      }

      return composeRoute(routeStart, matrix, visited, options)
        .switchMap(({ route }) => optimizeRoute(routeStart, route, options))
        .map(({ route, counter, elementsCount }) => ({
          matrix,
          visited: visited.merge(route.keySeq()),
          routes: routes.push(route).sortBy(x => -x.size),
          requestCount: requestCount + counter,
          elementsTotal: elementsTotal + elementsCount,
        }));
    }),
  );
}

export function estimateRequestRoutes(options: RoutingOptions) {
  const initialRoute = OrderedSet.of(
    options.get("warehouse"),
    options.get("destinationWarehouse"),
  ).filter(Map.isMap);

  let estimatedCount = 0;
  let isSingleArray = false;
  if (initialRoute.size > 1) {
    // TODO Because there are both from and to warehouses selected
    estimatedCount++;
  }
  const orderLocations = options.get("locations");

  let roundSize = 0;

  switch (options.get("groupByDistance")) {
    case GROUP_BY_FIRST_DECIMAL_PLACE:
      roundSize = 10;
      break;
    case GROUP_BY_SECOND_DECIMAL_PLACE:
      roundSize = 100;
      break;
    case GROUP_BY_THIRD_DECIMAL_PLACE:
      roundSize = 1000;
      break;
    default:
      roundSize = 10000;
      break;
  }

  if (
    options.get("splitByNeighborhoods") ||
    (!fp.isEmpty(options.get("groupByDistance")) &&
      options.get("groupByDistance") !== NO_GROUP)
  ) {
    // TODO Split by Groups
    let groupByNeighborhood = {};
    const groupByGeoLocation = {};
    let lastGroupLocations = {};
    if (options.get("splitByNeighborhoods")) {
      groupByNeighborhood = _.groupBy(orderLocations.toJS(), "neighborhoodId");
      if (
        !fp.isEmpty(options.get("groupByDistance")) &&
        options.get("groupByDistance") !== NO_GROUP
      ) {
        fromJS(groupByNeighborhood)
          .keySeq()
          .toSet()
          .forEach(neighborhoodId => {
            const filteredRoute = Map().withMutations(item => {
              groupByNeighborhood[neighborhoodId].forEach(destination => {
                if (destination.orderId) {
                  const destinationLat = destination.lat;
                  const destinationLng = destination.lng;

                  let alreadyExistedNearDestination = false;
                  // if(value.has("orderId")) {
                  item.valueSeq().forEach(existed => {
                    if (existed.orderId) {
                      const visitedLat = existed.lat;
                      const visitedLng = existed.lng;

                      if (
                        round(visitedLat, roundSize) ===
                          round(destinationLat, roundSize) &&
                        round(visitedLng, roundSize) ===
                          round(destinationLng, roundSize)
                      ) {
                        alreadyExistedNearDestination = true;
                      }
                    }
                  });

                  if (!alreadyExistedNearDestination) {
                    item.set(
                      `${neighborhoodId}_${destinationLat}_${destinationLng}`,
                      destination,
                    );
                  }
                }
              });
            });

            groupByGeoLocation[neighborhoodId] = filteredRoute.toArray();
          });

        lastGroupLocations = groupByGeoLocation;
      } else {
        lastGroupLocations = groupByNeighborhood;
      }
    } else if (
      !fp.isEmpty(options.get("groupByDistance")) &&
      options.get("groupByDistance") !== NO_GROUP
    ) {
      isSingleArray = true;
      lastGroupLocations = Map().withMutations(item => {
        orderLocations.forEach(destination => {
          if (destination.get("orderId")) {
            const destinationLat = destination.get("lat");
            const destinationLng = destination.get("lng");

            let alreadyExistedNearDestination = false;
            item.valueSeq().forEach(existed => {
              if (existed.get("orderId")) {
                const visitedLat = existed.get("lat");
                const visitedLng = existed.get("lng");

                if (
                  round(visitedLat, roundSize) ===
                    round(destinationLat, roundSize) &&
                  round(visitedLng, roundSize) ===
                    round(destinationLng, roundSize)
                ) {
                  alreadyExistedNearDestination = true;
                }
              }
            });

            if (!alreadyExistedNearDestination) {
              item.set(destination.get("orderId"), destination);
            }
          }
        });
      });
    }

    estimatedCount = calculateRequests({
      inputLocations: fromJS(lastGroupLocations)
        .valueSeq()
        .toSet(),
      initialRoute,
      isSingleArray,
      estimatedCount,
      options,
    });
  } else {
    // TODO NO Split
    estimatedCount = calculateRequests({
      inputLocations: orderLocations,
      initialRoute,
      isSingleArray: true,
      estimatedCount,
      options,
    });
  }

  return estimatedCount;
}

function calculateRequests({
  inputLocations,
  initialRoute,
  isSingleArray = false,
  estimateCounter = 0,
}) {
  const originLocation = initialRoute.last();

  const orders = Set().withMutations(nodes => {
    inputLocations.forEach(item => {
      nodes.add(item);
    });
  });

  const locationsArray = isSingleArray ? [orders] : orders;

  locationsArray.forEach(groupLocations => {
    const generatedLocation = isSingleArray
      ? groupLocations.add(originLocation)
      : groupLocations.push(originLocation);

    const distanceMatrix = Map().withMutations(matrix => {
      generatedLocation.forEach(origin => {
        generatedLocation.forEach(destination => {
          if (
            origin.equals(destination) ||
            (origin.hasIn([origin, destination]) &&
              origin.hasIn([destination, origin]))
          ) {
            return;
          }

          const metrics = Map(getDistanceWithDuration(origin, destination));

          matrix.setIn([origin, destination], metrics);
          matrix.setIn([destination, origin], metrics);
        });
      });
    });

    const requests = [];
    const locations = distanceMatrix.keySeq().toList();
    const locationChunks = splitToChunks(locations, 10);

    locationChunks.forEach(origins => {
      locationChunks.forEach(destinations => {
        requests.push([origins, destinations]);
      });
    });

    // eslint-disable-next-line no-param-reassign
    estimateCounter += requests.length;
  });

  return estimateCounter;
}
