import React from "react";
import { List } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, { DataListColumn } from "../data-list/DataList";
import { formatDate } from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
);

DriverLiabilityOpenShipmentsTable.propTypes = {
  list: PropTypes.instanceOf(List).isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function DriverLiabilityOpenShipmentsTable(props) {
  const { getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");
  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.total}
      list={props.list}
      rowCount={props.list.size}
      overscanRowCount={4}
      classNameWithFooter={true}
      rowGetter={options => props.list.get(options.index)}
      filter={props.filter}
      onFilterChange={props.onFilterChange}
    >
      <DataListColumn
        width={128}
        label={getLocalisationMessage("awb", "AWB")}
        dataKey="code"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row => row.cellData.get("code") || NA}
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("status", "Status")}
        dataKey="status"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row =>
          formatOrderStatusCodeForLocalisation(
            row.cellData.get("status"),
            getLocalisationMessage,
          ) || NA
        }
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("shipment_value", "Shipment Value")}
        dataKey="value"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row =>
          (
            <PriceWrapper
              price={row.cellData.get("value")}
              code={row.cellData.get("currency")}
            />
          ) || NA
        }
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage(
          "public_service_fee",
          "Public Service Fee",
        )}
        dataKey="public_service_fee"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row =>
          (
            <PriceWrapper
              price={row.cellData.get("public_service_fee")}
              code={row.cellData.get("currency")}
            />
          ) || NA
        }
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("cod_amount", "COD Amount")}
        dataKey="cod"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row =>
          (
            <PriceWrapper
              price={row.cellData.get("cod")}
              code={row.cellData.get("currency")}
            />
          ) || NA
        }
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("delivery_date", "Delivery Date")}
        dataKey="completed_date"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row =>
          formatDate(row.cellData.get("completed_date")) || NA
        }
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("created_date", "Created Date")}
        dataKey="created_date"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row => formatDate(row.cellData.get("created_date")) || NA}
      />
    </DataList>
  );
}

export default enhancer(DriverLiabilityOpenShipmentsTable);
