import _ from "lodash";
import {
  is,
  Map,
  Set,
  List,
  Iterable,
  OrderedMap,
  OrderedSet,
} from "immutable";
import invariant from "invariant";
import { computeDistance } from "./GeoUtils";

const round = (n: number): number =>
  _.isFinite(n) ? Math.round(n * 100) / 100 : 0;

const computeDuration = (
  from: Map,
  to: Map,
  maxSpeed: number = 1000 /* 60 km/h */,
): number => {
  const acceleration = 2;
  const deceleration = 3;
  const distance = computeDistance(from, to);
  const velocity = maxSpeed / 60;
  const seconds =
    0.5 * (velocity / acceleration + velocity / deceleration) +
    distance / velocity;

  return round(seconds / 60);
};

const getDuration = (from: Map, to: Map, durationMatrix: Map): Map => {
  invariant(Map.isMap(from), "Provided invalid `from` argument.\n%s", from);
  invariant(Map.isMap(to), "Provided invalid `to` argument.\n%s", to);
  invariant(
    Map.isMap(durationMatrix),
    "Provided invalid `durationMatrix` argument.\n%s",
    durationMatrix,
  );
  invariant(
    durationMatrix.has(from),
    "Argument `from` is not found in `durationMatrix`\n`durationMatrix`:\n%s\n`from`:\n%s",
    durationMatrix,
    from,
  );
  invariant(
    durationMatrix.hasIn([from, to]),
    "Argument `to` is not found in `durationMatrix.get(from)`\n`durationMatrix.get(from)`:\n%s\n`to`:\n%s",
    durationMatrix.get(from),
    from,
  );

  return round(durationMatrix.getIn([from, to]));
};

const sortDestinations = (origin, destinations) =>
  destinations.sortBy(
    (duration, destination) => [duration, destination],
    ([aDuration, aDestination], [bDuration, bDestination]) => {
      if (aDestination.get("zone") !== bDestination.get("zone")) {
        if (origin.get("zone") === aDestination.get("zone")) {
          return -1;
        }

        if (origin.get("zone") === bDestination.get("zone")) {
          return 1;
        }
      }

      return aDuration - bDuration;
    },
  );

const createMatrix = (
  nodes: Iterable,
  durationGetter: (from: Map, to: Map) => number,
): OrderedMap =>
  OrderedMap()
    .withMutations(matrix => {
      nodes.forEach(from => {
        nodes.forEach(to => {
          if (!matrix.has(from)) {
            matrix.set(from, OrderedMap());
          }

          if (!matrix.has(to)) {
            matrix.set(to, OrderedMap());
          }

          if (
            !is(from, to) &&
            !matrix.hasIn([to, from]) &&
            !matrix.hasIn([from, to])
          ) {
            const duration = durationGetter(from, to);

            matrix.setIn([from, to], duration);
            matrix.setIn([to, from], duration);
          }
        });
      });
    })
    .map((destinations, origin) => sortDestinations(origin, destinations))
    .sortBy(node => node.first());

export const computeDurationMatrix = (locations: Map, speed): OrderedMap =>
  createMatrix(locations, (from, to) => computeDuration(from, to, speed));

const createMetrics = (options: {
  item: Map,
  startPoint: boolean,
  durationMatrix: Map,
  extraDuration: number,
  routeStart: OrderedMap,
}): Map => {
  const prevMetrics = options.routeStart.last();
  const prev = options.routeStart.keySeq().last();

  const duration = prev
    ? getDuration(prev, options.item, options.durationMatrix)
    : 0;
  const totalDuration = round(duration + options.extraDuration);
  const runDuration = prevMetrics
    ? round(prevMetrics.get("runDuration") + totalDuration)
    : totalDuration;
  const overAllDuration = prevMetrics
    ? round(prevMetrics.get("overAllDuration") + totalDuration)
    : totalDuration;

  return Map({
    duration,
    totalDuration,
    overAllDuration,
    runDuration: options.startPoint ? 0 : runDuration,
  });
};

const createRouteStart = (
  options: { durationMatrix: Map, extraDuration: number },
  ...locations: Map[]
): OrderedMap =>
  OrderedMap().withMutations(routeStart => {
    OrderedSet.of(...locations)
      .filter(Boolean)
      .forEach(item => {
        const metrics = createMetrics({
          ...options,
          item,
          routeStart,
          startPoint: true,
        });

        routeStart.set(item, metrics);
      });
  });

const addClosestLocationsToRoute = (options: {
  size: string,
  supplierId: number,
  visited: Set,
  durationMatrix: Map,
  routeStart: OrderedMap,
  maxDuration: number,
  extraDuration: number,
}): Map => {
  let { routeStart } = options;
  const lastItem = routeStart.keySeq().last();

  options.durationMatrix.get(lastItem).forEach((duration, destination) => {
    // Order in another route.
    if (options.visited.has(destination)) {
      return true;
    }

    // Order already in current route.
    if (routeStart.has(destination)) {
      return true;
    }

    // Order size is not same.
    if (options.size !== destination.get("size")) {
      return true;
    }

    // Order supplier is not same.
    if (options.supplierId !== destination.get("supplierId")) {
      return true;
    }

    const metrics = createMetrics(
      _.assign({}, options, {
        routeStart,
        item: destination,
      }),
    );

    if (metrics.get("overAllDuration") > options.maxDuration) {
      return false;
    }

    routeStart = routeStart.set(destination, metrics);

    routeStart = addClosestLocationsToRoute(
      _.assign({}, options, {
        routeStart,
      }),
    );

    return false;
  });

  return routeStart;
};

const createOptimalCluster = (options: {
  visited: Set,
  durationMatrix: Map,
  routeStart: OrderedMap,

  maxDuration: number,
  extraDuration: number,
}): List => {
  const clusters = List()
    .withMutations(list => {
      options.durationMatrix.forEach((destinations, order) => {
        if (options.visited.has(order) || options.routeStart.has(order)) {
          return;
        }

        const metrics = createMetrics({ ...options, item: order });

        const routeStart = options.routeStart.set(order, metrics);
        const cluster = addClosestLocationsToRoute({
          ...options,
          routeStart,
          size: order.get("size"),
          supplierId: order.get("supplierId"),
        });

        list.push(cluster);
      });
    })
    .sort((a, b) => {
      if (a.size > b.size) {
        return -1;
      }

      if (a.size < b.size) {
        return 1;
      }

      return a.last().get("overAllDuration") - b.last().get("overAllDuration");
    });

  return clusters.first();
};

function optimizeClusterRoute(options: {
  nodes: Iterable,
  durationMatrix: Map,
  extraDuration: number,
  routeStart: OrderedMap,
}): OrderedMap {
  let nodes = options.nodes
    .toOrderedSet()
    .filter(item => !options.routeStart.has(item));
  const startPoint = options.routeStart.keySeq().last();

  if (startPoint) {
    nodes = nodes.sortBy(item =>
      getDuration(startPoint, item, options.durationMatrix),
    );
  } else {
    nodes = createMatrix(nodes, (from, to) =>
      getDuration(from, to, options.durationMatrix),
    )
      .keySeq()
      .toOrderedSet();
  }

  return options.routeStart.withMutations(routeStart => {
    while (nodes.size > 0) {
      const order = nodes.first();

      nodes = sortDestinations(order, nodes.delete(order));

      routeStart.set(
        order,
        createMetrics(_.assign({}, options, { routeStart, item: order })),
      );
    }
  });
}

const createClustersFromDurationMatrix = (options: {
  durationMatrix: Map,
  routeStart: OrderedMap,

  maxDuration: number,
  extraDuration: number,
}): List => {
  let visited = Set();

  const clusters = List().withMutations(list => {
    while (visited.size < options.durationMatrix.size) {
      const cluster = createOptimalCluster({ ...options, visited });

      /* istanbul ignore else */
      if (cluster) {
        const optimizedCluster = optimizeClusterRoute({
          ...options,
          nodes: cluster.keySeq(),
        });

        list.push(optimizedCluster);
        visited = visited.merge(cluster.keySeq());
      } else {
        /* istanbul ignore next */
        break; // Could not create cluster. TODO: Find the way replicate in tests.
      }
    }
  });

  return clusters;
};

export const createClusters = (options: {
  zones: Map,
  durationMatrix: Map,
  routeStartPoints: Map[],

  maxDuration: number,
  sortingDuration: number,
  handlingDuration: number,
}) => {
  const routeStart = createRouteStart(
    {
      extraDuration: options.sortingDuration,
      durationMatrix: options.durationMatrix,
    },
    ...options.routeStartPoints,
  );

  const clusters = createClustersFromDurationMatrix({
    routeStart,
    durationMatrix: options.durationMatrix,
    maxDuration: options.maxDuration,
    extraDuration: options.handlingDuration,
  });

  return clusters.sortBy(item => -item.size);
};
