import React from "react";
import _ from "lodash";
import { List, Iterable } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import DataList, { DataListColumn } from "../data-list/DataList";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: { "& > div": { display: "flex", flex: "1 1 0%" } },
    courierName: { textTransform: "uppercase", fontWeight: "bold" },
    header: { textTransform: "uppercase" },
  }),
  withState("state", "setState", {
    filter: new DataListFilter(),
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const reportListStream = propsStream
          .distinctUntilChanged("reportList")
          .filter(props => props.showTable)
          .map(
            fp.flow(fp.get("reportList"), list =>
              list.get("list", List()).map((i, x) => i.set("overall_rank", x)),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(reportListStream, (props, reportList) => ({
            ...props,
            reportList,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const scoreRankingListStream = propsStream
          .map(props => ({
            list: props.reportList,
            filter: props.state.filter,
          }))
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const { filter, list } = props;

            const orderBy = fp.toPath(filter.getOrderBy());
            const orderByAsc = filter.isOrderByAsc();

            return orderBy.length > 0
              ? list.sort((a, b) => {
                  const aValue = a.getIn(orderBy);
                  const bValue = b.getIn(orderBy);

                  if (aValue < bValue) {
                    return orderByAsc ? -1 : 1;
                  }
                  if (aValue > bValue) {
                    return orderByAsc ? 1 : -1;
                  }

                  return 0;
                })
              : list;
          })
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(scoreRankingListStream, (props, list) => ({
            ...props,
            list,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

const roundNumber = number => _.round(number, 2);

ScoreRankingReport.propTypes = {
  showTable: PropTypes.bool,
  classes: PropTypes.object,
  reportList: PropTypes.instanceOf(Iterable),

  state: PropTypes.object,
  setState: PropTypes.func,

  list: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function ScoreRankingReport(props) {
  const { classes, state, getLocalisationMessage } = props;

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox flex={true} element={<Card className={classes.card} />}>
            <FlexBox
              gutter={8}
              flex={true}
              direction="column"
              element={<CardContent />}
            >
              <FlexBox flex={true}>
                <DataList
                  isLoading={false}
                  list={props.list}
                  rowCount={props.list.size}
                  overscanRowCount={8}
                  filter={state.filter}
                  onFilterChange={x => props.setState(fp.set("filter", x))}
                  rowGetter={options => props.list.get(options.index)}
                  withFooter={false}
                >
                  <DataListColumn
                    width={150}
                    label=""
                    dataKey="id"
                    disableSort={true}
                    justifyContent="center"
                    cellRenderer={row => (
                      <span className={classes.courierName}>
                        {row.cellData.get("courier")}
                      </span>
                    )}
                  />

                  <DataListColumn
                    width={150}
                    label={
                      <span className={classes.header}>
                        {getLocalisationMessage(
                          "pick_up_lead_time",
                          "Pick-up Lead time",
                        )}
                      </span>
                    }
                    dataKey="pickup_score"
                    justifyContent="center"
                    cellRenderer={row =>
                      roundNumber(row.cellData.get("pickup_score"))
                    }
                  />

                  <DataListColumn
                    width={150}
                    label={
                      <span className={classes.header}>
                        {getLocalisationMessage(
                          "first_delivery_attempt_lead_time",
                          "First Delivery Attempt Lead time",
                        )}
                      </span>
                    }
                    dataKey="delivery_attempt_score"
                    justifyContent="center"
                    cellRenderer={row =>
                      roundNumber(row.cellData.get("delivery_attempt_score"))
                    }
                  />

                  <DataListColumn
                    width={150}
                    label={
                      <span className={classes.header}>
                        {getLocalisationMessage(
                          "delivery_lead_time",
                          "Delivery lead time",
                        )}
                      </span>
                    }
                    dataKey="delivery_score"
                    justifyContent="center"
                    cellRenderer={row =>
                      roundNumber(row.cellData.get("delivery_score"))
                    }
                  />

                  <DataListColumn
                    width={150}
                    label={
                      <span className={classes.header}>
                        {getLocalisationMessage("delivery", "Delivery")} %
                      </span>
                    }
                    dataKey="delivery_percentage_score"
                    justifyContent="center"
                    cellRenderer={row =>
                      roundNumber(row.cellData.get("delivery_percentage_score"))
                    }
                  />

                  <DataListColumn
                    width={150}
                    label={
                      <span className={classes.header}>
                        {getLocalisationMessage(
                          "speed_accuracy_of_cod_remittance",
                          "Speed & accuracy of COD remittance",
                        )}
                      </span>
                    }
                    dataKey="cod_score"
                    justifyContent="center"
                    cellRenderer={row =>
                      roundNumber(row.cellData.get("cod_score"))
                    }
                  />

                  <DataListColumn
                    width={150}
                    label={
                      <span className={classes.header}>
                        {getLocalisationMessage(
                          "customer_feedback",
                          "Customer Feedback",
                        )}
                      </span>
                    }
                    dataKey="customer_feedback_score"
                    justifyContent="center"
                    cellRenderer={row =>
                      roundNumber(row.cellData.get("customer_feedback_score"))
                    }
                  />

                  <DataListColumn
                    width={150}
                    label={
                      <span className={classes.header}>
                        {getLocalisationMessage(
                          "overall_score",
                          "Overall Score",
                        )}
                      </span>
                    }
                    dataKey="over_all_score"
                    justifyContent="center"
                    cellRenderer={row =>
                      roundNumber(row.cellData.get("over_all_score"))
                    }
                  />

                  <DataListColumn
                    width={150}
                    label={
                      <span className={classes.header}>
                        {getLocalisationMessage("overall_rank", "Overall Rank")}
                      </span>
                    }
                    dataKey="overall_rank"
                    justifyContent="center"
                    cellRenderer={row => row.cellData.get("overall_rank")}
                  />
                </DataList>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(ScoreRankingReport);
