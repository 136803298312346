import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import ExportFileDialog from "../files/ExportFileDialog";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import { toJSON } from "../../../shared/helpers/DataSerializer";

const createFilename = fp.flow(
  () => new Date(),
  formatDateTimeToUrl,
  fp.kebabCase,
  x => `${x}.wostjson`,
);

const enhancer = compose(
  mapPropsStream(propsStream => {
    const fileContentStream = propsStream
      .map(
        fp.flow(
          fp.pick(["task", "orders"]),
          fp.update("orders", orders =>
            orders
              .toList()
              .map(x => Map({ bin: x.get("bin"), number: x.get("number") })),
          ),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .map(toJSON);

    return propsStream.combineLatest(
      fileContentStream,
      (props, fileContent) => ({ ...props, fileContent }),
    );
  }),
  pureComponent(fp.pick(["open", "fileContent"])),
);

OrderSortingExportDialog.propTypes = {
  fileContent: PropTypes.string,

  open: PropTypes.bool,
  onRequestClose: PropTypes.func,

  task: PropTypes.instanceOf(Map).isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,
};

function OrderSortingExportDialog(props) {
  return (
    <ExportFileDialog
      open={props.open}
      fileType="application/json"
      fileName={createFilename()}
      fileContent={props.fileContent}
      onRequestClose={props.onRequestClose}
    />
  );
}

export default enhancer(OrderSortingExportDialog);
