import React from "react";
import _ from "lodash";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AsyncAutoComplete from "./AsyncAutoComplete";
import { formatDriverName } from "../../helpers/DriverHelper";
import DataListFilter from "../../helpers/DataListFilter";
import {
  getDriver,
  getDriverChoose,
  isDriverChooseFetching,
} from "../../reducers/DriverReducer";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter()
  .setPageSize(20)
  .setValue("status", ACTIVE);

const formatOption = formatDriverName;
const parseOption = item => item.toJS();

const enhancer = compose(
  connect((state, { value, supplierId }) => {
    const id = _.toFinite(value && value.id);
    const name = _.toString(value && value.name);
    const item = getDriver(state, id);
    const filter = baseFilter
      .setSearch(name)
      .setValue("supplier_id", _.toInteger(supplierId) || null);

    return {
      id,
      filter,
      options: getDriverChoose(state, filter),
      isLoading: isDriverChooseFetching(state, filter),
      searchText: name || _.trim(item && formatOption(item)),
    };
  }),
  getContext({
    fetchDriver: PropTypes.func.isRequired,
    fetchDriverChoose: PropTypes.func.isRequired,
  }),
);

DriverAutoComplete.propTypes = {
  supplierId: PropTypes.number,
  fetchDriver: PropTypes.func,
  fetchDriverChoose: PropTypes.func,
};

function DriverAutoComplete({ fetchDriver, fetchDriverChoose, ...restProps }) {
  const inputProps = _.omit(restProps, ["supplierId", "dispatch"]);

  return (
    <AsyncAutoComplete
      {...inputProps}
      formatOption={formatOption}
      parseOption={parseOption}
      fetchItem={fetchDriver}
      fetchChoose={fetchDriverChoose}
    />
  );
}

export default enhancer(DriverAutoComplete);
