import { OrderedSet } from "immutable";

export const URGENT = "bullet";
export const SAVER = "in_5_days";
export const NEXT_DAY = "next_day";
export const SAME_DAY = "same_day";
export const OVER_ALL = "over_all";
export const GUARANTEED_NEXT_DAY = "guaranteed_next_day";

export default OrderedSet.of(
  SAVER,
  NEXT_DAY,
  GUARANTEED_NEXT_DAY,
  SAME_DAY,
  URGENT,
  OVER_ALL,
);
