import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { Collapse } from "react-collapse";
import ModalPaper from "../ui-core/ModalPaper";
import OrderJobDetailsDialogTabs, {
  MAP_TAB,
  ORDERS_TAB,
  HISTORY_TAB,
  SIGNATURE_TAB,
} from "../order-job/OrderJobDetailsDialogTabs";
import OrderJobDetailsDialogHeader from "../order-job/OrderJobDetailsDialogHeader";
import { renderIf } from "../../helpers/HOCUtils";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { primary4 } from "../../../shared/theme/main-theme";

const enhancer = compose(
  useSheet({
    paper: { fontFamily: "Blogger Sans" },
    background: {
      zIndex: -10,
      width: "100%",
      height: "100%",
      display: "flex",
      position: "absolute",
      flexDirection: "column",
    },

    topBackground: {
      zIndex: 1,
      flex: "1 1 0%",
      borderRadius: 0,
      backgroundColor: primary4,
      transition: "background 0.5s ease",
    },

    bottomBackground: { backgroundColor: "#ebebeb", flex: "1 1 0%" },

    loading: {
      top: 0,
      width: "100%",
      height: "300px",
      textAlign: "center",
      lineHeight: "300px",
      position: "absolute",
      transition: "opacity 1s ease",
    },
    visibleLoader: { opacity: 1 },
    invisibleLoader: { opacity: 0, top: "2000px" },

    collapse: {
      overflow: "hidden",
      marginLeft: "-3px",
      paddingLeft: "3px",
      marginBottom: "-3px",
      paddingBottom: "3px",
      marginRight: "-3px",
      paddingRight: "3px",
    },

    container: { transition: "opacity 1s ease" },
    visibleContainer: { opacity: 1 },
    invisibleContainer: { opacity: 0 },
  }),
  renderIf("open"),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(null, { showErrorMessage, showSuccessMessage }),
);

OrderJobDetailsDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  onTabChange: PropTypes.func.isRequired,
  tab: PropTypes.oneOf([ORDERS_TAB, HISTORY_TAB, MAP_TAB, SIGNATURE_TAB]),
  onEditClick: PropTypes.func,
  onEditCostsClick: PropTypes.func,
  onCancelOrderClick: PropTypes.func,
  children: PropTypes.node,
  withoutCourierTab: PropTypes.bool,
  withoutSignaturesTab: PropTypes.bool,
  withoutCustomerTab: PropTypes.bool,
  getDriverUrl: PropTypes.func,
  getSupplierUrl: PropTypes.func,
  getCustomerUrl: PropTypes.func,
  isOrderClosed: PropTypes.bool,
  withoutMap: PropTypes.bool,
  withCODHistory: PropTypes.bool,
  onJobEditClick: PropTypes.func,
};

function OrderJobDetailsDialog(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.paper}
    >
      <div
        className={cx(classes.loading, {
          [classes.invisibleLoader]: !props.isLoading,
          [classes.visibleLoader]: props.isLoading,
        })}
      >
        Loading...
      </div>

      <div
        className={cx(classes.container, {
          [classes.visibleContainer]: !props.isLoading,
          [classes.invisibleContainer]: props.isLoading,
        })}
      >
        <div className={classes.background}>
          <Paper className={classes.topBackground} />
          <div className={classes.bottomBackground} />
        </div>

        <OrderJobDetailsDialogHeader
          order={props.order}
          getDriverUrl={props.getDriverUrl}
          getSupplierUrl={props.getSupplierUrl}
          getCustomerUrl={props.getCustomerUrl}
          onJobEditClick={props.onJobEditClick}
        />

        <OrderJobDetailsDialogTabs
          tab={props.tab}
          withoutCourierTab={props.withoutCourierTab}
          order={props.order}
          onTabChange={props.onTabChange}
          onEditClick={props.onEditClick}
          onEditCostsClick={props.onEditCostsClick}
          withoutSignaturesTab={props.withoutSignaturesTab}
          onCancelOrderClick={props.onCancelOrderClick}
          isOrderClosed={props.isOrderClosed}
          withoutCustomerTab={props.withoutCustomerTab}
          withoutMap={props.withoutMap}
          withCODHistory={props.withCODHistory}
        />

        {props.order.get("id") > 0 && (
          <Collapse isOpened={!props.isLoading}>{props.children}</Collapse>
        )}
      </div>
    </ModalPaper>
  );
}

export default enhancer(OrderJobDetailsDialog);
