import React from "react";
import { Map, List, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, SubmissionError } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import FormSelectField from "../form/FormSelectField";
import ImportFileDialog from "../files/ImportFileDialog";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import {
  OVERRIDE,
  MERGE_OURS,
  MERGE_THEIRS,
} from "../../constants/MergeStrategies";
import { fromJSON } from "../../../shared/helpers/DataSerializer";

const mergeOptions = OrderedSet.of(OVERRIDE, MERGE_THEIRS, MERGE_OURS);

const enhancer = compose(
  renderIf("open"),
  reduxForm({
    form: "OrderSortingImportDialog",
    initialValues: { mergeStrategy: OVERRIDE },
    validate: values => ({
      mergeStrategy:
        !mergeOptions.has(values.mergeStrategy) && "Select Merge Strategy",
    }),
  }),
  formValues({ data: "data" }),
);

const formatMergeStrategy = x => {
  switch (x) {
    case OVERRIDE:
      return "Override (Replace current task data)";

    case MERGE_OURS:
      return "Merge (Take changes from task on conflicts)";

    case MERGE_THEIRS:
      return "Merge (Take changes from file on conflicts)";

    default:
      return null;
  }
};

OrderSortingImportDialog.propTypes = {
  data: PropTypes.object,

  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool,
  onRequestClose: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

function OrderSortingImportDialog(props) {
  if (!props.data) {
    return (
      <ImportFileDialog
        open={props.open}
        accept=".wostjson"
        onRequestClose={props.onRequestClose}
        onSubmit={file =>
          Promise.resolve(file)
            .then(fromJSON)
            .catch(fp.noop)
            .then(x => {
              if (
                fp.isPlainObject(x) &&
                Map.isMap(x.task) &&
                List.isList(x.orders)
              ) {
                return x;
              }

              throw new SubmissionError({ file: "Corrupted File Content" });
            })
        }
        onSubmitSuccess={x => props.change("data", x)}
      />
    );
  }

  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <DialogContent>
        <PageLoading isLoading={props.submitting} />

        <FormSelectField
          fullWidth={true}
          name="mergeStrategy"
          options={mergeOptions}
          formatOption={formatMergeStrategy}
          label="Merge Strategy"
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}> Dismiss </Button>
          <Button onClick={props.handleSubmit}> Submit </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderSortingImportDialog);
