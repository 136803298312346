import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  Menu,
  Paper,
  Divider,
  Popover,
  MenuItem,
  Button,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  Sort,
  FilterList,
  LastPage,
  FirstPage,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import ReactAutoScroll from "./ScrollToPosition";
import DriversRadarAlertsItem from "./DriversRadarAlertsItem";
import Text, { MUTED } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatNumber } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    showErrorMessage,
    showSuccessMessage,
  })),
  useSheet({
    container: {
      width: "500px",
      position: "relative",
    },
    pages: {
      fontSize: "13px",
      color: "#6f6a6a",
      fontFamily: "Blogger Sans",
    },
    headerContainer: {
      padding: "8px",
      fontSize: "16px",
      fontFamily: "Blogger Sans",
    },
    listContainer: {
      padding: 0,
      fontSize: "16px",
      maxWidth: "491px",
      overflowY: "auto",
      overflowX: "hidden",
      fontFamily: "Blogger Sans",
    },
  }),
  mapPropsStream(propsStream => {
    const pagesCountStream = propsStream
      .map(fp.pick(["alertsList", "filter", "total"]))
      .distinctUntilChanged(isEqualData)
      .map(({ alertsList, filter, total }) => {
        const listSize = alertsList && alertsList.size;
        const page = filter.getPage() || 0;

        if (!total) {
          return "";
        }

        if (page === 0 && listSize < total) {
          return `1-50 of ${formatNumber(total)}`;
        } else if (listSize === total) {
          return `1-${listSize} of ${listSize}`;
        }

        return `${page * 50 + 1}-${page * 50 + listSize} of ${formatNumber(
          total,
        )}`;
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(pagesCountStream, (props, pagesCount) => ({
        ...props,
        pagesCount,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  pureComponent(
    fp.pick([
      "alertItem",
      "alertsList",
      "listPosition",
      "alertDetailed",
      "isLoadingList",
      "scrollingList",
      "isLoadingAlert",
    ]),
  ),
);

class DriversRadarAlertsList extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  static propTypes = {
    classes: PropTypes.object,

    onItemClick: PropTypes.func,
    onSortClick: PropTypes.func,
    onFilterClick: PropTypes.func,
    onScrollingDone: PropTypes.func,
    onItemCloseClick: PropTypes.func,
    onItemFilterClick: PropTypes.func,
    onAlertStatusClick: PropTypes.func,

    filter: PropTypes.instanceOf(DataListFilter),

    alertItem: PropTypes.number,

    isLoadingList: PropTypes.bool,

    pagesCount: PropTypes.string,

    ordersCount: PropTypes.number,

    total: PropTypes.number,
    alertsList: PropTypes.instanceOf(Immutable.List),
    alertDetailed: PropTypes.instanceOf(Immutable.Map),

    isLoadingAlert: PropTypes.bool,

    onChangePage: PropTypes.func,

    listPosition: PropTypes.number,
    scrollingList: PropTypes.bool,
    getLocalisationMessage: PropTypes.func,
  };

  render() {
    const { classes, filter, getLocalisationMessage } = this.props;
    const page = filter.getPage() || 0;
    const disabledFirstButtons =
      page === 0 || this.props.total === this.props.alertsList.size;
    const disabledLastButtons =
      page * 50 >= this.props.total ||
      this.props.total === this.props.alertsList.size;

    return (
      <ReactAutoScroll
        targetPosition={this.props.listPosition}
        easeType="linear"
        speed={30}
        updateInterval={40}
        onScrollingDone={this.props.onScrollingDone}
        scrollTargetRef={this.scrollList}
        isEnabled={this.props.scrollingList}
      >
        <FlexBox container={16} className={classes.container}>
          <div className={classes.arrow} />

          <FlexBox
            gutter={8}
            flex={true}
            direction="column"
            element={<Paper />}
          >
            <FlexBox>
              <FlexBox
                gutter={8}
                flex={true}
                className={classes.headerContainer}
              >
                <FlexBox flex={true} align="center">
                  {`${this.props.ordersCount} ${getLocalisationMessage(
                    "orders",
                    "orders",
                  )}`}
                </FlexBox>

                <FlexBox style={{ position: "relative" }}>
                  <Button
                    labelPosition="before"
                    secondary={Boolean(this.props.filter.getValue("order_by"))}
                    icon={<Sort />}
                    onClick={event => {
                      event.preventDefault();

                      this.setState(
                        fp.flow(
                          fp.update("openSort", value => !value),
                          fp.set("anchorEl", event.currentTarget),
                        ),
                      );
                    }}
                  >
                    {getLocalisationMessage("sort", "Sort")}
                  </Button>
                  <Popover
                    open={this.state.openSort}
                    anchorEl={this.state.anchorEl}
                    targetOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    onRequestClose={() =>
                      this.setState(
                        fp.flow(
                          fp.set("anchorEl", undefined),
                          fp.set("openSort", undefined),
                        ),
                      )
                    }
                  >
                    <Menu
                      value={this.props.filter.getValue("order_by")}
                      onChange={(e, v) => {
                        this.setState(
                          fp.flow(
                            fp.set("anchorEl", undefined),
                            fp.set("openSort", undefined),
                          ),
                        );

                        this.props.onSortClick(v);
                      }}
                    >
                      <MenuItem
                        primaryText={getLocalisationMessage("none", "None")}
                      />
                      <Divider />
                      <MenuItem
                        primaryText={getLocalisationMessage(
                          "by_created_date",
                          "By Created Date",
                        )}
                        value="created_date"
                      />
                      <MenuItem
                        primaryText={getLocalisationMessage(
                          "by_courier_company_name",
                          "By Courier Company Name",
                        )}
                        value="supplier_name"
                      />
                      <MenuItem
                        primaryText={getLocalisationMessage(
                          "by_alert_type",
                          "By Alert Type",
                        )}
                        value="alert_type"
                      />
                      <MenuItem
                        primaryText={getLocalisationMessage(
                          "by_status",
                          "By Status",
                        )}
                        value="status"
                      />
                    </Menu>
                  </Popover>
                </FlexBox>

                <FlexBox>
                  <Button
                    secondary={Boolean(
                      filter.getValue("alert_types") ||
                        filter.getValue("alert_statuses") ||
                        filter.getValue("from_date_time") ||
                        filter.getValue("to_date_time") ||
                        filter.getValue("supplier_id") ||
                        filter.getSearch(),
                    )}
                    labelPosition="before"
                    icon={<FilterList />}
                    onClick={this.props.onFilterClick}
                  >
                    {getLocalisationMessage("filter", "Filter")}
                  </Button>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox
              flex={true}
              direction="column"
              className={classes.listContainer}
              element={
                <div
                  ref={x => {
                    this.scrollList = x;
                  }}
                />
              }
            >
              {this.props.isLoadingList && <LinearProgress />}

              {Boolean(
                !this.props.isLoadingList && this.props.alertsList.size === 0,
              ) && (
                <FlexBox justify="center">
                  <Text type={MUTED}>
                    {getLocalisationMessage(
                      "alerts_not_found",
                      "Alerts Not Found!",
                    )}
                  </Text>
                </FlexBox>
              )}

              {!this.props.isLoadingList &&
                this.props.alertsList.map((item, idx) => (
                  <DriversRadarAlertsItem
                    item={item}
                    key={item.get("driver_id")}
                    onItemClick={x => {
                      this.props.onItemClick(x, idx);
                    }}
                    isLoading={
                      this.props.isLoadingAlert &&
                      this.props.alertItem === item.get("driver_id")
                    }
                    alertDetailed={this.props.alertDetailed}
                    onCloseClick={this.props.onItemCloseClick}
                    onFilterClick={x => {
                      this.props.onItemFilterClick(x, idx);
                    }}
                    onAlertStatusClick={this.props.onAlertStatusClick}
                    itemOpen={
                      !this.props.isLoadingAlert &&
                      this.props.alertItem === item.get("driver_id") &&
                      this.props.alertDetailed.size > 0
                    }
                  />
                ))}
            </FlexBox>

            {Boolean(
              !this.props.isLoadingList && this.props.alertsList.size > 0,
            ) && (
              <FlexBox>
                <FlexBox gutter={8} flex={true}>
                  <FlexBox>
                    <IconButton
                      disabled={disabledFirstButtons}
                      onClick={() => this.props.onChangePage(0)}
                    >
                      <FirstPage />
                    </IconButton>
                  </FlexBox>

                  <FlexBox>
                    <IconButton
                      disabled={disabledFirstButtons}
                      onClick={() => this.props.onChangePage(page - 1)}
                    >
                      <ChevronLeft />
                    </IconButton>
                  </FlexBox>

                  <FlexBox
                    flex={true}
                    align="center"
                    justify="center"
                    className={classes.pages}
                  >
                    {this.props.pagesCount}
                  </FlexBox>

                  <FlexBox>
                    <IconButton
                      disabled={disabledLastButtons}
                      onClick={() => this.props.onChangePage(page + 1)}
                    >
                      <ChevronRight />
                    </IconButton>
                  </FlexBox>

                  <FlexBox>
                    <IconButton
                      disabled={disabledLastButtons}
                      onClick={() =>
                        this.props.onChangePage(
                          Math.ceil(this.props.total / 50 - 1),
                        )
                      }
                    >
                      <LastPage />
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      </ReactAutoScroll>
    );
  }
}

export default enhancer(DriversRadarAlertsList);
