import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router";
import AlarmIcon from "../icons/AlarmIcon";
import TooltipOverlay from "../ui-core/TooltipOverlay";
import MultiLineCell from "../data-list/MultiLineCell";
import { danger1 } from "../../../shared/theme/main-theme";
import NameAndAvatar from "../../../client/components/avatars/AvatarWithName";

const NA = "N/A";

const enhancer = compose(
  useSheet({
    hoverable: { cursor: "pointer", textDecoration: "underline" },
    avatar: {
      container: { paddingLeft: 0 },
      nameBox: { position: "relative" },
    },
    nav_icon: {
      position: "absolute",
      left: "0",
      bottom: "5px",
      marginLeft: "-20px",
    },
    multiLineClass: {
      root: { marginRight: "20px", maxWidth: "100%" },
      line: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
  }),
);

JobListAddress.propTypes = {
  classes: PropTypes.object,
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  order: PropTypes.object,
  notificationHref: PropTypes.string,
};

JobListAddress.defaultProps = { element: "span" };

function JobListAddress({ classes, order, ...props }) {
  let addressFromTo = 0;
  switch (order.get("type")) {
    case "pickup":
      addressFromTo = 0;
      break;
    case "delivery":
      addressFromTo = 1;
      break;
    default:
      addressFromTo = 0;
      break;
  }
  return (
    <NameAndAvatar
      name={
        order.getIn(
          addressFromTo ? ["package", "from_city"] : ["package", "to_city"],
          order.getIn(["locations", addressFromTo, "address"]),
        ) || order.getIn(["locations", addressFromTo, "address_city"])
      }
      classes={classes.avatar}
    >
      <MultiLineCell
        style={{ paddingRight: "30px", width: "100%" }}
        firstLine={
          <div>
            <strong>
              {order.getIn(["locations", addressFromTo, "contact_name"])}
            </strong>
          </div>
        }
        secondLine={
          <TooltipOverlay
            label={order.getIn(["locations", addressFromTo, "address"]) || NA}
          >
            <span className={classes.hoverable}>
              {order.getIn(["locations", addressFromTo, "address"])}
            </span>
          </TooltipOverlay>
        }
      />
      {order.getIn(["locations", addressFromTo, "confirmed_by_recipient"]) ? (
        <Link to={props.notificationHref}>
          <Avatar
            style={{
              position: "absolute",
              right: "5px",
              top: "50%",
              marginTop: "-12px",
              backgroundColor: "transparent",
              color: danger1,
            }}
            size={23}
            className={classes.hoverable}
          >
            <AlarmIcon />
          </Avatar>
        </Link>
      ) : (
        ""
      )}
    </NameAndAvatar>
  );
}

export default enhancer(JobListAddress);
