import React from "react";
import { OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, Button } from "@material-ui/core";
import { connect } from "react-redux";
import reduxActions from "redux-form/es/actions";
import OperationalReportFilterForm from "./OperationalReportFilterForm";
import FlexBox from "../ui-core/FlexBox";
import SelectField from "../deprecated/SelectField";
import { formatOperationalReportsTypesLocalize } from "../../helpers/ReportsHelper";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  useSheet({
    card: { "& > div": { display: "flex", flex: "1 1 0%" } },
    button: { paddingBottom: "20px" },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isRTL: getIsRTL(state),
    }),
    dispatch => ({
      resetForm: () =>
        dispatch(reduxActions.reset("OperationalReportFilterForm")),
    }),
  ),
);

OperationalReportForm.propTypes = {
  classes: PropTypes.object,
  showDownloadReport: PropTypes.bool,
  downloadReportUrl: PropTypes.string,
  reportType: PropTypes.string,

  showFilter: PropTypes.bool,

  withoutDate: PropTypes.bool,

  onChangeFilter: PropTypes.func,
  onChangeReportType: PropTypes.func,

  getDriverPredictions: PropTypes.func,
  getCachedDriver: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  getCachedSupplier: PropTypes.func,

  withDriver: PropTypes.bool,
  withSupplier: PropTypes.bool,

  resetForm: PropTypes.func,

  operationalReportTypes: PropTypes.instanceOf(OrderedSet),
  getLocalisationMessage: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
};

function OperationalReportForm(props) {
  const { classes, getLocalisationMessage, isRTL } = props;

  return (
    <FlexBox gutter={8} flex={true} element={<Card className={classes.card} />}>
      <FlexBox flex={true} element={<CardContent />}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox align="flex-end">
            <SelectField
              autoWidth={true}
              value={props.reportType}
              onChange={value => {
                props.onChangeReportType(value);
                props.resetForm();
              }}
              hintText={getLocalisationMessage(
                "select_report",
                "Select Report",
              )}
              floatingLabelFixed={true}
              label={getLocalisationMessage("report_type", "Report Type")}
              options={props.operationalReportTypes}
              formatOption={x =>
                formatOperationalReportsTypesLocalize(x, getLocalisationMessage)
              }
              menuItemStyle={{ textAlign: isRTL ? "right" : "left" }}
              iconStyle={{
                left: !isRTL ? "auto" : "0",
                right: isRTL ? "auto" : "0",
              }}
              labelStyle={{
                paddingLeft: !isRTL ? "0" : "56px",
                paddingRight: isRTL ? "0" : "56px",
                textAlign: isRTL ? "right" : "left",
              }}
              floatingLabelStyle={{
                right: isRTL ? "0" : "auto",
              }}
            />
          </FlexBox>

          {props.showFilter && (
            <OperationalReportFilterForm
              reportType={props.reportType}
              withoutDate={props.withoutDate}
              getDriverPredictions={props.getDriverPredictions}
              getCachedDriver={props.getCachedDriver}
              getCachedSupplier={props.getCachedSupplier}
              getSupplierPredictions={props.getSupplierPredictions}
              onChange={props.onChangeFilter}
              withDriver={props.withDriver}
              withSupplier={props.withSupplier}
            />
          )}

          {props.showDownloadReport && (
            <FlexBox
              align="flex-end"
              justify="flex-end"
              className={classes.button}
            >
              <Button
                variant="contained"
                color="primary"
                component="a"
                target="_blank"
                href={props.downloadReportUrl}
              >
                {getLocalisationMessage("download_report", "Download Report")}
              </Button>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OperationalReportForm);
