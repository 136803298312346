import React from "react";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";
import DriverLiabilityTypes, {
  COD_PENDING,
  OPEN_SHIPMENTS,
} from "../../constants/DriverLiabilityTypes";

const driverLiabilityHelper = (type, getLocalisationMessage) => {
  switch (type) {
    case COD_PENDING:
      return getLocalisationMessage("cod_pending", "COD Pending");

    case OPEN_SHIPMENTS:
      return getLocalisationMessage("open_shipments", "Open Shipments");

    default:
      return getLocalisationMessage("na", "N/A");
  }
};

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  withContext(
    { getCachedDriver: PropTypes.func, getDriverPredictions: PropTypes.func },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
    }),
  ),
  reduxForm({
    form: "DriverLiabilityFilterForm",
    enableReinitialize: true,
  }),
);

DriverLiabilityFilterForm.propTypes = {
  getCachedDriver: PropTypes.func.isRequired,
  getDriverPredictions: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function DriverLiabilityFilterForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox gutter={16} flex={true} element={<form />}>
      <FlexBox>
        <FormDriverAutoComplete
          name="driver"
          floatingLabelFixed={true}
          hintText={getLocalisationMessage("find_driver", "Find Driver")}
          label={getLocalisationMessage("driver", "Driver")}
        />
      </FlexBox>
      <FlexBox>
        <FormSelectField
          name="type"
          label={getLocalisationMessage("report_type", "Report Type")}
          options={DriverLiabilityTypes}
          formatOption={option =>
            driverLiabilityHelper(option, getLocalisationMessage)
          }
        />
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverLiabilityFilterForm);
