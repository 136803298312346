import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, withState, getContext } from "recompose";
import PropTypes from "prop-types";
import {
  Chip,
  Paper,
  Button,
  IconButton,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";
import { red, green } from "@material-ui/core/colors";
import { PanTo, Marker, MarkerIcon } from "react-google-map-components";
import BrandMarker from "../maps/BrandMarker";
import PolylineWrapper from "../maps/PolylineWrapper";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import UserAvatar from "../avatars/UserAvatar";
import FlexBox from "../ui-core/FlexBox";
import { renderIf } from "../../helpers/HOCUtils";
import {
  formatDistanceInUnity,
  formatDateDistanceToNow,
} from "../../helpers/FormatUtils";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
import { getMarketplaceDefaultUnitOfDistance } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ModalPaper from "../../components/ui-core/ModalPaper";
import {
  primary4,
  newButtonColor1,
  newButtonColor1Hover,
} from "../../../shared/theme/main-theme";
import { fromJSON, fromBase64 } from "../../../shared/helpers/DataSerializer";
import TimeIcon from "../order-job/assets/time_icon.svg";
import DistanceIcon from "../order-job/assets/distance_icon.svg";
import CarGrey from "../../components/maps/assets/car_green.png";

const NA = "N/A";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    unitOfDistance: getMarketplaceDefaultUnitOfDistance(state),
  })),
  renderIf(props => props.driverId > 0),
  withState("isLoading", "setIsLoading", false),
  useSheet({
    paper: { minWidth: "900px", maxWidth: "900px" },
    map: { height: "300px" },
    header: { backgroundColor: primary4, color: "white", padding: "10px 12px" },
    informerTextBlock: {
      display: "flex",
      lineHeight: 1.2,
      marginLeft: "8px",
      flexDirection: "column",
      justifyContent: "center",
    },
    avatarSize: { height: "40px", width: "40px" },
    columns: { paddingRight: "20px" },
    status: {
      display: "flex",
      textAlign: "right",
      flexDirection: "row-reverse",
      margin: "0",
      padding: "0",
      height: "30px",
      marginTop: "5px",
      textTransform: "uppercase",
      borderRadius: "5px",
      float: "left",
    },
    chipContent: { width: "100%", paddingRight: "20px", paddingLeft: "40px" },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(null, { showErrorMessage, showSuccessMessage }),
);

SuggestedDriverOnTheMapDialog.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map).isRequired,
  driverId: PropTypes.number,
  data: PropTypes.string,
  location: PropTypes.object,
  onRequestClose: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  unitOfDistance: PropTypes.string,
};

function SuggestedDriverOnTheMapDialog(props) {
  const { classes, order, getLocalisationMessage, unitOfDistance } = props;

  const data = fromJSON(fromBase64(props.data));

  const position = {
    lat: data.get("lat"),
    lng: data.get("lng"),
  };

  const pickUpLocation = {
    lat: order.getIn(["locations", 0, "lat"]),
    lng: order.getIn(["locations", 0, "lon"]),
  };

  return (
    <ModalPaper
      open={true}
      paperClassName={classes.paper}
      rightIcon={
        <IconButton onClick={props.onRequestClose}>
          <Close />
        </IconButton>
      }
      onRequestClose={props.onRequestClose}
    >
      <FlexBox
        flex={true}
        justify="center"
        element={<Paper />}
        direction="column"
      >
        <FlexBox className={classes.header}>
          <FlexBox className={classes.columns} style={{ minWidth: "200px" }}>
            <div className={classes.avatarSize}>
              <UserAvatar
                size={40}
                name={
                  data.get("supplier_name")
                    ? data.get("supplier_name")
                    : data.get("name")
                }
              />
            </div>
            <div className={classes.informerTextBlock}>
              <div>
                {data.get("name") || NA} ({data.get("supplier_name")})
              </div>
            </div>
          </FlexBox>

          <FlexBox className={classes.columns} style={{ width: "200px" }}>
            <div className={classes.avatarSize}>
              <UserAvatar size={38} src={DistanceIcon} />
            </div>
            <div className={classes.informerTextBlock}>
              <div>
                {formatDistanceInUnity(data.get("distance"), unitOfDistance) ||
                  NA}
              </div>
            </div>
          </FlexBox>

          <FlexBox className={classes.columns} style={{ width: "200px" }}>
            <div className={classes.avatarSize}>
              <UserAvatar size={38} src={TimeIcon} />
            </div>
            <div className={classes.informerTextBlock}>
              <div>
                {formatDateDistanceToNow(
                  data.get("date_time"),
                  null,
                  getLocalisationMessage,
                ) || NA}
              </div>
            </div>
          </FlexBox>
          <FlexBox className={classes.columns}>
            <div className={classes.informerTextBlock}>
              <div className={classes.chipContent}>
                {data.get("busy") ? (
                  <Chip
                    className={classes.status}
                    labelColor="white"
                    backgroundColor={red[500]}
                  >
                    {getLocalisationMessage("busy", "Busy")}
                  </Chip>
                ) : (
                  <Chip
                    className={classes.status}
                    labelColor="white"
                    backgroundColor={green[500]}
                  >
                    {getLocalisationMessage("available", "Available")}
                  </Chip>
                )}
              </div>
            </div>
          </FlexBox>
        </FlexBox>

        <GoogleMapWrapper className={classes.map}>
          {isValidCoordinates(position) && isValidCoordinates(pickUpLocation) && (
            <div>
              <PanTo latLng={position} />

              <PolylineWrapper origin={position} destination={pickUpLocation} />

              <Marker
                position={position}
                icon={
                  <MarkerIcon
                    url={CarGrey}
                    scaledSize={{ width: 30, height: 40 }}
                  />
                }
              />
              <BrandMarker position={pickUpLocation} />
            </div>
          )}
        </GoogleMapWrapper>
        <FlexBox element={<CardActions />} justify="flex-end">
          <Button
            hoverColor={newButtonColor1Hover}
            backgroundColor={newButtonColor1}
            labelStyle={{ color: "white" }}
            onClick={props.onRequestClose}
          >
            {getLocalisationMessage("close")}
          </Button>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(SuggestedDriverOnTheMapDialog);
