import React from "react";
import { Set, List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Chip, Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { FilterList } from "@material-ui/icons";
import OrderList from "../orders-core/OrderList";
import OrderListStore from "../../stores/OrderListStore";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { FIND_ORDERS_CARD } from "../../containers/admin/AdminOrderRouterContainer";

const enhancer = compose(
  connect((state, { orderIds, filter }) => {
    const orderListStore: OrderListStore = state.OrderListStore;
    const params = filter.toRequestParams();

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      list: orderListStore.getListValue(params),
      total: orderListStore.getListTotal(params),
      isLoading: orderListStore.isListLoading(params),
      ordersOnMap: orderIds
        .map(id => orderListStore.getShortItem(id))
        .filter(Boolean)
        .toList(),
    };
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRowSelect,
      stream: onRowSelectStream,
    } = createEventHandler();
    const selectedItemsStream = onRowSelectStream.startWith(OrderedMap());

    const sideEffectsStream = propsStream
      .filter(fp.get("list"))
      .distinctUntilChanged("list", isEqualData)
      .do(() => onRowSelect(OrderedMap()))
      .startWith(null);

    return propsStream
      .combineLatest(
        selectedItemsStream,
        sideEffectsStream,
        (props, selectedItems) => ({
          ...props,
          onRowSelect,
          selectedItems,
        }),
      )
      .distinctUntilChanged();
  }),
);

OrderRouterOrders.propTypes = {
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  ordersOnMap: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  onOrderIdsChange: PropTypes.func.isRequired,
  orderIds: PropTypes.instanceOf(Set).isRequired,
  onFilterClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  selectedCard: PropTypes.string,
};

function OrderRouterOrders(props) {
  const { getLocalisationMessage } = props;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {Boolean(props.ordersOnMap && props.ordersOnMap.size > 0) && (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {props.ordersOnMap
            .map(item => (
              <Chip
                style={{ margin: 4 }}
                key={item.get("id")}
                onRequestDelete={() =>
                  props.onOrderIdsChange([
                    props.orderIds.delete(item.get("id")),
                    FIND_ORDERS_CARD,
                  ])
                }
              >
                {item.get("order_number")}
              </Chip>
            ))
            .toArray()}
          <Chip
            style={{ margin: 4 }}
            onClick={() =>
              props.onOrderIdsChange([props.orderIds.clear(), FIND_ORDERS_CARD])
            }
          >
            {getLocalisationMessage("remove_all", "Remove All")}
          </Chip>
        </div>
      )}

      <OrderList
        list={props.list || List()}
        totalCount={props.total || 0}
        isLoading={props.isLoading}
        onRowSelect={props.onRowSelect}
        selectedItems={props.selectedItems}
        filter={props.filter}
        onFilterChange={props.onFilterChange}
        cardActionIcons={
          <IconButton onClick={props.onFilterClick}>
            <FilterList />
          </IconButton>
        }
        altHeader={
          <Button
            onClick={() =>
              props.onOrderIdsChange([
                props.orderIds.merge(props.selectedItems.keySeq()),
                null,
              ])
            }
          >
            {getLocalisationMessage("add_to_map", "Add to Map")}
          </Button>
        }
      />
    </div>
  );
}

export default enhancer(OrderRouterOrders);
