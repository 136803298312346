import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";
import { fromJS } from "immutable";
import { ACTIVE } from "../constants/OverallStatusTabs";
import { ON_DUTY } from "../constants/DriverDutyStatuses";

export const toAlertsListFilter = fp.compose(
  DataListFilter.create,

  mapSchema({
    ...getListFilterSchema(),

    from_date_time: types.string,
    to_date_time: types.string,
    courier_type: types.string,
    search_type: types.string,
    driver_status: types.string,

    marketplaceId: types.ID,
    last_mile_driver_id: types.ID,
    customer_id: types.ID,
    driver_id: types.ID,
    company_id: types.ID,
    supplier_id: types.ID,
    warehouse_id: types.ID,
    destination_warehouse_id: types.ID,
    from_city_id: types.ID,
    to_city_id: types.ID,
    contract_owner_id: types.ID,
    parent_order_id: types.ID,

    use_solr: types.boolean,
    include_exception: types.boolean,
    is_uae: types.boolean,
    include_dw: types.boolean,
    simple: types.boolean,
    only_dedicated: types.boolean,
    with_driver: types.boolean,
    requires_attention: types.boolean,
    scheduled_for_cancellation: types.boolean,
    auto_cancelled: types.boolean,
    is_merchant: types.boolean,
    current_alerts: types.boolean,
    current_orders: types.boolean,

    marketplaceIds: queryTypes.IDSeq,
    customer_ids: queryTypes.IDSeq,
    exclude_customer_ids: queryTypes.IDSeq,
    driver_ids: queryTypes.IDSeq,
    exclude_driver_ids: queryTypes.IDSeq,
    company_ids: queryTypes.IDSeq,
    exclude_company_ids: queryTypes.IDSeq,
    supplier_ids: queryTypes.IDSeq,
    exclude_supplier_ids: queryTypes.IDSeq,
    parent_supplier_ids: queryTypes.IDSeq,
    exclude_parent_supplier_ids: queryTypes.IDSeq,
    ids: queryTypes.IDSeq,
    pickupTimeslotIds: queryTypes.IDSeq,
    dropoffTimeslotIds: queryTypes.IDSeq,

    scores: queryTypes.floatSeq,

    status: queryTypes.stringSeq,
    cod_status: queryTypes.stringSeq,
    payment_type: queryTypes.stringSeq,
    order_size: queryTypes.stringSeq,
    courier_types: queryTypes.stringSeq,
    types: queryTypes.stringSeq,
    alert_types: queryTypes.stringSeq,
    alert_statuses: queryTypes.stringSeq,
  }),
);

export const toAlertsHistoryListFilter = fp.compose(
  DataListFilter.create,

  mapSchema({
    size: types.ID,

    from_date_time: types.string,
    to_date_time: types.string,

    alert_types: queryTypes.stringSeq,
    alert_statuses: queryTypes.stringSeq,
  }),
);
export const defaultAlertTabFilters = fromJS([
  {
    name: "ALL",
    sort_order: 0,
    filter: {
      status: fp.toUpper(ACTIVE),
      duty_status: null,
      driver_availability: "ALL",
    },
  },
  {
    name: "BUSY",
    sort_order: 1,
    filter: {
      status: fp.toUpper(ACTIVE),
      duty_status: ON_DUTY,
      driver_availability: "BUSY",
    },
  },
  {
    name: "AVAILABLE",
    sort_order: 2,
    filter: {
      status: fp.toUpper(ACTIVE),
      duty_status: ON_DUTY,
      driver_availability: "AVAILABLE",
    },
  },
]);
export const defaultSuggestedDriversTabFilters = fromJS([
  {
    name: "All",
    sort_order: 0,
    filter: {
      duty_status: "ALL",
    },
  },
  {
    name: "Busy",
    sort_order: 1,
    filter: {
      duty_status: "BUSY",
    },
  },
  {
    name: "Available",
    sort_order: 2,
    filter: {
      duty_status: "AVAILABLE",
    },
  },
]);
