import sprintf from "sprintf";
import { api } from "./BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ADDRESS_BOOK_LIST_URL = `${API_V2_ROOT_URL}/admin/address_books`;
const ADDRESS_BOOK_CREATE_URL = `${API_V2_ROOT_URL}/admin/address_books/`;
const ADDRESS_BOOK_ITEM_URL = `${API_V2_ROOT_URL}/admin/address_books/%s`;
// const ADDRESS_BOOK_CHOOSE_URL = `${API_V2_ROOT_URL}/address_books/search_by`;
const ADDRESS_BOOK_BATCH_DELETE_URL = `${
  API_V2_ROOT_URL
}/admin/address_books/batch_delete`;

export const chooseAddressBook = (filter: DataListFilter) =>
  api.getStream(ADDRESS_BOOK_LIST_URL, { params: filter.getDefinedValues() });

export const getAddressBook = id =>
  api.getStream(sprintf(ADDRESS_BOOK_ITEM_URL, id));

export type AddressBookRequest = {
  address: string,
  address_type: string,
  apartment: string,
  building: string,
  city: {
    code: string,
    id: number,
    name: string,
  },
  country: {
    code: string,
    id: number,
    name: string,
  },
  floor: string,
  id: number,
  landmark: string,
  name: string,
  neighborhood: {
    code: string,
    id: number,
    name: string,
  },
  phone: string,
  phone_code: string,
  phone_without_code: string,
  street: string,
  lat: number,
  lon: number,
  pickup: boolean,
  default_address: boolean,
};

export const createAddressBook = (body: AddressBookRequest) =>
  api.post(ADDRESS_BOOK_CREATE_URL, { body });

export const updateAddressBook = (body: AddressBookRequest) =>
  api.post(sprintf(ADDRESS_BOOK_CREATE_URL), { body });

export const getAddressBookList = (request: DataListFilter) =>
  api.getStream(ADDRESS_BOOK_LIST_URL, { params: request.getDefinedValues() });

export const deleteBatchAddressBook = ids =>
  api.delete(ADDRESS_BOOK_BATCH_DELETE_URL, { params: { ids } });
