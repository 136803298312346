import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import AutoComplete from "./AutoComplete";
import { isEqualData } from "../../helpers/DataUtils";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const {
      handler: handleUpdateInput,
      stream: handleUpdateInputStream,
    } = createEventHandler();
    const onUpdateInput = fp.rest(handleUpdateInput);

    const sideEffectsStream = Observable.merge(
      propsStream
        .filter(
          props =>
            props.id > 0 &&
            _.isInteger(props.id) &&
            _.isEmpty(_.trim(props.searchText)) &&
            _.isFunction(props.fetchItem),
        )
        .distinctUntilKeyChanged("id")
        .do(props => props.fetchItem(props.id)),
      propsStream
        .filter(fp.get("searchText"))
        .distinctUntilKeyChanged("filter", isEqualData)
        .do(props => props.fetchChoose(props.filter)),
      handleUpdateInputStream
        .map(([searchText, , options]) => ({
          searchText,
          source: options.source,
        }))
        .filter(props => props.source === "change")
        .withLatestFrom(propsStream)
        .do(([value, props]) => props.onChange({ name: value.searchText })),
    ).startWith(null);

    return propsStream
      .map(fp.omit(["id", "filter", "fetchItem", "fetchChoose"]))
      .distinctUntilChanged(isEqualData)
      .combineLatest(sideEffectsStream, props => ({
        ...props,
        onUpdateInput,
        menuStyle: {
          maxHeight: "256px",
          overflowX: "hidden",
          overflowY: "auto",
        },
      }));
  }),
);

AsyncAutoComplete.propTypes = {
  id: PropTypes.any,
  filter: PropTypes.any,
  fetchItem: PropTypes.func,
  fetchChoose: PropTypes.func,
};

function AsyncAutoComplete(props) {
  return <AutoComplete {...props} filter={fp.stubTrue} />;
}

export default enhancer(AsyncAutoComplete);
