import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavTabs from "../ui-core/NavTabs";
import TabBadge from "../deprecated/TabBadge";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ALL, PICK_UP } from "../../constants/OrderJobsTypes";

const baseFilter = fromJS({
  page: 0,
  size: 1,
  order_by: null,
  order_by_direction: null,
});

const ALL_TAB = "All";
const PICK_UP_TAB = "Pick Up";

const tabBaseFilters = OrderedMap()
  .set(ALL_TAB, fromJS({ page: 0, types: ALL }))
  .set(
    PICK_UP_TAB,
    fromJS({
      page: 0,
      types: PICK_UP,
    }),
  );

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const valueStream = propsStream
      .map(props => ({
        pathname: props.basePathname,
        query: props.filter.setPage(0).getDefinedValues(),
      }))
      .distinctUntilChanged(isEqualData);

    const tabsQueryStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props =>
        tabBaseFilters.map(tabFilter => props.filter.setValueMap(tabFilter)),
      )
      .distinctUntilChanged(isEqualData);

    const tabCountsStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(props => ({
        batchGetOrderListCount: props.batchGetOrderListCount,
        filters: tabBaseFilters.map(tabFilter =>
          props.filter.setValueMap(tabFilter).setValueMap(baseFilter),
        ),
      }))
      .distinctUntilKeyChanged("filters", isEqualData)
      .switchMap(
        ({ filters, batchGetOrderListCount }) =>
          batchGetOrderListCount
            ? batchGetOrderListCount(filters)
            : Observable.of(fromJS({})),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        valueStream,
        tabsQueryStream,
        tabCountsStream,
        (props, value, tabsQuery, tabCounts) => ({
          ...props,
          value,
          width: tabsQuery.size * 176,
          tabs: tabsQuery
            .map((filter: DataListFilter, tab) => ({
              label: (
                <TabBadge
                  label={props.getLocalisationMessage(_.snakeCase(tab)) || tab}
                  badge={tabCounts.get(tab)}
                />
              ),
              value: {
                pathname: props.basePathname,
                query: filter.getDefinedValues(),
              },
            }))
            .toArray(),
        }),
      )
      .map(fp.omit(["filter", "basePathname", "batchGetOrderListCount"]))
      .distinctUntilChanged(isEqualData);
  }),
);

const OrderListJobTabs = enhancer(NavTabs);

OrderListJobTabs.propTypes = {
  batchGetOrderListCount: PropTypes.func,
  basePathname: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func,
};

export default OrderListJobTabs;
