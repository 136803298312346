import { OrderedSet } from "immutable";

// report types
export const DRIVER_ATTENDANCE = "DRIVER_ATTENDANCE";
export const DAILY_CALL_PERFORMANCE = "DAILY_CALL_PERFORMANCE";
export const SUPPLIER_DUE_BALANCE = "SUPPLIER_DUE_BALANCE";
export const COURIER_TURNAROUND_TIMES = "COURIER_TURNAROUND_TIMES";
export const SHIPMENTS_PENDING = "SHIPMENTS_PENDING";
export const SHIPMENTS_DELIVERED = "SHIPMENTS_DELIVERED";
export const SHIPMENTS_CREATED = "SHIPMENTS_CREATED";
export const PENDING_COD_SHIPMENT = "PENDING_COD_SHIPMENT";
export const SCORE_RANKING = "SCORE_RANKING";
export const UN_ATTEMPTED_SHIPMENTS = "UN_ATTEMPTED_SHIPMENTS";
export const NOT_OFD_SHIPMENTS = "NOT_OFD_SHIPMENTS";
export const PENDING_RTO_SHIPMENTS = "PENDING_RTO_SHIPMENTS";
export const COD_RECEIVABLES_AGING = "COD_RECEIVABLES_AGING";

// group by
export const BY_WAREHOUSE = "BY_WAREHOUSE";
export const BY_SUPPLIER = "BY_SUPPLIER";
export const BY_CUSTOMER = "BY_CUSTOMER";
export const BY_DRIVER = "BY_DRIVER";

// show by
export const DESTINATION_COUNTRY = "DESTINATION_COUNTRY";
export const DESTINATION_CITY = "DESTINATION_CITY";
export const ORIGIN_COUNTRY = "ORIGIN_COUNTRY";
export const URGENCY = "URGENCY";

// filter by
export const ALL_INTERNATIONAL = "all_international";
export const ALL_DOMESTIC = "all_domestic";

export const COMPLETED = "COMPLETED";
export const RTO = "RTO";
export const AVERAGE_PER_DAY = "AVERAGE_PER_DAY";

// report by
export const BIKE = "bike";
export const SEDAN = "sedan";
export const MINIVAN = "minivan";
export const PANELVAN = "panelvan";

// drivers
export const ALL_DRIVERS = "all_drivers";
export const DEDICATED_ONLY = "dedicated_only";

// customer types
export const SOUQ_CUSTOMER = "SOUQ_CUSTOMER";
export const MARHABA_CUSTOMER = "MARHABA_CUSTOMER";
export const NON_SOUQ_CUSTOMER = "NON_SOUQ_CUSTOMER";

// calls
export const BY_SIP = "by_sip";
export const BY_TIME = "by_time";

export default OrderedSet.of(
  SHIPMENTS_PENDING,
  SHIPMENTS_DELIVERED,
  SHIPMENTS_CREATED,
  PENDING_COD_SHIPMENT,
  DRIVER_ATTENDANCE,
  DAILY_CALL_PERFORMANCE,
  SCORE_RANKING,
);
