export const DRIVER_LOCATION_CHANNEL = "/driver-location";
export const ADMIN_DRIVER_LOCATION_CHANNEL = "/admin-driver-location";
export const SUPPLIER_DRIVER_LOCATION_CHANNEL = "/supplier-driver-location";

export const DRIVER_LOCATION_UPDATED = "DRIVER_LOCATION/UPDATED";
export const ADMIN_DRIVER_LOCATION_UPDATED = "ADMIN_DRIVER_LOCATION/UPDATED";
export const SUPPLIER_DRIVER_LOCATION_UPDATED =
  "SUPPLIER_DRIVER_LOCATION/UPDATED";

export const ADMIN_DRIVER_LOCATION_QUERY = "/user/queue/locations";
export const ADMIN_DRIVER_LOCATION_QUERY_NEW = "/user/queue/driver/listing";
export const SUPPLIER_DRIVER_LOCATION_QUERY = "/user/queue/locations";
export const SUPPLIER_DRIVER_LOCATION_V2_QUERY =
  "/user/queue/supplier/locations";

export const ADMIN_RADAR_ORDER_COUNT = "/queue/admin/order/new/%s";
export const SUPPLIER_RADAR_ORDER_COUNT = "/queue/supplier/order/new/%s";
