import React from "react";
import Immutable from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import DriverAlertHistoryItem from "./DriverAlertHistoryItem";
import FlexBox from "../ui-core/FlexBox";

const enhancer = compose(
  useSheet({
    list: {
      overflowY: "auto",
    },
  }),
);

DriverAlertHistoryList.propTypes = {
  classes: PropTypes.object,

  list: PropTypes.instanceOf(Immutable.List),
  alertDetailed: PropTypes.instanceOf(Immutable.Map),

  onDismissClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

function DriverAlertHistoryList(props) {
  const { classes } = props;

  return (
    <FlexBox container={8} flex={true}>
      <FlexBox
        gutter={8}
        direction="column"
        flex={true}
        className={classes.list}
      >
        {props.list.map(item => (
          <DriverAlertHistoryItem
            key={item.get("id")}
            item={item}
            alertItemDetail={props.alertDetailed}
            onDeleteClick={props.onDeleteClick}
            onDismissClick={props.onDismissClick}
          />
        ))}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverAlertHistoryList);
