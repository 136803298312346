import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, getContext } from "recompose";
import PropTypes from "prop-types";
import { Paper, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import SuggestedDriversList from "./SuggestedDriversList";
import SuggestedDriverOnTheMapDialog from "./SuggestedDriverOnTheMapDialog";
import FlexBox from "../ui-core/FlexBox";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  newButtonColor1,
  newButtonColor1Hover,
} from "../../../shared/theme/main-theme";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const enhancer = compose(
  withState("isLoading", "setIsLoading", false),
  useSheet({
    container: {
      flex: "1 1 0%",
      display: "flex",
      flexDirection: "column",
      background: "#fff",
      minHeight: "480px",
    },
    row: {
      flex: "1 1 0%",
      display: "flex",
      flexDirection: "row",
      "& > div": { flex: "1 1 0%", display: "flex" },
    },
    colMargin: { margin: "6px" },

    contentCard: {
      flex: "1 1 0%",
      color: "#575757",
      display: "flex",
      padding: "12px",
      overflowY: "auto",
      flexDirection: "column",
      fontFamily: "Blogger Sans",
    },

    detailsTitle: {
      display: "block",
      fontWeight: "bold",
      textAlign: "center",
      textTransform: "uppercase",
    },

    itemTitle: { fontSize: "12px" },

    attachment: {
      marginRight: "-15px",
      border: `1px solid rgba(0, 0, 0, 0.26)`,
    },

    paymentPrices: { textTransform: "uppercase", marginTop: "12px" },

    serviceType: { color: "#bbc200" },

    listActionRoot: { zIndex: 20, marginTop: "-5px" },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  connect(null, { showErrorMessage, showSuccessMessage }),
);

OrderJobSuggestedDrivers.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map).isRequired,
  driverList: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  setLocationQuery: PropTypes.func,

  location: PropTypes.object,

  handleSubmit: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  onFilterChange: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onRequestAssign: PropTypes.func,
  setIsLoading: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

function OrderJobSuggestedDrivers(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.colMargin}>
          <FlexBox
            flex={true}
            justify="center"
            element={<Paper />}
            direction="column"
          >
            <SuggestedDriversList
              list={props.driverList}
              isLoading={props.isLoading}
              onFilterChange={props.onFilterChange}
              totalCount={0}
              onRequestAssign={props.onRequestAssign}
            />

            <FlexBox element={<CardActions />} justify="flex-end">
              <Button
                hoverColor={newButtonColor1Hover}
                backgroundColor={newButtonColor1}
                labelStyle={{ color: "white" }}
                onClick={() =>
                  props.setLocationQuery(fp.unset("assign_driver"))
                }
              >
                {getLocalisationMessage("close", "Close")}
              </Button>
            </FlexBox>
          </FlexBox>
          <SuggestedDriverOnTheMapDialog
            driverId={fp.toFinite(props.location.query.driver_id)}
            data={props.location.query.data}
            order={props.order}
            onRequestClose={() => {
              props.setLocationQuery(
                fp.flow(fp.unset("driver_id"), fp.unset("data")),
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default enhancer(OrderJobSuggestedDrivers);
