import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import SelectField from "../deprecated/SelectField";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import VehicleTypes from "../../constants/VehicleTypes";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    showErrorMessage,
    showSuccessMessage,
    isRTL: getIsRTL(state),
  })),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const getVehicleResponseStream = propsStream
      .distinctUntilKeyChanged("vehicleId")
      .filter(props => Boolean(props.vehicleId > 0 && props.getVehicle))
      .switchMap(props =>
        props
          .getVehicle(props.vehicleId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(getVehicleResponseStream, (props, vehicleResponse) => ({
        ...props,
        onRequestRefresh,
        vehicle: vehicleResponse.get("payload"),
        isLoading: vehicleResponse.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

VehicleChangeType.propTypes = {
  vehicleId: PropTypes.number,
  vehicle: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  getVehicle: PropTypes.func.isRequired,
  updateVehicle: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
  isRTL: PropTypes.bool,
};

function VehicleChangeType(props) {
  const { getLocalisationMessage, isRTL } = props;
  return (
    <FlexBox flex={true}>
      <PageLoading isLoading={props.isLoading} />

      <SelectField
        fullWidth={true}
        label={getLocalisationMessage("vehicle_type", "Vehicle Type")}
        disabled={props.vehicleId === 0}
        options={VehicleTypes}
        formatOption={x => getLocalisationMessage(x) || formatText(x)}
        value={props.vehicle.get("type")}
        menuItemStyle={{ textAlign: isRTL ? "right" : "left" }}
        iconStyle={{
          left: !isRTL ? "auto" : "0",
          right: isRTL ? "auto" : "0",
        }}
        labelStyle={{
          paddingLeft: !isRTL ? "0" : "56px",
          paddingRight: isRTL ? "0" : "56px",
          textAlign: isRTL ? "right" : "left",
        }}
        floatingLabelStyle={{
          right: isRTL ? "0" : "auto",
        }}
        onChange={type =>
          props
            .updateVehicle(props.vehicleId, {
              ...props.vehicle.toJS(),
              type,
            })
            .then(() => {
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_changed",
                  "Successfully changed",
                ),
              );
              props.onRequestRefresh();
            })
            .catch(props.showErrorMessage)
        }
      />
    </FlexBox>
  );
}

export default enhancer(VehicleChangeType);
