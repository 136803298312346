import { Set } from "immutable";
import { combineReducers } from "redux-immutable";
import { injectReducer } from "../../shared/helpers/ReducerContext";

const CLEAR_DRIVER_LIABILITY_QUEUE =
  "DRIVER_LIABILITY/CLEAR_DRIVER_LIABILITY_QUEUE";

const UPDATE_DRIVER_LIABILITY_QUEUE =
  "DRIVER_LIABILITY/UPDATE_DRIVER_LIABILITY_QUEUE";

const selector = injectReducer(
  "driverLiability",
  combineReducers({
    selectedItems: (state = Set(), { type, payload }) => {
      switch (type) {
        case CLEAR_DRIVER_LIABILITY_QUEUE:
          return state.clear();

        case UPDATE_DRIVER_LIABILITY_QUEUE:
          return state.clear().union(payload);

        default:
          return state;
      }
    },
  }),
);

export function getDriverLiabilityQueue(state) {
  return selector(state).get("selectedItems");
}

export const clearDriverLiabilityQueue = () => ({
  payload: [],
  type: CLEAR_DRIVER_LIABILITY_QUEUE,
});

export const updateDriverLiabilityQueue = numbers => ({
  payload: numbers,
  type: UPDATE_DRIVER_LIABILITY_QUEUE,
});
