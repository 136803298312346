import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import FlexBox from "../ui-core/FlexBox";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { primary1 } from "../../../shared/theme/main-theme";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

export const ORDERS_TAB = "brief";
export const HISTORY_TAB = "history";
export const MAP_TAB = "map";
export const SIGNATURE_TAB = "signature";

const enhancer = compose(
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      showErrorMessage,
      showSuccessMessage,
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
    };
  }),
  useSheet({
    root: {
      display: "flex",
      padding: "0",
      background: "white",
      position: "absolute",
      zIndex: "10",
      width: "100%",
    },

    tab: {
      fontSize: "14px",
      padding: "25px 25px 22px 25px",
      color: grey[900],
      border: "none",
      outline: "none",
      width: "100%",
      cursor: "pointer",
      borderBottom: `3px solid ${grey[100]}`,
      background: "none",
      fontFamily: "Blogger Sans",
      textTransform: "uppercase",
      transition: `color 250ms ease`,
      "&:hover": {
        color: primary1,
        textDecoration: "none",
        borderBottom: `3px solid ${primary1}`,
      },
    },

    tabActive: { color: primary1, borderBottom: `3px solid ${primary1}` },
  }),
);

OrderDetailsDialogTabs.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
  onTabChange: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
  onEditCostsClick: PropTypes.func,
  withoutCourierTab: PropTypes.bool,
  withoutCustomerTab: PropTypes.bool,
  withoutSignaturesTab: PropTypes.bool,
  onCancelOrderClick: PropTypes.func,
  isOrderClosed: PropTypes.bool,
  tab: PropTypes.oneOf([ORDERS_TAB, HISTORY_TAB, MAP_TAB, SIGNATURE_TAB]),
  withCODHistory: PropTypes.bool,
  withoutMap: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  isAdminViewer: PropTypes.bool,
};

function OrderDetailsDialogTabs(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox className={classes.root}>
      <FlexBox flex={true}>
        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(ORDERS_TAB)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === ORDERS_TAB,
          })}
        >
          {getLocalisationMessage("orders")}
        </button>
      </FlexBox>
      <FlexBox flex={true}>
        <button
          disabled={props.disabled}
          onClick={() => props.onTabChange(HISTORY_TAB)}
          className={cx(classes.tab, {
            [classes.tabActive]: props.tab === HISTORY_TAB,
          })}
        >
          {getLocalisationMessage("history")}
        </button>
      </FlexBox>

      {!props.withoutMap && (
        <FlexBox flex={true}>
          <button
            disabled={props.disabled}
            onClick={() => props.onTabChange(MAP_TAB)}
            className={cx(classes.tab, {
              [classes.tabActive]: props.tab === MAP_TAB,
            })}
          >
            {getLocalisationMessage("map")}
          </button>
        </FlexBox>
      )}

      {!props.withoutSignaturesTab && (
        <FlexBox flex={true}>
          <button
            disabled={props.disabled}
            onClick={() => props.onTabChange(SIGNATURE_TAB)}
            className={cx(classes.tab, {
              [classes.tabActive]: props.tab === SIGNATURE_TAB,
            })}
          >
            {getLocalisationMessage("signatures")}
          </button>
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(OrderDetailsDialogTabs);
