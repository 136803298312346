import React from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import PriceWrapper from "../ui-core/PriceWrapper";
import DescriptionTerm from "../ui-core/DescriptionTerm";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: { "& > div": { display: "flex", flex: 1 } },
    totals: { textAlign: "center", padding: "4px 0" },
    withoutMargin: { margin: 0 },
  }),
);

DriverLiabilityCODPendingTotal.propTypes = {
  liabilityTotals: PropTypes.instanceOf(Map),
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function DriverLiabilityCODPendingTotal(props) {
  const { classes, getLocalisationMessage } = props;
  return (
    Boolean(props.liabilityTotals && props.liabilityTotals.size) > 0 && (
      <FlexBox gutter={8} flex={true} className={classes.totals}>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={`${getLocalisationMessage(
              "liability_cod",
              "Liability COD",
            )} :`}
            contentClassName={classes.withoutMargin}
          >
            <PriceWrapper
              price={props.liabilityTotals.getIn(["deliveredCod", "total"])}
              code={props.liabilityTotals.getIn([
                "deliveredCod",
                "currency",
                "code",
              ])}
            />
          </DescriptionTerm>
        </FlexBox>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={`${getLocalisationMessage(
              "order_item_value",
              "Order Item Value",
            )}:`}
            contentClassName={classes.withoutMargin}
          >
            <PriceWrapper
              price={props.liabilityTotals.getIn(["openValue", "total"])}
              code={props.liabilityTotals.getIn([
                "openValue",
                "currency",
                "code",
              ])}
            />
          </DescriptionTerm>
        </FlexBox>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={`${getLocalisationMessage(
              "delivery_charge_liability_in_pickup",
              "Delivery Charge Liability in Pickup",
            )}:`}
            contentClassName={classes.withoutMargin}
          >
            <PriceWrapper
              price={props.liabilityTotals.getIn([
                "pickedUpPickupCollectible",
                "total",
              ])}
              code={props.liabilityTotals.getIn([
                "pickedUpPickupCollectible",
                "currency",
                "code",
              ])}
            />
          </DescriptionTerm>
        </FlexBox>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={`${getLocalisationMessage(
              "delivery_charge_liability_in_delivery",
              "Delivery Charge Liability in Delivery",
            )}:`}
            contentClassName={classes.withoutMargin}
          >
            <PriceWrapper
              price={props.liabilityTotals.getIn([
                "deliveredPickupCollectible",
                "total",
              ])}
              code={props.liabilityTotals.getIn([
                "deliveredPickupCollectible",
                "currency",
                "code",
              ])}
            />
          </DescriptionTerm>
        </FlexBox>
        <FlexBox flex={true} justify="center">
          <DescriptionTerm
            title={`${getLocalisationMessage(
              "total_liability",
              "Total Liability",
            )}:`}
            contentClassName={classes.withoutMargin}
          >
            <PriceWrapper
              price={props.liabilityTotals.getIn([
                "totalCollectibleLiability",
                "total",
              ])}
              code={props.liabilityTotals.getIn([
                "totalCollectibleLiability",
                "currency",
                "code",
              ])}
            />
          </DescriptionTerm>
        </FlexBox>
      </FlexBox>
    )
  );
}

export default enhancer(DriverLiabilityCODPendingTotal);
