import React from "react";
import { Set, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapProps, withContext, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormCheckbox from "../form/FormCheckbox";
import FormChipInput from "../form/FormChipInput";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormAutoComplete from "../form/FormAutoComplete";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { formatText } from "../../helpers/FormatUtils";
import { formatOrderStatusCodeForLocalisation } from "../../helpers/OrderHelper";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getUser } from "../../reducers/ProfileReducer";
import { isEnableEventDateAtBatchUpdate } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  ARRIVED,
  ACCEPTED,
  PICKED_UP,
  ON_HIS_WAY,
  UNASSIGNED,
  PARTIAL_COMPLETE,
  ASSIGNED_TO_COURIER,
} from "../../constants/OrderStatusCodes";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const formatOrderStatus = (status, getLocalisationMessage) => {
  switch (status) {
    case PICKED_UP:
      return getLocalisationMessage("completed", "Completed");

    case ON_HIS_WAY:
      return getLocalisationMessage("on_the_way", "On the Way");

    default:
      return formatOrderStatusCodeForLocalisation(
        status,
        getLocalisationMessage,
      );
  }
};

const jobStatusOptions = OrderedSet.of(
  UNASSIGNED,
  ACCEPTED,
  ASSIGNED_TO_COURIER,
  ON_HIS_WAY,
  ARRIVED,
  PICKED_UP,
  PARTIAL_COMPLETE,
);

const enhancer = compose(
  connect((state) => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      showErrorMessage,
      showSuccessMessage,
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      isEnableEventDateAtBatchUpdate: isEnableEventDateAtBatchUpdate(state),
    };
  }),
  renderIf("open"),
  withTheme,
  useSheet({
    rootStyle: {
      "& h3": {
        border: "0",
        backgroundColor: (props) => props.theme.palette.primary1Color,
      },
    },
  }),
  withHandlers({
    onSubmit:
      (props) =>
      ({ driver, supplier, ...restValues }) => {
        const values = { ...restValues };

        if (isValidObjectId(driver)) {
          values.driver = driver;
        }

        if (isValidObjectId(supplier)) {
          values.supplier = supplier;
        }

        return props.onSubmit(values);
      },
  }),
  reduxForm({
    form: "BatchUpdateJobDialog",
    validate: (values, props) => ({
      orderNumbers:
        fp.isEmpty(values.orderNumbers) &&
        props.getLocalisationMessage("add_orders", "Add Orders"),
      orderStatus:
        fp.isEmpty(values.orderStatus) &&
        props.getLocalisationMessage("select_status", "Select Status"),
    }),
  }),
  formValues({
    supplier: "supplier",
    orderStatus: "orderStatus",
    orderNumbers: "orderNumbers",
    supplierDriverOnly: "supplierDriverOnly",
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,

      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,

      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
    },
    (props) => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,

      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,

      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
    }),
  ),
  mapProps((props) => ({
    ...props,
  })),
);

BatchUpdateJobDialog.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  supplier: PropTypes.object,
  orderStatus: PropTypes.string,
  orderNumbers: PropTypes.array,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,

  getCachedDriver: PropTypes.func,
  getDriverPredictions: PropTypes.func,

  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,

  getCachedWarehouse: PropTypes.func,
  getWarehousePredictions: PropTypes.func,

  privacyTypes: PropTypes.instanceOf(Set),

  getLocalisationMessage: PropTypes.func,
  isAdminViewer: PropTypes.bool,
  isEnableEventDateAtBatchUpdate: PropTypes.bool,
  theme: PropTypes.object,
};

function BatchUpdateJobDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      open={props.open}
      className={classes.rootStyle}
      onClose={props.onRequestClose}
    >
      <DialogTitle>
        {`${getLocalisationMessage("batch_update", "Batch Update")} ${fp.size(
          props.orderNumbers,
        )} ${getLocalisationMessage("jobs", "jobs")} `}
      </DialogTitle>
      <DialogContent>
        <PageLoading isLoading={props.submitting} />
        <form>
          <FormChipInput
            name="orderNumbers"
            focus={true}
            fullWidth={true}
            addOnBlur={true}
            floatingLabelText={getLocalisationMessage("jobs", "Jobs")}
          />

          <FormAutoComplete
            name="orderStatus"
            fullWidth={true}
            floatingLabelText={getLocalisationMessage("status")}
            options={jobStatusOptions}
            formatOption={(x) => formatOrderStatus(x, getLocalisationMessage)}
            hintText={getLocalisationMessage(
              "type_to_search",
              "Type to search ...",
            )}
          />

          {Boolean(
            props.getCachedSupplier &&
              props.orderStatus === ASSIGNED_TO_COURIER,
          ) && (
            <FormSupplierAutoComplete
              name="supplier"
              fullWidth={true}
              floatingLabelText={getLocalisationMessage("supplier")}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
            />
          )}

          {Boolean(
            props.orderStatus === ACCEPTED || props.orderStatus === PICKED_UP,
          ) && (
            <FormDriverAutoComplete
              name="driver"
              fullWidth={true}
              floatingLabelText={getLocalisationMessage("driver")}
              hintText={getLocalisationMessage(
                "type_to_search",
                "Type to search ...",
              )}
            />
          )}

          <FormTextField
            name="note"
            rows={4}
            multiLine={true}
            fullWidth={true}
            label={getLocalisationMessage("note_content")}
          />

          {props.privacyTypes && (
            <FormSelectField
              name="privacy"
              autoWidth={true}
              fullWidth={true}
              formatOption={(x) => getLocalisationMessage(x, formatText(x))}
              floatingLabelText={getLocalisationMessage("privacy")}
              options={props.privacyTypes}
            />
          )}

          {props.isEnableEventDateAtBatchUpdate && (
            <FormDateField
              name="eventDate"
              floatingLabelText={getLocalisationMessage("event_date")}
            />
          )}

          {Boolean(
            props.orderStatus === ACCEPTED ||
              props.orderStatus === ASSIGNED_TO_COURIER,
          ) && (
            <FormCheckbox
              name="keepSupplier"
              label={getLocalisationMessage("keep_supplier")}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button primary={true} onClick={props.onRequestClose}>
            {getLocalisationMessage("cancel", "Cancel")}
          </Button>
          <Button primary={true} onClick={props.handleSubmit}>
            {getLocalisationMessage("ok", "OK")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchUpdateJobDialog);
