import { Map } from "immutable";
import { combineReducers } from "redux-immutable";
import { getItem, createAsyncItemReducer } from "../helpers/ReducerFactory";
import { injectReducer } from "../../shared/helpers/ReducerContext";

export const FETCH_WAREHOUSE = "WAREHOUSE/FETCH_WAREHOUSE";

const selector = injectReducer(
  "warehouse",
  combineReducers({ item: createAsyncItemReducer(FETCH_WAREHOUSE) }),
);

export function getWarehouse(state, id): Map {
  return getItem(selector(state).get("item"), id);
}
