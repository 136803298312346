import fp from "lodash/fp";
import { toSnakeCase } from "./CaseMapper";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toCustomerFeedbacksFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    from_date: queryTypes.date,
    to_date: queryTypes.date,

    from_date_time: queryTypes.dateTime,
    to_date_time: queryTypes.dateTime,

    scores: queryTypes.floatSeq,

    cod_status: queryTypes.stringSeq,
    courier_types: queryTypes.stringSeq,
    payment_type: queryTypes.stringSeq,

    ids: queryTypes.IDSeq,
    customer_ids: queryTypes.IDSeq,
    exclude_customer_ids: queryTypes.IDSeq,
    driver_ids: queryTypes.IDSeq,
    exclude_driver_ids: queryTypes.IDSeq,
    supplier_ids: queryTypes.IDSeq,
    exclude_supplier_ids: queryTypes.IDSeq,
    company_ids: queryTypes.IDSeq,
    warehouse_ids: queryTypes.IDSeq,
    issued_by_ids: queryTypes.IDSeq,
    exclude_company_ids: queryTypes.IDSeq,
    parent_supplier_ids: queryTypes.IDSeq,
    marketplaceIds: queryTypes.IDSeq,

    marketplaceId: types.ID,
    jobId: types.ID,
    last_mile_driver_id: types.ID,
    customer_id: types.ID,
    driver_id: types.ID,
    company_id: types.ID,
    supplier_id: types.ID,
    warehouse_id: types.ID,
    destination_warehouse_id: types.ID,
    from_city_id: types.ID,
    to_city_id: types.ID,

    include_exception: types.boolean,
    include_dw: types.boolean,
    simple: types.boolean,
    requires_attention: types.boolean,
    scheduled_for_cancellation: types.boolean,
    auto_cancelled: types.boolean,

    use_solr: types.booleanOr(false),

    courier_type: types.string,
    eventType: types.string,
    eventTarget: types.string,
    entityType: types.string,
    entityID: types.string,
    search_type: types.string,

    score: types.ID,
    creater_rule_type: types.string,
  }),
  toSnakeCase,
);
