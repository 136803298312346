import { Map, Set, fromJS, Record } from "immutable";
import { injectReducer } from "../../shared/helpers/ReducerContext";

export const FETCH_ORDER_LIST = "OrderListStore/FETCH_ORDER_LIST";
const FETCH_ORDER_LIST_PENDING = `${FETCH_ORDER_LIST}_PENDING`;
const FETCH_ORDER_LIST_REJECTED = `${FETCH_ORDER_LIST}_REJECTED`;
const FETCH_ORDER_LIST_FULFILLED = `${FETCH_ORDER_LIST}_FULFILLED`;

export function createHash(request) {
  return Map(request).hashCode();
}

export default class OrderListStore extends Record(
  { list: Map(), listLoading: Set(), shortItemMap: Map() },
  "OrderListStore",
) {
  getShortItem(id) {
    return this.getIn(["shortItemMap", id]);
  }

  getList(filter) {
    return this.getIn(["list", createHash(filter)]);
  }

  getListValue(filter) {
    return this.getIn(["list", createHash(filter), "list"]);
  }

  getListTotal(filter) {
    return this.getIn(["list", createHash(filter), "total"]);
  }

  isListLoading(filter) {
    return this.hasIn(["listLoading", createHash(filter)]);
  }
}

injectReducer("OrderListStore", (state = new OrderListStore(), action) => {
  const { meta, payload } = action;

  switch (action.type) {
    case FETCH_ORDER_LIST_PENDING:
      return state.update("listLoading", item => item.add(meta.hashCode));

    case FETCH_ORDER_LIST_FULFILLED: {
      const list = fromJS(payload.data);
      const shortItemMap = state.get("shortItemMap").withMutations(map => {
        list.get("list").forEach(item => {
          map.set(item.get("id"), item);
        });
      });

      return state
        .set("shortItemMap", shortItemMap)
        .update("list", item => item.set(meta.hashCode, list))
        .update("listLoading", item => item.delete(meta.hashCode));
    }

    case FETCH_ORDER_LIST_REJECTED:
      return state
        .update("list", item => item.delete(meta.hashCode))
        .update("listLoading", item => item.delete(meta.hashCode));

    default:
      return state;
  }
});
