import React from "react";
import Immutable from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { HighlightOff } from "@material-ui/icons";
import DriverAlertInfo from "./DriverAlertInfo";
import DriverAvatarMultiLine from "./DriverAvatarMultiLine";
import FlexBox from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: { padding: "12px 24px" },
    ordersCount: { color: "#fff", fontSize: "16px" },
    column: { width: "250px" },
  }),
);

DriverAlertHistoryInfo.propTypes = {
  classes: PropTypes.object,
  onCloseClick: PropTypes.func,

  alerts: PropTypes.instanceOf(Immutable.Map),
  alertDetailed: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func,
};

function DriverAlertHistoryInfo(props) {
  const { classes, getLocalisationMessage } = props;

  const NA = getLocalisationMessage("na", "N/A");

  return (
    <FlexBox container={24} flex={true} className={classes.container}>
      <FlexBox gutter={24} flex={true} align="center">
        <FlexBox className={classes.column}>
          {props.alerts.size > 0 && (
            <DriverAvatarMultiLine
              open={true}
              isHistory={true}
              vehicleType={props.alerts.get("vehicle_type")}
              driverPhoto={props.alerts.get("driver_photo")}
              firstLine={props.alerts.get("driver_name") || NA}
              secondLine={props.alerts.get("supplier_name")}
              thirdLine={props.alerts.get("driver_phone")}
            />
          )}
        </FlexBox>

        <FlexBox flex={true} justify="center">
          {props.alertDetailed.size > 0 && (
            <DriverAlertInfo alertDetailed={props.alertDetailed} />
          )}
        </FlexBox>

        <FlexBox justify="flex-end" className={classes.column}>
          <FlexBox gutter={24}>
            <FlexBox
              className={classes.ordersCount}
              align="center"
            >{`${props.alerts.get("orders") || 0} ${getLocalisationMessage(
              "_orders",
              "orders",
            )}`}</FlexBox>

            <FlexBox>
              <IconButton onClick={props.onCloseClick}>
                <HighlightOff color="#fff" />
              </IconButton>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverAlertHistoryInfo);
