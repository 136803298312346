import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import Portal from "react-overlays/lib/Portal";
import { accent1 } from "../../../shared/theme/main-theme";

const enhancer = compose(
  useSheet({
    root: {
      zIndex: 3020,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.54)",
    },
    body: { overflow: "hidden" },
    linearProgress: {
      color: "#fff",
      position: "absolute",
      left: "50%",
      top: "50%",
      maxWidth: "256px",
      minWidth: "256px",
      textAlign: "center",
      transform: "translate(-50%, -50%)",
    },
  }),
);

PageProgressOverlay.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  progress: PropTypes.number,
  children: PropTypes.node,
};

function PageProgressOverlay(props) {
  if (!props.open) {
    return null;
  }

  return (
    <Portal>
      <div className={props.classes.root}>
        <div className={props.classes.linearProgress}>
          {props.children}

          <LinearProgress
            value={props.progress}
            variant="determinate"
            color={accent1}
          />
        </div>
      </div>
    </Portal>
  );
}

export default enhancer(PageProgressOverlay);
