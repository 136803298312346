import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { Avatar, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import UserAvatar from "../avatars/UserAvatar";
import FlexBox from "../ui-core/FlexBox";
import {
  formatOrderStatusCodeForLocalisation,
  jobStatusColor,
} from "../../helpers/OrderHelper";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import MenuItemForm from "../../components/ui-core/MenuItemForm";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import { headerInfoColor } from "../../../shared/theme/main-theme";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { CREATE_ORDER_JOB_MANIFEST_URL } from "../../../shared/constants/FileProxyControllerConstants";
import OrderIdIcon from "../order-job/assets/order_id_icon.svg";
import { hasRole } from "../../helpers/RoleUtils";

const enhancer = compose(
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      statusMessages: getStatusLocalisation(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
    };
  }),
  useSheet({
    root: {
      flex: "1 1 0%",
      height: "64px",
      padding: "12px",
      display: "flex",
      flexDirection: "column",

      color: "#fff",

      "& a": {
        color: "#fff",
      },
    },

    panel: {
      display: "flex",
      flexDirection: "row",
      "& > div": {
        flex: "1 1 0%",
        fontFamily: "Blogger Sans",
      },
    },

    createdDate: {
      width: "32px",
      height: "32px",
      margin: "4px",
      fill: "rgba(0, 0, 0, 0.54)",
    },

    informerTextBlock: {
      display: "flex",
      lineHeight: 1.2,
      marginLeft: "8px",
      flexDirection: "column",
      justifyContent: "center",
      textTransform: "uppercase",
    },

    informerSecondaryText: {
      fontSize: "10px",
      textTransform: "normal",
      color: headerInfoColor,
      paddingRight: "10px",
    },

    shipment: { textAlign: "right" },
    customer: { display: "flex", marginTop: "20px", flexDirection: "row" },
    customerTextBlock: {
      marginLeft: "12px",
      lineHeight: "15px",
      paddingTop: "4px",
      textTransform: "uppercase",
    },
    customerTitle: { color: "#7e8092", fontSize: "10px" },

    courier: {
      display: "flex",
      marginTop: "0",
      textAlign: "right",
      flexDirection: "row-reverse",
    },

    status: {
      display: "flex",
      textAlign: "right",
      flexDirection: "row-reverse",
      margin: "0",
      padding: "0",
      height: "30px",
      marginTop: "5px",
      textTransform: "uppercase",
      borderRadius: "5px",
      float: "left",
    },

    courierTextBlock: {
      paddingTop: "4px",
      lineHeight: "15px",
      marginRight: "12px",
      textTransform: "uppercase",
    },
    courierTitle: { color: "#7e8092", fontSize: "10px" },
    toCourier: { textAlign: "center", marginTop: "20px", lineHeight: "18px" },
    toCourierTime: { fontSize: "12px" },
  }),
);

const BATCH_JOB_ORDERS_UPDATE_DIALOG_HASH = "#BJOUDH";
const BATCH_JOB_ORDER_RESCHEDULE_DIALOG_HASH = "#BJORDH";

OrderJobDetailsDialogHeader.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map).isRequired,
  replaceLocationHash: PropTypes.func,
  setLocationQuery: PropTypes.func,
  onJobEditClick: PropTypes.func,

  isAdminViewer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  statusMessages: PropTypes.instanceOf(Map),
};

function OrderJobDetailsDialogHeader(props) {
  const { classes, order, getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na");

  return (
    <div className={classes.root}>
      <div className={classes.panel}>
        <FlexBox>
          <Avatar
            size={40}
            style={{
              backgroundColor: "#fff",
            }}
            src={OrderIdIcon}
          />

          <div className={classes.informerTextBlock}>
            <div>
              {getLocalisationMessage("job")}: #
              {order.get("order_number") || NA}
            </div>
            <div className={classes.informerSecondaryText}>
              {order.get("type") || NA}
            </div>
          </div>
        </FlexBox>

        <FlexBox>
          <div style={{ height: "40px", width: "40px" }}>
            <UserAvatar
              size={40}
              name={order.getIn(["customer", "name"])}
              src={order.getIn(["customer", "photo"])}
            />
          </div>
          <div className={classes.informerTextBlock}>
            <div>{order.getIn(["customer", "name"])}</div>
            <div className={classes.informerSecondaryText}>
              <span style={{ textTransform: "normal" }}>
                {order.getIn(["locations", 1, "address"])}
              </span>
            </div>
          </div>
        </FlexBox>

        {order.get("driver") ? (
          <FlexBox>
            <div style={{ height: "40px", width: "40px" }}>
              <UserAvatar
                size={40}
                name={order.getIn(["driver", "name"])}
                src={order.getIn(["driver", "photo"])}
              />
            </div>
            <div className={classes.informerTextBlock}>
              <div>{order.getIn(["driver", "name"])}</div>
              <div className={classes.informerSecondaryText}>
                <span style={{ textTransform: "normal" }}>
                  {order.getIn(["supplier", "name"])}
                </span>
              </div>
            </div>
          </FlexBox>
        ) : (
          order.get("supplier") && (
            <FlexBox>
              <div style={{ height: "40px", width: "40px" }}>
                <UserAvatar
                  size={40}
                  name={order.getIn(["supplier", "name"])}
                  src={order.getIn(["supplier", "photo"])}
                />
              </div>
              <div className={classes.informerTextBlock}>
                <div>{order.getIn(["supplier", "name"])}</div>
              </div>
            </FlexBox>
          )
        )}

        <FlexBox>
          <div
            style={{ width: "100%", paddingRight: "20px", paddingLeft: "40px" }}
          >
            <div
              className={classes.status}
              style={{ backgroundColor: jobStatusColor(order.get("status")) }}
            >
              {formatOrderStatusCodeForLocalisation(
                order.get("status"),
                getLocalisationMessage,
              )}
            </div>
          </div>
        </FlexBox>

        {!props.isAdminViewer && (
          <MenuButtonMore
            style={{
              textAlign: "right",
              position: "absolute",
              right: "0",
              top: "7px",
            }}
            color="#fff"
          >
            <MenuItem
              onClick={() =>
                props.replaceLocationHash(BATCH_JOB_ORDERS_UPDATE_DIALOG_HASH)
              }
            >
              {getLocalisationMessage("batch_update")}
            </MenuItem>
            <MenuItem onClick={props.onJobEditClick}>
              {getLocalisationMessage("edit_job")}
            </MenuItem>
            <MenuItem
              onClick={() =>
                props.replaceLocationHash(
                  BATCH_JOB_ORDER_RESCHEDULE_DIALOG_HASH,
                )
              }
            >
              {getLocalisationMessage("reschedule")}
            </MenuItem>
            <MenuItem
              onClick={() =>
                props.setLocationQuery(fp.set("assign_driver", true))
              }
            >
              {getLocalisationMessage("driver_suggestion")}
            </MenuItem>
            <MenuItemForm
              primaryText={getLocalisationMessage("print_manifest")}
              url={CREATE_ORDER_JOB_MANIFEST_URL}
              params={{
                order_numbers: [order.get("order_number")],
              }}
            />
            <MenuItem onClick={() => props.replaceLocationHash("OJDDC")}>
              {getLocalisationMessage("download_csv")}
            </MenuItem>
          </MenuButtonMore>
        )}
      </div>
    </div>
  );
}

export default enhancer(OrderJobDetailsDialogHeader);
