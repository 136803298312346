import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  sortableHandle,
  sortableElement,
  sortableContainer,
} from "react-sortable-hoc";
import { Menu } from "@material-ui/icons";

const SortableListHandle = sortableHandle(Menu);

const itemEnhancer = compose(
  useSheet({
    root: { zIndex: 1500 },
    item: { paddingLeft: "56px" },
    icon: { fill: "rgba(0, 0, 0, 0.26)", cursor: "row-resize" },
  }),
  sortableElement,
);

export const SortableListItem = itemEnhancer(
  ({ sheet, classes, rightIcon, rightIconButton, nestedItems, ...props }) => (
    <div>
      <ListItem button={true} style={props.style} className={classes.root}>
        <ListItemIcon>
          <SortableListHandle className={classes.icon} />
        </ListItemIcon>

        <ListItemText {...props} innerDivStyle={{ paddingLeft: "56px" }}>
          {" "}
          {props.primaryText}{" "}
        </ListItemText>
        {rightIcon}
        {rightIconButton}
      </ListItem>
      {nestedItems}
    </div>
  ),
);

SortableListItem.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  primaryText: PropTypes.node,
  rightAvatar: PropTypes.element,
  rightIcon: PropTypes.element,
  rightIconButton: PropTypes.element,
  secondaryText: PropTypes.node,
  secondaryTextLines: PropTypes.oneOf([1, 2]),
  style: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

const SortableList = sortableContainer(props => (
  <List>
    {React.Children.map(props.children, (child, index) =>
      React.cloneElement(child, { index }),
    )}
  </List>
));

SortableList.propTypes = {
  lockAxis: PropTypes.bool,
  onSortEnd: PropTypes.func,
  onSortStart: PropTypes.func,
  listItemClassName: PropTypes.string,
};

SortableList.defaultProps = {
  ...SortableList.defaultProps,
  lockAxis: true,
  useDragHandle: true,
};

export default SortableList;
