import _ from "lodash";
import WingApi from "../helpers/WingApi";
import { BATCH_ASSIGN_ORDER_LAST_MILE_DRIVER } from "../reducers/OrderReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const ORDER_LIST_URL = `${API_ROOT_URL}/admin/orders`;
const ORDER_BATCH_LAST_MILE_DRIVER_ASSIGN_URL = `${
  ORDER_LIST_URL
}/update_last_mile_driver`;
const FILE_TEMPLATE = `${API_ROOT_URL}/file/template`;

export function batchAssignOrderLastMileDriver(items) {
  return (dispatch, getState, api: WingApi) =>
    dispatch({
      meta: { orderIds: _.map(items, "order_id") },
      type: BATCH_ASSIGN_ORDER_LAST_MILE_DRIVER,
      payload: api.put(ORDER_BATCH_LAST_MILE_DRIVER_ASSIGN_URL, {
        body: { items },
      }),
    });
}

export function uploadCSVTemplate(fileType) {
  return (dispatch, getState, api) => file =>
    api
      .postStream(FILE_TEMPLATE, { file, params: { fileType } })
      .map(({ progress, pending, payload }) => ({
        progress,
        pending,
        payload: _.get(payload, "data.url", null),
      }));
}
