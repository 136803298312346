import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, Map, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withContext, withState } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import { ContactPhone } from "@material-ui/icons";
import FormCheckbox from "../form/FormCheckbox";
import FormDateField from "../form/FormDateField";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import FormImageListUpload from "../form/FormImageListUploadV2";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormCountryAutoComplete from "../form/FormCountryAutoComplete";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FormSupplierAutoComplete from "../form/FormSupplierAutoComplete";
import ManualSetOrderLocationDialog from "../form/ManualSetOrderLocationDialog";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import PriceWrapper from "../ui-core/PriceWrapper";
import AdminAddressBookSelectDialogPickup from "../address-book-core/AdminAddressBookSelectDialogPickup";
import AdminAddressBookSelectDialogDropOff from "../address-book-core/AdminAddressBookSelectDialogDropOff";
import {
  getValue,
  isEmpty,
  isEqualData,
  isEqualDataIn,
  isShallowEqual,
  toArray,
  toPlainObject,
} from "../../helpers/DataUtils";
import { isValidDate, safeParseDate } from "../../helpers/DateUtils";
import {
  formatDateToUrl,
  formatText,
  parseOnlyPositiveFloat,
  parsePhone,
} from "../../helpers/FormatUtils";
import {
  formatLocalisedPaymentType,
  formatOrderStatusCodeForLocalisation,
  shouldDisablePayer,
  shouldDisableRecipientNotAvailable,
} from "../../helpers/OrderHelper";
import { pipeStreams } from "../../helpers/StreamUtils";
import {
  isPositive,
  isValidCoordinates,
  isValidObjectId,
  validatePackage,
  validatePackageMenu,
  validatePaymentType,
  validatePhoneNumberForLocalisation,
  validateStringForLocalisation,
} from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { geocode } from "../../helpers/GoogleMapsHelper";
import { getUser } from "../../reducers/ProfileReducer";
import {
  getMarketplace as getStateMarketplace,
  isCustomMarketplace,
  showMarketplaceAlternatePhoneNumbers,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { DROP, PICKUP } from "../../constants/TimeSlotType";
import OrderPayerTypes, {
  RECIPIENT,
  SENDER,
} from "../../constants/OrderPayerTypes";
import { CREDIT_BALANCE } from "../../constants/OrderPaymentTypes";
import { ON_DUTY } from "../../constants/DriverDutyStatuses";
import {
  COD,
  SERVICE,
  SERVICE_CUSTOM,
} from "../../constants/OrderChargeItemTypes";
import OrderRecipientNotAvailableActionTypes, {
  DO_NOT_DELIVER,
} from "../../constants/OrderRecipientNotAvailableActionTypes";
import { getCountries } from "../../api/shared/CountryApi";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import { getPackageMenu } from "../../api/shared/PackageMenuApi";
import { getPackagePrices } from "../../api/shared/PackagePricesApi";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const trueFalseOptions = List.of(true, false);

const getValues = getFormValues("OrderForm");

const getCountryCode = fp.flow(
  fp.first,
  fp.get("address_components"),
  fp.find(fp.flow(fp.get("types"), fp.includes("country"))),
  fp.get("short_name"),
);

const validateLocation = (location, country, getLocalisationMessage) =>
  !isValidCoordinates(location)
    ? getLocalisationMessage(
        "type_address_above_to_search",
        "Type address above to search",
      )
    : getValue(location, "country") !== getValue(country, "code") &&
      getLocalisationMessage(
        "please_select_address_with_selected_country",
        "Please select address with selected country",
      );

const mapResponseDataList = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      list: response.getIn(["payload", "data", "list"], List()),
    }),
);

const isValidCOD = fp.overSome([fp.isNil, isPositive]);

const enhancer = compose(
  useSheet({
    addressInfo: {
      marginTop: "15px",
      width: "100%",
      maxHeight: "57px",
      overflow: "overlay",
    },
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
        showMarketplaceAlternatePhoneNumbers: showMarketplaceAlternatePhoneNumbers(
          state,
        ),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  withState("state", "setState", {
    showPickUpAddressDialog: false,
    showDropoffAddressDialog: false,
  }),

  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  connect(state => ({
    isCustom: isCustomMarketplace(state),
    orderReferenceIdVisibility: getStateMarketplace(state).getIn(
      ["setting", "settings", "orderReferenceIdVisibility"],
      false,
    ),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .pluck("order")
      .distinctUntilChanged(isEqualData)
      .combineLatest(getCountries().distinctUntilChanged(isEqualData))
      .switchMap(([order, countries]) => {
        if (isEmpty(order)) {
          const marketplaceStream = getMarketplace()
            .takeLast(1)
            .catch(() => Observable.of({}))
            .map(fp.flow(fp.get("payload.data"), fp.toPlainObject, fromJS));

          return marketplaceStream.map(
            fp.flow(
              item => item.getIn(["country", "id"]),
              countryId => {
                const country = countries.find(c => c.get("id") === countryId);

                return country || countries.find(c => c.get("code") === "AE");
              },
              country =>
                Map({ pickupCountry: country, dropoffCountry: country }),
            ),
          );
        }

        const pickupCountryCode = order.getIn([
          "package",
          "from_country",
          "description",
        ]);
        const dropoffCountryCode = order.getIn([
          "package",
          "to_country",
          "description",
        ]);
        if (Boolean(pickupCountryCode) && Boolean(dropoffCountryCode)) {
          const pickupCountry = countries.find(
            c => c.get("code") === pickupCountryCode,
          );
          const dropoffCountry = countries.find(
            c => c.get("code") === dropoffCountryCode,
          );

          return Observable.of(order.merge({ pickupCountry, dropoffCountry }));
        }

        return Observable.combineLatest(
          geocode({
            location: {
              lat: order.getIn(["locations", 0, "lat"]),
              lng: order.getIn(["locations", 0, "lon"]),
            },
          }),
          geocode({
            location: {
              lat: order.getIn(["locations", 1, "lat"]),
              lng: order.getIn(["locations", 1, "lon"]),
            },
          }),
          (pickup, dropoff) => {
            const pickupCountry = countries.find(
              c => c.get("code") === getCountryCode(pickup),
            );
            const dropoffCountry = countries.find(
              c => c.get("code") === getCountryCode(dropoff),
            );

            return order.merge({ pickupCountry, dropoffCountry });
          },
        );
      })
      .distinctUntilChanged(isEqualData)
      .map(order => {
        const chargeItems = order.get("charge_items")
          ? order
              .get("charge_items")
              .toMap()
              .mapEntries(([, v]) => [v.get("charge_type"), v])
          : Map();

        return {
          id: order.get("id"),
          reference_id: order.get("reference_id"),
          customer: toPlainObject(order.get("customer")),
          supplier: toPlainObject(order.get("supplier")),
          driver: toPlainObject(order.get("driver")),

          package: order.get("package"),
          menu: order.get("menu"),
          attachments: toArray(order.get("attachments")),

          note: order.get("note"),
          payer: order.get("payer"),
          status: order.get("status"),
          pieceCount: order.get("piece_count", 1),
          parcelValue: order.get("parcel_value"),
          fragile: order.get("fragile"),
          paymentType: order.get("payment_type"),
          recipientNotAvailable: order.get("recipient_not_available"),

          codCharge: chargeItems.getIn([COD, "charge"], null),
          codChargePaid: chargeItems.getIn([COD, "paid"], false),

          serviceCharge: chargeItems.getIn([SERVICE, "charge"], null),
          serviceChargePaid: chargeItems.getIn([SERVICE, "paid"], false),

          pickupCountry: order.get("pickupCountry"),
          pickupDetails: order.getIn(["locations", 0, "details"]),
          pickupContactName: order.getIn(["locations", 0, "contact_name"]),
          pickupContactPhone: order.getIn(["locations", 0, "phone"]),
          pickupContactEmail: order.getIn(["locations", 0, "email"]),
          pickupSecondPhone: order.getIn(["locations", 0, "second_phone"]),
          pickupThirdPhone: order.getIn(["locations", 0, "third_phone"]),
          pickupLocation: {
            lat: order.getIn(["locations", 0, "lat"]),
            lng: order.getIn(["locations", 0, "lon"]),
            country: order.getIn(["pickupCountry", "code"]),
            address: order.getIn(["locations", 0, "address"]),
          },
          pickupContactSave: false,

          dropoffCountry: order.get("dropoffCountry"),
          dropoffDetails: order.getIn(["locations", 1, "details"]),
          dropoffContactName: order.getIn(["locations", 1, "contact_name"]),
          dropoffContactPhone: order.getIn(["locations", 1, "phone"]),
          dropoffContactEmail: order.getIn(["locations", 1, "email"]),
          dropoffSecondPhone: order.getIn(["locations", 1, "second_phone"]),
          dropoffThirdPhone: order.getIn(["locations", 1, "third_phone"]),
          dropoffLocation: {
            lat: order.getIn(["locations", 1, "lat"]),
            lng: order.getIn(["locations", 1, "lon"]),
            country: order.getIn(["dropoffCountry", "code"]),
            address: order.getIn(["locations", 1, "address"]),
          },
          dropoffContactSave: false,

          pickupTimeSlot: order.get("pickup_timeslot"),
          estimatedPickupTime: safeParseDate(
            order.get("estimated_pickup_time"),
          ),

          deliveryTimeSlot: order.get("delivery_timeslot"),
          estimatedDeliveryTime: safeParseDate(
            order.get("estimated_delivery_time"),
          ),
        };
      });

    const handleSubmit = props => values => {
      if (!props.onSubmit) {
        return null;
      }

      const pickupTimeSlotId =
        values.pickupTimeSlot &&
        values.pickupTimeSlot.get("timeslot_availability_id");
      const deliveryTimeSlotId =
        values.deliveryTimeSlot &&
        values.deliveryTimeSlot.get("timeslot_availability_id");

      return props.onSubmit({
        pickup_time_now: !pickupTimeSlotId, // legacy api

        id: values.id,
        reference_id: values.reference_id,

        customer: isValidObjectId(values.customer) ? values.customer : null,
        supplier: isValidObjectId(values.supplier) ? values.supplier : null,
        driver: isValidObjectId(values.driver) ? values.driver : null,

        package: values.package,

        attachments: values.attachments,

        note: values.note,
        payer: values.payer,
        status: values.status,
        piece_count: values.pieceCount || 1,
        parcel_value: values.parcelValue,
        fragile: values.fragile,
        payment_type: values.paymentType,
        recipient_not_available: values.recipientNotAvailable,

        charge_items: [
          {
            charge_type: COD,
            charge: values.codCharge,
            paid: values.codChargePaid,
          },
          {
            charge_type: SERVICE_CUSTOM,
            charge: values.serviceCharge,
            paid: values.serviceChargePaid,
          },
        ],

        locations: [
          {
            pickup: true,
            lat: values.pickupLocation.lat,
            lon: values.pickupLocation.lng,
            address: values.pickupLocation.address || values.pickupDetails,
            details: values.pickupDetails,
            phone: values.pickupContactPhone,
            email: values.pickupContactEmail,
            contact_name: values.pickupContactName,
            second_phone: values.pickupSecondPhone,
            third_phone: values.pickupThirdPhone,
            save: values.pickupContactSave,
          },
          {
            pickup: false,
            lat: values.dropoffLocation.lat,
            lon: values.dropoffLocation.lng,
            address: values.dropoffLocation.address || values.dropoffDetails,
            details: values.dropoffDetails,
            phone: values.dropoffContactPhone,
            email: values.dropoffContactEmail,
            contact_name: values.dropoffContactName,
            second_phone: values.dropoffSecondPhone,
            third_phone: values.dropoffThirdPhone,
            save: values.dropoffContactSave,
          },
        ],

        pickup_timeslot_id: pickupTimeSlotId || null,
        estimated_pickup_time: pickupTimeSlotId
          ? values.estimatedPickupTime
          : null,
        delivery_timeslot_id: deliveryTimeSlotId || null,
        estimated_delivery_time: deliveryTimeSlotId
          ? values.estimatedDeliveryTime
          : null,
      });
    };

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
        onSubmit: handleSubmit(props),
      }))
      .distinctUntilChanged(isShallowEqual);
  }),
  reduxForm({
    form: "OrderForm",
    enableReinitialize: true,

    validate: (values, props) => ({
      pickupCountry:
        !getValue(values.pickupCountry, "id") &&
        props.getLocalisationMessage(
          "please_select_pickup_country",
          "Please select pickup country",
        ),
      pickupLocation: validateLocation(
        values.pickupLocation,
        values.pickupCountry,
        props.getLocalisationMessage,
      ),
      pickupDetails: validateStringForLocalisation(
        values.pickupDetails,
        props.getLocalisationMessage("enter_suite", "Enter suite"),
        props.getLocalisationMessage,
      ),
      pickupContactName: validateStringForLocalisation(
        values.pickupContactName,
        props.getLocalisationMessage(
          "please_enter_sender_name",
          "Please enter sender name",
        ),
        props.getLocalisationMessage,
      ),
      pickupContactPhone: validatePhoneNumberForLocalisation(
        values.pickupContactPhone,
        props.getLocalisationMessage,
      ),
      customer:
        !isValidObjectId(values.customer) &&
        props.getLocalisationMessage(
          "customer_is_required",
          "Customer is required",
        ),
      paymentType:
        validatePaymentType(values.paymentType) &&
        props.getLocalisationMessage(
          "payment_type_is_required",
          "Payment Type is required",
        ),
      package:
        validatePackage(values.package) &&
        props.getLocalisationMessage(
          "package_is_required",
          "Package is required",
        ),
      menu:
        validatePackageMenu(values.menu) &&
        props.getLocalisationMessage(
          "this_field_is_required",
          "This field is required",
        ),

      dropoffCountry:
        !getValue(values.dropoffCountry, "id") &&
        props.getLocalisationMessage(
          "please_select_dropoff_country",
          "Please select dropoff country",
        ),
      dropoffLocation: validateLocation(
        values.dropoffLocation,
        values.dropoffCountry,
        props.getLocalisationMessage,
      ),
      dropoffDetails: validateStringForLocalisation(
        values.dropoffDetails,
        props.getLocalisationMessage("enter_suite", "Enter suite"),
        props.getLocalisationMessage,
      ),
      dropoffContactName: validateStringForLocalisation(
        values.dropoffContactName,
        props.getLocalisationMessage(
          "please_enter_recipient_name",
          "Please enter recipient name",
        ),
        props.getLocalisationMessage,
      ),
      dropoffContactPhone: validatePhoneNumberForLocalisation(
        values.dropoffContactPhone,
        props.getLocalisationMessage,
      ),

      codCharge:
        !isValidCOD(values.codCharge) &&
        props.getLocalisationMessage(
          "please_enter_valid_price",
          "Please enter valid price",
        ),
      parcelValue:
        !isValidCOD(values.parcelValue) &&
        props.getLocalisationMessage(
          "please_enter_valid_price",
          "Please enter valid price",
        ),

      pieceCount:
        values.pieceCount > 100 &&
        props.getLocalisationMessage(
          "please_enter_valid_piece_count",
          "Please enter valid piece Count",
        ),

      serviceCharge:
        !isPositive(values.serviceCharge) &&
        props.getLocalisationMessage(
          "please_enter_valid_price",
          "Please enter valid price",
        ),
    }),
  }),
  connect(
    fp.flow(getValues, fp.toPlainObject, values => ({
      values: {
        ...values,
        menuId: values.menu && values.menu.get("menu_id"),
        packageId: values.package && values.package.get("id"),
        customerId: values.customer && values.customer.id,
        validLocations:
          isValidCoordinates(values.pickupLocation) &&
          isValidCoordinates(values.dropoffLocation),
      },
    })),
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const packageMenuStream = propsStream
          .map(
            props =>
              new DataListFilter({
                from_country_id: getValue(props.values.pickupCountry, "id"),
                to_country_id: getValue(props.values.dropoffCountry, "id"),
              }),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getPackageMenu(filter).catch(() => Observable.of({})),
          )
          .startWith({})
          .map(response =>
            fromJS(response).getIn(["payload", "data", "list"], List()),
          )
          .distinctUntilChanged(isEqualData);

        const packagePricesStream = propsStream
          .map(
            fp.flow(
              fp.get("values"),
              fp.pick([
                "menuId",
                "customer",
                "validLocations",
                "pickupLocation",
                "dropoffLocation",
              ]),
            ),
          )
          .distinctUntilChanged(isEqualData)
          .map(values =>
            values.menuId > 0 &&
            values.validLocations &&
            isValidObjectId(values.customer)
              ? new DataListFilter({
                  menu_id: values.menuId,
                  customer_id: values.customer.id,
                  from_lat: values.pickupLocation.lat,
                  from_lon: values.pickupLocation.lng,
                  to_lat: values.dropoffLocation.lat,
                  to_lon: values.dropoffLocation.lng,
                })
              : null,
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            filter
              ? getPackagePrices(filter).catch(() => Observable.of({}))
              : Observable.of({}),
          )
          .startWith({})
          .map(mapResponseDataList)
          .map(
            fp.flow(
              response => response.get("list"),
              packagePrices =>
                packagePrices.reduce(
                  (acc, price) =>
                    acc.concat(
                      price
                        .get("packages")
                        .map(item =>
                          item.set("vehicleName", price.get("vehicleName")),
                        ),
                    ),
                  List(),
                ),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const pickupTimeSlotsStream = propsStream
          .map(props => ({
            getTimeSlots: props.getTimeSlots,
            filter:
              props.values.packageId > 0 &&
              props.values.validLocations &&
              isValidDate(props.values.estimatedPickupTime)
                ? new DataListFilter({
                    package_id: props.values.packageId,
                    timeslot_type: PICKUP,
                    lat: props.values.pickupLocation.lat,
                    lng: props.values.pickupLocation.lng,
                    date: formatDateToUrl(props.values.estimatedPickupTime),
                  })
                : null,
          }))
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            props.filter
              ? props.getTimeSlots(props.filter).catch(() => Observable.of({}))
              : Observable.of({}),
          )
          .startWith({})
          .map(response =>
            fromJS(response).getIn(["payload", "data", "list"], List()),
          )
          .distinctUntilChanged(isEqualData);

        const deliveryTimeSlotsStream = propsStream
          .map(props => ({
            getTimeSlots: props.getTimeSlots,
            filter:
              props.values.packageId > 0 &&
              props.values.validLocations &&
              isValidDate(props.values.estimatedDeliveryTime)
                ? new DataListFilter({
                    timeslot_type: DROP,
                    package_id: props.values.packageId,
                    lat: props.values.dropoffLocation.lat,
                    lng: props.values.dropoffLocation.lng,
                    date: formatDateToUrl(props.values.estimatedDeliveryTime),
                  })
                : null,
          }))
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            props.filter
              ? props.getTimeSlots(props.filter).catch(() => Observable.of({}))
              : Observable.of({}),
          )
          .startWith({})
          .map(response =>
            fromJS(response).getIn(["payload", "data", "list"], List()),
          )
          .distinctUntilChanged(isEqualData);

        const paymentTypesStream = propsStream
          .map(props => ({
            customerId: props.values.customerId,
            getPaymentMethods: props.getPaymentMethods,
            filter:
              props.values.packageId > 0 &&
              props.values.validLocations &&
              props.values.customerId
                ? fromJS({
                    package_id: props.values.packageId,
                    locations: [
                      {
                        pickup: true,
                        lat: props.values.pickupLocation.lat,
                        lon: props.values.pickupLocation.lng,
                      },
                      {
                        pickup: false,
                        lat: props.values.dropoffLocation.lat,
                        lon: props.values.dropoffLocation.lng,
                      },
                    ],
                  })
                : null,
          }))
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props =>
            props.filter
              ? props
                  .getPaymentMethods(props.customerId, props.filter)
                  .catch(() => Observable.of({}))
              : Observable.of({}),
          )
          .startWith({})
          .map(response => fromJS(response).get("data", List()))
          .distinctUntilChanged(isEqualData);

        const sideEffectsStream = Observable.merge(
          propsStream
            .distinctUntilChanged(
              fp.overEvery([
                isEqualDataIn("values.package"),
                isEqualDataIn("values.paymentType"),
              ]),
            )
            .filter(fp.get("dirty"))
            .do(props => {
              const serviceCharge = getValue(
                props.values.package,
                ["price", "total"],
                0,
              );

              if (props.values.serviceCharge !== serviceCharge) {
                props.change("serviceCharge", serviceCharge);
              }

              if (
                props.values.serviceChargePaid !==
                props.initialValues.serviceChargePaid
              ) {
                props.change(
                  "serviceChargePaid",
                  props.initialValues.serviceChargePaid,
                );
              }
            }),
          propsStream
            .map(
              fp.flow(
                fp.pick(["dirty", "change", "values"]),
                fp.update(
                  "values",
                  fp.pick([
                    "codCharge",
                    "payer",
                    "paymentType",
                    "recipientNotAvailable",
                    "customer",
                  ]),
                ),
              ),
            )
            .distinctUntilChanged(isEqualDataIn("values"))
            .filter(fp.get("dirty"))
            .do(props => {
              const {
                codCharge,
                payer,
                paymentType,
                recipientNotAvailable,
                customer,
              } = props.values;

              if (customer && customer.description) {
                props.change("pickupContactEmail", customer.description);
              }

              if (2 === 3 && payer !== SENDER) {
                props.change("payer", SENDER);
              } else if (
                paymentType === CREDIT_BALANCE &&
                payer !== RECIPIENT
              ) {
                if (props.isCustomMarketplace) props.change("payer", RECIPIENT);
                else props.change("payer", SENDER);
              } else if (
                (payer === RECIPIENT || codCharge > 0) &&
                recipientNotAvailable !== DO_NOT_DELIVER
              ) {
                props.change("recipientNotAvailable", DO_NOT_DELIVER);
              }
            }),
        )
          .mapTo(null)
          .startWith(null)
          .distinctUntilChanged();

        return propsStream
          .map(
            fp.flow(
              fp.pick([
                "change",
                "values",
                "classes",
                "orderReferenceIdVisibility",
                "showMarketplaceAlternatePhoneNumbers",
                "dirty",
                "pristine",
                "submitting",
                "reset",
                "onDismiss",
                "handleSubmit",
                "getTimeSlots",
                "getPaymentMethods",
                "getCachedDriver",
                "getDriverPredictions",
                "getCachedSupplier",
                "getSupplierPredictions",
                "getCachedCustomer",
                "getCustomerPredictions",
                "orderStatusCodes",
                "getLocalisationMessage",
                "state",
                "setState",
                "touch",
              ]),
              fp.update(
                "values",
                fp.pick([
                  "supplier",
                  "payer",
                  "paymentType",
                  "pickupCountry",
                  "pickupLocation",
                  "dropoffCountry",
                  "dropoffLocation",
                  "reference_id",
                ]),
              ),
            ),
          )
          .combineLatest(
            packageMenuStream,
            paymentTypesStream,
            packagePricesStream,
            pickupTimeSlotsStream,
            deliveryTimeSlotsStream,
            sideEffectsStream,
            (
              props,
              packageMenu,
              paymentTypes,
              packagePrices,
              pickupTimeSlots,
              deliveryTimeSlots,
            ) => ({
              ...props,
              packageMenu,
              paymentTypes,
              packagePrices,
              pickupTimeSlots,
              deliveryTimeSlots,
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .filter(
              props =>
                !props.packageMenu.isEmpty() && props.packageMenu.size === 1,
            )
            .take(1)
            .do(props => {
              const item = props.packageMenu.get(0);

              if (item) {
                props.change("menu", item);
              }
            }),
          propsStream
            .filter(
              props =>
                !props.packagePrices.isEmpty() &&
                props.packagePrices.size === 1,
            )
            .take(1)
            .do(props => {
              const item = props.packagePrices.get(0);

              if (item) {
                props.change("package", item);
              }
            }),
        ).startWith(null);

        return propsStream
          .combineLatest(sideEffectsStream, fp.identity)
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

OrderForm.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  reset: PropTypes.func,
  dirty: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  packageMenu: PropTypes.instanceOf(List),
  paymentTypes: PropTypes.instanceOf(List),
  packagePrices: PropTypes.instanceOf(List),
  pickupTimeSlots: PropTypes.instanceOf(List),
  deliveryTimeSlots: PropTypes.instanceOf(List),
  orderStatusCodes: PropTypes.instanceOf(OrderedSet),

  state: PropTypes.object,
  setState: PropTypes.func,
  change: PropTypes.func,
  touch: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onDismiss: PropTypes.func,
  order: PropTypes.instanceOf(Map),
  getTimeSlots: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  getCachedDriver: PropTypes.func.isRequired,
  getDriverPredictions: PropTypes.func.isRequired,
  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  getCachedCustomer: PropTypes.func,
  getCustomerPredictions: PropTypes.func,
  isCustomMarketplace: PropTypes.bool,
  orderReferenceIdVisibility: PropTypes.bool,
  showMarketplaceAlternatePhoneNumbers: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderForm(props) {
  const { values, getLocalisationMessage, classes } = props;

  const supplierId = getValue(values.supplier, "id");
  const pickupCountryCode = getValue(values.pickupCountry, "code");
  const dropoffCountryCode = getValue(values.dropoffCountry, "code");
  const pickupAddress = getValue(values.pickupLocation, "address");
  const dropoffAddress = getValue(values.dropoffLocation, "address");

  const disablePayer = shouldDisablePayer(values.paymentType);
  const disableRecipientNotAvailable = shouldDisableRecipientNotAvailable(
    values.payer,
    values.paymentType,
  );

  return (
    <FlexBox
      align="center"
      container={16}
      direction="column"
      element={<form id="OrderForm" onSubmit={props.handleSubmit} />}
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox
        flex="none"
        gutter={8}
        direction="column"
        style={{ width: "768px" }}
      >
        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8}>
                <FlexBox direction="column" flex={true}>
                  <FormCustomerAutoComplete
                    name="customer"
                    label={`${getLocalisationMessage(
                      "customer",
                      "Customer",
                    )} *`}
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                </FlexBox>

                <FlexBox direction="column" flex={true}>
                  <FormSupplierAutoComplete
                    name="supplier"
                    label={getLocalisationMessage("supplier", "Supplier")}
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                </FlexBox>

                <FlexBox direction="column" flex={true}>
                  <FormDriverAutoComplete
                    name="driver"
                    disabled={!supplierId}
                    fullWidth={true}
                    supplierId={supplierId}
                    label={getLocalisationMessage("driver", "Driver")}
                    dutyStatus={ON_DUTY}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FormSelectField
                name="status"
                maxHeight={256}
                autoWidth={true}
                fullWidth={true}
                formatOption={
                  x =>
                    formatOrderStatusCodeForLocalisation(
                      x,
                      getLocalisationMessage,
                    )

                  // formatText((statusMessages && statusMessages.get(x)) || x)
                }
                label={getLocalisationMessage("status", "Status")}
                options={props.orderStatusCodes}
              />
            </CardContent>
          </Card>
        </FlexBox>

        {props.orderReferenceIdVisibility && (
          <FlexBox direction="column">
            <Card>
              <CardContent>
                <FormTextField
                  name="reference_id"
                  autoWidth={true}
                  fullWidth={true}
                  label={getLocalisationMessage("reference_id", "Reference ID")}
                />
              </CardContent>
            </Card>
          </FlexBox>
        )}

        <FlexBox gutter={8}>
          <FlexBox style={{ width: "50%" }}>
            <Card>
              <CardContent>
                <FlexBox>
                  <FormTextField
                    name="pickupContactName"
                    label={`${getLocalisationMessage(
                      "sender_name",
                      "Sender Name",
                    )} *`}
                    fullWidth={true}
                  />

                  <IconButton
                    onClick={() =>
                      props.setState(fp.set("showPickUpAddressDialog", true))
                    }
                  >
                    <ContactPhone />
                  </IconButton>
                </FlexBox>

                <AdminAddressBookSelectDialogPickup
                  open={props.state.showPickUpAddressDialog}
                  onRequestClose={() =>
                    props.setState(fp.set("showPickUpAddressDialog", false))
                  }
                  onSubmit={details => {
                    props.setState(fp.set("showPickUpAddressDialog", false));

                    props.change("pickupLocation", {
                      country: pickupCountryCode,
                      lat: details.address.lat,
                      lng: details.address.lon,
                      address: details.address.address,
                    });
                    props.change("pickupDetails", details.address.details);
                    props.change("pickupContactPhone", details.address.phone);
                    props.change(
                      "pickupContactName",
                      details.address.contact_name,
                    );
                    props.change("pickupContactEmail", details.address.email);
                  }}
                />

                <FormCountryAutoComplete
                  readOnly={true}
                  fullWidth={true}
                  openOnFocus={false}
                  name="pickupCountry"
                  label={`${getLocalisationMessage(
                    "pickup_country",
                    "Pickup Country",
                  )} *`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                />

                {Boolean(pickupCountryCode) && (
                  <FlexBox>
                    {!isValidCoordinates(values.pickupLocation) ? (
                      <FormGeoAutoComplete
                        fullWidth={true}
                        name="pickupLocation"
                        countryCode={pickupCountryCode}
                        label={`${getLocalisationMessage(
                          "pickup_location",
                          "Pickup Location",
                        )} *`}
                        hintText={getLocalisationMessage(
                          "type_to_search_3_letters",
                          "Type to Search (3 letters minimum)",
                        )}
                      />
                    ) : (
                      <div className={classes.addressInfo}>
                        <strong>
                          {getLocalisationMessage(
                            "pickup_address",
                            "Pickup Address",
                          )}
                          :
                        </strong>
                        <br />
                        {pickupAddress}
                      </div>
                    )}
                    <ManualSetOrderLocationDialog
                      fieldName="pickupLocation"
                      location={values.pickupLocation}
                      change={props.change}
                      countryCode={pickupCountryCode}
                    />
                  </FlexBox>
                )}

                <FormTextField
                  name="pickupDetails"
                  label={`${getLocalisationMessage(
                    "pickup_details",
                    "Pickup Details",
                  )} *`}
                  fullWidth={true}
                />

                <FormTextField
                  name="pickupContactPhone"
                  label={`${getLocalisationMessage(
                    "sender_phone",
                    "Sender Phone",
                  )} *`}
                  fullWidth={true}
                />

                {props.showMarketplaceAlternatePhoneNumbers && (
                  <div>
                    <FormTextField
                      parse={parsePhone}
                      name="pickupSecondPhone"
                      fullWidth={true}
                      label={`${getLocalisationMessage(
                        "alternate_phone1",
                        "Alternate Phone 1",
                      )}`}
                    />

                    <FormTextField
                      parse={parsePhone}
                      name="pickupThirdPhone"
                      fullWidth={true}
                      label={`${getLocalisationMessage(
                        "alternate_phone2",
                        "Alternate Phone 2",
                      )}`}
                    />
                  </div>
                )}

                <FormTextField
                  name="pickupContactEmail"
                  label={getLocalisationMessage("sender_email", "Sender Email")}
                  fullWidth={true}
                />

                <FormCheckbox
                  name="pickupContactSave"
                  label={getLocalisationMessage(
                    "save_to_address_book",
                    "Save To Address Book",
                  )}
                />
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox style={{ width: "50%" }}>
            <Card>
              <CardContent>
                <FlexBox>
                  <FormTextField
                    name="dropoffContactName"
                    label={`${getLocalisationMessage(
                      "recipient_name",
                      "Recipient Name",
                    )} *`}
                    fullWidth={true}
                  />

                  <IconButton
                    onClick={() =>
                      props.setState(fp.set("showDropoffAddressDialog", true))
                    }
                  >
                    <ContactPhone />
                  </IconButton>
                </FlexBox>

                <AdminAddressBookSelectDialogDropOff
                  open={props.state.showDropoffAddressDialog}
                  onRequestClose={() =>
                    props.setState(fp.set("showDropoffAddressDialog", false))
                  }
                  onSubmit={details => {
                    props.setState(fp.set("showDropoffAddressDialog", false));

                    props.change("dropoffLocation", {
                      country: dropoffCountryCode,
                      lat: details.address.lat,
                      lng: details.address.lon,
                      address: details.address.address,
                    });
                    props.change("dropoffDetails", details.address.details);
                    props.change("dropoffContactPhone", details.address.phone);
                    props.change(
                      "dropoffContactName",
                      details.address.contact_name,
                    );
                    props.change("dropoffContactEmail", details.address.email);
                  }}
                />

                <FormCountryAutoComplete
                  fullWidth={true}
                  name="dropoffCountry"
                  label={`${getLocalisationMessage(
                    "dropoff_country",
                    "Dropoff Country",
                  )} *`}
                  hintText={getLocalisationMessage(
                    "type_to_search",
                    "Type To Search...",
                  )}
                />

                {Boolean(dropoffCountryCode) && (
                  <FlexBox>
                    {!isValidCoordinates(values.dropoffLocation) ? (
                      <FormGeoAutoComplete
                        fullWidth={true}
                        name="dropoffLocation"
                        countryCode={dropoffCountryCode}
                        label={`${getLocalisationMessage(
                          "dropoff_location",
                          "Dropoff Location",
                        )} *`}
                        hintText={getLocalisationMessage(
                          "type_to_search_3_letters",
                          "Type to Search (3 letters minimum)",
                        )}
                      />
                    ) : (
                      <div className={classes.addressInfo}>
                        <strong>
                          {getLocalisationMessage(
                            "dropoff_address",
                            "Dropoff Address",
                          )}
                          :
                        </strong>
                        <br />
                        {dropoffAddress}
                      </div>
                    )}

                    <ManualSetOrderLocationDialog
                      fieldName="dropoffLocation"
                      location={values.dropoffLocation}
                      change={props.change}
                      countryCode={dropoffCountryCode}
                    />
                  </FlexBox>
                )}

                <FormTextField
                  name="dropoffDetails"
                  label={`${getLocalisationMessage(
                    "dropoff_details",
                    "Dropoff Details",
                  )} *`}
                  fullWidth={true}
                />

                <FormTextField
                  name="dropoffContactPhone"
                  label={`${getLocalisationMessage(
                    "recipient_phone",
                    "Recipient Phone",
                  )} *`}
                  fullWidth={true}
                />

                {props.showMarketplaceAlternatePhoneNumbers && (
                  <div>
                    <FormTextField
                      parse={parsePhone}
                      name="dropoffSecondPhone"
                      fullWidth={true}
                      label={`${getLocalisationMessage(
                        "alternate_phone1",
                        "Alternate Phone 1",
                      )}`}
                    />

                    <FormTextField
                      parse={parsePhone}
                      name="dropoffThirdPhone"
                      fullWidth={true}
                      label={`${getLocalisationMessage(
                        "alternate_phone2",
                        "Alternate Phone 2",
                      )}`}
                    />
                  </div>
                )}

                <FormTextField
                  name="dropoffContactEmail"
                  label={getLocalisationMessage(
                    "recipient_email",
                    "Recipient Email",
                  )}
                  fullWidth={true}
                />

                <FormCheckbox
                  name="dropoffContactSave"
                  label={getLocalisationMessage(
                    "save_to_address_book",
                    "Save To Address Book",
                  )}
                />
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8}>
                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="menu"
                    autoWidth={true}
                    fullWidth={true}
                    options={props.packageMenu}
                    disabled={props.packageMenu.isEmpty()}
                    label={`${getLocalisationMessage("what", "What?")} *`}
                    formatOption={v => v.get("title")}
                    compareOptions={isEqualDataIn("menu_id")}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="fragile"
                    autoWidth={true}
                    fullWidth={true}
                    options={trueFalseOptions}
                    label={getLocalisationMessage(
                      "is_it_fragile",
                      "Is It Fragile?",
                    )}
                    formatOption={v =>
                      v
                        ? getLocalisationMessage("yes", "Yes")
                        : getLocalisationMessage("no", "No")
                    }
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="package"
                    autoWidth={true}
                    fullWidth={true}
                    label={`${getLocalisationMessage("how", "How?")} *`}
                    formatOption={item => (
                      <span>
                        {item.get("name")} - {item.get("delivery_label")} -{" "}
                        <PriceWrapper
                          price={item.getIn(["price", "total"])}
                          code={item.getIn(["price", "currency", "code"])}
                        />
                      </span>
                    )}
                    compareOptions={isEqualDataIn("id")}
                    options={props.packagePrices}
                    disabled={props.packagePrices.isEmpty()}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormDateField
                  name="estimatedPickupTime"
                  fullWidth={true}
                  label={getLocalisationMessage("pickup_date", "Pickup Date")}
                />

                <FormSelectField
                  name="pickupTimeSlot"
                  autoWidth={true}
                  fullWidth={true}
                  options={props.pickupTimeSlots}
                  label={getLocalisationMessage(
                    "pickup_timeslot",
                    "Pickup Timeslot",
                  )}
                  disabled={props.pickupTimeSlots.isEmpty()}
                  compareOptions={isEqualDataIn("timeslot_availability_id")}
                  formatInput={item => item.get("name")}
                  formatOption={item =>
                    `${item.get("name")} (${item.get(
                      "start_time",
                    )} - ${item.get("end_time")})`
                  }
                />
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FormDateField
                  name="estimatedDeliveryTime"
                  fullWidth={true}
                  label={getLocalisationMessage("dropoff_date", "Dropoff Date")}
                />

                <FormSelectField
                  name="deliveryTimeSlot"
                  autoWidth={true}
                  fullWidth={true}
                  options={props.deliveryTimeSlots}
                  label={getLocalisationMessage(
                    "dropoff_timeslot",
                    "Dropoff Timeslot",
                  )}
                  disabled={props.deliveryTimeSlots.isEmpty()}
                  compareOptions={isEqualDataIn("timeslot_availability_id")}
                  formatInput={item => item.get("name")}
                  formatOption={item =>
                    `${item.get("name")} (${item.get(
                      "start_time",
                    )} - ${item.get("end_time")})`
                  }
                />
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8} flex={true}>
                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    autoWidth={true}
                    fullWidth={true}
                    name="paymentType"
                    label={`${getLocalisationMessage(
                      "payment_type",
                      "Payment Type",
                    )} *`}
                    options={props.paymentTypes}
                    formatOption={x =>
                      formatLocalisedPaymentType(x, getLocalisationMessage)
                    }
                    disabled={props.paymentTypes.isEmpty()}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="payer"
                    autoWidth={true}
                    fullWidth={true}
                    disabled={disablePayer}
                    formatOption={x => formatText(getLocalisationMessage(x, x))}
                    options={OrderPayerTypes}
                    label={getLocalisationMessage(
                      "delivery_charge_paid_by",
                      "Delivery Charge Paid By",
                    )}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column">
                  <FormSelectField
                    name="recipientNotAvailable"
                    autoWidth={true}
                    fullWidth={true}
                    disabled={disableRecipientNotAvailable}
                    formatOption={x => formatText(getLocalisationMessage(x, x))}
                    label={getLocalisationMessage(
                      "if_recipient_not_available",
                      "If Recipient not Available",
                    )}
                    options={OrderRecipientNotAvailableActionTypes}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FlexBox gutter={8} flex={true} direction="column">
                <FlexBox flex={true} direction="column">
                  <FlexBox gutter={8} flex={true}>
                    <FlexBox flex={true} direction="column">
                      <FormTextField
                        name="codCharge"
                        fullWidth={true}
                        parseOnBlur={fp.toFinite}
                        label={getLocalisationMessage(
                          "cod_amount",
                          "COD Amount",
                        )}
                      />
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <FormSelectField
                        name="codChargePaid"
                        autoWidth={true}
                        fullWidth={true}
                        label={getLocalisationMessage("cod_paid", "COD Paid")}
                        options={trueFalseOptions}
                        formatOption={v =>
                          v
                            ? getLocalisationMessage("paid", "Paid")
                            : getLocalisationMessage("not_paid", "Not paid")
                        }
                      />
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <FormTextField
                        fullWidth={true}
                        name="serviceCharge"
                        parseOnBlur={fp.toFinite}
                        label={`${getLocalisationMessage(
                          "service_charge",
                          "Service Charge",
                        )} *`}
                      />
                    </FlexBox>

                    <FlexBox flex={true} direction="column">
                      <FormSelectField
                        name="serviceChargePaid"
                        autoWidth={true}
                        fullWidth={true}
                        label={getLocalisationMessage(
                          "service_charge_paid",
                          "Service Charge Paid",
                        )}
                        options={trueFalseOptions}
                        formatOption={v =>
                          v
                            ? getLocalisationMessage("paid", "Paid")
                            : getLocalisationMessage("not_paid", "Not paid")
                        }
                      />
                    </FlexBox>
                  </FlexBox>

                  <FlexBox gutter={8} flex={true}>
                    <FlexBox direction="column">
                      <FormTextField
                        name="parcelValue"
                        label={getLocalisationMessage(
                          "item_value",
                          "Item Value",
                        )}
                        parseOnBlur={parseOnlyPositiveFloat}
                        fullWidth={true}
                      />
                    </FlexBox>

                    <FlexBox direction="column">
                      <FormTextField
                        name="pieceCount"
                        label={getLocalisationMessage(
                          "piece_count",
                          "Piece Count",
                        )}
                        fullWidth={true}
                        type="number"
                      />
                    </FlexBox>
                  </FlexBox>

                  <FormTextField
                    name="note"
                    rowsMax={6}
                    fullWidth={true}
                    multiLine={true}
                    label={getLocalisationMessage("note", "Note")}
                  />
                </FlexBox>
              </FlexBox>
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardContent>
              <FormImageListUpload
                name="attachments"
                label={getLocalisationMessage("photos", "Photos")}
              />
            </CardContent>
          </Card>
        </FlexBox>

        <FlexBox direction="column">
          <Card>
            <CardActions>
              <FlexBox justify="flex-end">
                {props.dirty ? (
                  <Button onClick={props.reset}>
                    {getLocalisationMessage("reset", "Reset")}
                  </Button>
                ) : (
                  Boolean(props.onDismiss) && (
                    <Button onClick={props.onDismiss}>
                      {getLocalisationMessage("dismiss", "Dismiss")}
                    </Button>
                  )
                )}

                <Button type="submit">
                  {getLocalisationMessage("submit", "Submit")}
                </Button>
              </FlexBox>
            </CardActions>
          </Card>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderForm);
