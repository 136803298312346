import React, { Component } from "react";
import PropTypes from "prop-types";
import { calculateScrollPosition } from "../../helpers/calculationUtils";

export default class ReactAutoScroll extends Component {
  static propTypes = {
    isEnabled: PropTypes.bool,
    scrollTargetRef: PropTypes.any,
    targetPosition: PropTypes.number,
    updateInterval: PropTypes.number,
    onScrollingDone: PropTypes.func,
    children: PropTypes.node,
  };

  componentDidMount() {
    const willbeabletoScroll = this.canScroll(this.props);
    if (this.props.scrollTargetRef && !willbeabletoScroll) {
      // eslint-disable-next-line no-console
      console.log(
        `please check your scrollTargetRef (${
          this.props.scrollTargetRef
        }), a scrollTop can not be found!`,
      );
    } else if (this.props.isEnabled) {
      this.doScrollAction();
    } else {
      this.resetAndStopScrolling();
    }
  }

  componentDidUpdate() {
    if (this.props.isEnabled) {
      this.doScrollAction();
    } else {
      this.resetAndStopScrolling();
    }
  }

  canScroll(props) {
    if (!props.scrollTargetRef) {
      return false;
    }

    const scrollTarget = this.getScrollTargetRef(props);

    if (
      !scrollTarget ||
      scrollTarget.scrollTop === null ||
      scrollTarget.scrollTop === undefined
    ) {
      return false;
    }

    return true;
  }

  doScrollAction() {
    if (
      this.props.targetPosition >= 0 &&
      !this.isAtScrollTarget(
        this.getScrollTargetRef(this.props),
        this.props.targetPosition,
      )
    ) {
      this.isScrolling = true;

      if (this.scrollIntervalId) {
        clearInterval(this.scrollIntervalId);
      }
      this.scrollIntervalId = setInterval(
        () => this.scrollToTarget(this.props),
        this.props.updateInterval,
      );
    }
  }

  resetAndStopScrolling() {
    this.stopScrolling();
  }

  // eslint-disable-next-line class-methods-use-this
  getScrollTargetRef(props) {
    if (!props.scrollTargetRef) {
      return null;
    }

    return props.scrollTargetRef.refs
      ? props.scrollTargetRef.refs.scrollable
      : props.scrollTargetRef;
  }

  stopScrolling() {
    this.isScrolling = false;
    if (this.scrollIntervalId) {
      clearInterval(this.scrollIntervalId);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isAtScrollTarget(scrollTargetRef, targetPosition) {
    if (!scrollTargetRef || !scrollTargetRef.scrollTop) {
      return false;
    }

    return Math.abs(scrollTargetRef.scrollTop - targetPosition) <= 2;
  }

  scrollToTarget(props) {
    if (this.canScroll(props)) {
      const scrollTargetRef = this.getScrollTargetRef(props);

      const top =
        scrollTargetRef.scrollHeight -
        scrollTargetRef.scrollTop -
        scrollTargetRef.offsetHeight;

      if (top <= 0) {
        this.props.onScrollingDone();
        this.stopScrolling();
      }

      if (this.isScrolling) {
        scrollTargetRef.scrollTop = calculateScrollPosition(
          scrollTargetRef.scrollTop,
          props.targetPosition,
          props.speed,
          props.easeType,
        );

        if (this.isAtScrollTarget(scrollTargetRef, props.targetPosition)) {
          scrollTargetRef.scrollTop = props.targetPosition;
          this.props.onScrollingDone();
          this.stopScrolling();
        }
      }
    }
  }

  render() {
    return <div className="autoscrollcomponent">{this.props.children}</div>;
  }
}
