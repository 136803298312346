import React from "react";
import _ from "lodash";
import I, { Map } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox, {
  ALIGN_END,
  FLEX_GROW,
  ALIGN_CENTER,
} from "../ui-core/FlexBox";
import Checkbox from "../deprecated/Checkbox";
import TextField from "../deprecated/TextField";
import SelectField from "../deprecated/SelectField";
import { calculateClusteringData } from "../../actions/OrderClusterActions";
import { createRoutes } from "../../actions/OrderRoutingActions";
import { formatText } from "../../helpers/FormatUtils";
import { decimalFilterOptions } from "../../helpers/OrderRoutingUtils";
import { getUser as getUserReducer } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import {
  ROLE_SUPER_ADMIN,
  ROLE_MARKETPLACE_ADMIN,
  ROLE_MARKETPLACE_OWNER,
} from "../../../shared/constants/Authorities";
import { hasRole } from "../../helpers/RoleUtils";

const enhancer = compose(
  getContext({
    loadSupplierPredictions: PropTypes.func,
  }),
  connect(
    state => {
      const userRoles = getUserReducer(state).get("roles") || [];
      return {
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
        isMarketplaceAdmin:
          hasRole(userRoles, ROLE_SUPER_ADMIN) ||
          hasRole(userRoles, ROLE_MARKETPLACE_OWNER) ||
          hasRole(userRoles, ROLE_MARKETPLACE_ADMIN),
      };
    },
    {
      createRoutes,
      showErrorMessage,
      calculateClusteringData,
    },
  ),
);

class AdminOrderListMapCluster extends React.Component {
  static propTypes = {
    onOptionsChange: PropTypes.func,
    orders: PropTypes.instanceOf(Map).isRequired,

    showErrorMessage: PropTypes.func,
    createRoutes: PropTypes.func,
    calculateClusteringData: PropTypes.func,
    loadSupplierPredictions: PropTypes.func,

    clusterOptions: PropTypes.instanceOf(Map).isRequired,

    onEditWarehouseClick: PropTypes.func.isRequired,
    onEditDestinationWarehouseClick: PropTypes.func.isRequired,
    getLocalisationMessage: PropTypes.func,
    isMarketplaceAdmin: PropTypes.bool,
    ordersEstimatedRequestCount: PropTypes.number,
    ordersEstimatedRequestElements: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);

    this.state = this.createState(props);
  }

  componentWillReceiveProps(nextProps) {
    const { clusterOptions: o1 } = nextProps;
    const { clusterOptions: o2 } = this.props;

    if (!I.is(o1, o2)) {
      this.setState(this.createState(nextProps));
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.props !== nextProps;
  // }

  createState({ clusterOptions } = this.props) {
    return {
      maxDuration: clusterOptions.get("maxDuration"),
      mergeNoSupplier: clusterOptions.get("mergeNoSupplier"),
      sortingDuration: clusterOptions.get("sortingDuration"),
      handlingDuration: clusterOptions.get("handlingDuration"),
      splitByNeighborhoods: clusterOptions.get("splitByNeighborhoods"),
      groupByDistance: clusterOptions.get("groupByDistance"),
      estimateRequestCount: clusterOptions.get("estimateRequestCount"),
      isMapBox: clusterOptions.get("isMapBox"),
    };
  }

  handleSplitToClustersClick = () => {
    this.handleClusteringRequest();

    this.props.calculateClusteringData(
      this.props.clusterOptions.merge(this.state),
    );
  };

  handleSplitToClustersBetaClick = () => {
    if (
      this.props.clusterOptions.hasIn(["warehouse", "id"]) ||
      this.props.clusterOptions.hasIn(["destinationWarehouse", "id"])
    ) {
      const input = this.state;
      input.isMapBox = false;

      this.handleClusteringRequest();

      this.props.createRoutes(this.props.clusterOptions.merge(input));
    } else {
      this.props.showErrorMessage(
        this.props.getLocalisationMessage(
          "select_pickup_or_destination_warehouse",
          "Select pickup or destination warehouse.",
        ),
      );
    }
  };

  handleSplitToClustersMapBoxClick = () => {
    if (
      this.props.clusterOptions.hasIn(["warehouse", "id"]) ||
      this.props.clusterOptions.hasIn(["destinationWarehouse", "id"])
    ) {
      const input = this.state;
      input.isMapBox = true;

      this.handleClusteringRequest();

      this.props.createRoutes(this.props.clusterOptions.merge(input));
    } else {
      this.props.showErrorMessage(
        this.props.getLocalisationMessage(
          "select_pickup_or_destination_warehouse",
          "Select pickup or destination warehouse.",
        ),
      );
    }
  };

  // handleEstimateRequestRoutes = () => {
  //   if (
  //     this.props.clusterOptions.hasIn(["warehouse", "id"]) ||
  //     this.props.clusterOptions.hasIn(["destinationWarehouse", "id"])
  //   ) {
  //     this.handleClusteringRequest();
  //
  //     const estimatedRequestCount = estimateRequestRoutes(
  //       this.props.clusterOptions.merge(this.state),
  //     );
  //     this.setState({ estimatedRequestCount });
  //   } else {
  //     this.props.showErrorMessage(
  //       this.props.getLocalisationMessage(
  //         "select_pickup_or_destination_warehouse",
  //         "Select pickup or destination warehouse.",
  //       ),
  //     );
  //   }
  // };

  handleClusteringRequest() {
    this.props.onOptionsChange(this.state);
  }

  render() {
    const { clusterOptions, getLocalisationMessage } = this.props;

    const warehouse = clusterOptions.getIn(["warehouse", "name"]);
    const destinationWarehouse = clusterOptions.getIn([
      "destinationWarehouse",
      "name",
    ]);

    return (
      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true}>
            <TextField
              readOnly={true}
              fullWidth={true}
              value={warehouse}
              label={getLocalisationMessage("warehouse", "Warehouse")}
              onClick={this.props.onEditWarehouseClick}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <TextField
              readOnly={true}
              fullWidth={true}
              value={destinationWarehouse}
              label={getLocalisationMessage(
                "destination_warehouse",
                "Destination Warehouse",
              )}
              onClick={this.props.onEditDestinationWarehouseClick}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <TextField
              fullWidth={true}
              parse={_.toInteger}
              label={getLocalisationMessage(
                "max_duration_min",
                "Max Duration (min)",
              )}
              value={this.state.maxDuration}
              onChange={maxDuration => this.setState({ maxDuration })}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <TextField
              fullWidth={true}
              parse={_.toInteger}
              label={getLocalisationMessage(
                "sorting_time_min",
                "Sorting Time (min)",
              )}
              value={this.state.sortingDuration}
              onChange={sortingDuration => this.setState({ sortingDuration })}
            />
          </FlexBox>

          <FlexBox flex={true}>
            <TextField
              fullWidth={true}
              label={getLocalisationMessage(
                "handling_time_min",
                "Handling Time (min)",
              )}
              value={this.state.handlingDuration}
              onChange={handlingDuration => this.setState({ handlingDuration })}
            />
          </FlexBox>
          {false && this.props.loadSupplierPredictions && (
            <FlexBox flex={true} align="flex-end">
              <Checkbox
                label={getLocalisationMessage(
                  "merge_empty_suppliers",
                  "Merge empty suppliers",
                )}
                value={this.state.mergeNoSupplier}
                onChange={mergeNoSupplier => this.setState({ mergeNoSupplier })}
              />
            </FlexBox>
          )}
        </FlexBox>

        {this.props.isMarketplaceAdmin && (
          <FlexBox>
            <FlexBox align="flex-start">
              <SelectField
                floatingLabelText={getLocalisationMessage(
                  "merge_by_distance",
                  "Merge By Distance",
                )}
                onChange={groupByDistance => this.setState({ groupByDistance })}
                autoWidth={true}
                value={this.state.groupByDistance}
                options={decimalFilterOptions}
                formatOption={formatText}
              />
            </FlexBox>
            <FlexBox flex={FLEX_GROW} align={ALIGN_CENTER}>
              <Checkbox
                label={getLocalisationMessage(
                  "split_by_neighborhoods",
                  "Split by Neighborhoods",
                )}
                value={this.state.splitByNeighborhoods}
                onChange={splitByNeighborhoods =>
                  this.setState({ splitByNeighborhoods })
                }
              />

              <Checkbox
                label={getLocalisationMessage(
                  "estimate_request_count",
                  "Estimate Request Count",
                )}
                value={this.state.estimateRequestCount}
                onChange={estimateRequestCount =>
                  this.setState({ estimateRequestCount })
                }
              />
            </FlexBox>
          </FlexBox>
        )}

        <FlexBox justify="flex-end">
          <Button
            variant="text"
            label={getLocalisationMessage(
              "split_to_clusters",
              "Split to Clusters",
            )}
            onClick={this.handleSplitToClustersClick}
          />

          <Button
            variant="text"
            label={getLocalisationMessage(
              "split_to_clusters_beta",
              "Split to Clusters (Beta)",
            )}
            onClick={this.handleSplitToClustersBetaClick}
          />

          {this.props.isMarketplaceAdmin && (
            <Button onClick={this.handleSplitToClustersMapBoxClick}>
              {getLocalisationMessage(
                "split_to_clusters_mapbox",
                "Split to Cluster (Matrix size:25)",
              )}
            </Button>
          )}
        </FlexBox>

        {this.props.ordersEstimatedRequestCount > 0 && (
          <FlexBox align={ALIGN_END} direction="column">
            <FlexBox>
              Estimated Request Count:{" "}
              <strong>{this.props.ordersEstimatedRequestCount}</strong>
            </FlexBox>
            <FlexBox>
              Estimated Request Elements:{" "}
              <strong>{this.props.ordersEstimatedRequestElements}</strong>
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
    );
  }
}

export default enhancer(AdminOrderListMapCluster);
