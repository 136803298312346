import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  ListItem,
  ListSubheader,
  IconButton,
  List,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Edit } from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";
import PriceWrapper from "../ui-core/PriceWrapper";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { APPROVED, COMPLETED } from "../../constants/CODTransferStatus";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

CODTransferDetails.propTypes = {
  onEditRefClick: PropTypes.func,
  onEditStatusClick: PropTypes.func,
  item: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CODTransferDetails(props) {
  const { getLocalisationMessage } = props;
  return (
    <Card>
      <CardContent>
        <List>
          <ListItem>
            <ListItemText
              disabled={true}
              primary={getLocalisationMessage("status", "Status")}
              secondary={getLocalisationMessage(
                props.item.get("status"),
                formatText(props.item.get("status")),
              )}
            />
            {props.onEditStatusClick && (
              <ListItemSecondaryAction>
                <IconButton onClick={props.onEditStatusClick}>
                  <Edit />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
          <ListItem>
            <ListItemText
              disabled={true}
              primary={getLocalisationMessage("payment_ref", "Payment ref #")}
              secondary={
                getLocalisationMessage(
                  props.item.get("reference"),
                  formatText(props.item.get("reference")),
                ) || getLocalisationMessage("na", "N/A")
              }
            />
            {Boolean(
              props.item.get("status") === COMPLETED ||
                props.item.get("status") === APPROVED,
            ) &&
              fp.isEmpty(props.item.get("bank_payment_date")) &&
              props.onEditRefClick && (
                <ListItemSecondaryAction>
                  <IconButton onClick={props.onEditRefClick}>
                    <Edit />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
          </ListItem>
          <ListItem>
            <ListItemText
              disabled={true}
              primary={getLocalisationMessage("note", "Note")}
              secondaryLines={2}
              secondary={props.item.get("note") || "-"}
            />
          </ListItem>
        </List>
        <FlexBox container={8} direction="column">
          <FlexBox flex={true} direction="column">
            <ListSubheader>
              {_.toUpper(
                getLocalisationMessage("transfer_amount", "Transfer Amount"),
              )}
              :{` `}
              <PriceWrapper
                price={props.item.get("amount")}
                code={props.item.getIn(["currency", "code"])}
              />
            </ListSubheader>
          </FlexBox>
        </FlexBox>
      </CardContent>
    </Card>
  );
}

export default enhancer(CODTransferDetails);
