import React from "react";
import _ from "lodash";
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  isAfter,
  isBefore,
  isToday,
  isTomorrow,
  isYesterday,
  startOfDay,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { IconButton } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import {
  ArrowLeft as HardwareKeyboardArrowLeft,
  ArrowRight as HardwareKeyboardArrowRight,
} from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatDate } from "../../helpers/FormatUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

export const DAY_TYPE = "day";
export const WEEK_TYPE = "week";
export const MONTH_TYPE = "month";
export const YEAR_TYPE = "year";

const enhancer = compose(
  useSheet({
    button: { minWidth: "40px" },
    container: { margin: "6px 0" },
    dateContainer: { position: "relative" },
    dateTitle: {
      position: "absolute",
      fontWeight: "bold",
      top: "16px",
      width: "100%",
      textAlign: "center",
    },
    disabledDateTitle: { color: "rgba(0, 0, 0, 0.3)" },
    datePicker: { opacity: 0, "& > div": { cursor: "pointer" } },
  }),
  connect(state => ({
    i18n: getMessages(state),
  })),
);

const onChangeDate = (
  date,
  type = DAY_TYPE,
  increment = true,
  quantity = 1,
) => {
  switch (type) {
    case WEEK_TYPE:
      return increment ? addWeeks(date, quantity) : subWeeks(date, quantity);

    case MONTH_TYPE:
      return increment ? addMonths(date, quantity) : subMonths(date, quantity);

    case YEAR_TYPE:
      return increment ? addYears(date, quantity) : subYears(date, quantity);

    default:
      return increment ? addDays(date, quantity) : subDays(date, quantity);
  }
};

const formatDateTitle = (date, dateWithWords, i18n) => {
  if (dateWithWords) {
    if (isToday(date)) {
      return _.toUpper(i18n.get("today", "TODAY"));
    } else if (isYesterday(date)) {
      return _.toUpper(i18n.get("yesterday", "YESTERDAY"));
    } else if (isTomorrow(date)) {
      return _.toUpper(i18n.get("tomorrow", "TOMORROW"));
    }
  }

  return formatDate(date);
};

SwitcherDatePickerComponent.propTypes = {
  classes: PropTypes.object,

  meta: PropTypes.object,
  input: PropTypes.object,

  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  dateWithWords: PropTypes.bool,
  withoutButtons: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  rightButtonLabel: PropTypes.string,

  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  i18n: PropTypes.instanceOf(Map),
};

SwitcherDatePickerComponent.defaultProps = {
  type: DAY_TYPE,

  maxDate: addYears(new Date(), 100),
  minDate: subYears(new Date(), 100),
};

function SwitcherDatePickerComponent({ input, meta, ...props }) {
  const { classes, i18n } = props;
  const date = safeParseDate(input.value);
  const custom = _.omit(props, [
    "sheet",
    "type",
    "classes",
    "leftButtonLabel",
    "rightButtonLabel",
    "dateWithWords",
    "withoutButtons",
    "className",
  ]);
  const minDate = endOfDay(safeParseDate(custom.minDate));
  const maxDate = startOfDay(safeParseDate(custom.maxDate));

  return (
    <FlexBox
      gutter={8}
      flex={true}
      justify="space-between"
      className={cx(classes.container, props.className)}
    >
      <FlexBox align="center">
        {!props.withoutButtons && (
          <IconButton
            hoverColor="transparent"
            rippleColor="transparent"
            className={classes.button}
            disabled={!date || isBefore(date, minDate) || props.disabled}
            onClick={() =>
              input.onChange(onChangeDate(date, props.type, false))
            }
          >
            <HardwareKeyboardArrowLeft />
          </IconButton>
        )}
      </FlexBox>

      <FlexBox
        className={classes.dateContainer}
        justify="center"
        align="center"
      >
        <div
          className={cx(classes.dateTitle, {
            [classes.disabledDateTitle]: props.disabled,
          })}
        >
          {date
            ? formatDateTitle(
                date,
                Boolean(props.dateWithWords && props.type === DAY_TYPE),
                i18n,
              )
            : i18n.get("select_date", "SELECT DATE")}
        </div>
        <DatePicker
          {...input}
          {...custom}
          onBlur={fp.noop}
          onFocus={fp.noop}
          value={date}
          errorText={meta.touched && meta.error}
          onShow={fp.flow(fp.noop, input.onFocus)}
          onDismiss={fp.flow(fp.noop, input.onBlur)}
          onChange={fp.rest(
            fp.flow(fp.nth(1 /* get second argument */), input.onChange),
          )}
          className={classes.datePicker}
        />
      </FlexBox>

      <FlexBox align="center">
        {!props.withoutButtons && (
          <IconButton
            hoverColor="transparent"
            component="a"
            rippleColor="transparent"
            className={classes.button}
            disabled={!date || isAfter(date, maxDate) || props.disabled}
            onClick={() => input.onChange(onChangeDate(date, props.type))}
            labelPosition="before"
          >
            <HardwareKeyboardArrowRight />
          </IconButton>
        )}
      </FlexBox>
    </FlexBox>
  );
}

FormSwitcherDatePicker.propTypes = {
  name: PropTypes.string.isRequired,

  maxDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),

  leftButtonLabel: PropTypes.string,
  rightButtonLabel: PropTypes.string,

  type: PropTypes.string,
  formatDate: PropTypes.func,
  withoutButtons: PropTypes.bool,
  dateWithWords: PropTypes.bool,
};

export default function FormSwitcherDatePicker(props) {
  return <Field {...props} component={enhancer(SwitcherDatePickerComponent)} />;
}
