import { Map, fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import { injectReducer } from "../../shared/helpers/ReducerContext";

export const CREATE_ORDER_CLUSTER = "ORDER_CLUSTER/CREATE_ORDER_CLUSTER";
export const CREATE_ORDER_CLUSTER_PROGRESS =
  "ORDER_CLUSTER/CREATE_ORDER_CLUSTER_PROGRESS";

export const CREATE_ORDER_CLUSTER_ERROR =
  "ORDER_CLUSTER/CREATE_ORDER_CLUSTER_ERROR";
const CLEAR_ORDER_CLUSTER_ERROR = "ORDER_CLUSTER/CLEAR_ORDER_CLUSTER_ERROR";

const selector = injectReducer(
  "orderCluster",
  combineReducers({
    progress(state = Map(), action) {
      switch (action.type) {
        case CREATE_ORDER_CLUSTER_PROGRESS:
          return state.set(action.meta.options, action.payload);
        case CREATE_ORDER_CLUSTER_ERROR:
        case CREATE_ORDER_CLUSTER:
          return state.delete(action.meta.options);
        default:
          return state;
      }
    },
    error(state = Map(), action) {
      switch (action.type) {
        case CREATE_ORDER_CLUSTER_ERROR:
          return state.set(action.meta.options, action.payload);

        case CREATE_ORDER_CLUSTER:
        case CREATE_ORDER_CLUSTER_PROGRESS:
          return state.delete(action.meta.options);

        case CLEAR_ORDER_CLUSTER_ERROR:
          return state.clear();

        default:
          return state;
      }
    },
    items(state = Map(), action) {
      switch (action.type) {
        case CREATE_ORDER_CLUSTER:
          return state.set(action.meta.options, action.payload);
        default:
          return state;
      }
    },
  }),
);

// Selectors.

export function getClusters(state, options) {
  const payload = selector(state).getIn(["items", options]);

  if (payload && payload.routes) return payload.routes;

  return payload;
}

export function getClustersDistanceBetweenWarehouse(state, options) {
  const payload = selector(state).getIn(["items", options]);

  if (payload && payload.distanceWarehouse) return payload.distanceWarehouse;

  return Map();
}

export function getClustersFilteredOrders(state, options) {
  const payload = selector(state).getIn(["items", options]);

  if (payload && payload.filtered) return fromJS(payload.filtered);

  return Map();
}

export function getRequestedCount(state, options) {
  const payload = selector(state).getIn(["items", options]);

  if (payload && payload.requestCount) return payload.requestCount;

  return 0;
}

export function getRequestedElements(state, options) {
  const payload = selector(state).getIn(["items", options]);

  if (payload && payload.elementsTotal) return payload.elementsTotal;

  return 0;
}

export function isClusteringPending(state, options) {
  return selector(state).hasIn(["progress", options]);
}

export function getClusteringProgress(state, options) {
  return selector(state).getIn(["progress", options]);
}

export function getClusteringError(state, options) {
  return selector(state).getIn(["error", options]);
}

// Actions.

export function clearOrderClusterError() {
  return { type: CLEAR_ORDER_CLUSTER_ERROR };
}
