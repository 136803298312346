import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormAdminAddressBookAutoCompleteV2 from "../form/FormAdminAddressBookAutoCompleteV2";
import PageLoading from "../ui-core/PageLoading";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  reduxForm({
    form: "AdminAddressBookSelectV2DialogPickup",
    validate: (values, props) => ({
      address:
        !isValidObjectId(values.address) &&
        props.getLocalisationMessage("select_address", "Select Address"),
    }),
  }),
);

AdminAddressBookSelectV2DialogPickup.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  open: PropTypes.bool.isRequired,
  countryId: PropTypes.number,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function AdminAddressBookSelectV2DialogPickup(props) {
  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogContent style={{ width: "320px" }}>
        <form>
          <FormAdminAddressBookAutoCompleteV2
            focus={true}
            name="address"
            countryId={props.countryId}
            hintText={props.getLocalisationMessage(
              "type_to_search",
              "Type To Search...",
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onRequestClose}>
          {props.getLocalisationMessage("dismiss", "Dismiss")}
        </Button>
        <Button variant="text" onClick={props.handleSubmit}>
          {props.getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(AdminAddressBookSelectV2DialogPickup);
