import React from "react";
import fp from "lodash";
import useSheet from "react-jss";
import PropTypes from "prop-types";
import { Grade as ActionGrade } from "@material-ui/icons";
import RatingColors from "../../constants/RatingColors";

const enhancer = useSheet({
  container: { whiteSpace: "nowrap", textAlign: "center" },
  grade: { color: "rgba(0,0,0,0.54)", width: "18px", margin: "0 0 5px" },
  text: {
    display: "block",
    padding: "2px 5px",
    borderRadius: "2px",
    color: "#fff",
    width: 50,
  },
});

RatingStars.propTypes = { sheet: PropTypes.object, score: PropTypes.number };
RatingStars.defaultProps = { score: 0 };

function RatingStars({ sheet: { classes }, score }) {
  const grades = fp.map(fp.range(1, RatingColors.count()), n => (
    <ActionGrade
      key={n}
      style={{ fill: RatingColors.get(n <= score ? score : 0) }}
      className={classes.grade}
    />
  ));
  // const text = <div className={classes.text} style={{backgroundColor: colors[0]}}>{score} stars</div>

  return <div className={classes.container}>{grades}</div>;
}

export default enhancer(RatingStars);
