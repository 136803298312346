import React from "react";
import { List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { MenuItem, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import {
  Add,
  FilterList as ContentFilterList,
  Refresh,
} from "@material-ui/icons";
import DataList, { DataListColumn } from "../data-list/DataList";
import DeviceTypeIcon from "../device-type/DeviceTypeIcon";
import RatingStars from "../customer-feedback/RatingStars";
import FeedbackNote from "../customer-feedback/FeedbackNote";
import {
  DATE_TIME_URL_FORMAT,
  formatDate,
  formatText,
} from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { CREATE_CUSTOMER_FEEDBACK_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";
import MultiLineCell from "../data-list/MultiLineCell";
import CustomButton, { CONTAINED } from "../ui-core/CustomButton";
import { SECONDARY } from "../form/FormTextField";
import { lightGreen } from "@material-ui/core/colors";

const FILTER_DIALOG_HASH = "#FD";
export const ADD_FEEDBACK_DIALOG_HASH = "#AFDH";

export const SELECTION_PER_PAGE = "per-page";

const enhancer = compose(
  useSheet({
    row: {
      paddingLeft: 0,
    },
    firstCol: {
      backgroundColor: "rgba(0,0,0,0.01)",
      paddingLeft: 0,
    },
  }),
  connect(state => {
    const userRoles = getUser(state).get("roles") || [];

    return {
      isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    };
  }),
);

const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

CustomerFeedbackList.propTypes = {
  list: PropTypes.instanceOf(List).isRequired,
  totalCount: PropTypes.number,
  filter: PropTypes.instanceOf(DataListFilter),
  isLoading: PropTypes.bool.isRequired,
  setLocationQueryFilter: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  location: PropTypes.object,
  maxSearchItems: PropTypes.number,
  sheet: PropTypes.object,
  altHeader: PropTypes.node,
  searchTypes: PropTypes.instanceOf(OrderedMap),
  selectedItems: PropTypes.instanceOf(OrderedMap),
  getLocalisationMessage: PropTypes.func,

  isAdminViewer: PropTypes.bool,
};

CustomerFeedbackList.defaultProps = {
  maxSearchItems: 1,
  selectionType: SELECTION_PER_PAGE,
  hasSupplier: false,
};

function CustomerFeedbackList(props) {
  const {
    location,
    sheet: { classes },
    getLocalisationMessage,
  } = props;
  const NA = getLocalisationMessage("na", "N/A");
  const styles = useStyles();

  return (
    <DataList
      isLoading={props.isLoading}
      totalCount={props.totalCount}
      list={props.list}
      getRowClassName={fp.constant(classes.row)}
      rowCount={props.list.size}
      overscanRowCount={9}
      rowGetter={options => props.list.get(options.index)}
      filter={props.filter}
      maxSearchItems={props.maxSearchItems}
      onFilterChange={filter => props.setLocationQueryFilter(filter)}
      altHeader={props.altHeader}
      selectedRowCount={props.selectedItems ? props.selectedItems.size : 0}
      cardActionIcons={
        <div>
          <CustomButton
            onClick={() => props.replaceLocationHash(ADD_FEEDBACK_DIALOG_HASH)}
            startIcon={<Add />}
            variant={CONTAINED}
            color={SECONDARY}
            style={{ borderRadius: "20px" }}
          >
            {getLocalisationMessage("create", "Create")}
          </CustomButton>

          <Tooltip
            title={getLocalisationMessage("filter_orders", "Filter Orders")}
          >
            <Link to={updateHash(location, FILTER_DIALOG_HASH)}>
              <IconButton>
                <ContentFilterList />
              </IconButton>
            </Link>
          </Tooltip>

          <IconButton
            className={props.isLoading && styles.refresh}
            tooltip={getLocalisationMessage("refresh", "Refresh")}
            onClick={() => props.onRequestRefresh()}
          >
            <Refresh />
          </IconButton>

          <MenuButtonMore>
            <MenuItem
              target="_blank"
              component="a"
              href={updateQuery(
                CREATE_CUSTOMER_FEEDBACK_CSV_URL,
                props.filter.getDefinedValues(),
              )}
            >
              {getLocalisationMessage("download_csv", "Download CSV")}
            </MenuItem>
          </MenuButtonMore>
        </div>
      }
      searchTypes={props.searchTypes}
    >
      <DataListColumn
        width={180}
        label={getLocalisationMessage("order_number", "Order Number")}
        dataKey="order_number"
        disableSort={true}
        cellRenderer={row => (
          <strong>
            <Link
              to={updateQuery(
                location,
                fp.assign({
                  view: row.cellData.get("order_id"),
                  view_tab: "history",
                }),
              )}
            >
              {row.cellData.get("order_number")}
            </Link>
          </strong>
        )}
      />

      <DataListColumn
        width={200}
        className={classes.firstCol}
        label={getLocalisationMessage("user_name", "User Name")}
        dataKey="user.name"
        disableSort={true}
        cellRenderer={row => {
          const name = row.cellData.getIn(["user", "name"]);

          return name || getLocalisationMessage("na", "N/A");
        }}
      />

      <DataListColumn
        width={200}
        className={classes.firstCol}
        label={getLocalisationMessage("issued_by_operator", "Issued By")}
        dataKey="issued_by.name"
        disableSort={true}
        cellRenderer={row => {
          const name = row.cellData.getIn(["issued_by", "name"]);

          return name || getLocalisationMessage("na", "N/A");
        }}
      />

      <DataListColumn
        width={200}
        label={getLocalisationMessage("driver_name", "Driver Name")}
        dataKey="driver.name"
        disableSort={true}
        cellRenderer={row => {
          const name = row.cellData.getIn(["driver", "name"]);

          return name || getLocalisationMessage("na", "N/A");
        }}
      />

      <DataListColumn
        width={200}
        className={classes.firstCol}
        label={getLocalisationMessage("post_office", "Post Office")}
        dataKey="warehouse"
        disableSort={true}
        cellRenderer={row => {
          const name = row.cellData.getIn(["warehouse", "name"]);

          return name || getLocalisationMessage("na", "N/A");
        }}
      />
      <DataListColumn
        width={104}
        justifyContent="center"
        label={getLocalisationMessage("score", "Score")}
        dataKey="score"
        cellRenderer={row => (
          <div>
            <RatingStars score={row.cellData.get("score")} />
          </div>
        )}
      />

      <DataListColumn
        width={400}
        label={getLocalisationMessage("note", "Note")}
        dataKey="note"
        disableSort={true}
        cellRenderer={row => (
          <FeedbackNote score={row.cellData.get("score")}>
            {row.cellData.get("note", getLocalisationMessage("na", "N/A"))}
          </FeedbackNote>
        )}
      />

      <DataListColumn
        width={130}
        label={getLocalisationMessage("order_courier_type", "Courier Type")}
        dataKey="courier_type"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row =>
          getLocalisationMessage(
            row.cellData.get("courier_type"),
            formatText(row.cellData.get("courier_type")),
          ) || NA
        }
      />

      <DataListColumn
        width={130}
        label={getLocalisationMessage("phone_number", "Phone number")}
        dataKey="phone_number"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => row.cellData.get("phone_number") || NA}
      />

      <DataListColumn
        width={150}
        justifyContent="center"
        label={getLocalisationMessage("date", "Date")}
        dataKey="created_date"
        cellRenderer={row => (
          <MultiLineCell
            firstLine={formatDate(
              row.cellData.get("created_date"),
              DATE_TIME_URL_FORMAT,
            )}
          />
        )}
      />

      <DataListColumn
        width={130}
        label={getLocalisationMessage("payment_type", "Payment Type")}
        dataKey="payment_type"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row =>
          getLocalisationMessage(
            row.cellData.get("payment_type"),
            formatText(row.cellData.get("payment_type")),
          ) || NA
        }
      />

      <DataListColumn
        width={130}
        label={getLocalisationMessage("device_type", "Device Type")}
        dataKey="device_details"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <DeviceTypeIcon
            color={lightGreen[700]}
            deviceType={row.cellData.get("device_details")}
          />
        )}
      />

      <DataListColumn
        width={130}
        label={getLocalisationMessage("created_by", "Created By")}
        dataKey="creater_rule_type"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row =>
          getLocalisationMessage(
            row.cellData.get("creater_rule_type"),
            formatText(row.cellData.get("creater_rule_type")),
          ) || NA
        }
      />
    </DataList>
  );
}

export default enhancer(CustomerFeedbackList);
