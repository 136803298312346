import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapProps, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, getFormValues } from "redux-form";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessages } from "../../reducers/LocalizationReducer";

const getValues = getFormValues("ParseBatchIdsDialog");

const enhancer = compose(
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
    values: getValues(state),
  })),
  reduxForm({
    form: "ParseBatchIdsDialog",
    validate: (values, props) => ({
      batchNumbers:
        fp.isEmpty(values.batchNumbers) &&
        ((props.i18n && props.i18n.get("add_orders", "Add Orders")) ||
          "Add Orders"),
    }),
  }),
  formValues({
    supplier: "supplier",
    batchNumbers: "batchNumbers",
  }),
  withContext(
    {
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    props => ({
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  mapProps(props => ({
    ...props,
    result: fp.isEmpty(props.batchNumbers) ? "Empty" : props.batchNumbers[0],
  })),
);

ParseBatchIdsDialog.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  batchNumbers: PropTypes.array,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,

  getCachedSupplier: PropTypes.func,
  getSupplierPredictions: PropTypes.func,
  onBatchAssignSupplier: PropTypes.func,
  showBatchAssignSupplier: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
  theme: PropTypes.object,
};

function ParseBatchIdsDialog(props) {
  const { classes, i18n } = props;

  return (
    <Dialog open={props.open} maxWidth="auto">
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {`${i18n.get("fetch")} ${fp.size(props.batchNumbers)} ${i18n.get(
          "barcodes",
          "Barcodes",
        )}`}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <form>
          <FormChipInput
            name="batchNumbers"
            focus={true}
            fullWidth={true}
            addOnBlur={true}
            label={i18n.get("batch_ids", "Batch Ids")}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button primary={true} onClick={props.onRequestClose}>
          {i18n.get("cancel", "Cancel")}
        </Button>
        {props.showBatchAssignSupplier && (
          <Button
            primary={true}
            onClick={() => props.onBatchAssignSupplier(props.batchNumbers)}
          >
            {i18n.get("batch_assign_supplier", "Batch Assign Supplier")}
          </Button>
        )}
        <Button primary={true} onClick={props.handleSubmit}>
          {i18n.get("batch_update", "Batch update")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(ParseBatchIdsDialog);
