import { ALL } from "./OverallStatusTabs";
import { DASHBOARD_URL } from "./DashboardPathConstants";

export const ORDER_TRACK_PATH = "order/track";
export const ORDER_LIST_PATH = "order/list(/:statusCode)";
export const ORDER_TRACKING_PATH = "order/tracking(/:orderNumbers)";

export const ORDER_CREATE_PATH = "order/create";
export const ORDER_CREATE_URL = `${DASHBOARD_URL}/${ORDER_CREATE_PATH}`;

export const ORDER_LIST_URL = `${DASHBOARD_URL}/order/list`;
export const ORDER_TRACK_URL = `${DASHBOARD_URL}/order/track`;
export const ORDER_TRACKING_URL = `${DASHBOARD_URL}/order/tracking/`;

export const ORDER_JOBS_PATH = "order-jobs";
export const ORDER_JOBS_URL = `${DASHBOARD_URL}/order-jobs`;

export const ORDER_LIST_MAP_URL = `${DASHBOARD_URL}/order/map`;
export const ORDER_LIST_MAP_PATH = "order/map";

export const FINANCE_ORDER_LIST_PATH = "finance/order/list(/:codStatus)";
export const FINANCE_TRANSFER_LIST_PATH =
  "finance/transfer/list(/:transferStatus)";
export const FINANCE_TRANSFER_ITEM_PATH = "finance/transfer/item/:id";
export const FINANCE_BALANCE_PATH = "finance/balance";

export const FINANCE_BALANCE_URL = `${DASHBOARD_URL}/finance/balance`;
export const FINANCE_ORDER_LIST_URL = `${DASHBOARD_URL}/finance/order/list`;
export const FINANCE_TRANSFER_LIST_URL = `${
  DASHBOARD_URL
}/finance/transfer/list`;
export const FINANCE_TRANSFER_ITEM_URL = `${
  DASHBOARD_URL
}/finance/transfer/item/`;

export const DRIVER_RADAR_PATH = "driver/maps";
export const DRIVER_RADAR_V2_PATH = "driver/maps_v2";
export const DRIVER_LIST_PATH = "driver/list(/:statusCode)";

export const DRIVER_LIST_URL = `${DASHBOARD_URL}/driver/list`;
export const DRIVER_RADAR_URL = `${DASHBOARD_URL}/driver/maps`;
export const DRIVER_RADAR_V2_URL = `${DASHBOARD_URL}/driver/maps_v2`;

export const VEHICLE_LIST_PATH = "vehicle/list(/:statusCode)";
export const VEHICLE_LIST_URL = `${DASHBOARD_URL}/vehicle/list`;

export const SUPPLIER_USER_LIST_PATH = "supplier/user/list(/:statusCode)";

export const SUPPLIER_USER_LIST_URL = `${DASHBOARD_URL}/supplier/user/list`;
export const SUPPLIER_USER_LIST_ALL_URL = `${SUPPLIER_USER_LIST_URL}/${ALL}`;

// Communication Settings
export const COMMUNICATION_SETTINGS_LIST_PATH = "communication-settings/list";
export const COMMUNICATION_SETTINGS_CREATE_PATH =
  "communication-settings/create";
export const COMMUNICATION_SETTINGS_ITEM_PATH =
  "communication-settings/item/:id";

export const COMMUNICATION_SETTINGS_LIST_URL = `${
  DASHBOARD_URL
}/communication-settings/list`;
export const COMMUNICATION_SETTINGS_CREATE_URL = `${
  DASHBOARD_URL
}/communication-settings/create`;
export const COMMUNICATION_SETTINGS_ITEM_URL = `${
  DASHBOARD_URL
}/communication-settings/item/`;

export const CUSTOMER_FEEDBACK_LIST_PATH = "customer/feedback/list";
export const CUSTOMER_FEEDBACK_LIST_URL = `${
  DASHBOARD_URL
}/customer/feedback/list`;

// Reporting

export const REPORTING_ROOT_PATH = "reporting";
export const REPORTING_REPORTS_URL = `${DASHBOARD_URL}/reporting`;

export const REPORTING_OPERATIONAL_PATH = `reporting/operational`;
export const REPORTING_OPERATIONAL_URL = `${
  DASHBOARD_URL
}/reporting/operational`;

// Dashboard
export const CHARTS_PATH = "charts";
export const CHARTS_URL = `${DASHBOARD_URL}/charts`;

export const ORDER_SORTING_VIEW_PATH = "order/sorting";
export const ORDER_SORTING_VIEW_URL = `${DASHBOARD_URL}/order/sorting`;

// Driver Liability
export const DRIVER_LIABILITY_PATH = "driver-liability";
export const DRIVER_LIABILITY_URL = `${DASHBOARD_URL}/driver-liability`;
