import { fromJS } from "immutable";
import fp from "lodash/fp";
import { SettingsApi } from "./SettingsApi";
import { mapResponseStream } from "../../helpers/ApiUtils";
import {
  ACCEPTED,
  ARRIVED,
  ARRIVED_TO_DELIVERY_ADDRESS,
  ASSIGNED_TO_COURIER,
  BAD_RECIPIENT_ADDRESS,
  BAD_WEATHER_DURING_DELIVERY,
  CANCELLED,
  COD_NOT_READY,
  COLLECTION_ARRANGED_OR_REQUESTED,
  COMPLETED,
  DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
  DELIVERY_FAILED,
  DELIVERY_REJECTED,
  DELIVERY_SCHEDULED,
  DESTROYED_ON_CUSTOMER_REQUEST,
  DISPATCHED,
  DRIVER_REJECTED,
  FIRST_DELIVERY_ATTEMPT,
  FIRST_PICKUP_ATTEMPT,
  FUTURE_DELIVERY_REQUESTED,
  ID_OR_DOCUMENT_REQUIRED_MISSING,
  IN_SORTING_FACILITY,
  IN_TRANSIT,
  IN_TRANSIT_TO_SUPPLIER,
  INCOMPLETE_PARCEL,
  ISSUED_TO_RECIPIENT,
  NO_CAPACITY_FOR_DELIVERY,
  NO_TIME_FOR_DELIVERY,
  ON_HIS_WAY,
  ON_HOLD,
  OTHER_DELIVERY_ATTEMPT,
  OTHER_PICKUP_ATTEMPT,
  OUT_FOR_DELIVERY,
  OUT_FOR_RETURN,
  OUT_OF_DELIVERY_AREA,
  PICKED_UP,
  PICKED_UP_AUTO,
  PREPARED_FOR_TRANSIT,
  READY_FOR_DELIVERY,
  RECIPIENT_ADDRESS_CHANGE_REQUESTED,
  RECIPIENT_MOBILE_NO_RESPONSE,
  RECIPIENT_MOBILE_SWITCHED_OFF,
  RECIPIENT_MOBILE_WRONG,
  RECIPIENT_NAME_MISSING,
  RECIPIENT_NOT_AVAILABLE,
  RETURNED_TO_ORIGIN,
  RETURNING_TO_ORIGIN,
  SECOND_DELIVERY_ATTEMPT,
  SECOND_PICKUP_ATTEMPT,
  THIRD_DELIVERY_ATTEMPT,
  THIRD_PICKUP_ATTEMPT,
  TO_BE_RETURNED,
  UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
  UNASSIGNED,
  WRONG_SHIPMENT,
} from "../../constants/OrderStatusCodes";

const defaultFilters = fromJS([
  // Buttons
  // {
  //   name: "Urgent",
  //   sort_order: 0,
  //   filter: {
  //     courier_types: fp.toUpper(URGENT),
  //     exclude_customer_ids: null,
  //   },
  // },
  // {
  //   name: "Same Day",
  //   sort_order: 1,
  //   filter: {
  //     courier_types: fp.toUpper(SAME_DAY),
  //     exclude_customer_ids: [757380, 834984].join(","),
  //   },
  // },
  // {
  //   name: "Next Day",
  //   sort_order: 2,
  //   filter: {
  //     courier_types: fp.toUpper(NEXT_DAY),
  //     exclude_customer_ids: [757380, 834984, 11075697].join(","),
  //   },
  // },
  // TABS
  {
    is_tab: false,
    name: "letters",
    sort_order: 0,
    filter: {
      size: 50,
      courier_types: "LETTER",
      page: 0,
      search_history: false,
    },
  },
  {
    name: "parcels",
    sort_order: 1,
    is_tab: null,
    filter: {
      size: 50,
      courier_types: "PARCEL",
      page: 0,
      search_history: false,
    },
  },
  {
    name: "parcel_post",
    sort_order: 2,
    filter: {
      size: 50,
      courier_types: "BANDEROL",
      page: 0,
      search_history: false,
    },
    is_tab: null,
  },
  {
    name: "small_packets",
    sort_order: 3,
    filter: {
      size: 50,
      courier_types: "SMALL_PACKETS",
      page: 0,
      search_history: false,
    },
    is_tab: null,
  },
  {
    is_tab: true,
    name: "not_payed",
    sort_order: 4,
    filter: {
      size: 50,
      page: 0,
      status: ASSIGNED_TO_COURIER,
      include_exception: false,
      search_history: false,
    },
  },
  {
    is_tab: true,
    name: "taken",
    sort_order: 5,
    filter: {
      size: 50,
      page: 0,
      status: IN_SORTING_FACILITY,
      include_exception: false,
      search_history: false,
    },
  },
  {
    is_tab: true,
    name: "prepared_for_transit",
    sort_order: 6,
    filter: {
      size: 50,
      page: 0,
      status: PREPARED_FOR_TRANSIT,
      include_exception: false,
      search_history: false,
    },
  },
  {
    is_tab: true,
    name: "in_transit",
    sort_order: 7,
    filter: {
      size: 50,
      page: 0,
      status: IN_TRANSIT,
      include_exception: false,
      source_warehouse_id: 830,
      search_history: false,
    },
  },
  {
    is_tab: true,
    name: "ready_for_delivery",
    sort_order: 8,
    filter: {
      size: 50,
      page: 0,
      status: READY_FOR_DELIVERY,
      include_exception: false,
      warehouse_ids: 830,
      search_history: false,
    },
  },
  {
    is_tab: true,
    name: "issued_by",
    sort_order: 9,
    filter: {
      size: 50,
      page: 0,
      status: [ISSUED_TO_RECIPIENT, COMPLETED].join(","),
      include_exception: false,
      search_history: false,
    },
  },
]);
const CUSTOM_FILTERS = "filters";

export class OrderSettingsApi extends SettingsApi {
  constructor(options) {
    super({ ...options, domain: "order" });
  }

  getCustomFilters() {
    return this.getItem(CUSTOM_FILTERS)
      .let(
        mapResponseStream(
          fp.flow(fp.get("data.settings"), json => {
            try {
              return JSON.parse(json);
            } catch (e) {
              return [];
            }
          }),
        ),
      )
      .map(response =>
        response.get("pending")
          ? response
          : response.update("payload", x => (x.isEmpty() ? defaultFilters : x)),
      );
  }

  saveCustomFilters(filters) {
    return this.saveItem(CUSTOM_FILTERS, JSON.stringify(filters.toJS()));
  }
}

export const orderGroupStatuses = fromJS([
  {
    name: "Unassigned",
    sort_order: 0,
    percentage: 0,
    color: "#09A0FD",
    statuses: [UNASSIGNED, ASSIGNED_TO_COURIER],
  },
  {
    name: "Assigned",
    sort_order: 1,
    percentage: 20,
    color: "#FF9800",
    statuses: [
      OTHER_PICKUP_ATTEMPT,
      ACCEPTED,
      ON_HIS_WAY,
      ARRIVED,
      FIRST_PICKUP_ATTEMPT,
      SECOND_PICKUP_ATTEMPT,
      THIRD_PICKUP_ATTEMPT,
      DRIVER_REJECTED,
    ],
  },
  {
    name: "Picked Up",
    sort_order: 2,
    percentage: 40,
    color: "#FF9800",
    statuses: [
      FIRST_PICKUP_ATTEMPT,
      SECOND_PICKUP_ATTEMPT,
      THIRD_PICKUP_ATTEMPT,
      PICKED_UP_AUTO,
      DISPATCHED,
      IN_TRANSIT,
      PICKED_UP,
    ],
  },
  {
    name: "Out For Delivery",
    sort_order: 3,
    percentage: 70,
    color: "#FF9800",
    statuses: [
      OUT_FOR_DELIVERY,
      OTHER_DELIVERY_ATTEMPT,
      IN_SORTING_FACILITY,
      IN_TRANSIT,
      PICKED_UP_AUTO,
      PICKED_UP,
      ARRIVED_TO_DELIVERY_ADDRESS,
      ON_HOLD,
      FIRST_DELIVERY_ATTEMPT,
      SECOND_DELIVERY_ATTEMPT,
      THIRD_DELIVERY_ATTEMPT,
      BAD_RECIPIENT_ADDRESS,
      RECIPIENT_NOT_AVAILABLE,
      RECIPIENT_MOBILE_SWITCHED_OFF,
      RECIPIENT_MOBILE_WRONG,
      RECIPIENT_MOBILE_NO_RESPONSE,
      RECIPIENT_ADDRESS_CHANGE_REQUESTED,
      COD_NOT_READY,
      FUTURE_DELIVERY_REQUESTED,
      OUT_OF_DELIVERY_AREA,
      UNABLE_TO_ACCESS_RECIPIENT_PREMISES,
      NO_CAPACITY_FOR_DELIVERY,
      ID_OR_DOCUMENT_REQUIRED_MISSING,
      BAD_WEATHER_DURING_DELIVERY,
      RECIPIENT_NAME_MISSING,
      COLLECTION_ARRANGED_OR_REQUESTED,
      WRONG_SHIPMENT,
      INCOMPLETE_PARCEL,
      DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
      NO_TIME_FOR_DELIVERY,
      DELIVERY_REJECTED,
      DELIVERY_SCHEDULED,
      DELIVERY_FAILED,
      DISPATCHED,
      IN_TRANSIT_TO_SUPPLIER,
      PREPARED_FOR_TRANSIT,
    ],
  },
  {
    name: "Completed",
    sort_order: 4,
    percentage: 100,
    color: "#4CAF50",
    statuses: [
      FIRST_DELIVERY_ATTEMPT,
      SECOND_DELIVERY_ATTEMPT,
      THIRD_DELIVERY_ATTEMPT,
      COMPLETED,
    ],
  },
  {
    name: "Cancelled",
    sort_order: 5,
    percentage: 100,
    color: "#E93333",
    statuses: [
      CANCELLED,
      RETURNING_TO_ORIGIN,
      TO_BE_RETURNED,
      OUT_FOR_RETURN,
      DESTROYED_ON_CUSTOMER_REQUEST,
      RETURNED_TO_ORIGIN,
      RETURNING_TO_ORIGIN,
      TO_BE_RETURNED,
      OUT_FOR_RETURN,
    ],
  },
]);
