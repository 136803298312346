import React from "react";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Checkbox as MUICheckbox } from "@material-ui/core";

Checkbox.propTypes = {
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = { onBlur: fp.noop, onFocus: fp.noop };

const cleanupProps = fp.omit([
  "error",
  "invalid",
  "touched",
  "initialValue",
  "autofill",
  "onUpdate",
  "onChange",
  "valid",
  "dirty",
  "pristine",
  "active",
  "visited",
  "autofilled",
]);

export default function Checkbox(props) {
  const inputProps = cleanupProps(props);

  return (
    <MUICheckbox
      {...inputProps}
      value={Boolean(props.value)}
      checked={Boolean(props.value)}
      onBlur={fp.flow(fp.noop, props.onBlur)}
      onFocus={fp.flow(fp.noop, props.onFocus)}
      onChange={(event, value) => props.onChange(value)}
      onCheck={(event, value) => props.onChange(value)}
    />
  );
}
