import React from "react";
import fp from "lodash/fp";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormReferenceAutoComplete from "../form/FormReferenceAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { formatText } from "../../helpers/FormatUtils";
import { isEnableVehicleMake } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import UpperOverallStatus from "../../constants/UpperOverallStatus";
import useSheet from "react-jss";
import FormDriverChips from "../form/FormDriverChips";

const valueSelector = formValueSelector("VehicleForm");

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    enableVehicleMake: isEnableVehicleMake(state),
  })),
  useSheet({
    innerMarginBottom: {
      "&>div": {
        marginBottom: 15,
      },
    },
    includeButton: {
      padding: 8,
      marginLeft: 10,
    },
  }),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
      getCachedReference: PropTypes.func,
      getReferencePredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
    },
    (props) => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedReference: props.getCachedReference,
      getReferencePredictions: props.getReferencePredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
    }),
  ),
  reduxForm({
    form: "VehicleForm",
    enableReinitialize: true,
    validate: (values, props) => ({
      // drivers:
      //   fp.isEmpty(values.drivers) &&
      //   props.getLocalisationMessage("select_driver", "Select Driver"),
      model:
        fp.isEmpty(values.model) &&
        props.getLocalisationMessage(
          "vehicle_type_required",
          "Vehicle type is required",
        ),
      plateNumber:
        fp.isEmpty(values.plateNumber) &&
        props.getLocalisationMessage(
          "register_number_required",
          "Register number is required",
        ),
    }),
  }),
  connect((state) => ({
    values: valueSelector(state, "make", "driver", "supplier"),
  })),
);

VehicleForm.propTypes = {
  classes: PropTypes.object,
  reset: PropTypes.func,
  dirty: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function VehicleForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <form autoComplete="off" name="VehicleForm">
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={8} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardContent>
                <FlexBox
                  direction="column"
                  className={classes.innerMarginBottom}
                >
                  <FormReferenceAutoComplete
                    name="model"
                    fullWidth={true}
                    parentCode="model"
                    label={`${getLocalisationMessage("vehicle_type")} *`}
                    formatOption={(x) =>
                      getLocalisationMessage(x.name) || formatText(x.name)
                    }
                    hintText={getLocalisationMessage("type_to_search")}
                  />
                  <FormReferenceAutoComplete
                    name="color"
                    fullWidth={true}
                    parentCode="color"
                    label={`${getLocalisationMessage("color", "Color")} *`}
                    formatOption={(x) =>
                      getLocalisationMessage(x.name) || formatText(x.name)
                    }
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                  />

                  <FormTextField
                    name="plateNumber"
                    label={`${getLocalisationMessage("register_number")} *`}
                    fullWidth={true}
                  />
                  <FormSelectField
                    name="status"
                    autoWidth={true}
                    fullWidth={true}
                    formatOption={(x) =>
                      getLocalisationMessage(x) || formatText(x)
                    }
                    label={getLocalisationMessage("status", "Status")}
                    options={UpperOverallStatus}
                  />
                  <FormDriverChips
                    name="drivers"
                    fullWidth={true}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={`${getLocalisationMessage("drivers")}`}
                  />
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>
      </FlexBox>

      <FlexBox justify="flex-end">
        <CardActions>
          {props.dirty ? (
            <Button onClick={props.reset}>
              {getLocalisationMessage("reset", "Reset")}
            </Button>
          ) : (
            Boolean(props.onDismiss) && (
              <Button onClick={props.onDismiss}>
                {getLocalisationMessage("dismiss", "dismiss")}
              </Button>
            )
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </form>
  );
}

export default enhancer(VehicleForm);
