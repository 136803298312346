import React from "react";
import useSheet from "react-jss";
import PropTypes from "prop-types";
// import fp from "lodash";
import RatingColors from "../../constants/RatingColors";

const enhancer = useSheet();

FeedbackNote.propTypes = {
  sheet: PropTypes.object,
  children: PropTypes.node,
  score: PropTypes.number,
};
FeedbackNote.defaultProps = {
  score: 0,
};

function FeedbackNote({ children, score }) {
  return <div style={{ color: RatingColors.get(score) }}>{children}</div>;
}

export default enhancer(FeedbackNote);
