import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { spring, Motion } from "react-motion";
import AlertLocationUpdateInfo from "./AlertLocationUpdateInfo";
import FlexBox from "../ui-core/FlexBox";
import { formatDate, formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ALERTED,
  DELETED,
  PRIMARY,
  CONTRAST,
  DISMISSED,
} from "../../constants/AlertsContastants";

const statusTime = item => {
  switch (item.get("status")) {
    case ALERTED:
      return <span>{formatDate(item.get("created_date"))}</span>;

    case DISMISSED:
      return <span>{formatDate(item.get("dismissed_time"))}</span>;

    default:
      return null;
  }
};

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    item: {
      lineHeight: "18px",
      color: "rgba(255, 255, 255, 0.54)",
      fontSize: "14px",
      position: "relative",
      padding: 0,
    },
    button: {
      color: "#fff",
    },
    alertedItem: {
      backgroundColor: "#C62828",
    },
    dismissButton: {
      width: "96px",
    },
    alertedText: {
      color: "#fff",
      fontSize: "16px",
    },
    dismissedText: {
      color: "#9E9E9E",
      fontSize: "16px",
    },
    subtitle: {
      color: "rgba(158, 158, 158, 0.54)",
    },
  }),
  withState("state", "setState", {}),
);

DriverAlertHistoryItem.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.instanceOf(Immutable.Map),
  alertItemDetail: PropTypes.instanceOf(Immutable.Map),
  onDismissClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  style: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function DriverAlertHistoryItem(props) {
  const { classes, state, getLocalisationMessage } = props;
  const alertedItem = props.item.get("status") === ALERTED;
  const notDeletedItem = props.item.get("status") !== DELETED;
  const NA = getLocalisationMessage("na", "N/A");
  return (
    <Motion
      defaultStyle={{ height: 64 }}
      style={{ height: spring(state.height ? 0 : 64) }}
    >
      {({ height }) => (
        <FlexBox
          flex="none"
          style={{
            ...props.style,
            height: `${height}px`,
          }}
          className={cx(classes.item, { [classes.alertedItem]: alertedItem })}
        >
          <Motion
            defaultStyle={{ left: 0, opacity: 1 }}
            style={{
              left: spring(state.left ? -900 : 0, { stiffness: 300 }),
              opacity: spring(state.left ? 0 : 1),
            }}
            onRest={() => props.setState(fp.set("height", true))}
          >
            {({ left, opacity }) => (
              <FlexBox
                flex={true}
                style={{
                  top: "4px",
                  width: "100%",
                  position: "absolute",
                  left: `${left}px`,
                  opacity,
                }}
              >
                <FlexBox flex={true}>
                  <AlertLocationUpdateInfo
                    isHistory={true}
                    isDismissed={!alertedItem}
                    item={props.item}
                    color={alertedItem ? PRIMARY : CONTRAST}
                  />
                </FlexBox>

                <FlexBox flex={true} direction="column" justify="center">
                  <span className={cx({ [classes.subtitle]: !alertedItem })}>
                    {getLocalisationMessage("order", "Order")}#:
                  </span>
                  <span
                    className={cx({
                      [classes.alertedText]: alertedItem,
                      [classes.dismissedText]: !alertedItem,
                    })}
                  >
                    {props.item.get("order_id") || NA}
                  </span>
                </FlexBox>

                <FlexBox flex={true} direction="column" justify="center">
                  <span
                    className={cx({
                      [classes.alertedText]: alertedItem,
                      [classes.dismissedText]: !alertedItem,
                    })}
                  >
                    {getLocalisationMessage(
                      props.item.get("status"),
                      formatText(props.item.get("status")),
                    )}
                  </span>
                  <span className={cx({ [classes.subtitle]: !alertedItem })}>
                    {statusTime(props.item)}
                  </span>
                </FlexBox>

                <FlexBox align="center" className={classes.dismissButton}>
                  {alertedItem && (
                    <Button
                      onClick={() => {
                        props.setState(fp.set("left", true));
                        props.onDismissClick(props.item.get("id"));
                      }}
                      className={classes.button}
                    >
                      {getLocalisationMessage("dismiss", "Dismiss")}
                    </Button>
                  )}
                </FlexBox>

                <FlexBox align="center" className={classes.dismissButton}>
                  {notDeletedItem && (
                    <Button
                      onClick={() => {
                        props.setState(fp.set("left", true));
                        props.onDeleteClick(props.item.get("id"));
                      }}
                      className={cx({
                        [classes.alertedText]: alertedItem,
                        [classes.dismissedText]: !alertedItem,
                      })}
                    >
                      {getLocalisationMessage("delete", "Delete")}
                    </Button>
                  )}
                </FlexBox>
              </FlexBox>
            )}
          </Motion>
        </FlexBox>
      )}
    </Motion>
  );
}

export default enhancer(DriverAlertHistoryItem);
