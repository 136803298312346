import React from "react";
import { Map, List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Paper, Button, IconButton, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";
import OrderCustomFilterList from "./OrderCustomFilterList";
import Text from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  createOrderSmartFilter,
  parserOrderSmartFilter,
} from "../../helpers/OrderSmartFiltersHelper";
import { getMessages } from "../../reducers/LocalizationReducer";
import { withTheme } from "@material-ui/core/styles";

const enhancer = compose(
  renderIf("open"),
  withTheme,
  useSheet({
    list: { overflowY: "auto" },
    paper: { maxWidth: "492px", minWidth: "492px" },
  }),
  reduxForm({ form: "OrderCustomFiltersDialog", enableReinitialize: true }),
  formValues({
    filters: "filters",
    editFilterIndex: "editFilterIndex",
    editFilterNameIndex: "editFilterNameIndex",
  }),
  connect(state => ({
    i18n: getMessages(state),
  })),
);

OrderCustomFiltersDialog.propTypes = {
  classes: PropTypes.object,

  change: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  filters: PropTypes.instanceOf(List),

  editFilterIndex: PropTypes.number,
  editFilterNameIndex: PropTypes.number,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  renderFilterDialog: PropTypes.func.isRequired,

  initialValues: PropTypes.object,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  i18n: PropTypes.instanceOf(Map),
  theme: PropTypes.object,
};

function OrderCustomFiltersDialog(props) {
  const { classes, i18n } = props;

  return (
    <ModalPaper
      title={i18n.get("custom_filter", "Custom Filter")}
      open={props.open}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
      rightIcon={
        <IconButton
          style={{
            color: props.theme.palette.appBarTextColor,
          }}
          onClick={props.onRequestClose}
        >
          <Close />
        </IconButton>
      }
    >
      <PageLoading isLoading={props.submitting} />

      {Boolean(
        props.editFilterIndex === -1 ||
          props.filters.has(props.editFilterIndex),
      ) &&
        props.renderFilterDialog({
          open: true,
          isCustomFilter: true,
          onRequestClose: () => props.change("editFilterIndex", null),

          filter:
            props.editFilterIndex === -1
              ? new DataListFilter()
              : parserOrderSmartFilter(
                  props.filters.getIn([props.editFilterIndex, "filter"]),
                ).setValueMap({
                  filter_name: props.filters.getIn([
                    props.editFilterIndex,
                    "name",
                  ]),
                  is_tab: props.filters.getIn([
                    props.editFilterIndex,
                    "is_tab",
                  ]),
                }),

          onFilterChange: x => {
            if (props.editFilterIndex === -1) {
              props.change(
                "filters",
                props.filters.push(
                  Map({
                    is_tab: x.getBoolValue("is_tab"),
                    name: x.getValue("filter_name"),
                    sort_order: props.filters.size,
                    filter: createOrderSmartFilter(
                      x.setValueMap({
                        filter_name: null,
                        is_tab: null,
                      }),
                    ),
                  }),
                ),
              );
            } else {
              props.change(
                "filters",
                props.filters
                  .setIn(
                    [props.editFilterIndex, "filter"],
                    createOrderSmartFilter(
                      x.setValueMap({
                        filter_name: null,
                        is_tab: null,
                      }),
                    ),
                  )
                  .setIn(
                    [props.editFilterIndex, "name"],
                    x.getValue("filter_name"),
                  )
                  .setIn(
                    [props.editFilterIndex, "is_tab"],
                    x.getValue("is_tab"),
                  ),
              );
            }

            x.setValue("filter_name", null).setValue("is_tab", null);
            props.change("editFilterIndex", null);
          },
        })}

      <FlexBox container={8} flex={true} element={<Paper />}>
        {props.filters.isEmpty() ? (
          <FlexBox gutter={8} direction="column" flex={true}>
            <FlexBox
              flex={true}
              align="center"
              justify="center"
              direction="column"
            >
              <Text element="h4" type="align-center">
                {i18n.get("you_havent_created", "You haven't created")}
                <br />
                {i18n.get("any_filters_yet", "any filters yet")}
              </Text>

              <br />

              <CardActions>
                <Button onClick={() => props.change("editFilterIndex", -1)}>
                  {i18n.get("created_new", "Create New")}
                </Button>

                <Button
                  primary={true}
                  onClick={() => {
                    props.change("filters", List());
                    props.handleSubmit();
                  }}
                >
                  {i18n.get("use_defaults", "Use Defaults")}
                </Button>
              </CardActions>
            </FlexBox>
          </FlexBox>
        ) : (
          <FlexBox gutter={8} direction="column" flex={true}>
            <FlexBox flex={true} direction="column" className={classes.list}>
              <OrderCustomFilterList
                filtersList={props.filters}
                onChange={x => props.change("filters", x)}
                onEditClick={x => props.change("editFilterIndex", x)}
              />
            </FlexBox>

            <FlexBox element={<CardActions />} justify="flex-end">
              <Button onClick={() => props.change("editFilterIndex", -1)}>
                {i18n.get("add", "Add")}
              </Button>

              <Button
                style={{
                  backgroundColor: props.theme.palette.primary.main,
                  color: props.theme.palette.appBarTextColor,
                }}
                onClick={props.handleSubmit}
              >
                {i18n.get("save", "Save")}
              </Button>
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(OrderCustomFiltersDialog);
