import React from "react";
import { Set, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import FormChipInput from "../form/FormChipInput";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { formatText } from "../../helpers/FormatUtils";
import {
  formatInquiriesTypes,
  formatReasonTypesLocalised,
} from "../../helpers/OrderHelper";
import {
  getMessage,
  getCurrentLanguage,
} from "../../reducers/LocalizationReducer";
import { RUSSIAN_LANGUAGE } from "../../constants/LocaleTypes";

const valueSelector = formValueSelector("BatchOrderNoteDialog");

const enhancer = compose(
  connect(state => ({
    orderNumbers: valueSelector(state, "orderNumbers"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    currentLanguage: getCurrentLanguage(state),
  })),
  withTheme,
  renderIf("open"),
  useSheet({
    dialogTitle: {
      color: props => props.theme.palette.appBarTextColor,
      backgroundColor: props => props.theme.palette.primary.main,
    },
    paper: {
      minWidth: "500px",
      maxWidth: "1000px",
      minHeight: "auto",
    },
  }),
  reduxForm({
    form: "BatchOrderNoteDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      reason:
        !values.reason &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_reason")) ||
          "Select Reason"),
      content:
        !values.content &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("enter_note_content")) ||
          "Enter note content"),
      privacy:
        !values.privacy &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_privacy_type")) ||
          "Select Privacy Type"),
      inquiries:
        !values.reason &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_source_of_inquiries")) ||
          "Select Source of Inquiries"),
      orderNumbers:
        fp.size(values.orderNumbers) === 0 &&
        ((props.getLocalisationMessage &&
          props.getLocalisationMessage("select_orders")) ||
          "Select Orders"),
    }),
  }),
);

const title = (currentLanguage, ordersNumber) => {
  switch (currentLanguage) {
    case RUSSIAN_LANGUAGE:
      return `Добавить заметки (заказов-${ordersNumber})`;

    default:
      return `Add note for ${ordersNumber} Orders `;
  }
};

BatchOrderNoteDialog.propTypes = {
  orderNumbers: PropTypes.array,
  reset: PropTypes.func,
  dirty: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  privacyTypes: PropTypes.instanceOf(Set),

  reasonTypes: PropTypes.instanceOf(OrderedSet),
  inquiriesTypes: PropTypes.instanceOf(OrderedSet),
  getLocalisationMessage: PropTypes.func,
  currentLanguage: PropTypes.string,
  classes: PropTypes.object,
  theme: PropTypes.object,
};

function BatchOrderNoteDialog(props) {
  const { getLocalisationMessage, currentLanguage, classes } = props;
  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting} />
      <DialogTitle
        style={{ color: props.theme.palette.appBarTextColor }}
        className={classes.dialogTitle}
      >
        {title(currentLanguage, fp.size(props.orderNumbers))}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <FlexBox element={<form />} gutter={8} direction="column">
          <FlexBox>
            <FormChipInput
              focus={true}
              fullWidth={true}
              addOnBlur={true}
              name="orderNumbers"
              label={getLocalisationMessage("orders_numbers", "Orders Numbers")}
            />
          </FlexBox>

          <FlexBox>
            <FlexBox flex={true} gutter={8}>
              <FlexBox flex={true}>
                <FormTextField
                  name="content"
                  rows={4}
                  multiLine={true}
                  fullWidth={true}
                  label={getLocalisationMessage("content", "Content")}
                />
              </FlexBox>

              {props.inquiriesTypes && (
                <FlexBox>
                  <FormSelectField
                    name="inquiries"
                    autoWidth={true}
                    options={props.inquiriesTypes}
                    formatOption={x =>
                      getLocalisationMessage(x, formatInquiriesTypes(x))
                    }
                    label={getLocalisationMessage(
                      "source_of_inquiries",
                      "Source Of Inquiries",
                    )}
                  />
                </FlexBox>
              )}
            </FlexBox>
          </FlexBox>

          {Boolean(props.privacyTypes) && (
            <FlexBox>
              <FormSelectField
                name="privacy"
                fullWidth={true}
                formatOption={x => getLocalisationMessage(x, formatText(x))}
                label={getLocalisationMessage("privacy", "Privacy")}
                options={props.privacyTypes}
              />
            </FlexBox>
          )}

          {props.reasonTypes && (
            <FlexBox>
              <FormSelectField
                name="reason"
                fullWidth={true}
                label={getLocalisationMessage("reason", "Reason")}
                options={props.reasonTypes}
                formatOption={x =>
                  formatReasonTypesLocalised(x, getLocalisationMessage)
                }
              />
            </FlexBox>
          )}
        </FlexBox>
      </DialogContent>
      <DialogActions>
        {props.dirty ? (
          <Button onClick={props.reset} primary={true}>
            {getLocalisationMessage("reset", "Reset")}
          </Button>
        ) : (
          Boolean(props.onRequestClose) && (
            <Button onClick={props.onRequestClose} primary={true}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
          )
        )}

        <Button onClick={props.handleSubmit} primary={true}>
          {getLocalisationMessage("submit", "Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(BatchOrderNoteDialog);
