import React from "react";
import useSheet from "react-jss";
import cx from "classnames";
import PropTypes from "prop-types";
import { Motorcycle, DirectionsCar, LocalShipping } from "@material-ui/icons";

import UserAvatar from "../avatars/UserAvatar";
import FlexBox from "../ui-core/FlexBox";
import { BIKE, SEDAN } from "../../constants/VehicleTypes";

const enhancer = useSheet({
  line: {
    fontSize: "14px",
    maxWidth: "236px",
    lineHeight: "18px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "rgba(0, 0, 0, 0.54)",
  },
  historyLine: {
    maxWidth: "150px",
  },
  cars: {
    width: "18px",
    height: "18px",
    marginLeft: "6px",
  },
  firstLine: {
    fontWeight: "bold",
    overflow: "hidden",
    color: "#4a4949",
    fontSize: "16px",
    "& > span": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  firstLineOpen: {
    color: "#fff",
  },
  lineOpen: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  lineInactive: {
    color: "rgba(33, 33, 33, 0.54)",
  },
  avatarContainer: {
    position: "relative",
  },
  avatarOverlay: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,.54)",
  },
});

DriverAvatarMultiLine.propTypes = {
  classes: PropTypes.object,

  firstLine: PropTypes.string.isRequired,
  thirdLine: PropTypes.string,
  secondLine: PropTypes.string,

  driverPhoto: PropTypes.string,

  vehicleType: PropTypes.string,

  open: PropTypes.bool,
  inactive: PropTypes.bool,

  isHistory: PropTypes.bool,
};

function DriverAvatarMultiLine(props) {
  const { classes } = props;
  const iconColor = props.open
    ? "#fff"
    : props.inactive
    ? "#2121218a"
    : "#212121";

  return (
    <FlexBox gutter={16} flex={true}>
      <FlexBox align="center" className={classes.avatarContainer}>
        {Boolean(props.inactive && !props.open) && (
          <div className={classes.avatarOverlay} />
        )}

        <UserAvatar src={props.driverPhoto} name={props.firstLine} />
      </FlexBox>

      <FlexBox flex={true}>
        <FlexBox flex={true} justify="space-around" direction="column">
          <FlexBox
            align="center"
            className={cx(classes.line, {
              [classes.historyLine]: props.isHistory,
            })}
          >
            <FlexBox
              className={cx(classes.firstLine, {
                [classes.lineInactive]: props.inactive && !props.open,
                [classes.firstLineOpen]: props.open,
              })}
            >
              <span title={props.firstLine}>{props.firstLine}</span>
            </FlexBox>

            {props.vehicleType && (
              <FlexBox flex="none">
                {props.vehicleType === BIKE ? (
                  <Motorcycle color={iconColor} className={classes.cars} />
                ) : props.vehicleType === SEDAN ? (
                  <DirectionsCar color={iconColor} className={classes.cars} />
                ) : (
                  <LocalShipping color={iconColor} className={classes.cars} />
                )}
              </FlexBox>
            )}
          </FlexBox>

          {props.secondLine && (
            <FlexBox
              className={cx(classes.line, {
                [classes.lineInactive]: props.inactive && !props.open,
                [classes.lineOpen]: props.open,
                [classes.historyLine]: props.isHistory,
              })}
            >
              {props.secondLine}
            </FlexBox>
          )}

          {props.thirdLine && (
            <FlexBox
              className={cx(classes.line, {
                [classes.lineInactive]: props.inactive && !props.open,
                [classes.lineOpen]: props.open,
                [classes.historyLine]: props.isHistory,
              })}
            >
              {props.thirdLine}
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(DriverAvatarMultiLine);
